/**
 * クラス名：形鋼の共通ファイル
 * 説明：形鋼に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/10/30
 * バージョン：1.0
 */

import { Area_IQ5ManufactSizeKind, Bend_IQ5ManufactSizeKind, Cut_IQ5ManufactSizeKind, Debbur_IQ5ManufactSizeKind, Groove_IQ5ManufactSizeKind, Hole_IQ5ManufactSizeKind, Mount_IQ5ManufactSizeKind, Notch_IQ5ManufactSizeKind, Weight_IQ5ManufactSizeKind, WeldFinish_IQ5ManufactSizeKind } from "./Constant";
import { WorkType, EstimateCreationType } from "./enums";
import dayjs from 'dayjs';
import { unitLst } from '../common/Constant';
import React, { useState } from 'react';

// 材質区分情報に変更があったかチェックする
export const compareIQ5Info = (beforeObj, afterObj) => {
  let isDiffInfo = false;
  if (beforeObj) {
    Object.keys(beforeObj).forEach((key, index, obj) => {
      if (JSON.stringify(beforeObj?.[key]) !== JSON.stringify(afterObj?.[key])) {
        isDiffInfo = true;
        obj.length = index + 1;
      }
    });
  }
  return isDiffInfo;
};

// 面断の面積計算方
export const getSectionalarea = (shapeNo, steelMaterial) => {
  let steelMaterialA = steelMaterial?.a ? Number(steelMaterial?.a) : 0;
  let steelMaterialB = steelMaterial?.b ? Number(steelMaterial?.b) : 0;
  let steelMaterialT1 = steelMaterial?.t1 ? Number(steelMaterial?.t1) : 0;
  let steelMaterialT2 = steelMaterial?.t2 ? Number(steelMaterial?.t2) : 0;
  if (shapeNo == 1) return steelMaterialA * steelMaterialT1;
  if (shapeNo == 2) return steelMaterialT1 * (2 * steelMaterialA - steelMaterialT1);
  if (shapeNo == 3) return steelMaterialT1 * (steelMaterialA + 2 * steelMaterialB - 2 * steelMaterialT1);
  if (shapeNo == 13 /*4*/)
    return 2 * steelMaterialT2 * (steelMaterialB - steelMaterialT1) + steelMaterialA * steelMaterialT1;
  if (shapeNo == 9 /*5*/)
    return 2 * steelMaterialT1 * (steelMaterialB - steelMaterialT1) + steelMaterialA * steelMaterialT1;
  if (shapeNo == 10 /*6*/)
    return steelMaterialT1 * (steelMaterialA + 2 * steelMaterialB + 2 * steelMaterial?.c - 4 * steelMaterialT1);
  if (shapeNo == 11 /*7*/)
    return 2 * steelMaterialT2 * (steelMaterialB - steelMaterialT1) + steelMaterialA * steelMaterialT1;
  if (shapeNo == 12 /*8*/)
    return steelMaterialB * steelMaterialT2 + (steelMaterialA - steelMaterialT2) * steelMaterialT1;
  if (shapeNo == 14 /*9*/) return steelMaterialT1 * (steelMaterialA + steelMaterialB - steelMaterialT1);
  if (shapeNo == 4 /*10*/) return 4 * steelMaterialT1 * (steelMaterialA - steelMaterialT1);
  if (shapeNo == 5 /*11*/) return 2 * steelMaterialT1 * (steelMaterialA + steelMaterialB - 2 * steelMaterialT1);
  if (shapeNo == 6 /*12*/ || shapeNo == 17 /*13*/ || shapeNo == 18 /*14*/)
    return steelMaterialT1 * Math.PI * (steelMaterialA - steelMaterialT1);
  if (shapeNo == 7 /*15*/) return (Math.PI * steelMaterialA * steelMaterialA) / 4;
  if (shapeNo == 8 /*16*/) return steelMaterialA * steelMaterialA;
  if (shapeNo == 15 /*17*/) return (Math.sqrt(3) / 2) * steelMaterialA * steelMaterialA;
  if (shapeNo == 16 /*18*/) {
    return Math.PI * steelMaterialA * steelMaterialA * 0.25;
  }
  return 0;
};

export const getPerimeter = (shapeNo, steelMaterial, innerDiameterIncluded = false) => {
  let steelMaterialA = steelMaterial?.a ? Number(steelMaterial?.a) : 0;
  let steelMaterialB = steelMaterial?.b ? Number(steelMaterial?.b) : 0;
  let steelMaterialT1 = steelMaterial?.t1 ? Number(steelMaterial?.t1) : 0;
  let steelMaterialT2 = steelMaterial?.t2 ? Number(steelMaterial?.t2) : 0;
  if (shapeNo == 1) return 2 * (steelMaterialA + steelMaterialT1);
  if (shapeNo == 2) return 4 * steelMaterialA;
  if (shapeNo == 3 || shapeNo == 13 /*4*/ || shapeNo == 9 /*5*/ || shapeNo == 11 /*7*/)
    return 4 * steelMaterialB + 2 * steelMaterialA - 2 * steelMaterialT1;
  if (shapeNo == 10 /*6*/) return 4 * steelMaterialB + 2 * steelMaterialA + 4 * steelMaterial?.c - 6 * steelMaterialT1;
  if (shapeNo == 12 /*8*/ || shapeNo == 14 /*9*/) return 2 * (steelMaterialA + steelMaterialB);
  if (shapeNo == 4 /*10*/) {
    if (innerDiameterIncluded) return 8 * (steelMaterialA - steelMaterialT1); // システム設定の内径含むフラグ追加
    else return 4 * steelMaterialA;
  }
  if (shapeNo == 5 /*11*/) {
    if (innerDiameterIncluded) return 4 * (steelMaterialA + steelMaterialB - 2 * steelMaterialT1);
    else return 2 * (steelMaterialA + steelMaterialB);
  }
  if (shapeNo == 6 /*12*/ || shapeNo == 17 /*13*/ || shapeNo == 18 /*14*/) {
    if (innerDiameterIncluded) return 2 * Math.PI * (steelMaterialA - steelMaterialT1);
    else return Math.PI * steelMaterialA;
  }
  if (shapeNo == 7 /*15*/) return Math.PI * steelMaterialA;
  if (shapeNo == 8 /*16*/) return 4 * steelMaterialA;
  if (shapeNo == 15 /*17*/) return 2 * Math.sqrt(3) * steelMaterialA;
  if (shapeNo == 16 /*18*/) {
    return steelMaterialA * Math.PI;
  }
  return 0;
};

// 形鋼部品見積の初期値
export const iq5EstimateInitInfo = [
  {
    id: 0,
    created: dayjs().format(),
    modified: dayjs().format(),
    modifierId: 0,
    modifier: '',
    estimateProductId: 0,
    no: 0,
    creationType: EstimateCreationType.Auto,
    imageUrl: '',
    imageId: '',
    drawingNo: '',
    name: '',
    partCount: 1,
    size: 0, //寸法
    surfaceProtection: 0,
    weight: 0,
    totalSurfaceArea: 0,
    materialIq5Id: 0,
    materialName: '',
    materialTypeIq5Id: 0,
    materialTypeName: '',
    steelShapeId: 0,
    steelShapeName: '',
    materialInstruction: 1, // 材料指示
    materialSize: 0, // 材料寸法
    materialWeight: 0, // 材料重量
    standardLen: 0, // 定尺名称
    materialUnitType: 1, // 採用単価
    materialUnit: 0,
    thickness: 0,
    materialQuantity: 1,
    weightUnitPrice: 0,
    surfaceAddPrice: 0,
    weightAddPrice: 0,
    scrapPrice: 0,
    costUnitPrice: 0,
    costTotalPrice: 0,
    materialCostUnitPrice: 0,
    materialCostTotalRate: 0,
    materialCostTotalPrice: 0,
    workCostUnitPrice: 0,
    workCostTotalPrice: 0,
    purchaseUnitPrice: 0,
    purchaseTotalPrice: 0,
    surfaceUnitPrice: 0,
    surfaceTotalPrice: 0,
    createrId: 0,
    creater: '',
    processSelect: {
      id: 0,
      name: '',
      details: [],
      modified: dayjs().format(),
    },
    processInput: [],
    additions: [],
    purchases: [],
    calcParameters: {
      settings: {},
      parameters: {},
    },
    info: {
      isUsed: true,
      remarks: '',
      sortNum: 0,
    },
  },
];

// 材料費計算
export const calculateIQ5Material = (
  esIq5Info,
  materialSize,
  materialUnitType,
  materialUnit,
  materialQuantity,
  materialWeight,
  materialIq5Info,
  iq5_manufacturingCnt,
  qty
) => {
  let upEsIq5Info = esIq5Info;
  //材料費計算 (M単価の場合M単価×材料寸法/1000、Kg単価の場合Kg単価×材料重量)
  let iq5EstimateMaterial = 0;
  if (unitLst?.find((u) => u.key === materialUnitType)?.label === 'M単価') {
    // M単価の場合、単価×本数×材料寸法(m)
    iq5EstimateMaterial = (materialIq5Info?.info?.pricePerM * materialQuantity * materialSize) / 1000;
    materialUnit = materialIq5Info?.info?.pricePerM;
  } else if (unitLst?.find((u) => u.key === materialUnitType)?.label === 'Kg単価') {
    //Kg単価の場合、単価×本数×材料重量(kg)
    iq5EstimateMaterial = materialIq5Info?.info?.pricePerKg * materialQuantity * materialWeight;
    materialUnit = materialIq5Info?.info?.pricePerKg;
  } else if (unitLst?.find((u) => u.key === materialUnitType)?.label === '手動') {
    // 単価×本数
    iq5EstimateMaterial = materialUnit * materialQuantity;
  }
  // let iq5EstimateMaterialTotal = iq5EstimateMaterial ? iq5EstimateMaterial : 0;
  upEsIq5Info.materialCostTotalPrice = iq5EstimateMaterial;
  upEsIq5Info.materialCostUnitPrice = Number.isFinite(iq5EstimateMaterial / iq5_manufacturingCnt)
    ? iq5EstimateMaterial / iq5_manufacturingCnt
    : 0;
  upEsIq5Info.materialSize = materialSize; //材料寸法再設定
  upEsIq5Info.materialUnitType = materialUnitType; //採用単価タイプ
  upEsIq5Info.materialUnit = materialUnit; //採用した単価
  return upEsIq5Info;
};

// 鋼材区分の詳細情報を得る
export const getProcessingShapeCoeffNm = (id, steelShapeData) => {
  var coeffId = id ? parseInt(id) : 0;
  var steelShapeNmValue = '';
  var steelShapeNm = [];
  steelShapeNm = steelShapeData?.filter((item) => item.shapeNo === coeffId);
  steelShapeNmValue = steelShapeNm?.length > 0 ? steelShapeNm?.[0]?.name : '';
  return steelShapeNmValue;
};

// 鋼材区分の詳細情報を得る
export const getIQ5CoeffDetailValues = (id, coeffList) => {
  var detailValue = {};
  var coeffId = id ? parseInt(id) : 0;
  detailValue = Object.entries(coeffList)?.filter(([key, data]) => parseInt(key) === coeffId)?.[0];
  detailValue = detailValue?.length > 0 ? detailValue[1] : 0;
  return detailValue;
};

// 形鋼材質区分の詳細情報を得る
export const getMaterialTypeIq5Nm = (id, materialTypes) => {
  var typeId = id ? parseInt(id) : 0;
  var steelNmValue = '';
  var steelNm = [];
  steelNm = materialTypes?.filter((item) => item.id === typeId);
  steelNmValue = steelNm?.length > 0 ? steelNm?.[0]?.name : '';
  return steelNmValue;
};

// 鋼材区分の詳細情報を取得
export const getWeightDetailNm = (id, processDetailTypes) => {
  var weightDetailId = id ? parseInt(id) : 0;
  var weightDetailNmVal = '';
  var weightDetailNm = [];
  weightDetailNm = processDetailTypes?.filter((item) => item.id === weightDetailId);
  weightDetailNmVal = weightDetailNm?.length > 0 ? weightDetailNm?.[0]?.name : '';
  return weightDetailNmVal;
};

// 鋼材区分のIdを得る
export const getProcessingShapeCoeffShapeNo = (name, steelShapeData) => {
  var steelShapeNo = 0;
  var steelShapeNm = [];
  steelShapeNm = steelShapeData?.filter((item) => item.name === name);
  steelShapeNo = steelShapeNm?.length > 0 ? steelShapeNm?.[0]?.shapeNo : 0;
  steelShapeNo = steelShapeNo ? parseInt(steelShapeNo) : steelShapeNo;
  return steelShapeNo;
};

// 形鋼材質区分のを得る
export const getIQ5IdByName = (name, materialTypes) => {
  var materialTypeId = 0;
  var materialType = [];
  materialType = materialTypes?.filter((item) => item.name === name);
  materialTypeId = materialType?.length > 0 ? materialType?.[0]?.id : 0;
  materialTypeId = materialTypeId ? parseInt(materialTypeId) : materialTypeId;
  return materialTypeId;
};

// 各装置の合計計算
export const updateProcessAdditionsAndCalculateTotals = (item, upAdditionsDetails, deviceId, selectedIQ5DataDetail, selectedEstimateData) => {
  let iq5_manufacturingCnt =
  selectedIQ5DataDetail?.partCount * selectedEstimateData?.estimateProducts?.quantity;
      item.deviceAdditionsDetails = upAdditionsDetails;
      // 各装置の合計計算
      let totalDandoriTime = 0;
      let totalDandoriAmt = 0;
      let totalSagyoTime = 0;
      let totalSagyoAmt = 0;
      let totalDataTime = 0;
      let totalDataPrice = 0;
      let sagyoTimeTotalByType = 0;
      let sagyoAmtTotalByType = 0;
      if (deviceId > 0) {
        upAdditionsDetails
            ?.filter((f) => f.deviceId === deviceId)?.[0]
            ?.sagyoDetails?.map((sagyoDetail) => {
                sagyoTimeTotalByType += sagyoDetail.sagyoTime;
                sagyoAmtTotalByType += sagyoDetail.sagyoAmt;
            });
        
        //　各装置の加工時間合計計算
        upAdditionsDetails?.map((item) => {
          if (item.deviceId === deviceId) {
            item.sagyoTime = sagyoTimeTotalByType;
            item.sagyoAmt = sagyoAmtTotalByType;
          }
        });
      }
      upAdditionsDetails?.forEach((d) => {
        totalDandoriTime += d.dandoriTime;
        totalDandoriAmt += d.dandoriAmt;
        totalSagyoTime += d.sagyoTime;
        totalSagyoAmt += d.sagyoAmt;
        totalDataTime += d.dandoriTime + d.sagyoTime;
        totalDataPrice += d.dandoriAmt + d.sagyoAmt;
      });
      item.dataItems.totalDandoriTime = totalDandoriTime;
      item.dataItems.totalDandoriAmt = totalDandoriAmt;
      item.dataItems.totalSagyoTime = totalSagyoTime;
      item.dataItems.totalSagyoAmt = totalSagyoAmt;
      item.totalDataTime = totalDandoriTime + (totalSagyoTime * iq5_manufacturingCnt);
      item.totalDataPrice = totalDandoriAmt + (totalSagyoAmt * iq5_manufacturingCnt);
      item.editItems = item.dataItems;
      // if(item.editItems.totalDandoriAmt !== 0){
      //   item.editItems = item.editItems;
      // }else {
      //   item.editItems = item.dataItems;
      // }
      item.totalEditTime = item.totalDataTime;
      item.totalEditPrice = item.totalDataPrice;
    return item;
}

export const getCoeff = (coeffObj, detailTypeId) => {
  if (coeffObj == undefined) return 1;
  let coeff = 1;
  Object.entries(coeffObj)?.map(([key], index) => {
    if (parseInt(key) === parseInt(detailTypeId)) {
      coeff = coeffObj[key];
    }
  });
  coeff = coeff ? coeff : 1;
  return coeff;
};

export const getWeightMmCoeff = (masterObj, itemObj, weightMm) => {
  if (weightMm > 0) {
    if (masterObj == undefined && itemObj == undefined) return 1;
    let coeff = 1;
    let weightCoeffThMaster = masterObj?.filter(
      (item) => (item.value == weightMm || item.value > weightMm) && !item?.isOutOfRange
    );
    let weightCoeffThItems =
      weightCoeffThMaster?.length > 0 ? itemObj?.filter((item) => item.no == weightCoeffThMaster[0]?.no) : [itemObj[0]];
    coeff = weightCoeffThItems?.[0]?.value;
    coeff = coeff ? coeff : 1;
    return coeff;
  }
  return 0;
};

export const getProductionQuantityCoeff = (deviceMst, selectedIQ5DataDetail, selectedEstimateData) => {
  let productionQuantityCoeff = 1;
  let quantity = Number(selectedEstimateData?.estimateProducts?.quantity);
  let partCnt = Number(selectedIQ5DataDetail?.partCount); // 員数
  let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
  // 生産個数係数
  let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
    (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
  );
  if (productionCoeffTh) {
    let productionCoeffThNo =
      productionCoeffTh?.length > 0
        ? productionCoeffTh[0]?.no
        : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
    let productionCoeff =
      productionCoeffThNo == 0
        ? 0
        : deviceMst?.details?.productionQtyCoeffThItems?.filter(
            (item) => item.no == productionCoeffThNo && !item.isOutOfRange
          )[0]?.value;

    productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
  }
  return productionQuantityCoeff;
};

export const calAmt = (timeSec, charge) => {
  if (timeSec > 0) {
    //段階時間
    let mSetTimeMin = timeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = charge;
    //段階時間＊時間チャージ
    let amt = Math.round((mSetTimeMin * mSetHRate) / 60.0);
    amt = Number.isFinite(amt) ? amt : 0;
    return amt;
  }
  return 0;
};

// 切断段取と加工計算
export const calculateSteelCutAmt = (deviceProcessInputDetails, additionsDetails, selectedIQ5DataDetail, estimateParameters, selectedEstimateData, curDeviceLst, cutTimes, Cut_IQ5ManufactSizeKind) => {
  let upAdditionsDetails = JSON.parse(JSON.stringify(additionsDetails));
  let upDeviceProcessInputDetails = JSON.parse(JSON.stringify(deviceProcessInputDetails));
  let steelShapeId = selectedIQ5DataDetail?.steelShapeId;
  let steelShape = estimateParameters?.steelShape?.filter((item) => item.shapeNo === steelShapeId)?.[0];
  let materialTypeIq5 = estimateParameters?.materialTypeIq5?.filter(
    (item) => item.info.isUsed && !item.isDeleted && item.id === selectedIQ5DataDetail?.materialTypeIq5Id
  )?.[0];
  let materialTypeId = estimateParameters?.materialType?.filter(
    (item) => item.info.isUsed && !item.isDeleted && item.id === materialTypeIq5?.materialTypeId
  )?.[0]?.id;
  let materialIq5 = estimateParameters?.materialIq5?.filter(
    (item) => item.info.isUsed && !item.isDeleted && item.id === selectedIQ5DataDetail?.materialIq5Id
  )?.[0];

  upDeviceProcessInputDetails?.map((processInput) => {
    let curDevice = curDeviceLst?.filter((d) => d.id === processInput.deviceId);
    if (curDevice?.length > 0) {
      // 加工時間・加工金額計算
      let processingShapeCoeff = getCoeff(curDevice?.[0]?.details?.processingShapeCoeff, steelShapeId); //形状区分係数
      let machiningMaterialCoeff = getCoeff(curDevice?.[0]?.details?.machiningMaterialCoeff, materialTypeId); //材質区分係数
      let t1Coeff = 1;
      let aCoeff = 1;
      let bCoeff = 1;
      let cCoeff = 1;
      if (steelShape?.t1) {
        let t1mm = materialIq5?.info?.t1;
        t1Coeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.t1SizeCoeffThMaster,
          curDevice?.[0]?.details?.t1SizeCoeffThItems,
          t1mm
        ); //t1係数
      }
      if (steelShape?.a) {
        let amm = materialIq5?.info?.a;
        aCoeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.aSizeCoeffThMaster,
          curDevice?.[0]?.details?.aSizeCoeffThItems,
          amm
        ); //a係数
      }
      if (steelShape?.b) {
        let bmm = materialIq5?.info?.b;
        bCoeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.bSizeCoeffThMaster,
          curDevice?.[0]?.details?.bSizeCoeffThItems,
          bmm
        ); //b係数
      }
      if (steelShape?.c) {
        let cmm = materialIq5?.info?.c;
        cCoeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.cSizeCoeffThMaster,
          curDevice?.[0]?.details?.cSizeCoeffThItems,
          cmm
        ); //c係数
      }
      let productionQuantityCoeff = getProductionQuantityCoeff(curDevice?.[0], selectedIQ5DataDetail, selectedEstimateData); // 生産個数係数
      let sagyoTimeTotalByType = 0;
      let sagyoAmtTotalByType = 0;
      // 各種類のループ
      processInput.details?.map((detail, index) => {
        //回数が１以上の場合に計算を行う
        
        detail.count = cutTimes;
        detail.cutCount = cutTimes;
        
        if (detail.count > 0) {
          //加工の重量区分係数
          let processingWeightCoeff = 1;
            processingWeightCoeff = getWeightMmCoeff(
              curDevice?.[0]?.details?.processingWeightCoeffThMaster,
              curDevice?.[0]?.details?.processingWeightCoeffThItems,
              selectedIQ5DataDetail?.weight
            );
          
          // 1回の段取起動時間と金額計算
          if (index == 0) {
            let basicTime = curDevice?.[0]?.details?.prepBasicTime; //初期段取時間
            let setupShapeCoeff = getCoeff(curDevice?.[0]?.details?.setupShapeCoeff, steelShapeId); //形状区分係数
            let materialClassificationCoeff = getCoeff(
              curDevice?.[0]?.details?.materialClassificationCoeff,
              materialTypeId
            ); //材質区分係数
            let weightClassificationCoeff = getWeightMmCoeff(
              curDevice?.[0]?.details?.weightClassificationCoeffThMaster,
              curDevice?.[0]?.details?.weightClassificationCoeffThItems,
              selectedIQ5DataDetail?.weight
            ); //重量区分係数
            // 段取時間＝初期段取時間×形状区分係数×材質区分係数×重量区分係数）
            let dandoriTime = basicTime * setupShapeCoeff * materialClassificationCoeff * weightClassificationCoeff;
            // 段取金額＝段取時間×チャージ
            let prep = curDevice?.[0]?.details?.charges?.prep;
            let dandoriAmt = calAmt(dandoriTime, prep);
            upAdditionsDetails?.map((item) => {
              if (item.deviceId === processInput.deviceId) {
                item.dandoriTime = dandoriTime;
                item.dandoriAmt = dandoriAmt;
              }
            });
          }
          
          let kindNo = detail.kindNo;
          //  切断
          let cutLength = detail?.cutLength;
          
          //　装置の加工時間取得
          let basicSagyoTime = 0;

          let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]?.name;
          if (kindNm === Cut_IQ5ManufactSizeKind[0]) {
            const singleProcessTimes = curDevice?.[0]?.details?.singleProcessTimeSec || {};
            basicSagyoTime = singleProcessTimes || 0;
          } else {
            basicSagyoTime = getWeightMmCoeff(
              curDevice?.[0]?.details?.processingSpeedMinThMaster,
              curDevice?.[0]?.details?.processingSpeedMinThItems,
              cutLength
            ); 
            basicSagyoTime = (cutLength / basicSagyoTime) * 60;
          }
          

          basicSagyoTime = detail.count * basicSagyoTime;
          
          // 加工時間＝１回の加工時間×形状区分係数×材質区分係数×T1係数×A係数×B係数×C係数×重量係数×生産工数係数
          let sagyoTime =
            basicSagyoTime *
            processingShapeCoeff *
            machiningMaterialCoeff *
            t1Coeff *
            aCoeff *
            bCoeff *
            cCoeff *      
            processingWeightCoeff *
            productionQuantityCoeff;
          let work = curDevice?.[0]?.details?.charges?.work;
          // 加工金額＝加工時間×チャージ
          let sagyoAmt = calAmt(sagyoTime, work);
          upAdditionsDetails
            ?.filter((f) => f.deviceId === processInput.deviceId)?.[0]
            ?.sagyoDetails?.map((sagyoDetail) => {
              if (sagyoDetail.no === detail.no) {
                sagyoDetail.sagyoTime = sagyoTime;
                sagyoDetail.sagyoAmt = sagyoAmt;
                sagyoTimeTotalByType += sagyoTime;
                sagyoAmtTotalByType += sagyoAmt;
              }
            });
        }
      });
      //　各装置の加工時間合計計算
      upAdditionsDetails?.map((item) => {
        if (item.deviceId === processInput.deviceId) {
          item.sagyoTime = sagyoTimeTotalByType;
          item.sagyoAmt = sagyoAmtTotalByType;
        }
      });
    }
  });

  return {upAdditionsDetails, upDeviceProcessInputDetails};
};

export const calculateCommonMaterial = (
  bladeWidth, 
  disposal,
  count, 
  materialLength, 
  requiredMaterialLength, 
  requiredMaterial, 
  materialSize, 
  manualMaterialValue = 0
) => {
    // 材料寸法
    let material = 0;
    let cutTimes = 0;
    let originalMaterials = 0;
    if (Number(materialLength) === 0) {
      return {material, cutTimes, originalMaterials};
    }
    if (manualMaterialValue === 0) {
      //　材料寸法データがある場合
      if (materialSize !== undefined && materialSize > 0 && materialSize !== materialLength){
        material = Number(materialSize);
      } 
      //　材料寸法データがない場合
      else {
        material = Number(materialLength) + Number(disposal) + (Number(bladeWidth) * 2);
      }
    }
    //　材料寸法データを入力した場合 
    else {
      material = Number(manualMaterialValue);
    }

    // 使える材料寸法
    let effectiveLength = Number(material) - Number(disposal);

    // 刃で切断する材料ごとに必要な長さ
    let singleCutLength = Number(requiredMaterialLength) + Number(bladeWidth);

    // 1 つのオ材料寸法からの最大材料数
    let materialsPerOriginal = Math.floor(
      Number.isFinite(Number(effectiveLength) / Number(singleCutLength)) ? Number(effectiveLength) / Number(singleCutLength) : 0
    );

    // 必要な材料の数
    originalMaterials = Math.ceil(Number.isFinite(Number(requiredMaterial) / Number(materialsPerOriginal)) ? Number(requiredMaterial) / Number(materialsPerOriginal) : 0 );

    // カット数
    let totalCutTimes = Number(requiredMaterial) + Number(originalMaterials);
    cutTimes = 0;
    if (count === 0 || count === undefined) {
      cutTimes = Number(totalCutTimes);
    } else {
      cutTimes = Number(count);
    }
    
    return {material, cutTimes, originalMaterials};
};

// エリアを計算してmm2からcm2へ変更
export const calculateArea = (x, y) => {
  return x * y * 0.01;
};

export const calculateByProcess = (
  deviceProcessInputDetails, 
  additionsDetails, 
  selectedIQ5DataDetail, 
  estimateParameters, 
  selectedEstimateData, 
  curDeviceLst, 
  cutTimes, 
  workType,
) => {
  let iq5_manufacturingCnt = Number(selectedIQ5DataDetail?.partCount) * Number(selectedEstimateData?.estimateProducts?.quantity);
  let upAdditionsDetails = JSON.parse(JSON.stringify(additionsDetails));
  let upDeviceProcessInputDetails = JSON.parse(JSON.stringify(deviceProcessInputDetails));
  let steelShapeId = selectedIQ5DataDetail?.steelShapeId;
  let steelShape = estimateParameters?.steelShape?.filter((item) => item.shapeNo === steelShapeId)?.[0];
  let materialTypeIq5 = estimateParameters?.materialTypeIq5?.filter(
    (item) => item.info.isUsed && !item.isDeleted && item.id === selectedIQ5DataDetail?.materialTypeIq5Id
  )?.[0];
  let materialTypeId = estimateParameters?.materialType?.filter(
    (item) => item.info.isUsed && !item.isDeleted && item.id === materialTypeIq5?.materialTypeId
  )?.[0]?.id;
  let materialIq5 = estimateParameters?.materialIq5?.filter(
    (item) => item.info.isUsed && !item.isDeleted && item.id === selectedIQ5DataDetail?.materialIq5Id
  )?.[0];

  upDeviceProcessInputDetails?.map((processInput) => {
    let curDevice = curDeviceLst?.filter((d) => d.id === processInput.deviceId);
    if (curDevice?.length > 0) {
      // 加工時間・加工金額計算
      let processingShapeCoeff = getCoeff(curDevice?.[0]?.details?.processingShapeCoeff, steelShapeId); //形状区分係数
      let machiningMaterialCoeff = getCoeff(curDevice?.[0]?.details?.machiningMaterialCoeff, materialTypeId); //材質区分係数
      let t1Coeff = 1;
      let aCoeff = 1;
      let bCoeff = 1;
      let cCoeff = 1;
      if (steelShape?.t1) {
        let t1mm = materialIq5?.info?.t1;
        t1Coeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.t1SizeCoeffThMaster,
          curDevice?.[0]?.details?.t1SizeCoeffThItems,
          t1mm
        ); //t1係数
      }
      if (steelShape?.a) {
        let amm = materialIq5?.info?.a;
        aCoeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.aSizeCoeffThMaster,
          curDevice?.[0]?.details?.aSizeCoeffThItems,
          amm
        ); //a係数
      }
      if (steelShape?.b) {
        let bmm = materialIq5?.info?.b;
        bCoeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.bSizeCoeffThMaster,
          curDevice?.[0]?.details?.bSizeCoeffThItems,
          bmm
        ); //b係数
      }
      if (steelShape?.c) {
        let cmm = materialIq5?.info?.c;
        cCoeff = getWeightMmCoeff(
          curDevice?.[0]?.details?.cSizeCoeffThMaster,
          curDevice?.[0]?.details?.cSizeCoeffThItems,
          cmm
        ); //c係数
      }
      let productionQuantityCoeff = getProductionQuantityCoeff(curDevice?.[0], selectedIQ5DataDetail, selectedEstimateData); // 生産個数係数
      let sagyoTimeTotalByType = 0;
      let sagyoAmtTotalByType = 0;
      // 各種類のループ
      processInput.details?.map((detail, index) => {
        //回数が１以上の場合に計算を行う
        // if (props?.workType === WorkType.PaSteelCut && (detail.count === 0 || detail.count === undefined || detail.count !== cutTimes)) {
        if (workType === WorkType.PaSteelCut) {
          detail.count = cutTimes;
          detail.cutCount = cutTimes;
        }
        // if (detail.count > 0) {
          let weight = detail?.weight;
          //加工の重量区分係数
          let processingWeightCoeff = 1;
          if (
            workType === WorkType.PaSteelArea ||
            workType === WorkType.PaSteelWeldFinish ||
            workType === WorkType.PaSteelWeld ||
            workType === WorkType.PaSteelDebbur ||
            workType === WorkType.PaSteelGroove ||
            workType === WorkType.PaSteelMount ||
            workType === WorkType.PaSteelBend ||
            workType === WorkType.PaSteelNotch ||
            workType === WorkType.PaSteelHole || 
            workType === WorkType.PaSteelCut
          ) {
            processingWeightCoeff = getWeightMmCoeff(
              curDevice?.[0]?.details?.processingWeightCoeffThMaster,
              curDevice?.[0]?.details?.processingWeightCoeffThItems,
              selectedIQ5DataDetail?.weight
            );
          } else if (workType === WorkType.PaSteelWeight) {
            processingWeightCoeff = getWeightMmCoeff(
              curDevice?.[0]?.details?.processingWeightCoeffThMaster,
              curDevice?.[0]?.details?.processingWeightCoeffThItems,
              weight
            );
          }

          // 1回の段取起動時間と金額計算
          if (index == 0) {
            let dandoriTime = 0;
            if (iq5_manufacturingCnt > 0) {
              let basicTime = curDevice?.[0]?.details?.prepBasicTime; //初期段取時間
              let setupShapeCoeff = getCoeff(curDevice?.[0]?.details?.setupShapeCoeff, steelShapeId); //形状区分係数
              let materialClassificationCoeff = getCoeff(
                curDevice?.[0]?.details?.materialClassificationCoeff,
                materialTypeId
              ); //材質区分係数
              let weightClassificationCoeff = getWeightMmCoeff(
                curDevice?.[0]?.details?.weightClassificationCoeffThMaster,
                curDevice?.[0]?.details?.weightClassificationCoeffThItems,
                selectedIQ5DataDetail?.weight
              ); //重量区分係数
              // 段取時間＝初期段取時間×形状区分係数×材質区分係数×重量区分係数）
              dandoriTime = basicTime * setupShapeCoeff * materialClassificationCoeff * weightClassificationCoeff;
            }
            
            // 段取金額＝段取時間×チャージ
            let prep = curDevice?.[0]?.details?.charges?.prep;
            let dandoriAmt = calAmt(dandoriTime, prep);
            upAdditionsDetails?.map((item) => {
              if (item.deviceId === processInput.deviceId) {
                item.dandoriTime = dandoriTime;
                item.dandoriAmt = dandoriAmt;
              }
            });
          }

          let processDetailTypeNo = detail.processDetailTypeNo;
          let kindNo = detail.kindNo;
          // 溶接仕上・溶接・バリ取り・開先
          let weldLength = detail?.weldLength;
          // 取付
          let mountSize = detail?.mountSize;
          // 曲げ
          let bendLength = detail?.bendLength;
          let bendSize = detail?.bendSize;
          // 切欠
          let notchLength = detail?.notchLength;
          // 穴
          let holeLength = detail?.holeLength;
          // 穴のその他
          let xL = detail?.xL;
          let yL = detail?.yL;
          //  切断
          let cutLength = detail?.cutLength;

          let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.find((k) => k.no === kindNo)?.name;
          if (kindNm === Hole_IQ5ManufactSizeKind[0] || kindNm === Hole_IQ5ManufactSizeKind[3]) {
            holeLength = xL;
          } else if (kindNm === Hole_IQ5ManufactSizeKind[1] || kindNm === Hole_IQ5ManufactSizeKind[4]) {
            holeLength = yL;
          }
          // 表面積
          let area = detail?.area;
          let x = detail?.x;
          let y = detail?.y;

          if (workType === WorkType.PaSteelArea) {
            let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]
              ?.name;
            if (kindNm === Area_IQ5ManufactSizeKind[3]) {
              area = calculateArea(x, y);
            }
          }

          //　装置の加工時間取得
          let basicSagyoTime = 0;
            if (iq5_manufacturingCnt > 0) {
            if (workType === WorkType.PaSteelArea) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                area,
                curDevice,
                workType,
                Area_IQ5ManufactSizeKind,
                'areaProcessingTimeThMaster',
                'areaProcessingTimeThItems',
                '',
                ''
              );
            } else if (workType === WorkType.PaSteelWeight) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                weight,
                curDevice,
                workType,
                Weight_IQ5ManufactSizeKind,
                'kgProcessingTimeThMaster',
                'kgProcessingTimeThItems',
                '',
                ''
              );
            } else if (workType === WorkType.PaSteelWeldFinish || workType === WorkType.PaSteelWeld) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                weldLength,
                curDevice,
                workType,
                WeldFinish_IQ5ManufactSizeKind,
                'processingSpeedThMaster',
                'processingSpeedThItems',
                '',
                ''
              );

              let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]
                ?.name;
              let perimeter = materialIq5?.info?.perimeter;
              if (kindNm === WeldFinish_IQ5ManufactSizeKind[2]) {
                basicSagyoTime = (perimeter / basicSagyoTime) * 60;
              } else if (kindNm === WeldFinish_IQ5ManufactSizeKind[3]) {
                basicSagyoTime = (perimeter / 2 / basicSagyoTime) * 60;
              } else if (kindNm === WeldFinish_IQ5ManufactSizeKind[4] || kindNm === WeldFinish_IQ5ManufactSizeKind[5]) {
                basicSagyoTime = (weldLength / basicSagyoTime) * 60;
              }
            } else if (workType === WorkType.PaSteelDebbur || workType === WorkType.PaSteelGroove) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                weldLength,
                curDevice,
                workType,
                workType === WorkType.PaSteelDebbur ? Debbur_IQ5ManufactSizeKind : Groove_IQ5ManufactSizeKind,
                'processingSpeedThMaster',
                'processingSpeedThItems',
                '',
                ''
              );

              let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]
                ?.name;
              let perimeter = materialIq5?.info?.perimeter;
              if (kindNm === Debbur_IQ5ManufactSizeKind[1] || kindNm === Groove_IQ5ManufactSizeKind[1]) {
                basicSagyoTime = (perimeter / basicSagyoTime) * 60;
              } else if (kindNm === Debbur_IQ5ManufactSizeKind[2] || kindNm === Groove_IQ5ManufactSizeKind[2]) {
                basicSagyoTime = (perimeter / 2 / basicSagyoTime) * 60;
              } else if (
                kindNm === Debbur_IQ5ManufactSizeKind[3] ||
                kindNm === Debbur_IQ5ManufactSizeKind[4] ||
                kindNm === Groove_IQ5ManufactSizeKind[3] ||
                kindNm === Groove_IQ5ManufactSizeKind[4]
              ) {
                basicSagyoTime = (weldLength / basicSagyoTime) * 60;
              }
            } else if (workType === WorkType.PaSteelMount) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                mountSize,
                curDevice,
                workType,
                Mount_IQ5ManufactSizeKind,
                'sizeProcessingTimeThMaster',
                'sizeProcessingTimeThItems',
                'processingSpeedThMaster',
                'processingSpeedThItems'
              );

              let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]
                ?.name;
              if (kindNm === Mount_IQ5ManufactSizeKind[1]) {
                basicSagyoTime = (mountSize / basicSagyoTime) * 60;
              }
            } else if (workType === WorkType.PaSteelBend) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                bendLength,
                curDevice,
                workType,
                Bend_IQ5ManufactSizeKind,
                'processingSpeedThMaster',
                'processingSpeedThItems',
                '',
                ''
              );
              let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]
                ?.name;
              let perimeter = materialIq5?.info?.perimeter;
              if (kindNm === Bend_IQ5ManufactSizeKind[1]) {
                basicSagyoTime = perimeter / basicSagyoTime;
              } else if (kindNm === Bend_IQ5ManufactSizeKind[2]) {
                basicSagyoTime = bendLength / basicSagyoTime;
              }
              let rCoeff = 1;
              rCoeff = getWeightMmCoeff(
                curDevice?.[0]?.details?.rSizeCoeffThMaster,
                curDevice?.[0]?.details?.rSizeCoeffThItems,
                bendSize
              ); //
              basicSagyoTime *= rCoeff; // for Rsize
            } else if (workType === WorkType.PaSteelNotch) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                notchLength,
                curDevice,
                workType,
                Notch_IQ5ManufactSizeKind,
                'processingSpeedThMaster',
                'processingSpeedThItems',
                '',
                ''
              );

              let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]
                ?.name;

              if (kindNm !== Notch_IQ5ManufactSizeKind[0]) {
                basicSagyoTime = (notchLength / basicSagyoTime) * 60;
              }
            } else if (workType === WorkType.PaSteelHole && holeLength > 0) {
              basicSagyoTime = getSagyoTimeCommon(
                kindNo,
                processDetailTypeNo,
                holeLength,
                curDevice,
                workType,
                Hole_IQ5ManufactSizeKind,
                'sizeProcessingTimeThMaster',
                'sizeProcessingTimeThItems',
                'processingSpeedThMaster',
                'processingSpeedThItems'
              );
            } else if (workType === WorkType.PaSteelCut) {
              let kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]?.name;
              if (kindNm === Cut_IQ5ManufactSizeKind[0]) {
                const singleProcessTimes = curDevice?.[0]?.details?.singleProcessTimeSec || {};
                basicSagyoTime = singleProcessTimes || 0;
              } else {
                basicSagyoTime = getWeightMmCoeff(
                  curDevice?.[0]?.details?.processingSpeedMinThMaster,
                  curDevice?.[0]?.details?.processingSpeedMinThItems,
                  cutLength
                ); 
                basicSagyoTime = (cutLength / basicSagyoTime) * 60;
              }
            }
          }

          basicSagyoTime = detail.count * basicSagyoTime;

          // 加工時間＝１回の加工時間×形状区分係数×材質区分係数×T1係数×A係数×B係数×C係数×重量係数×生産工数係数
          let sagyoTime =
            basicSagyoTime *
            processingShapeCoeff *
            machiningMaterialCoeff *
            t1Coeff *
            aCoeff *
            bCoeff *
            cCoeff *
            processingWeightCoeff *
            productionQuantityCoeff;
          let work = curDevice?.[0]?.details?.charges?.work;
          // 加工金額＝加工時間×チャージ
          let sagyoAmt = calAmt(sagyoTime, work);
          upAdditionsDetails
            ?.filter((f) => f.deviceId === processInput.deviceId)?.[0]
            ?.sagyoDetails?.map((sagyoDetail) => {
              if (sagyoDetail.no === detail.no) {
                if (workType === WorkType.PaSteelCut) {
                  sagyoDetail.sagyoTime = sagyoTime / iq5_manufacturingCnt;
                  sagyoDetail.sagyoAmt = sagyoAmt / iq5_manufacturingCnt;
                  sagyoTimeTotalByType += sagyoTime / iq5_manufacturingCnt;
                  sagyoAmtTotalByType += sagyoAmt / iq5_manufacturingCnt;
                } else {
                  sagyoDetail.sagyoTime = sagyoTime;
                sagyoDetail.sagyoAmt = sagyoAmt;
                sagyoTimeTotalByType += sagyoTime;
                sagyoAmtTotalByType += sagyoAmt;
                }
              }
            });
        // }
      });
      //　各装置の加工時間合計計算
      upAdditionsDetails?.map((item) => {
        if (item.deviceId === processInput.deviceId) {
          item.sagyoTime = sagyoTimeTotalByType;
          item.sagyoAmt = sagyoAmtTotalByType;
        }
      });
    }
  });

  return {upAdditionsDetails, upDeviceProcessInputDetails};
};

// 時間計算
const getSagyoTimeCommon = (
  kindNo,
  processDetailTypeNo,
  inputValue,
  curDevice,
  workType,
  kindConstants,
  processingTimeMastersKey,
  processingTimeItemsKey,
  processingSpeedMastersKey,
  processingSpeedItemsKey,
  
) => {
  let sagyoTime = 0;
  let adjustedInputValue = inputValue;

  const kindNm = curDevice?.[0]?.details?.iQ5ManufactSizeInfo?.kinds?.find((k) => k.no === kindNo)?.name;

  if (workType === WorkType.PaSteelHole) {
    if (
      kindNm === Hole_IQ5ManufactSizeKind[0] ||
      kindNm === Hole_IQ5ManufactSizeKind[1] ||
      kindNm === Hole_IQ5ManufactSizeKind[2]
    ) {
      let holeProcessingTimeThMaster = curDevice?.[0]?.details?.[processingTimeMastersKey]?.filter(
        (item) => (item.value == adjustedInputValue || item.value > adjustedInputValue) && !item.isOutOfRange
      )?.[0];
      let holeProcessingTimeItems = [];
      if (curDevice?.[0]?.details?.[processingTimeItemsKey]) {
        holeProcessingTimeItems = Object.entries(curDevice?.[0]?.details?.[processingTimeItemsKey])?.filter(
          ([key, data]) => parseInt(key) === processDetailTypeNo
        )?.[0];
      }
      holeProcessingTimeItems?.map((item, index) => {
        if (index > 0) {
          sagyoTime = item?.filter(
            (item) => parseInt(item.no) == holeProcessingTimeThMaster?.no && !item.isOutOfRange
          )?.[0]?.value;
        }
      });
    } else {
      let holeProcessingSpeedThMaster = curDevice?.[0]?.details?.[processingSpeedMastersKey]?.filter(
        (item) => (item.value == adjustedInputValue || item.value > adjustedInputValue) && !item.isOutOfRange
      )?.[0];
      let holeProcessingSpeedItems = [];
      if (curDevice?.[0]?.details?.[processingSpeedItemsKey]) {
        holeProcessingSpeedItems = Object.entries(curDevice?.[0]?.details?.[processingSpeedItemsKey])?.filter(
          ([key, data]) => parseInt(key) === processDetailTypeNo
        )?.[0];
      }
      holeProcessingSpeedItems?.map((item, index) => {
        if (index > 0) {
          sagyoTime = item?.filter(
            (item) => parseInt(item.no) == holeProcessingSpeedThMaster?.no && !item.isOutOfRange
          )?.[0]?.value;
        }
      });
      sagyoTime = (inputValue / sagyoTime) * 60;
    }
    return sagyoTime;
  }

  if (workType === WorkType.PaSteelWeldFinish || workType === WorkType.PaSteelWeld) {
    if (kindNm === kindConstants[1]) {
      const tempInstProcessTimes = curDevice?.[0]?.details?.tempInstProcessTimeSec || {};
      sagyoTime = tempInstProcessTimes[processDetailTypeNo] || 0;
      return sagyoTime;
    } else if (kindNm === kindConstants[3]) {
      // 部品溶接仕上(1/2)、 部品溶接(1/2)
      adjustedInputValue /= 2;
    }
  } else if (workType === WorkType.PaSteelMount) {
    if (kindNm === kindConstants[1]) {
      let mountProcessingSpeedThMaster = curDevice?.[0]?.details?.[processingSpeedMastersKey]?.filter(
        (item) => (item.value == adjustedInputValue || item.value > adjustedInputValue) && !item.isOutOfRange
      )?.[0];
      let mountProcessingSpeedItems = [];
      if (curDevice?.[0]?.details?.[processingSpeedItemsKey]) {
        mountProcessingSpeedItems = Object.entries(curDevice?.[0]?.details?.[processingSpeedItemsKey])?.filter(
          ([key, data]) => parseInt(key) === processDetailTypeNo
        )?.[0];
      }
      mountProcessingSpeedItems?.map((item, index) => {
        if (index > 0) {
          sagyoTime = item?.filter(
            (item) => parseInt(item.no) == mountProcessingSpeedThMaster?.no && !item.isOutOfRange
          )?.[0]?.value;
        }
      });
    } else {
      let mountProcessingTimeThMaster = curDevice?.[0]?.details?.[processingTimeMastersKey]?.filter(
        (item) => (item.value == adjustedInputValue || item.value > adjustedInputValue) && !item.isOutOfRange
      )?.[0];
      let mountProcessingTimeItems = [];
      if (curDevice?.[0]?.details?.[processingTimeItemsKey]) {
        mountProcessingTimeItems = Object.entries(curDevice?.[0]?.details?.[processingTimeItemsKey])?.filter(
          ([key, data]) => parseInt(key) === processDetailTypeNo
        )?.[0];
      }
      mountProcessingTimeItems?.map((item, index) => {
        if (index > 0) {
          sagyoTime = item?.filter(
            (item) => parseInt(item.no) == mountProcessingTimeThMaster?.no && !item.isOutOfRange
          )?.[0]?.value;
        }
      });
    }
    return sagyoTime;
  } else if (workType === WorkType.PaSteelBend) {
    if (kindNm !== kindConstants[0]) {
      if (adjustedInputValue > 0) {
        let bendProcessingTimeThMaster = curDevice?.[0]?.details?.[processingTimeMastersKey]?.filter(
          (item) => (item.value == adjustedInputValue || item.value > adjustedInputValue) && !item.isOutOfRange
        )?.[0];
        let bendProcessingTimeItems = [];
        if (curDevice?.[0]?.details?.[processingTimeItemsKey]) {
          bendProcessingTimeItems = Object.entries(curDevice?.[0]?.details?.[processingTimeItemsKey])?.filter(
            ([key, data]) => parseInt(key) === processDetailTypeNo
          )?.[0];
        }
        bendProcessingTimeItems?.map((item, index) => {
          if (index > 0) {
            sagyoTime = item?.filter(
              (item) => parseInt(item.no) == bendProcessingTimeThMaster?.no && !item.isOutOfRange
            )?.[0]?.value;
          }
        });
        return sagyoTime;
      }
      return sagyoTime;
    }
  } else if (workType === WorkType.PaSteelNotch) {
    if (kindNm !== kindConstants[0]) {
      if (adjustedInputValue > 0) {
        let notchProcessingTimeThMaster = curDevice?.[0]?.details?.[processingTimeMastersKey]?.filter(
          (item) => (item.value == adjustedInputValue || item.value > adjustedInputValue) && !item.isOutOfRange
        )?.[0];
        let notchProcessingTimeItems = [];
        if (curDevice?.[0]?.details?.[processingTimeItemsKey]) {
          notchProcessingTimeItems = Object.entries(curDevice?.[0]?.details?.[processingTimeItemsKey])?.filter(
            ([key, data]) => parseInt(key) === processDetailTypeNo
          )?.[0];
        }
        notchProcessingTimeItems?.map((item, index) => {
          if (index > 0) {
            sagyoTime = item?.filter(
              (item) => parseInt(item.no) == notchProcessingTimeThMaster?.no && !item.isOutOfRange
            )?.[0]?.value;
          }
        });
        return sagyoTime;
      }
      return sagyoTime;
    }
  }

  if (kindNm === kindConstants[0]) {
    //  面数、 回数
    const singleProcessTimes = curDevice?.[0]?.details?.singleProcessTimeSec || {};
    sagyoTime = singleProcessTimes[processDetailTypeNo] || 0;
    return sagyoTime;
  } else if (kindNm === kindConstants[2]) {
    // 部品表面積(1/2)、 部品重量(1/2)
    adjustedInputValue /= 2;
  }

  if (adjustedInputValue > 0) {
    let areaProcessingTimeThMaster = curDevice?.[0]?.details?.[processingTimeMastersKey]?.filter(
      (item) => (item.value == adjustedInputValue || item.value > adjustedInputValue) && !item.isOutOfRange
    )?.[0];
    let areaProcessingTimeItems = [];
    if (curDevice?.[0]?.details?.[processingTimeItemsKey]) {
      areaProcessingTimeItems = Object.entries(curDevice?.[0]?.details?.[processingTimeItemsKey])?.filter(
        ([key, data]) => parseInt(key) === processDetailTypeNo
      )?.[0];
    }
    areaProcessingTimeItems?.map((item, index) => {
      if (index > 0) {
        sagyoTime = item?.filter(
          (item) => parseInt(item.no) == areaProcessingTimeThMaster?.no && !item.isOutOfRange
        )?.[0]?.value;
      }
    });
  }

  return sagyoTime;
};

export const calculateWeightArea = (materialIq5Info, size) => {
  // 重量＝単位重量×寸法/1000
  let weightVal = (materialIq5Info?.info?.kgPerM * size) / 1000;
  // 面積＝周長×寸法/100
  let areaVal = (materialIq5Info?.info?.perimeter * size) / 100;
  return {weightVal, areaVal};
}

export const TimeInput = ({ value, onChange, onBlur, style, disabled }) => {
  const [time, setTime] = useState(value || "00:00:00");

  React.useEffect(() => {
    setTime(value || "00:00:00");
  }, [value]);

  // 入力時間のフォーマットチェック
  const formatTime = (value) => {
    const timeParts = value.split(":").map((part) => part.trim());
    // 3つの部分があることを確認します（HH、MM、SS）
    while (timeParts.length < 3) {
      timeParts.push("0");
    }
    // 各部分を数値に制限し、フォーマットされた文字列として戻ります
    return timeParts
      .map((part) => {
        return part.replace(/[^0-9]/g, ""); // 数字以外の文字を削除します
      })
      .join(":");
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;
    // 多数の柔軟性を可能にします
    inputValue = formatTime(inputValue);
    setTime(inputValue);
    if (onChange) {
      onChange(inputValue); // IQ5EstimateDetail.jsのTimeInputのonChangeを呼び出します
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(time);
    }
  };

  return (
    <input
      type="text"
      value={time}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="00:00:00"
      // style={{ padding: 3 }}
      className={'detail-tbl-val-title-iq5-input detail-iq5-tbl-val-title-label'}
      style={style}
      disabled={disabled} 
    />
  );
};
