/**
 * クラス名：ヘッダー画面
 * 説明：システムのヘッダー画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import {
  Card,
  Image,
  Space,
  Tooltip,
  Typography,
  Row,
  Col,
  Button,
  Checkbox,
  Select,
  Input,
  Radio,
  DatePicker,
} from 'antd';
import React, { forwardRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import logo from '../assets/images/logo.ico';
import user from '../assets/images/user.png';
import company from '../assets/images/company.png';
import usersetting from '../assets/images/usersetting.png';
import {
  createPartnerCompanySetting,
  getMaterialTypeInfo,
  getPartnerCompanySetting,
  getProcessPatternInfo,
  logOutSubmit,
  updatePartnerCompanySetting,
} from '../views/common/CommonAPI';
import logout from '../assets/images/logout.png';
import {
  CaretDownFilled,
  DownCircleFilled,
  MinusCircleFilled,
  MinusCircleOutlined,
  MinusOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { commonFooter, commonFooterOK, commonModal, commonResponsiveModal } from '../views/common/CommonModal';
import {
  DeliveryArea,
  DiffList,
  diffList,
  InstallationParts,
  MaxBendingLen,
  Painting,
  Plating,
  ProcessDateRange,
  TapForm,
  UsedPlace,
  WeldFinish,
  WeldPartCount,
} from '../views/common/RealGoku_Order_Setting';
import { ServiceClass } from '../views/common/enums';
const RadioGroup = Radio.Group;
const Header = (props, ref) => {
  const [loginUser, setLoginUser] = useState();
  const [openRealGokuSetting, setOpenRealGokuSetting] = useState(false);
  const [materialClassData, setMaterialClassData] = useState([]);
  const [oneOrderInfo, setOneOrderInfo] = useState({});
  const [repeatOrderInfo, setRepeatOrderInfo] = useState({});
  const [processPatternList, setProcessPatternList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoginUser(JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser'))));
  }, []);

  const showSetting = () => {
    navigate('/home/:userSetting');
  };

  const prepareDefaultChkInfo = (list, patternLst = []) => {
    const data = [];
    list?.map((item, index) => {
      if (patternLst?.length > 0) {
        // 利用箇所用
        data.push({
          no: index + 1,
          id: item.id,
          name: item.name,
          patternId: patternLst[0]?.id,
          isUsed: false,
        });
      } else {
        data.push({
          no: index + 1,
          id: item.id,
          name: item.name,
          isUsed: false,
        });
      }
    });
    return data;
  };

  const prepareDefaultChkInfoHyomen = (list) => {
    const data = [];
    list?.map((item, index) => {
      data.push({
        no: index + 1,
        id: item.id,
        name: item.name,
        isUsed: false,
        supportFrom: dayjs().format(),
        supportTo: dayjs().format(),
      });
    });
    return data;
  };

  //　ログアウトボタンの押下
  const handleClick = async (e) => {
    e.preventDefault();
    const result = await logOutSubmit();
    if (result) {
      navigate('/login');
    }
  };

  const prepareDefaultSetting = (materialData, patternLst) => {
    return {
      isSupport: false,
      orderFlg: false,
      quantityFrom: 0,
      quantityTo: 0,
      materialTypeList: [
        {
          no: 1,
          materialTypeId: materialData?.length > 0 ? materialData[0]?.id : 0,
          thicknessFrom: 0,
          thicknessTo: 0,
        },
      ],
      diff: prepareDefaultChkInfo(DiffList),
      weldFinish: prepareDefaultChkInfoHyomen(WeldFinish),
      usedPlace: prepareDefaultChkInfo(UsedPlace, patternLst),
      deliveryDateFrom: dayjs().format(),
      deliveryDateTo: dayjs().format(),
      weldPartCount: prepareDefaultChkInfo(WeldPartCount),
      installationParts: prepareDefaultChkInfo(InstallationParts),
      tapForm: prepareDefaultChkInfo(TapForm),
      maxBendingLen: prepareDefaultChkInfo(MaxBendingLen),
      maxX: 0,
      maxY: 0,
      maxZ: 0,
      deliveryArea: prepareDefaultChkInfo(DeliveryArea),
      platingProcess: prepareDefaultChkInfoHyomen(Plating),
      paintingProcess: prepareDefaultChkInfoHyomen(Painting),
    };
  };

  const openRealGokuSettingDialog = async (e) => {
    setOpenRealGokuSetting(true);
    const materialTypeData = await getMaterialTypeInfo();
    setMaterialClassData(materialTypeData);
    let patternInfos = await getProcessPatternInfo(0, 0, ServiceClass.SheetMetal, 'ASC', 'id');
    let patternLst = [];
    if (patternInfos) {
      patternLst = patternInfos ? patternInfos?.data : [];
      setProcessPatternList(patternLst);
    }
    let partnerCompSetting = await getPartnerCompanySetting();
    if (partnerCompSetting && Object.keys(partnerCompSetting)?.length > 0) {
      let firstOrder = partnerCompSetting?.firstOrder;
      let repeatOrder = partnerCompSetting?.repeatOrder;
      setOneOrderInfo(Object.keys(firstOrder)?.length > 0 ? JSON.parse(JSON.stringify(firstOrder)) : {});
      setRepeatOrderInfo(Object.keys(repeatOrder)?.length > 0 ? JSON.parse(JSON.stringify(repeatOrder)) : {});
    } else {
      let prepareDefaultSettingVal = prepareDefaultSetting(materialTypeData, patternLst);
      setOneOrderInfo(
        Object.keys(prepareDefaultSettingVal)?.length > 0 ? JSON.parse(JSON.stringify(prepareDefaultSettingVal)) : {}
      );
      setRepeatOrderInfo(
        Object.keys(prepareDefaultSettingVal)?.length > 0 ? JSON.parse(JSON.stringify(prepareDefaultSettingVal)) : {}
      );
    }
  };

  const settingOk = async (e) => {
    let upOneOrderInfo = oneOrderInfo;
    let upRepeatOrderInfo = repeatOrderInfo;
    let addPartnerCompSetting = await updatePartnerCompanySetting(upOneOrderInfo, upRepeatOrderInfo);
    if (addPartnerCompSetting) {
      setOpenRealGokuSetting(false);
    }
  };

  const settingCancel = (e) => {
    setOpenRealGokuSetting(false);
  };

  // 'From'日付に'To'より後の日付を選べないように制限
  const disabledFromDate = (deliveryDateTo) => {
    return (deliveryDateFrom) => {
      if (!deliveryDateTo) {
        return false;
      }
      return deliveryDateFrom && deliveryDateFrom.isAfter(dayjs(deliveryDateTo, 'YYYY-MM-DD'), 'day');
    };
    //return dayjs(current, 'YYYY-MM-DD') && dayjs(current, 'YYYY-MM-DD').isAfter(dayjs(dateTo, 'YYYY-MM-DD'), 'day');
  };

  // 'To'日付の選択制限を行う関数
  const disabledToDate = (deliveryDateFrom) => {
    return (deliveryDateTo) => {
      // 'From'日付以降の日付を選べるようにする
      if (!deliveryDateFrom) {
        return false;
      }
      return deliveryDateTo && deliveryDateTo.isBefore(dayjs(deliveryDateFrom, 'YYYY-MM-DD'), 'day');
    };
  };

  const modalTitle = (
    <div
      style={{
        background: '#005fab',
        color: 'white',
        fontSize: 14,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        height: 36,
        marginLeft: -24,
        marginTop: -20,
        marginRight: -24,
      }}
    >
      <p style={{ marginLeft: 10, paddingTop: 15, paddingLeft: 18, color: 'white' }}>RealGoku受注設定</p>
    </div>
  );

  const commonSettingItem_oneOrder = (
    <div className="real-goku-setting-div-padding">
      <div className="real-goku-setting-div">
        {/** 受注分類 */}
        <Row>
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">受注分類</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox
              className="real-goku-setting-item-chk"
              key="unitOrder"
              type="checkbox"
              name={'unitOrder'}
              id={'unitOrder'}
              checked={oneOrderInfo.orderFlg}
              onChange={(e) => {
                setOneOrderInfo({
                  ...oneOrderInfo,
                  orderFlg: e.target.checked,
                });
              }}
            >
              単発
            </Checkbox>
          </Col>
        </Row>
        {/** 個数 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">個数</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            （
            <Input
              className="real-goku-setting-item-input"
              value={oneOrderInfo.quantityFrom}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setOneOrderInfo({
                    ...oneOrderInfo,
                    quantityFrom: e.target.value,
                  });
                }
              }}
            ></Input>
            ）個～（
            <Input
              className="real-goku-setting-item-input "
              value={oneOrderInfo.quantityTo}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setOneOrderInfo({
                    ...oneOrderInfo,
                    quantityTo: e.target.value,
                  });
                }
              }}
            ></Input>
            ）個
          </Col>
        </Row>
        {/** 材質・板厚 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">材質・板厚</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {oneOrderInfo?.materialTypeList?.map((materialType, index) => (
              <div>
                <Select
                  className="real-goku-material-type-select"
                  onChange={(e) => {
                    let upMaterialTypeList = [...oneOrderInfo?.materialTypeList];
                    upMaterialTypeList[index].materialTypeId = e;
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      materialTypeList: upMaterialTypeList,
                    });
                  }}
                  id={materialType.no}
                  name={'materialType' + materialType.no}
                  value={materialType.materialTypeId}
                  // defaultValue={materialClassData?.length > 0 ? materialClassData[0]?.id : 0}
                  suffixIcon={<CaretDownFilled style={{ color: '#000000', fontSize: 20 }} />}
                >
                  {materialClassData?.map((item, index) => (
                    <Select.Option key={index} id={'materialRef' + item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                <label className="real-goku-setting-item-label">（</label>
                <Input
                  className="real-goku-setting-item-input "
                  value={materialType.thicknessFrom}
                  onChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value)) {
                      let upMaterialTypeList = [...oneOrderInfo?.materialTypeList];
                      upMaterialTypeList[index].thicknessFrom = e.target.value;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        materialTypeList: upMaterialTypeList,
                      });
                    }
                  }}
                ></Input>
                <label className="real-goku-setting-item-label">）mm～（</label>
                <Input
                  className="real-goku-setting-item-input "
                  value={materialType.thicknessTo}
                  onChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value)) {
                      let upMaterialTypeList = [...oneOrderInfo?.materialTypeList];
                      upMaterialTypeList[index].thicknessTo = e.target.value;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        materialTypeList: upMaterialTypeList,
                      });
                    }
                  }}
                ></Input>
                <label className="real-goku-setting-item-label">）mm</label>
                <PlusCircleFilled
                  className="real-goku-plus-minus-icon"
                  style={{ marginLeft: 20, marginRight: 20 }}
                  onClick={(e) => {
                    let upMaterialTypeList = [...oneOrderInfo?.materialTypeList];
                    const maxNo =
                      oneOrderInfo?.materialTypeList?.length > 0
                        ? Math.max(...oneOrderInfo?.materialTypeList?.map((item) => item.no))
                        : 0;
                    upMaterialTypeList.push({
                      no: maxNo + 1,
                      materialTypeId: materialClassData?.length > 0 ? materialClassData[0]?.id : 0,
                      thicknessFrom: 0,
                      thicknessTo: 0,
                    });
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      materialTypeList: upMaterialTypeList,
                    });
                  }}
                />
                <MinusCircleFilled
                  className="real-goku-plus-minus-icon"
                  onClick={(e) => {
                    let upMaterialTypeList = [...oneOrderInfo?.materialTypeList];
                    if (upMaterialTypeList?.length > 1) {
                      upMaterialTypeList = upMaterialTypeList?.filter((item) => item.no !== materialType.no);
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        materialTypeList: upMaterialTypeList,
                      });
                    }
                  }}
                />
              </div>
            ))}
          </Col>
        </Row>
        {/** 公差 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">公差</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {DiffList?.map((diff, index) => (
              <Checkbox
                className="real-goku-setting-item-chk"
                key={'diff' + index}
                type="checkbox"
                name={'diff' + index}
                id={'diff' + index}
                checked={oneOrderInfo?.diff?.length > 0 ? oneOrderInfo?.diff?.[index]?.isUsed : false}
                onChange={(e) => {
                  let upDiff = [...oneOrderInfo.diff];
                  upDiff[index].isUsed = e.target.checked;
                  setOneOrderInfo({
                    ...oneOrderInfo,
                    diff: upDiff,
                  });
                }}
              >
                {diff.name}
              </Checkbox>
            ))}
          </Col>
        </Row>
        {/** 仕上 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">仕上</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {WeldFinish?.map((weldFinish, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk"
                  key={'weldFinish' + index}
                  type="checkbox"
                  name={'weldFinish' + index}
                  id={'weldFinish' + index}
                  checked={oneOrderInfo?.weldFinish?.length > 0 ? oneOrderInfo?.weldFinish?.[index]?.isUsed : false}
                  onChange={(e) => {
                    let upWeldFinish = [...oneOrderInfo.weldFinish];
                    upWeldFinish[index].isUsed = e.target.checked;
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      weldFinish: upWeldFinish,
                    });
                  }}
                >
                  {weldFinish.name}
                </Checkbox>
                {index > 1 ? <br /> : ''}
                <div
                  style={{
                    display:
                      (weldFinish.name === '酸洗い' &&
                        weldFinish.id === oneOrderInfo?.weldFinish?.[index]?.id &&
                        oneOrderInfo?.weldFinish?.[index]?.isUsed) ||
                      (weldFinish.name === 'HL' &&
                        weldFinish.id === oneOrderInfo?.weldFinish?.[index]?.id &&
                        oneOrderInfo?.weldFinish?.[index]?.isUsed) ||
                      (weldFinish.name === '鏡面' &&
                        weldFinish.id === oneOrderInfo?.weldFinish?.[index]?.id &&
                        oneOrderInfo?.weldFinish?.[index]?.isUsed)
                        ? ''
                        : 'none',
                  }}
                >
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 30, marginBottom: 5 }}
                    value={
                      oneOrderInfo?.weldFinish?.[index]?.supportFrom
                        ? dayjs(oneOrderInfo?.weldFinish?.[index]?.supportFrom, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledFromDate(oneOrderInfo?.weldFinish?.[index]?.supportTo)} // From日付の制限
                    onChange={(_, dateString) => {
                      let upWeldFinish = [...oneOrderInfo.weldFinish];
                      upWeldFinish[index].supportFrom = dateString;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        weldFinish: upWeldFinish,
                      });
                    }}
                  />
                  <label className="real-goku-setting-item-chk">～</label>
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 0, marginBottom: 5 }}
                    value={
                      oneOrderInfo?.weldFinish?.[index]?.supportTo
                        ? dayjs(oneOrderInfo?.weldFinish?.[index]?.supportTo, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledToDate(oneOrderInfo?.weldFinish?.[index]?.supportFrom)} // 選択可能日付を制限
                    onChange={(_, dateString) => {
                      let upWeldFinish = [...oneOrderInfo.weldFinish];
                      upWeldFinish[index].supportTo = dateString;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        weldFinish: upWeldFinish,
                      });
                    }}
                  />
                </div>
              </>
            ))}
          </Col>
        </Row>
        {/** 利用箇所 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">利用箇所</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {UsedPlace?.map((used, index) => (
              <Row>
                <Col span={6}>
                  <Checkbox
                    className="real-goku-setting-item-chk"
                    key={'usedPlace' + index}
                    type="checkbox"
                    name={'usedPlace' + index}
                    id={'usedPlace' + index}
                    checked={oneOrderInfo?.usedPlace?.length > 0 ? oneOrderInfo?.usedPlace?.[index]?.isUsed : false}
                    onChange={(e) => {
                      let upUsedPlace = [...oneOrderInfo.usedPlace];
                      upUsedPlace[index].isUsed = e.target.checked;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        usedPlace: upUsedPlace,
                      });
                    }}
                  >
                    {used.name}
                  </Checkbox>
                </Col>
                <Col span={7}>
                  <label>⇒引当工程パターン</label>
                </Col>
                <Col span={10}>
                  <Select
                    className="real-goku-material-type-select-pattern "
                    onChange={(e) => {
                      let upUsedPlace = [...oneOrderInfo.usedPlace];
                      upUsedPlace[index].patternId = e;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        usedPlace: upUsedPlace,
                      });
                    }}
                    id={'process-pattern'}
                    name={'process-pattern'}
                    value={oneOrderInfo?.usedPlace?.length > 0 ? oneOrderInfo?.usedPlace?.[index]?.patternId : 0}
                    suffixIcon={<CaretDownFilled style={{ color: '#000000', fontSize: 20 }} />}
                  >
                    {processPatternList?.map((item, index) => (
                      <Select.Option key={index} id={'materialRef' + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        {/** 対応納期 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">対応納期</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DatePicker
              locale="ja"
              style={{ marginTop: 3, marginLeft: 10 }}
              value={oneOrderInfo?.deliveryDateFrom ? dayjs(oneOrderInfo?.deliveryDateFrom, 'YYYY-MM-DD') : ''}
              placeholder="対応納期Fromを選択してください。"
              disabledDate={disabledFromDate(oneOrderInfo?.deliveryDateTo)} // From日付の制限
              onChange={(_, dateString) => {
                let upProcessDateRange = [...oneOrderInfo.deliveryDateFrom];
                upProcessDateRange = dateString;
                setOneOrderInfo({
                  ...oneOrderInfo,
                  deliveryDateFrom: upProcessDateRange,
                });
              }}
            />
            <label className="real-goku-setting-item-chk">～</label>
            <DatePicker
              locale="ja"
              style={{ marginTop: 3, marginLeft: 0 }}
              value={oneOrderInfo?.deliveryDateTo ? dayjs(oneOrderInfo?.deliveryDateTo, 'YYYY-MM-DD') : ''}
              placeholder="対応納期Toを選択してください。"
              disabledDate={disabledToDate(oneOrderInfo?.deliveryDateFrom)} // 選択可能日付を制限
              onChange={(_, dateString) => {
                let upProcessDateRange = [...oneOrderInfo.deliveryDateTo];
                upProcessDateRange = dateString;
                setOneOrderInfo({
                  ...oneOrderInfo,
                  deliveryDateTo: upProcessDateRange,
                });
              }}
            />
          </Col>
        </Row>
        {/** 溶接部品点数 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">溶接部品点数</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {WeldPartCount?.map((partCount, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk real-goku-setting-item-chk-date-width"
                  key={'partCount' + index}
                  type="checkbox"
                  name={'partCount' + index}
                  id={'partCount' + index}
                  checked={
                    oneOrderInfo?.weldPartCount?.length > 0 ? oneOrderInfo?.weldPartCount?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upWeldPartCount = [...oneOrderInfo.weldPartCount];
                    upWeldPartCount[index].isUsed = e.target.checked;
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      weldPartCount: upWeldPartCount,
                    });
                  }}
                >
                  {partCount.name}
                </Checkbox>
                {/* 4個ごとに改行 */}
                {(index + 1) % 4 === 0 && <br />}
              </>
            ))}
          </Col>
        </Row>
        {/** 取付部品 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">取付部品</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {InstallationParts?.map((installPart, index) => (
              <Checkbox
                className="real-goku-setting-item-chk"
                key={'installPart' + index}
                type="checkbox"
                name={'installPart' + index}
                id={'installPart' + index}
                checked={
                  oneOrderInfo?.installationParts?.length > 0 ? oneOrderInfo?.installationParts?.[index]?.isUsed : false
                }
                onChange={(e) => {
                  let upInstallationParts = [...oneOrderInfo.installationParts];
                  upInstallationParts[index].isUsed = e.target.checked;
                  setOneOrderInfo({
                    ...oneOrderInfo,
                    installationParts: upInstallationParts,
                  });
                }}
              >
                {installPart.name}
              </Checkbox>
            ))}
          </Col>
        </Row>
        {/** タップ・成形 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">タップ・成形</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {TapForm?.map((tap, index) => (
              <Checkbox
                className="real-goku-setting-item-chk"
                key={'tap' + index}
                type="checkbox"
                name={'tap' + index}
                id={'tap' + index}
                checked={oneOrderInfo?.tapForm?.length > 0 ? oneOrderInfo?.tapForm?.[index]?.isUsed : false}
                onChange={(e) => {
                  let upTapForm = [...oneOrderInfo.tapForm];
                  upTapForm[index].isUsed = e.target.checked;
                  setOneOrderInfo({
                    ...oneOrderInfo,
                    tapForm: upTapForm,
                  });
                }}
              >
                {tap.name}
              </Checkbox>
            ))}
          </Col>
        </Row>
        {/** 最大曲げ長さ */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">最大曲げ長さ</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {MaxBendingLen?.map((bending, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk real-goku-setting-item-chk-date-width"
                  key={'bending' + index}
                  type="checkbox"
                  name={'bending' + index}
                  id={'bending' + index}
                  checked={
                    oneOrderInfo?.maxBendingLen?.length > 0 ? oneOrderInfo?.maxBendingLen?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upMaxBendingLen = [...oneOrderInfo.maxBendingLen];
                    upMaxBendingLen[index].isUsed = e.target.checked;
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      maxBendingLen: upMaxBendingLen,
                    });
                  }}
                >
                  {bending.name}
                </Checkbox>
                {/* 4個ごとに改行 */}
                {(index + 1) % 4 === 0 && <br />}
              </>
            ))}
          </Col>
        </Row>
        {/** 最大サイズ */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">最大サイズ</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ marginLeft: 15 }}>
              X
              <Input
                className="real-goku-setting-item-input"
                value={oneOrderInfo.maxX}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      maxX: e.target.value,
                    });
                  }
                }}
              ></Input>
              ）mm Y（
              <Input
                className="real-goku-setting-item-input"
                value={oneOrderInfo.maxY}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      maxY: e.target.value,
                    });
                  }
                }}
              ></Input>
              ）mm Z（
              <Input
                className="real-goku-setting-item-input"
                value={oneOrderInfo.maxZ}
                onChange={(e) => {
                  if (oneOrderInfo.maxZ != e.target.value) {
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      maxZ: e.target.value,
                    });
                  }
                }}
              ></Input>
              ）mm
            </div>
          </Col>
        </Row>
        {/** 納品可能エリア */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">納品可能エリア</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {DeliveryArea?.map((deliveryArea, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk real-goku-setting-item-chk-area-width"
                  key={'deliveryArea' + index}
                  type="checkbox"
                  name={'deliveryArea' + index}
                  id={'deliveryArea' + index}
                  checked={oneOrderInfo?.deliveryArea?.length > 0 ? oneOrderInfo?.deliveryArea?.[index]?.isUsed : false}
                  onChange={(e) => {
                    let upDeliveryArea = [...oneOrderInfo.deliveryArea];
                    upDeliveryArea[index].isUsed = e.target.checked;
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      deliveryArea: upDeliveryArea,
                    });
                  }}
                >
                  {deliveryArea.name}
                </Checkbox>
                {/* 5個ごとに改行 */}
                {(index + 1) % 5 === 0 && <br />}
              </>
            ))}
          </Col>
        </Row>
        {/** メッキ処理 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">メッキ処理</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {Plating?.map((plating, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk "
                  style={{ marginTop: index > 0 ? 7 : 0 }}
                  key="oneOrder1"
                  type="checkbox"
                  name={'oneOrder1'}
                  id={'oneOrder1'}
                  checked={
                    oneOrderInfo?.platingProcess?.length > 0 ? oneOrderInfo?.platingProcess?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upPlatingProcess = [...oneOrderInfo.platingProcess];
                    upPlatingProcess[index].isUsed = e.target.checked;
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      platingProcess: upPlatingProcess,
                    });
                  }}
                >
                  {plating.name}
                </Checkbox>
                <br />
                <div
                  style={{
                    display: oneOrderInfo?.platingProcess?.[index]?.isUsed ? '' : 'none',
                  }}
                >
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 30, marginBottom: 5 }}
                    value={
                      oneOrderInfo?.platingProcess?.[index]?.supportFrom
                        ? dayjs(oneOrderInfo?.platingProcess?.[index]?.supportFrom, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledFromDate(oneOrderInfo?.platingProcess?.[index]?.supportTo)} // From日付の制限
                    onChange={(_, dateString) => {
                      let upPlatingProcess = [...oneOrderInfo.platingProcess];
                      upPlatingProcess[index].supportFrom = dateString;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        platingProcess: upPlatingProcess,
                      });
                    }}
                  />
                  <label className="real-goku-setting-item-chk">～</label>
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 0, marginBottom: 5 }}
                    value={
                      oneOrderInfo?.platingProcess?.[index]?.supportTo
                        ? dayjs(oneOrderInfo?.platingProcess?.[index]?.supportTo, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledToDate(oneOrderInfo?.platingProcess?.[index]?.supportFrom)} // 選択可能日付を制限
                    onChange={(_, dateString) => {
                      let upPlatingProcess = [...oneOrderInfo.platingProcess];
                      upPlatingProcess[index].supportTo = dateString;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        platingProcess: upPlatingProcess,
                      });
                    }}
                  />
                  <br />
                </div>
              </>
            ))}
          </Col>
        </Row>
        {/** 塗装処理 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">塗装処理</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {Painting?.map((painting, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk"
                  style={{ marginTop: index > 0 ? 7 : 0 }}
                  key="oneOrder1"
                  type="checkbox"
                  name={'oneOrder1'}
                  id={'oneOrder1'}
                  checked={
                    oneOrderInfo?.paintingProcess?.length > 0 ? oneOrderInfo?.paintingProcess?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upPaintingProcess = [...oneOrderInfo.paintingProcess];
                    upPaintingProcess[index].isUsed = e.target.checked;
                    setOneOrderInfo({
                      ...oneOrderInfo,
                      paintingProcess: upPaintingProcess,
                    });
                  }}
                >
                  {painting.name}
                </Checkbox>
                <br />
                <div
                  style={{
                    display: oneOrderInfo?.paintingProcess?.[index]?.isUsed ? '' : 'none',
                  }}
                >
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 30, marginBottom: 5 }}
                    value={
                      oneOrderInfo?.paintingProcess?.[index]?.supportFrom
                        ? dayjs(oneOrderInfo?.paintingProcess?.[index]?.supportFrom, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledFromDate(oneOrderInfo?.paintingProcess?.[index]?.supportTo)} // From日付の制限
                    onChange={(_, dateString) => {
                      let upPaintingProcess = [...oneOrderInfo.paintingProcess];
                      upPaintingProcess[index].supportFrom = dateString;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        paintingProcess: upPaintingProcess,
                      });
                    }}
                  />
                  <label className="real-goku-setting-item-chk">～</label>
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 0, marginBottom: 5 }}
                    value={
                      oneOrderInfo?.paintingProcess?.[index]?.supportTo
                        ? dayjs(oneOrderInfo?.paintingProcess?.[index]?.supportTo, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledToDate(oneOrderInfo?.paintingProcess?.[index]?.supportFrom)} // 選択可能日付を制限
                    onChange={(_, dateString) => {
                      let upPaintingProcess = [...oneOrderInfo.paintingProcess];
                      upPaintingProcess[index].supportTo = dateString;
                      setOneOrderInfo({
                        ...oneOrderInfo,
                        paintingProcess: upPaintingProcess,
                      });
                    }}
                  />
                  <br />
                </div>
              </>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );

  const commonSettingItem_repeatOrder = (
    <div className="real-goku-setting-div-padding ">
      <div className="real-goku-setting-div">
        {/** 受注分類 */}
        <Row>
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">受注分類</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox
              className="real-goku-setting-item-chk"
              key="unitOrder"
              type="checkbox"
              name={'unitOrder'}
              id={'unitOrder'}
              checked={repeatOrderInfo.orderFlg}
              onChange={(e) => {
                setRepeatOrderInfo({
                  ...repeatOrderInfo,
                  orderFlg: e.target.checked,
                });
              }}
            >
              リピート
            </Checkbox>
          </Col>
        </Row>
        {/** 個数 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">個数</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            （
            <Input
              className="real-goku-setting-item-input"
              value={repeatOrderInfo.quantityFrom}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setRepeatOrderInfo({
                    ...repeatOrderInfo,
                    quantityFrom: e.target.value,
                  });
                }
              }}
            ></Input>
            ）個～（
            <Input
              className="real-goku-setting-item-input "
              value={repeatOrderInfo.quantityTo}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setRepeatOrderInfo({
                    ...repeatOrderInfo,
                    quantityTo: e.target.value,
                  });
                }
              }}
            ></Input>
            ）個
          </Col>
        </Row>
        {/** 材質・板厚 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">材質・板厚</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {repeatOrderInfo?.materialTypeList?.map((materialType, index) => (
              <div>
                <Select
                  className="real-goku-material-type-select"
                  onChange={(e) => {
                    let upMaterialTypeList = [...repeatOrderInfo?.materialTypeList];
                    upMaterialTypeList[index].materialTypeId = e;
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      materialTypeList: upMaterialTypeList,
                    });
                  }}
                  id={materialType.no}
                  name={'materialType' + materialType.no}
                  value={materialType.materialTypeId}
                  // defaultValue={materialClassData?.length > 0 ? materialClassData[0]?.id : 0}
                  suffixIcon={<CaretDownFilled style={{ color: '#000000', fontSize: 20 }} />}
                >
                  {materialClassData?.map((item, index) => (
                    <Select.Option key={index} id={'materialRef' + item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                <label className="real-goku-setting-item-label">（</label>
                <Input
                  className="real-goku-setting-item-input "
                  value={materialType.thicknessFrom}
                  onChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value)) {
                      let upMaterialTypeList = [...repeatOrderInfo?.materialTypeList];
                      upMaterialTypeList[index].thicknessFrom = e.target.value;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        materialTypeList: upMaterialTypeList,
                      });
                    }
                  }}
                ></Input>
                <label className="real-goku-setting-item-label">）mm～（</label>
                <Input
                  className="real-goku-setting-item-input "
                  value={materialType.thicknessTo}
                  onChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value)) {
                      let upMaterialTypeList = [...repeatOrderInfo?.materialTypeList];
                      upMaterialTypeList[index].thicknessTo = e.target.value;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        materialTypeList: upMaterialTypeList,
                      });
                    }
                  }}
                ></Input>
                <label className="real-goku-setting-item-label">）mm</label>
                <PlusCircleFilled
                  className="real-goku-plus-minus-icon"
                  style={{ marginLeft: 20, marginRight: 20 }}
                  onClick={(e) => {
                    let upMaterialTypeList = [...repeatOrderInfo?.materialTypeList];
                    const maxNo =
                      repeatOrderInfo?.materialTypeList?.length > 0
                        ? Math.max(...repeatOrderInfo?.materialTypeList?.map((item) => item.no))
                        : 0;
                    upMaterialTypeList.push({
                      no: maxNo + 1,
                      materialTypeId: materialClassData?.length > 0 ? materialClassData[0]?.id : 0,
                      thicknessFrom: 0,
                      thicknessTo: 0,
                    });
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      materialTypeList: upMaterialTypeList,
                    });
                  }}
                />
                <MinusCircleFilled
                  className="real-goku-plus-minus-icon"
                  onClick={(e) => {
                    let upMaterialTypeList = [...repeatOrderInfo?.materialTypeList];
                    if (upMaterialTypeList?.length > 1) {
                      upMaterialTypeList = upMaterialTypeList?.filter((item) => item.no !== materialType.no);
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        materialTypeList: upMaterialTypeList,
                      });
                    }
                  }}
                />
              </div>
            ))}
          </Col>
        </Row>
        {/** 公差 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">公差</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {DiffList?.map((diff, index) => (
              <Checkbox
                className="real-goku-setting-item-chk"
                key={'diff' + index}
                type="checkbox"
                name={'diff' + index}
                id={'diff' + index}
                checked={repeatOrderInfo?.diff?.length > 0 ? repeatOrderInfo?.diff?.[index]?.isUsed : false}
                onChange={(e) => {
                  let upDiff = [...repeatOrderInfo.diff];
                  upDiff[index].isUsed = e.target.checked;
                  setRepeatOrderInfo({
                    ...repeatOrderInfo,
                    diff: upDiff,
                  });
                }}
              >
                {diff.name}
              </Checkbox>
            ))}
          </Col>
        </Row>
        {/** 仕上 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">仕上</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {WeldFinish?.map((weldFinish, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk"
                  key={'weldFinish' + index}
                  type="checkbox"
                  name={'weldFinish' + index}
                  id={'weldFinish' + index}
                  checked={
                    repeatOrderInfo?.weldFinish?.length > 0 ? repeatOrderInfo?.weldFinish?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upWeldFinish = [...repeatOrderInfo.weldFinish];
                    upWeldFinish[index].isUsed = e.target.checked;
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      weldFinish: upWeldFinish,
                    });
                  }}
                >
                  {weldFinish.name}
                </Checkbox>
                {index > 1 ? <br /> : ''}
                <div
                  style={{
                    display:
                      (weldFinish.name === '酸洗い' &&
                        weldFinish.id === repeatOrderInfo?.weldFinish?.[index]?.id &&
                        repeatOrderInfo?.weldFinish?.[index]?.isUsed) ||
                      (weldFinish.name === 'HL' &&
                        weldFinish.id === repeatOrderInfo?.weldFinish?.[index]?.id &&
                        repeatOrderInfo?.weldFinish?.[index]?.isUsed) ||
                      (weldFinish.name === '鏡面' &&
                        weldFinish.id === repeatOrderInfo?.weldFinish?.[index]?.id &&
                        repeatOrderInfo?.weldFinish?.[index]?.isUsed)
                        ? ''
                        : 'none',
                  }}
                >
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 30, marginBottom: 5 }}
                    value={
                      repeatOrderInfo?.weldFinish?.[index]?.supportFrom
                        ? dayjs(repeatOrderInfo?.weldFinish?.[index]?.supportFrom, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledFromDate(repeatOrderInfo?.weldFinish?.[index]?.supportTo)} // From日付の制限
                    onChange={(_, dateString) => {
                      let upWeldFinish = [...repeatOrderInfo.weldFinish];
                      upWeldFinish[index].supportFrom = dateString;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        weldFinish: upWeldFinish,
                      });
                    }}
                  />
                  <label className="real-goku-setting-item-chk">～</label>
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 0, marginBottom: 5 }}
                    value={
                      repeatOrderInfo?.weldFinish?.[index]?.supportTo
                        ? dayjs(repeatOrderInfo?.weldFinish?.[index]?.supportTo, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledToDate(repeatOrderInfo?.weldFinish?.[index]?.supportFrom)} // 選択可能日付を制限
                    onChange={(_, dateString) => {
                      let upWeldFinish = [...repeatOrderInfo.weldFinish];
                      upWeldFinish[index].supportTo = dateString;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        weldFinish: upWeldFinish,
                      });
                    }}
                  />
                </div>
              </>
            ))}
          </Col>
        </Row>
        {/** 利用箇所 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">利用箇所</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {UsedPlace?.map((used, index) => (
              <Row>
                <Col span={6}>
                  <Checkbox
                    className="real-goku-setting-item-chk"
                    key={'usedPlace' + index}
                    type="checkbox"
                    name={'usedPlace' + index}
                    id={'usedPlace' + index}
                    checked={
                      repeatOrderInfo?.usedPlace?.length > 0 ? repeatOrderInfo?.usedPlace?.[index]?.isUsed : false
                    }
                    onChange={(e) => {
                      let upUsedPlace = [...repeatOrderInfo.usedPlace];
                      upUsedPlace[index].isUsed = e.target.checked;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        usedPlace: upUsedPlace,
                      });
                    }}
                  >
                    {used.name}
                  </Checkbox>
                </Col>
                <Col span={7}>
                  <label>⇒引当工程パターン</label>
                </Col>
                <Col span={10}>
                  <Select
                    className="real-goku-material-type-select-pattern "
                    onChange={(e) => {
                      let upUsedPlace = [...repeatOrderInfo.usedPlace];
                      upUsedPlace[index].patternId = e;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        usedPlace: upUsedPlace,
                      });
                    }}
                    id={'process-pattern'}
                    name={'process-pattern'}
                    value={repeatOrderInfo?.usedPlace?.length > 0 ? repeatOrderInfo?.usedPlace?.[index]?.patternId : 0}
                    suffixIcon={<CaretDownFilled style={{ color: '#000000', fontSize: 20 }} />}
                  >
                    {processPatternList?.map((item, index) => (
                      <Select.Option key={index} id={'materialRef' + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        {/** 対応納期 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">対応納期</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DatePicker
              locale="ja"
              style={{ marginTop: 3, marginLeft: 10 }}
              value={repeatOrderInfo?.deliveryDateFrom ? dayjs(repeatOrderInfo?.deliveryDateFrom, 'YYYY-MM-DD') : ''}
              placeholder="対応納期Fromを選択してください。"
              disabledDate={disabledFromDate(repeatOrderInfo?.deliveryDateTo)} // From日付の制限
              onChange={(_, dateString) => {
                let upProcessDateRange = [...repeatOrderInfo.deliveryDateFrom];
                upProcessDateRange = dateString;
                setRepeatOrderInfo({
                  ...repeatOrderInfo,
                  deliveryDateFrom: upProcessDateRange,
                });
              }}
            />
            <label className="real-goku-setting-item-chk">～</label>
            <DatePicker
              locale="ja"
              style={{ marginTop: 3, marginLeft: 0 }}
              value={repeatOrderInfo?.deliveryDateTo ? dayjs(repeatOrderInfo?.deliveryDateTo, 'YYYY-MM-DD') : ''}
              placeholder="対応納期Toを選択してください。"
              disabledDate={disabledToDate(repeatOrderInfo?.deliveryDateFrom)} // 選択可能日付を制限
              onChange={(_, dateString) => {
                let upProcessDateRange = [...repeatOrderInfo.deliveryDateTo];
                upProcessDateRange = dateString;
                setRepeatOrderInfo({
                  ...repeatOrderInfo,
                  deliveryDateTo: upProcessDateRange,
                });
              }}
            />
          </Col>
        </Row>
        {/** 溶接部品点数 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">溶接部品点数</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {WeldPartCount?.map((partCount, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk real-goku-setting-item-chk-date-width"
                  key={'partCount' + index}
                  type="checkbox"
                  name={'partCount' + index}
                  id={'partCount' + index}
                  checked={
                    repeatOrderInfo?.weldPartCount?.length > 0 ? repeatOrderInfo?.weldPartCount?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upWeldPartCount = [...repeatOrderInfo.weldPartCount];
                    upWeldPartCount[index].isUsed = e.target.checked;
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      weldPartCount: upWeldPartCount,
                    });
                  }}
                >
                  {partCount.name}
                </Checkbox>
                {/* 4個ごとに改行 */}
                {(index + 1) % 4 === 0 && <br />}
              </>
            ))}
          </Col>
        </Row>
        {/** 取付部品 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">取付部品</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {InstallationParts?.map((installPart, index) => (
              <Checkbox
                className="real-goku-setting-item-chk"
                key={'installPart' + index}
                type="checkbox"
                name={'installPart' + index}
                id={'installPart' + index}
                checked={
                  repeatOrderInfo?.installationParts?.length > 0
                    ? repeatOrderInfo?.installationParts?.[index]?.isUsed
                    : false
                }
                onChange={(e) => {
                  let upInstallationParts = [...repeatOrderInfo.installationParts];
                  upInstallationParts[index].isUsed = e.target.checked;
                  setRepeatOrderInfo({
                    ...repeatOrderInfo,
                    installationParts: upInstallationParts,
                  });
                }}
              >
                {installPart.name}
              </Checkbox>
            ))}
          </Col>
        </Row>
        {/** タップ・成形 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">タップ・成形</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {TapForm?.map((tap, index) => (
              <Checkbox
                className="real-goku-setting-item-chk"
                key={'tap' + index}
                type="checkbox"
                name={'tap' + index}
                id={'tap' + index}
                checked={repeatOrderInfo?.tapForm?.length > 0 ? repeatOrderInfo?.tapForm?.[index]?.isUsed : false}
                onChange={(e) => {
                  let upTapForm = [...repeatOrderInfo.tapForm];
                  upTapForm[index].isUsed = e.target.checked;
                  setRepeatOrderInfo({
                    ...repeatOrderInfo,
                    tapForm: upTapForm,
                  });
                }}
              >
                {tap.name}
              </Checkbox>
            ))}
          </Col>
        </Row>
        {/** 最大曲げ長さ */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">最大曲げ長さ</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {MaxBendingLen?.map((bending, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk real-goku-setting-item-chk-date-width"
                  key={'bending' + index}
                  type="checkbox"
                  name={'bending' + index}
                  id={'bending' + index}
                  checked={
                    repeatOrderInfo?.maxBendingLen?.length > 0 ? repeatOrderInfo?.maxBendingLen?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upMaxBendingLen = [...repeatOrderInfo.maxBendingLen];
                    upMaxBendingLen[index].isUsed = e.target.checked;
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      maxBendingLen: upMaxBendingLen,
                    });
                  }}
                >
                  {bending.name}
                </Checkbox>
                {/* 4個ごとに改行 */}
                {(index + 1) % 4 === 0 && <br />}
              </>
            ))}
          </Col>
        </Row>
        {/** 最大サイズ */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">最大サイズ</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ marginLeft: 15 }}>
              X
              <Input
                className="real-goku-setting-item-input"
                value={repeatOrderInfo.maxX}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      maxX: e.target.value,
                    });
                  }
                }}
              ></Input>
              ）mm Y（
              <Input
                className="real-goku-setting-item-input"
                value={repeatOrderInfo.maxY}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      maxY: e.target.value,
                    });
                  }
                }}
              ></Input>
              ）mm Z（
              <Input
                className="real-goku-setting-item-input"
                value={repeatOrderInfo.maxZ}
                onChange={(e) => {
                  if (/^\d*\.?\d*$/.test(e.target.value)) {
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      maxZ: e.target.value,
                    });
                  }
                }}
              ></Input>
              ）mm
            </div>
          </Col>
        </Row>
        {/** 納品可能エリア */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">納品可能エリア</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {DeliveryArea?.map((deliveryArea, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk real-goku-setting-item-chk-area-width"
                  key={'deliveryArea' + index}
                  type="checkbox"
                  name={'deliveryArea' + index}
                  id={'deliveryArea' + index}
                  checked={
                    repeatOrderInfo?.deliveryArea?.length > 0 ? repeatOrderInfo?.deliveryArea?.[index]?.isUsed : false
                  }
                  onChange={(e) => {
                    let upDeliveryArea = [...repeatOrderInfo.deliveryArea];
                    upDeliveryArea[index].isUsed = e.target.checked;
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      deliveryArea: upDeliveryArea,
                    });
                  }}
                >
                  {deliveryArea.name}
                </Checkbox>
                {/* 5個ごとに改行 */}
                {(index + 1) % 5 === 0 && <br />}
              </>
            ))}
          </Col>
        </Row>
        {/** メッキ処理 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">メッキ処理</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {Plating?.map((plating, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk"
                  style={{ marginTop: index > 0 ? 7 : 0 }}
                  key="oneOrder1"
                  type="checkbox"
                  name={'oneOrder1'}
                  id={'oneOrder1'}
                  checked={
                    repeatOrderInfo?.platingProcess?.length > 0
                      ? repeatOrderInfo?.platingProcess?.[index]?.isUsed
                      : false
                  }
                  onChange={(e) => {
                    let upPlatingProcess = [...repeatOrderInfo.platingProcess];
                    upPlatingProcess[index].isUsed = e.target.checked;
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      platingProcess: upPlatingProcess,
                    });
                  }}
                >
                  {plating.name}
                </Checkbox>
                <br />
                <div
                  style={{
                    display: repeatOrderInfo?.platingProcess?.[index]?.isUsed ? '' : 'none',
                  }}
                >
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 30, marginBottom: 5 }}
                    value={
                      repeatOrderInfo?.platingProcess?.[index]?.supportFrom
                        ? dayjs(repeatOrderInfo?.platingProcess?.[index]?.supportFrom, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledFromDate(repeatOrderInfo?.platingProcess?.[index]?.supportTo)} // From日付の制限
                    onChange={(_, dateString) => {
                      let upPlatingProcess = [...repeatOrderInfo.platingProcess];
                      upPlatingProcess[index].supportFrom = dateString;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        platingProcess: upPlatingProcess,
                      });
                    }}
                  />
                  <label className="real-goku-setting-item-chk">～</label>
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 0, marginBottom: 5 }}
                    value={
                      repeatOrderInfo?.platingProcess?.[index]?.supportTo
                        ? dayjs(repeatOrderInfo?.platingProcess?.[index]?.supportTo, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledToDate(repeatOrderInfo?.platingProcess?.[index]?.supportFrom)} // 選択可能日付を制限
                    onChange={(_, dateString) => {
                      let upPlatingProcess = [...repeatOrderInfo.platingProcess];
                      upPlatingProcess[index].supportTo = dateString;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        platingProcess: upPlatingProcess,
                      });
                    }}
                  />
                  <br />
                </div>
              </>
            ))}
          </Col>
        </Row>
        {/** 塗装処理 */}
        <Row className="real-goku-setting-item-row">
          <Col span={24}>
            <div className="real-goku-setting-item-label-div">
              <label className="real-goku-setting-item-label">塗装処理</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {Painting?.map((painting, index) => (
              <>
                <Checkbox
                  className="real-goku-setting-item-chk"
                  style={{ marginTop: index > 0 ? 7 : 0 }}
                  key="oneOrder1"
                  type="checkbox"
                  name={'oneOrder1'}
                  id={'oneOrder1'}
                  checked={
                    repeatOrderInfo?.paintingProcess?.length > 0
                      ? repeatOrderInfo?.paintingProcess?.[index]?.isUsed
                      : false
                  }
                  onChange={(e) => {
                    let upPaintingProcess = [...repeatOrderInfo.paintingProcess];
                    upPaintingProcess[index].isUsed = e.target.checked;
                    setRepeatOrderInfo({
                      ...repeatOrderInfo,
                      paintingProcess: upPaintingProcess,
                    });
                  }}
                >
                  {painting.name}
                </Checkbox>
                <br />
                <div
                  style={{
                    display: repeatOrderInfo?.paintingProcess?.[index]?.isUsed ? '' : 'none',
                  }}
                >
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 30, marginBottom: 5 }}
                    value={
                      repeatOrderInfo?.paintingProcess?.[index]?.supportFrom
                        ? dayjs(repeatOrderInfo?.paintingProcess?.[index]?.supportFrom, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledFromDate(repeatOrderInfo?.paintingProcess?.[index]?.supportTo)} // From日付の制限
                    onChange={(_, dateString) => {
                      let upPaintingProcess = [...repeatOrderInfo.paintingProcess];
                      upPaintingProcess[index].supportFrom = dateString;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        paintingProcess: upPaintingProcess,
                      });
                    }}
                  />
                  <label className="real-goku-setting-item-chk">～</label>
                  <DatePicker
                    locale="ja"
                    style={{ marginTop: 3, marginLeft: 0, marginBottom: 5 }}
                    value={
                      repeatOrderInfo?.paintingProcess?.[index]?.supportTo
                        ? dayjs(repeatOrderInfo?.paintingProcess?.[index]?.supportTo, 'YYYY-MM-DD')
                        : ''
                    }
                    placeholder="選択してください"
                    disabledDate={disabledToDate(repeatOrderInfo?.paintingProcess?.[index]?.supportFrom)} // 選択可能日付を制限
                    onChange={(_, dateString) => {
                      let upPaintingProcess = [...repeatOrderInfo.paintingProcess];
                      upPaintingProcess[index].supportTo = dateString;
                      setRepeatOrderInfo({
                        ...repeatOrderInfo,
                        paintingProcess: upPaintingProcess,
                      });
                    }}
                  />
                  <br />
                </div>
              </>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );

  const confirmOk = async () => {
    settingOk();
  };

  const realGokuSettingContent = (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row>
            <Col span={24}>
              <label className="real-goku-setting-item-title">単発対応設定項目</label>
              {/* <br />
              <Checkbox
                className="real-goku-setting-item-chk chk-color-red"
                key="supportChk-unit"
                id="supportChk-unit"
                checked={oneOrderInfo.isSupport}
                onChange={(e) => setOneOrderInfo({ ...oneOrderInfo, isSupport: e.target.checked })}
              >
                下記日付で対応可能とする
              </Checkbox>
              <br />
              <label className="real-goku-setting-item-label real-goku-setting-item-label-red">
                (2024)年(12)月(1)日 から (2025)年(1)月(31)日 まで
              </label> */}
            </Col>
          </Row>
          {commonSettingItem_oneOrder}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row>
            <Col span={24}>
              <label className="real-goku-setting-item-title">リピート対応設定項目</label>
              {/* <br />
              <Checkbox
                className="real-goku-setting-item-chk chk-color-red"
                key="supportChk-repeat"
                id="supportChk-repeat"
                checked={repeatOrderInfo.isSupport}
                onChange={(e) => setRepeatOrderInfo({ ...repeatOrderInfo, isSupport: e.target.checked })}
              >
                下記日付で対応可能とする
              </Checkbox>
              <br />
              <label className="real-goku-setting-item-label real-goku-setting-item-label-red">
                (2024)年(12)月(1)日 から (2025)年(1)月(31)日 まで
              </label> */}
            </Col>
          </Row>
          {commonSettingItem_repeatOrder}
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <div style={{ backgroundColor: '#1063aa', display: 'flex', alignItems: 'center', height: '5vh' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            width: '60%',
          }}
        >
          <Space size="large">
            <Image preview={false} width={35} src={logo} style={{ marginLeft: '5px', marginTop: '0px' }}></Image>
            {/** IQX_WEBEST-169 */}
            <h5 style={{ color: 'whitesmoke', marginLeft: '-13px', marginTop: '3px' }}>iQx-WebEstimate (1.0.72)</h5>
          </Space>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            width: '39%',
          }}
        >
          <SettingOutlined
            style={{ marginLeft: '25px', fontSize: 25, color: 'white', cursor: 'pointer' }}
            onClick={(e) => openRealGokuSettingDialog()}
          />
          <Typography.Text
            onClick={(e) => openRealGokuSettingDialog()}
            ellipsis={{ tooltip: [loginUser?.companyName] }}
            style={{
              color: 'whitesmoke',
              marginLeft: '4px',
              fontSize: '14px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            RealGoku受注設定
          </Typography.Text>
          <Image preview={false} width={32} src={company} style={{ marginLeft: '25px' }}></Image>
          <Typography.Text
            ellipsis={{ tooltip: [loginUser?.companyName] }}
            style={{
              color: 'whitesmoke',
              marginLeft: '28px',
              fontSize: '14px',
              marginRight: '20px',
              fontWeight: 'bold',
            }}
          >
            {loginUser?.companyName}
          </Typography.Text>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <Image preview={false} width={24} src={user}></Image>
            <Typography.Text
              ellipsis={{ tooltip: [loginUser?.userName] }}
              style={{
                color: 'whitesmoke',
                marginLeft: '10px',
                fontSize: '14px',
                marginRight: '20px',
                fontWeight: 'bold',
              }}
            >
              {loginUser?.userName}
            </Typography.Text>
          </div>
          {/* ログアウト */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <Image preview={false} width={24} src={logout}></Image>
            <Typography.Text
              className="menu-active-hover"
              ellipsis={{ tooltip: ['ログアウト'] }}
              style={{
                color: 'whitesmoke',
                marginLeft: '10px',
                fontSize: '14px',
                marginRight: '20px',
                fontWeight: 'bold',
              }}
            >
              ログアウト
            </Typography.Text>
          </div>
          {/* 設定 */}
          <div
            // onClick={handleClick}
            style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '5%', marginLeft: '10px' }}
          >
            <Tooltip title="設定" placement="bottom" overlayClassName="tooltip-text">
              <Image
                preview={false}
                width={37}
                src={usersetting}
                onClick={showSetting}
                style={{ cursor: 'pointer' }}
              ></Image>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* 子部品確認 */}
      {openRealGokuSetting
        ? commonResponsiveModal(
            openRealGokuSetting,
            modalTitle,
            commonFooterOK(confirmOk),
            // null,
            { height: 880 },
            1270,
            settingOk,
            settingCancel,
            realGokuSettingContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
    </>
  );
};

export default Header;
