/**
 * クラス名：材料計算一覧画面
 * 説明：形鋼に使用の材料計算一覧ファイルです。
 * 作成者：ナンス
 * 作成日：2024/10/03
 * バージョン：1.0
 */
import { Table, Row, Input, Select, Image, Space, Checkbox } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'split-pane-react/esm/themes/default.css';
import SplitPane from 'split-pane-react';

import '../../../../assets/styles/common.css';
import SteelMaterialCalculateDetail from './SteelMaterialCalculateDetail.js';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmDeleteContent,
  confirmCreateContent,
  confirmUpdateContent,
  commonActionFooter,
  commonDeleteFooter,
} from '../../../common/CommonModal.js';
import { actionArr, formValidatorMode, getMaterialTypeList } from '../../../common/Common.js';
import { pageSizes } from '../../../common/Constant.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';
import {
  createSteelFixedSizeInfo,
  deleteSteelFixedSizeInfo,
  getSteelFixedSizeInfo,
  getSteelUnitUseInfo,
  updateSteelFixedSizeInfo,
  updateSteelUnitUseInfo,
} from '../../../common/CommonAPI.js';
import { compareIQ5Info } from '../../../common/CommonIQ5.js';

dayjs.extend(customParseFormat);

const SteelMaterialCalculateList = forwardRef((props, ref) => {
  const [paramSelected, setParamSelected] = useState('材料計算');
  const [paramType, setParamType] = useState('iq5');
  const [steelMaterialCalculateData, setMaterialCalculateData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [mstMaterialTypeIq3List, setMstMaterialTypeIq3List] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState();
  const [chkData, setChkData] = useState([]);
  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  // Content Resize
  const [sizes, setSizes] = useState(['60%']);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);
  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();
  // pagination
  const [pageSize, setPageSize] = useState(30);
  const [totalCount, setTotalCount] = useState(0);
  const [ascSortFlg, setAscSortFlg] = useState('ASC');
  const [sortNm, setSortNm] = useState('id');
  const [tblLoading, setTblLoading] = useState(false);
  const [selectedMaterialType, setSelectedMaterialType] = useState([]);
  const [steelFixedSizeNm, setSteelFixedSizeNm] = useState([]);
  const [steelFixedSizeLen, setSteelFixedSizeLen] = useState([]);
  const [steelFixedSizeMatTypes, setSteelFixedSizeMatTypes] = useState([]);
  const [steelUnitUseNm, setSteelUnitUseNm] = useState([]);
  const [steelUnitUseLst, setSteelUnitUseLst] = useState([]);

  const detailRef = useRef();
  const navigate = useNavigate();
  const inputItemRef = {};

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 55,
      render: (id, record, index) => {
        let pageBefore = currentPage - 1;
        let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
        return startPage + (index + 1); // IQX_WEBEST-240
      },
      className: 'cm-a-right',
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: 180,
      sorter: (a, b) => setSortNm('name'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'length',
      title: '長さ',
      dataIndex: 'length',
      width: 180,
      sorter: (a, b) => setSortNm('sizeX'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    getEditMode: editMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  useEffect(() => {
    getMaterialCalculateIQ5Data();
  }, []);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(steelMaterialCalculateData?.find((item) => item.id === changeRecordId));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  // 形鋼材料計算情報を取得する
  const getMaterialCalculateIQ5Data = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    let steelMaterialCalculateData = [];
    let pageBefore =
      props.initialParam === 'iq5_steelMaterialCalculate' &&
      props?.rowSelectPage !== undefined &&
      props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    let propSortFlg =
      props.initialParam === 'iq5_steelMaterialCalculate' && props?.ascSortFlg !== undefined
        ? props?.ascSortFlg
        : ascSortFlg;
    let propSortNm =
      props.initialParam === 'iq5_steelMaterialCalculate' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
    let propFilterDatas =
      props.initialParam === 'iq5_steelMaterialCalculate' && props?.filterDatas !== undefined ? props?.filterDatas : {};
    const steelMaterialCalculateInfo = await getSteelFixedSizeInfo(pageSize, pageBefore, propSortFlg, propSortNm);
    if (steelMaterialCalculateInfo) {
      steelMaterialCalculateData = steelMaterialCalculateInfo ? steelMaterialCalculateInfo.data : [];
      setTotalCount(steelMaterialCalculateInfo ? steelMaterialCalculateInfo.totalCount : 0);
      // 形鋼材料種類カラム
      setSelectedMaterialType(steelMaterialCalculateInfo.mateiralTypes);
      const materialTypeColumns = steelMaterialCalculateInfo.mateiralTypes
        ?.map((mateiralType, index) => {
          return {
            title: mateiralType.name,
            dataIndex: 'steelMaterialType',
            key: 'steelMaterialType',
            width: 100 + 'px',
            className: 'cm-a-center',
            render: (record, i) => {
              const steelMaterialType = i.steelFixedSizeMatTypes?.filter(
                (item) => item.materialType.id == mateiralType?.id
              );
              return (
                <Checkbox
                  style={{ marginLeft: 10, marginRight: 10 }}
                  type="checkbox"
                  name={'steelMaterialType' + index + i.id}
                  id={'steelMaterialType' + index + i.id}
                  defaultChecked={false}
                  checked={steelMaterialType && steelMaterialType?.[0]?.info?.isUsed == 1 ? true : false}
                  disabled={true}
                />
              );
            },
            sorter: (a, b) => {
              setSortNm('steelMaterialType' + index);
            },
            sortIcon: ({ sortOrder }) => (
              <>
                <Image
                  preview={false}
                  src={sort_up}
                  style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
                />
                <Image
                  preview={false}
                  src={sort_down}
                  style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
                />
              </>
            ),
          };
        })
        ?.flat();
      setChkData(materialTypeColumns);
    }
    setMaterialCalculateData(steelMaterialCalculateData);
    if (steelMaterialCalculateData?.length > 0) {
      setSelectedRowId(steelMaterialCalculateData[0]?.id);
      setSelectedData(steelMaterialCalculateData[0]);
      // 前回選択された行がある場合
      if (
        props.initialParam === 'iq5_steelMaterialCalculate' &&
        props.selectedRowId !== undefined &&
        props.selectedRowId !== 0
      ) {
        let selectedRowData = steelMaterialCalculateData?.filter((item) => item.id === props?.selectedRowId);
        if (selectedRowData?.length > 0) {
          setSelectedRowId(selectedRowData?.[0]?.id);
          setSelectedData(selectedRowData?.[0]);
        }
      }
    }
    getSteelUnitUseInfoLst();
    props.setParam(paramType, paramSelected);
    let materialTypeList = await getMaterialTypeList();
    setMaterialTypeList(materialTypeList);
    let mstMaterialTypeIq3List = [];
    setMstMaterialTypeIq3List(mstMaterialTypeIq3List);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const getSteelUnitUseInfoLst = async () => {
    let steelUnitUse = await getSteelUnitUseInfo();
    if (steelUnitUse && steelUnitUse?.data?.length > 0) {
      setSteelUnitUseLst(steelUnitUse?.data);
      let usedData = steelUnitUse?.data?.filter((item) => item.used);
      setSteelUnitUseNm(usedData);
    }
  };

  // 材質区分名をIDで取得する
  const getMaterialTypeName = (materialTypeId) => {
    return materialTypeList?.filter((item) => item.value === materialTypeId)?.[0]?.label;
  };

  // 材質名称をIDで取得する
  const getMaterialTypeIq3Name = (materialTypeIq3Id) => {
    return mstMaterialTypeIq3List?.filter((item) => item.value === materialTypeIq3Id)?.[0]?.label;
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setTblLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let ascSortFlg = 'ASC';
    if (sorter?.order === undefined) {
      ascSortFlg = '';
    } else if (sorter?.order === 'ascend') {
      ascSortFlg = 'ASC';
    } else if (sorter?.order === 'descend') {
      ascSortFlg = 'DESC';
    }
    setAscSortFlg(ascSortFlg);
    let steelMaterialCalculateIQ5Info = await getSteelFixedSizeInfo(
      pageSize,
      pageBefore,
      ascSortFlg,
      sorter?.columnKey
    );
    if (steelMaterialCalculateIQ5Info) {
      let materialTypeIQ5 = steelMaterialCalculateIQ5Info ? steelMaterialCalculateIQ5Info?.data : [];
      setMaterialCalculateData(materialTypeIQ5);
      setTotalCount(steelMaterialCalculateIQ5Info ? steelMaterialCalculateIQ5Info?.totalCount : 0);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
  };

  const onRowSelect = (record, index) => {
    setChangeRecordId(record?.id);
    setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowId(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        navigate(':iq5.steelMaterialCalculate');
      }
    } else {
      setSelectedRowId(record?.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq5.steelMaterialCalculate');
    }
    props?.updateSelectedRowId(record?.id, 0, currentPage, ascSortFlg, sortNm, []);
    props?.resetInitialParam();
  };

  const handleOk = (e) => {
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
  };

  const updConfirmOk = async (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(steelMaterialCalculateData));
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      // 画面の入力チェック
      if (!(await detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false))) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let newSteelFixedSizeInfo = {
        id: 0,
        name: steelFixedSizeNm,
        length: steelFixedSizeLen,
        steelFixedSizeMatTypes: steelFixedSizeMatTypes,
      };
      let createdDataInfo = await createSteelFixedSizeInfo(newSteelFixedSizeInfo);
      if (createdDataInfo && createdDataInfo?.data?.length > 0) {
        let createdData = createdDataInfo?.data[0];
        stateData.push(createdData);
        setMaterialCalculateData(stateData);
        setSelectedRowId(createdData?.id);
        setSelectedData(createdData);
        setItemNo(stateData?.length);
        props?.updateSelectedRowId(createdData?.id, 0, 1, ascSortFlg, sortNm, {});
      }
      setVisibleAddNewModal(false);
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    }
  };

  const updConfirmCancel = () => {
    setUpdateCancelConfirm(false);
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      setEditModeCancelConfirm(true);
    }
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit();
      }
    } else {
      handleAddFormSubmit();
    }
  };

  // 新規作成ボタンの押下
  const handleAddFormSubmit = () => {
    setSteelFixedSizeNm('');
    setSteelFixedSizeLen(0);
    setSteelFixedSizeMatTypes([]);
    setVisibleAddNewModal(true);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const deleteOK = async () => {
    setVisibleDeleteModal(false);
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteSteelFixedSizeInfo(selectedData?.id);
    if (deletedData !== undefined && deletedData?.data?.[0].id > 0) {
      let data = [...steelMaterialCalculateData];
      data = data?.filter((item) => item.id != deletedData?.data?.[0].id);
      setMaterialCalculateData(data);
      setSelectedRowId(data?.length > 0 ? data[0]?.id : -1);
      setSelectedData(data?.length > 0 ? data[0] : []);
      props?.updateSelectedRowId(data?.length > 0 ? data[0]?.id : 0, 0, currentPage, ascSortFlg, sortNm, {});
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData, steelUnitUse) => {
    var tmpData = [...steelMaterialCalculateData];
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    let steelMaterialCalDiff = compareIQ5Info(selectedData, updateData);
    if (steelMaterialCalDiff) {
      updatedInfo = await updateSteelFixedSizeInfo(updateData);
      updatedInfo = updateData;
      var index = tmpData?.findIndex((item) => item.id === updatedInfo?.id);
      tmpData[index] = updatedInfo;
      setMaterialCalculateData(tmpData);
      setSelectedRowId(updatedInfo?.id);
      setSelectedData(updatedInfo);
      props?.updateSelectedRowId(updatedInfo?.id, 0, currentPage, ascSortFlg, sortNm, {});
    }
    let steelUnitUseDiff = compareIQ5Info(steelUnitUseLst, steelUnitUse);
    if (steelUnitUseDiff) {
      let steelUnitUseUp = await updateSteelUnitUseInfo(steelUnitUse);
      if (steelUnitUseUp && steelUnitUseUp?.data?.length > 0) {
        setSteelUnitUseLst(steelUnitUseUp?.data);
        let usedData = steelUnitUseUp?.data?.filter((item) => item.used);
        setSteelUnitUseNm(usedData);
      }
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  const addModalContent = (
    <div>
      <div id="showData" style={{ overflowY: 'scroll', height: '57.9vh' }}>
        <RTable id="param_detail_tbl" className="propertiesTbl">
          <thead>
            {/* 名称 */}
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">名称</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  className={'input-editable'}
                  name="name"
                  id="name"
                  value={steelFixedSizeNm}
                  type="text"
                  ref={(ref) => {
                    inputItemRef['name'] = ref;
                  }}
                  maxLength={50}
                  onChange={(e) => setSteelFixedSizeNm(e.target.value)}
                />
              </td>
            </tr>
            {/* 長さ */}
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">長さ</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  defaultValue={0}
                  className={'input-editable'}
                  name="length"
                  id="length"
                  value={steelFixedSizeLen}
                  type="text"
                  ref={(ref) => {
                    inputItemRef['length'] = ref;
                  }}
                  onChange={(e) => setSteelFixedSizeLen(e.target.value)}
                />
              </td>
            </tr>
            {/* SteelMaterialType */}
            {selectedMaterialType != undefined
              ? selectedMaterialType?.map((mateiralType, index) => {
                  let { id, name } = mateiralType;
                  return (
                    <>
                      <tr className="detail-tbl-row" key={index}>
                        <td className="param-detail-tbl-val-title">
                          <label className="param_detail-title-label">{name}</label>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <Checkbox
                            style={{ marginLeft: 10, marginRight: 10 }}
                            type="checkbox"
                            name={'steelMaterialType' + index}
                            id={'steelMaterialType' + index}
                            defaultChecked={false}
                            checked={
                              steelFixedSizeMatTypes?.filter((item) => item.materialType.id == id)?.[0]?.info?.isUsed
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              let steelFixedSizeMatExist = steelFixedSizeMatTypes?.filter(
                                (item) => item.materialType.id == id
                              );
                              let newState = [];
                              if (steelFixedSizeMatExist?.length == 0) {
                                newState = steelFixedSizeMatTypes
                                  ? JSON.parse(JSON.stringify([...steelFixedSizeMatTypes]))
                                  : [];
                                newState.push({
                                  id: 0,
                                  materialType: { id: id },
                                  info: { isUsed: e.target.checked },
                                });
                              } else {
                                newState = steelFixedSizeMatTypes
                                  ? JSON.parse(JSON.stringify([...steelFixedSizeMatTypes]))
                                  : [];
                                newState?.map((matType) => {
                                  if (matType.materialType.id == id) {
                                    matType.info.isUsed = e.target.checked;
                                  }
                                });
                              }
                              if (newState?.length > 0) setSteelFixedSizeMatTypes(newState);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })
              : ''}
          </thead>
        </RTable>
      </div>
    </div>
  );

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            <Space size="middle" className="search-bar">
              <div style={{ display: 'flex' }}>
                <p style={{ whiteSpace: 'pre-line', lineHeight: 2 }}>
                  {'採用単価初期設定 : ' + steelUnitUseNm?.[0]?.name}
                </p>
              </div>
            </Space>
            <>
              {/* 一覧アリア */}
              <Row>
                <Table
                  showSorterTooltip={false}
                  columns={[...columns, ...chkData]}
                  dataSource={steelMaterialCalculateData}
                  scroll={{ y: 590, x: '10vw' }}
                  rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                  rowKey={(record) => record.id}
                  pagination={{
                    position: ['bottomLeft'],
                    total: totalCount,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    defaultCurrent: 1,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizes,
                    showSizeChanger: true,
                    onShowSizeChange: handlePageSizeChange,
                    current: currentPage,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  className="param_tbListShow"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record, index);
                      },
                    };
                  }}
                  onChange={handleTableChange}
                  loading={tblLoading}
                />
              </Row>
            </>
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq5.steelMaterialCalculate"
                element={
                  <SteelMaterialCalculateDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={steelMaterialCalculateData?.find((element) => element.id === selectedRowId)}
                    materialTypeList={materialTypeList}
                    mstMaterialTypeIq3List={mstMaterialTypeIq3List}
                    itemNo={itemNo}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    getMaterialTypeName={getMaterialTypeName}
                    getMaterialTypeIq3Name={getMaterialTypeIq3Name}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    selectedMaterialType={selectedMaterialType}
                    steelUnitUseLst={steelUnitUseLst}
                    updateSteelUnitUseInfo={updateSteelUnitUseInfo}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 一括編集　Modal */}

      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材料計算（選択したデータをまとめて編集）', 1790, -24),
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'gpEditModal',
          null,
          true
        )}

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm
        ? commonModal(
            updateCancelConfirm,
            confirmModalTitle,
            commonFooter(updCancelOK, updCancelCancel),
            null,
            400,
            updCancelOK,
            updCancelCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材料計算（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default SteelMaterialCalculateList;
