/**
 * クラス名：形鋼子部品見積詳細
 * 説明：板形鋼子部品見積詳細ファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/07
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useState, useRef  } from 'react';
import { Input, Row, Col, Select, Image, Space, Button, Radio, Checkbox, Divider, Modal, Tabs, TimePicker, Spin } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';

import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  isDigit,
  handleKeyPress,
  JPYs,
  secondsToHms,
  toDecimal_place3,
  setTimetoSec,
} from '../common/Common.js';
import {
  calAmt,
  calculateCommonMaterial,
  calculateIQ5Material,
  getIQ5CoeffDetailValues,
  getIQ5IdByName,
  getMaterialTypeIq5Nm,
  getProcessingShapeCoeffNm,
  getProcessingShapeCoeffShapeNo,
  getWeightDetailNm,
  TimeInput,
} from '../common/CommonIQ5.js';
import { ErrorMessage } from '../common/Message.js';
import { commonSizeMsgFooter, commonModal, confirmModalTitle, error } from '../common/CommonModal.js';
import CurrencyInput from 'react-currency-input-field';
import { WorkType } from '../common/enums.js';
import { detailArrCreate, PNG, STEEL_SHAPE_URL, unitLst } from '../common/Constant.js';
import { WarningOutlined } from '@ant-design/icons';

// import { useModalContext } from './ModalContext';

const RadioGroup = Radio.Group;

const IQ5EstimateDetail = forwardRef((props, ref) => {
  const [detailData, setDetailData] = useState([]);
  const [currentRecordIndex, setCurrentRecordIndex] = useState(1);
  const [materialInstruction, setMaterialInstruction] = useState(1);
  const [openWorkType, setOpenWorkType] = useState(WorkType.PaSteelCut);
  const [dataType, setDataType] = useState(0);
  const [materialIq5Info, setMaterialIq5Info] = useState({});
  const [materialIq5UnitType, setMaterialIq5UnitType] = useState(1);
  const [standardLenKey, setStandardLenKey] = useState('');
  const [bladeWidth, setBladeWidth] = useState(0);
  const [disposal, setDisposal] = useState(0);
  const [materialDimension, setMaterialDimension] = useState(0);
  const [paSteelCutProcessInput, setPaSteelCutProcessInput] = useState({});
  const [size, setSize] = useState({});
  const [partCount, setPartCount] = useState({});
  const [materialUnit, setMaterialUnit] = useState(0);
  const [materialWeight, setMaterialWeight] = useState(0);
  const oldPartCountRef = useRef(partCount); 
  const oldSizeRef = useRef(size); 
  const oldMaterialUnitValueRef = useRef(materialUnit); 
  const [confirmSizeMsgOpen, setSizeConfirmMsgOpen] = useState(false);

  useEffect(() => {
    if (props?.tabOpen || props?.selectedIQ5DataDetail) {
      initEstimateDetailsInfo(props.selectedIQ5DataDetail);
    }
  }, [
    props?.tabOpen,
    props.selectedIQ5DataDetail,
    props.selectedIQ5DataDetail?.processInput,
    props.selectedIQ5DataDetail?.additions,
    props?.isDialogOk,
  ]);

  // 初期データを取得する
  const initEstimateDetailsInfo = (selectedIQ5DataDetail) => {
    const childPartDetail = selectedIQ5DataDetail;
    // setDetailData(childPartDetail);
    setDetailData({ ...childPartDetail });
    setDataType(childPartDetail?.additions?.length > 0 ? childPartDetail?.additions?.[0]?.dataType : 0);
    let materialIq5Parm = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.materialIq5;
    let materialIq5 = materialIq5Parm?.filter((item) => item.id === childPartDetail?.materialIq5Id);
    setMaterialIq5Info(materialIq5?.length > 0 ? materialIq5?.[0] : {});
    setMaterialIq5UnitType(childPartDetail?.materialUnitType);
    setMaterialDimension(childPartDetail?.materialSize);
    setMaterialInstruction(childPartDetail?.materialInstruction);
    setStandardLenKey(childPartDetail?.standardLen !== 0 ? childPartDetail?.standardLen : '');
    setSize(props?.selectedIQ5DataDetail?.size);
    setPartCount(props?.selectedIQ5DataDetail?.partCount);
    setMaterialUnit(props?.selectedIQ5DataDetail?.materialUnit);
    calculateMaterialWeight(materialIq5?.[0], childPartDetail?.materialSize);
    let paSteelCutProcess = props?.processMasterData?.filter((item) => item.workType === WorkType.PaSteelCut);
    let paSteelCutProcessSelect = childPartDetail.processSelect?.details?.filter(
      (item) => item.isUsedForCalc && item.processId === paSteelCutProcess?.[0]?.id
    );
    let paSteelCutProcessInput = childPartDetail.processInput?.filter(
      (item) => item.processId === paSteelCutProcessSelect?.[0]?.processId
    )?.[0];
    setPaSteelCutProcessInput(paSteelCutProcessInput);
    let paSteelCutProcessInputDetails = paSteelCutProcessInput?.deviceProcessInputDetails;
    if (paSteelCutProcessInputDetails?.length > 0) {
      let steelCutDevice = props?.deviceMasterInfo?.filter(
        (d) => d.id === paSteelCutProcessInputDetails?.[0]?.deviceId
      );
      //捨代
      setBladeWidth(steelCutDevice?.[0]?.details?.bladeWidth);
      // 刃幅
      setDisposal(steelCutDevice?.[0]?.details?.disposal);
    }
  };

  useImperativeHandle(ref, () => ({}), [props?.selectedIQ5DataDetail]);

  // 部品寸法確認メッセージを表示
  const showMaterialConfirm = () => {
    setSizeConfirmMsgOpen(true);
    props?.onIQ5EstimateDetailMaterialConfirmModalOpen();
  };

  // 部品寸法確認メッセージを閉じる
  const confirmOk = () => {
    setSizeConfirmMsgOpen(false);
    props?.onIQ5EstimateDetailMaterialConfirmModalClose();
  };
  
  // 部品寸法確認メッセージを閉じる
  const confirmCancle = () => {
    setSizeConfirmMsgOpen(false);
    props?.onIQ5EstimateDetailMaterialConfirmModalClose();
  };

  // 工程切り替え
  const onChangeProcessDetail = (workType) => {
    setOpenWorkType(workType);
    props?.onChangeOpenProcessDetail(true, workType);
  };

  // 部品寸法確認メッセージ
  const confirmContent = <p style={{ fontSize: '13.5px' }}><WarningOutlined style={{ color: "orange", fontSize: '30px' }} /> 部品寸法に数字を入力してください</p>;

  // 材料重量計算する
  const calculateMaterialWeight = (materialIQ5, materialSize) => {
    if (materialIQ5 !== undefined && materialSize) {
      setMaterialWeight( materialIQ5?.info?.kgPerM ? toDecimal_place3(( materialIQ5?.info?.kgPerM * materialSize) / 1000 , 3) : toDecimal_place3(0, 3));
    }
  }

  // 材料費を計算する
  const calculateMaterialCost = (materialIq5UnitType, materailUnitValue, materialNum, materialDimension) => {
    let materialSize = materialDimension;
    detailData.materialSize = materialSize;

    let materialWeight = (materialIq5Info?.info?.kgPerM * materialSize) / 1000;

    let iq5_manufacturingCnt = detailData?.partCount * props.selectedEstimateData?.estimateProducts?.quantity;

    let upSelectedData = calculateIQ5Material(
      detailData,
      materialSize,
      materialIq5UnitType,
      materailUnitValue,
      materialNum,
      materialWeight,
      materialIq5Info,
      iq5_manufacturingCnt,
      props.selectedEstimateData?.estimateProducts?.quantity
    );

    setDetailData((prev) => ({
      ...prev,
      upSelectedData
    }));
    props.selectedIQ5DataDetail.materialCostTotalPrice = upSelectedData?.materialCostTotalPrice;
    props.selectedIQ5DataDetail.materialCostUnitPrice = upSelectedData?.materialCostUnitPrice;
    props.selectedIQ5DataDetail.materialUnit = upSelectedData?.materialUnit;
  };

  // 単価で材料費を計算する
  const calculateMaterialCostByUnit = (materialIq5UnitType, materailUnitValue, materialNum, materialDimension) => {
    let materialSize = materialDimension;
    detailData.materialSize = materialSize;
    let materialWeight = (materialIq5Info?.info?.kgPerM * materialSize) / 1000;
    let iq5_manufacturingCnt = detailData?.partCount * props.selectedEstimateData?.estimateProducts?.quantity;

    let upSelectedData = calculateIQ5Material(
      detailData,
      materialSize,
      materialIq5UnitType,
      materailUnitValue,
      materialNum,
      materialWeight,
      materialIq5Info,
      iq5_manufacturingCnt,
      props.selectedEstimateData?.estimateProducts?.quantity
    );

    setDetailData(upSelectedData);
    setMaterialUnit(upSelectedData?.materialUnit);
    props.selectedIQ5DataDetail.materialCostTotalPrice = upSelectedData?.materialCostTotalPrice;
    props.selectedIQ5DataDetail.materialCostUnitPrice = upSelectedData?.materialCostUnitPrice;
    props.selectedIQ5DataDetail.materialUnit = upSelectedData?.materialUnit;
    props.selectedIQ5DataDetail.materialUnitType = upSelectedData?.materialUnitType;
  };

  // 材料指示（定尺選択の時）によって、カット数と本数計算式
  const changeMaterialValue = (e) => {
    let materialInst = materialInstructionLst?.filter((item) => item.key === e)?.[0]?.label;

    let count = 0;
    let materialLength = Number(props?.selectedIQ5DataDetail?.size); // mm
    let requiredMaterialLength = Number(props?.selectedIQ5DataDetail?.size); // mm
    let requiredMaterial =
      Number(props?.selectedIQ5DataDetail?.partCount * props?.selectedEstimateData?.estimateProducts?.quantity);
    let materialSize = Number(props?.selectedIQ5DataDetail?.materialSize);

    if (materialInst === '定尺') {
      let materialInst = standardLen?.filter((item) => item.key === 1)?.[0]?.label;
      if (materialInst !== '' || materialInst !== null || materialInst !== undefined) {
        const number = parseFloat(materialInst);
        const result = Number.isInteger(number) ? Math.floor(number) : number;
        setStandardLenKey(1);
        props.selectedIQ5DataDetail.standardLen = 1;
        let newMatriValue = result * 1000;

        const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
          bladeWidth,
          disposal,
          count,
          materialLength,
          requiredMaterialLength,
          requiredMaterial,
          materialSize,
          newMatriValue
        );
        setMaterialDimension(material);
        props.selectedIQ5DataDetail.materialSize = material;
        detailData.materialSize = material;
        calculateMaterialWeight(materialIq5Info, material);
        props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
        detailData.materialQuantity = originalMaterials;

        let processMaster = props?.processMasterData?.filter((item) => item.workType === props?.workType)?.[0];
        let processInput = props?.selectedProcessInput?.filter((item) => item.processId === processMaster?.id)?.[0];
        if (processInput) {
          processInput.deviceProcessInputDetails[0].details[0].cutCount = cutTimes;
        }
        calculateMaterialCost(materialIq5UnitType, detailData?.materialUnit, detailData?.materialQuantity, material);
        
      }
    } 
    else if (materialInst === '自動') {
      setStandardLenKey('');
      let newMaterial = materialLength + disposal + bladeWidth * 2;

      const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
        bladeWidth,
        disposal,
        count,
        materialLength,
        requiredMaterialLength,
        requiredMaterial,
        newMaterial
      );
      setMaterialDimension(material);
      props.selectedIQ5DataDetail.materialSize = material;
      detailData.materialSize = material;
      calculateMaterialWeight(materialIq5Info, material);
      props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
      detailData.materialQuantity = originalMaterials;

      let processMaster = props?.processMasterData?.filter((item) => item.workType === props?.workType)?.[0];
      let processInput = props?.selectedProcessInput?.filter((item) => item.processId === processMaster?.id)?.[0];
      if (processInput) {
        processInput.deviceProcessInputDetails[0].details[0].cutCount = cutTimes;
      }

      calculateMaterialCost(materialIq5UnitType, detailData?.materialUnit, detailData?.materialQuantity, material);
      
    } else {
      setStandardLenKey('');
      const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
        bladeWidth,
        disposal,
        count,
        materialLength,
        requiredMaterialLength,
        requiredMaterial,
        materialSize
      );
      setMaterialDimension(material);
      props.selectedIQ5DataDetail.materialSize = material;
      detailData.materialSize = material;
      calculateMaterialWeight(materialIq5Info, material);
      props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
      detailData.materialQuantity = originalMaterials;

      let processMaster = props?.processMasterData?.filter((item) => item.workType === props?.workType)?.[0];
      let processInput = props?.selectedProcessInput?.filter((item) => item.processId === processMaster?.id)?.[0];
      if (processInput) {
        processInput.deviceProcessInputDetails[0].details[0].cutCount = cutTimes;
      }

      calculateMaterialCost(materialIq5UnitType, detailData?.materialUnit, detailData?.materialQuantity, material);
    }
    props.selectedIQ5DataDetail.materialInstruction = e;
  };

  // 材料指示（定尺）によって、カット数と本数計算式
  const calculateMaterialByStandardLan = (e) => {
    if (e !== '') {
      let materialInst = standardLen?.filter((item) => item.key === e)?.[0]?.label;
      if (materialInst !== '' || materialInst !== null || materialInst !== undefined) {
        const number = parseFloat(materialInst);
        const result = Number.isInteger(number) ? Math.floor(number) : number;
        setStandardLenKey(e);
        let newMatriValue = result * 1000;
        let count = 0;

        let materialLength = props?.selectedIQ5DataDetail?.size; // mm
        let requiredMaterialLength = props?.selectedIQ5DataDetail?.size; // mm
        let requiredMaterial =
          props?.selectedIQ5DataDetail?.partCount * props?.selectedEstimateData?.estimateProducts?.quantity;
        let materialSize = props?.selectedIQ5DataDetail?.materialSize;

        const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
          bladeWidth,
          disposal,
          count,
          materialLength,
          requiredMaterialLength,
          requiredMaterial,
          materialSize,
          newMatriValue
        );

        setMaterialDimension(newMatriValue);
        props.selectedIQ5DataDetail.materialSize = material;
        detailData.materialSize = material;
        calculateMaterialWeight(materialIq5Info, material);
        props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
        detailData.materialQuantity = originalMaterials;

        let processMaster = props?.processMasterData?.filter((item) => item.workType === props?.workType)?.[0];
        let processInput = props?.selectedProcessInput?.filter((item) => item.processId === processMaster?.id)?.[0];
        if (processInput) {
          processInput.deviceProcessInputDetails[0].details[0].cutCount = cutTimes;
        }

        calculateMaterialCost(materialIq5UnitType, detailData?.materialUnit, detailData?.materialQuantity, material);
        props.selectedIQ5DataDetail.standardLen = e;
      }
    }
  };
   // 採用単価によって単価の値を取得する
  const getMaterialUnitValue = (materialUnitType) => {
    let matUnitNm = unitLst?.find((u) => u.key === materialUnitType)?.label;
    let matUnit = 0;
    if (matUnitNm === 'M単価') {
      matUnit = materialIq5Info?.info?.pricePerM ? JPYs.format(materialIq5Info?.info?.pricePerM) : JPYs.format(0);
    } else if (matUnitNm === 'Kg単価') {
      matUnit = materialIq5Info?.info?.pricePerKg ? JPYs.format(materialIq5Info?.info?.pricePerKg) : JPYs.format(0);
    } else {
      matUnit = JPYs.format(0);
    }
    setDetailData({
      ...detailData,
      materialUnit: matUnit,
    });
    return matUnit;
  };

  // 自動の段取り・加工を手動にコピーする
  const copyAmt = () => {
    let curDetailData = JSON.parse(
      JSON.stringify(detailData)
    );
    let calUpdateData = curDetailData;
    for (let j = 0; j < props?.processMasterData?.length; j++) {
      let curProcess = props?.processMasterData?.[j];
      
      let curAddition = calUpdateData?.additions?.filter((item) => item.processId == curProcess?.id)?.[0];
      let originalData = props?.selectedIQ5DataDetail?.additions?.filter((item) => item.processId == curProcess?.id)?.[0];
      // 
      curAddition.editItems.totalDandoriTime = curAddition.dataItems.totalDandoriTime;
      curAddition.editItems.totalDandoriAmt = curAddition.dataItems.totalDandoriAmt;
      curAddition.editItems.totalSagyoTime = curAddition.dataItems.totalSagyoTime;
      curAddition.editItems.totalSagyoAmt = curAddition.dataItems.totalSagyoAmt;
      curAddition.totalEditPrice = curAddition.totalDataPrice;

      originalData.editItems.totalDandoriTime = originalData.dataItems.totalDandoriTime;
      originalData.editItems.totalDandoriAmt = originalData.dataItems.totalDandoriAmt;
      originalData.editItems.totalSagyoTime = originalData.dataItems.totalSagyoTime;
      originalData.editItems.totalSagyoAmt = originalData.dataItems.totalSagyoAmt;
      originalData.totalEditPrice = originalData.totalDataPrice;
    }
    setDetailData(calUpdateData);
  }

  // 自動・手動のラジオボタン設定する
  const handleProcessDataType = (e) => {
    let curDetailData = JSON.parse(
      JSON.stringify(detailData)
    );
    for (let j = 0; j < props?.processMasterData?.length; j++) {
      let curProcess = props?.processMasterData?.[j];
      
      let curAddition = curDetailData?.additions?.filter((item) => item.processId == curProcess?.id)?.[0];
      let originalData = props?.selectedIQ5DataDetail?.additions?.filter((item) => item.processId == curProcess?.id)?.[0];
      curAddition.dataType = Number(e.target.value);
      originalData.dataType = Number(e.target.value);
    }
    // 
    setDetailData(curDetailData); 
  };

  // 段取り時間の変更によって段取り金額と部品の再計算をする
  const handleDetailDandoriTimeChange = (seconds, j) => {
    let totalSeconds = seconds
    let curDetailData = JSON.parse(
      JSON.stringify(detailData)
    );
    let curProcessId = props?.processMasterData?.[j]?.id;
    let curProcessInfo = props?.processMasterData?.filter((item) => item.id === curProcessId);
    let curProcessSelect = curDetailData.processSelect?.details?.filter(
      (item) => item.isUsedForCalc && item.processId === curProcessInfo?.[0]?.id
    );
    let curDevices = props?.deviceMasterInfo?.filter(
      (item) => item.processId === curProcessSelect?.[0]?.processId
    );
    
    let curAddition = curDetailData?.additions?.filter((item) => item.processId == curProcessId)?.[0];
    //
    let prep = curDevices?.[0]?.details?.charges?.prep;
    // 
    let sagyoAmt = calAmt(totalSeconds, prep);
    curAddition.editItems.totalDandoriAmt = sagyoAmt;
    curAddition.editItems.totalDandoriTime = totalSeconds;

    let originalData = props?.selectedIQ5DataDetail?.additions?.filter((item) => item.processId == curProcessId)?.[0];
    originalData.editItems.totalDandoriAmt = sagyoAmt;
    originalData.editItems.totalDandoriTime = totalSeconds;
    setDetailData(curDetailData);
  }

  // 加工時間の変更によって加工金額と部品の再計算をする
  const handleDetailSagyoTimeChange = (seconds, j) => {
    let totalSeconds = seconds;
    let curDetailData = JSON.parse(
      JSON.stringify(detailData)
    );
    let curProcessId = props?.processMasterData?.[j]?.id;
    let curProcessInfo = props?.processMasterData?.filter((item) => item.id === curProcessId);
    let curProcessSelect = curDetailData.processSelect?.details?.filter(
      (item) => item.isUsedForCalc && item.processId === curProcessInfo?.[0]?.id
    );
    let curDevices = props?.deviceMasterInfo?.filter(
      (item) => item.processId === curProcessSelect?.[0]?.processId
    );
    let curAddition = curDetailData?.additions?.filter((item) => item.processId == curProcessId)?.[0];
    // 
    let work = curDevices?.[0]?.details?.charges?.work;
    let sagyoAmt = calAmt(totalSeconds, work);
    curAddition.editItems.totalSagyoAmt = sagyoAmt;
    curAddition.editItems.totalSagyoTime = totalSeconds;

    let originalData = props?.selectedIQ5DataDetail?.additions?.filter((item) => item.processId == curProcessId)?.[0];
    originalData.editItems.totalSagyoAmt = sagyoAmt;
    originalData.editItems.totalSagyoTime = totalSeconds;
    
    setDetailData(curDetailData);
  } 

  const materialInstructionLst = [
    {
      label: '自動',
      key: 1,
    },
    {
      label: '手動',
      key: 2,
    },
    {
      label: '定尺',
      key: 3,
    },
  ];

  const standardLen = [
    {
      label: '2m',
      key: 1,
    },
    {
      label: '4m',
      key: 2,
    },
    {
      label: '4.5m',
      key: 3,
    },
    {
      label: '5.5m',
      key: 4,
    },
    {
      label: '8m',
      key: 5,
    },
    {
      label: '10m',
      key: 6,
    },
    {
      label: '12m',
      key: 7,
    },
  ];

  return (
    <>
     <Spin
        spinning={props?.isReCal ? true : false}
        tip=""
        size="large"
      >
      {/* {props.tabOpen ? ( */}
      <div style={{ width: '100%', paddingTop: 0 }}>
        {/* 基本情報 */}
        <Row style={{ width: '100%' }}>
          <Col span={21}>
            <label className="detail-iq5-tbl-val-title-label">【基本情報】</label>
            <div>
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 材料名称 */}
                  <tr>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">材料名称</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">{detailData?.materialName}</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">番号</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 3 }}
                        className={'input-editable-iq5-detail'}
                        name="drawingNo"
                        id="drawingNo"
                        value={detailData?.drawingNo}
                        // type="text"
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">寸法</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 5 }}
                        className={'input-editable-iq5-detail'}
                        name="size"
                        id="size"
                        value={size}
                        // type="text"
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          setSize(inputValue);
                        }}
                        onFocus={() => {
                          oldSizeRef.current = size;
                        }}
                        onBlur={(e) => {
                          let inputValue = e.target.value;
                          if (inputValue !== oldSizeRef.current){
                            // 材料費計算と切断工程計算
                            let curMaterialIq5 = props?.estimateParameters?.materialIq5?.find(
                              (item) => item.id === props?.selectedIQ5DataDetail?.materialIq5Id && item?.info?.isUsed
                            );
                            props.selectedIQ5DataDetail.size = inputValue;
                            props?.calMaterialAndProcessingAmtByAllWorkType(curMaterialIq5, props?.selectedIQ5DataDetail);
                            props?.updateiq5ActionForCal(true);
                            props?.updateIsRecal(true);
                          }
                        }}
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">員数</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 5 }}
                        className={'input-editable-iq5-detail'}
                        name="partCount"
                        id="partCount"
                        value={partCount}
                        // type="text"
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          setPartCount(inputValue);
                        }}
                        onFocus={() => {
                          oldPartCountRef.current = partCount;
                        }}
                        onBlur={(e) => {
                          // 員数はフォーカス離す、タブを離れることで自動計算される。
                          let inputValue = e.target.value;
                          if (inputValue !== oldPartCountRef.current){
                            // 材料費計算と切断工程計算
                            let curMaterialIq5 = props?.estimateParameters?.materialIq5?.find(
                              (item) => item.id === props?.selectedIQ5DataDetail?.materialIq5Id && item?.info?.isUsed
                            );
                            props.selectedIQ5DataDetail.partCount = inputValue;
                            props?.calMaterialAndProcessingAmtByAllWorkType(curMaterialIq5, props?.selectedIQ5DataDetail);
                            props?.updateiq5ActionForCal(true);
                            props.updateIsRecal(true);
                          }
                        }}
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label className="detail-iq5-tbl-val-title-label">
                        面積(cm<sup>2</sup>)
                      </label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">
                        {detailData?.totalSurfaceArea ? Math.round(detailData?.totalSurfaceArea) : 0}
                      </label>
                    </td>
                  </tr>
                  {/** 材質名称 */}
                  <tr>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title ">
                      <label>材質名称</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">{detailData?.materialTypeName}</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label>名称</label>
                    </td>
                    <td style={{ width: '14%' }} className="detail-tbl-val">
                      <Input
                        style={{ padding: 5 }}
                        className={'input-editable-iq5-detail'}
                        name="name"
                        id="name"
                        value={detailData?.name}
                        // type="text"
                      />
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title ">
                      <label>鋼材</label>
                    </td>
                    <td colSpan={2} style={{ width: '7%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">{detailData?.steelShapeName}</label>
                    </td>
                    <td style={{ width: '7%' }} className="detail-tbl-val-title">
                      <label>重量(kg)</label>
                    </td>
                    <td colSpan={2} style={{ width: '7%' }} className="detail-tbl-val detail-tbl-val-bg">
                      <label className="detail-iq5-tbl-val-title-label">
                        {detailData?.weight ? toDecimal_place3(detailData?.weight, 3) : toDecimal_place3(0, 3)}
                      </label>
                    </td>
                  </tr>
                </thead>
              </Table>
            </div>
            <div style={{ textAlign: 'left', marginTop: 5 }}>
              <Button
                className={'iq5-material-select-btn'}
                onClick={(e) => {
                  {
                    props?.showSteelMaterialList();
                  }
                }}
              >
                選択
              </Button>
            </div>
          </Col>
          <Col span={3}>
            <div className="steel-shape-img-div">
              <img
                className={
                  detailData?.imageId === undefined || detailData?.imageId === '' ? 'pmx-no-img' : 'steel-shape-img'
                }
                src={STEEL_SHAPE_URL + detailData?.imageId + PNG}
                alt=""
              />
            </div>
          </Col>
        </Row>
        {/* 材料情報 */}
        <Row style={{ width: '100%', marginTop: 20 }}>
          <Col span={24}>
            <label className="detail-iq5-tbl-val-title-label">【材料】</label>
            <Table className="iq5-detail-tbl">
              <thead>
                {/** 部品合計(mm) */}
                <tr>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>部品合計(mm)</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {detailData?.size *
                        detailData?.partCount *
                        props?.selectedEstimateData?.estimateProducts?.quantity}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料指示</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={1}
                      id="materialInstruction"
                      name="materialInstruction"
                      className={'materialInstruction'}
                      value={materialInstruction}
                      onChange={(e) => {
                        if (materialInstruction != e) {
                          setMaterialInstruction(e);
                          changeMaterialValue(e);
                          props?.updateiq5ActionForCal(true);
                          props?.updateIsRecal(true);
                        }
                      }}
                    >
                      {materialInstructionLst?.map((item, index) => (
                        <Select.Option key={index} id={'materialInstruction' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料寸法(mm)</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <Input
                      style={{
                        textAlign: 'right',
                        fontSize: '13px',
                        height: 30,
                        padding: 5,
                        borderColor: '#a5a5a563',
                        borderRadius: 4,
                      }}
                      className={
                        materialInstructionLst?.filter((item) => item.key === materialInstruction)?.[0]?.label ===
                        '手動'
                          ? 'input-editable'
                          : 'input-non-editable-koteicalculate'
                      }
                      name="materialDimension"
                      value={materialDimension}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (materialDimension != inputValue) {
                          setMaterialDimension(inputValue);
                        }
                      }}

                      onBlur={(e) => {
                        let inputValue = e.target.value;
                        let count = 0;
                        let materialLength = detailData?.size; // mm
                        let requiredMaterialLength = detailData?.size; // mm
                        let requiredMaterial = detailData?.partCount;
                        let materialSize = detailData?.materialSize;
                        const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
                          bladeWidth,
                          disposal,
                          0,
                          materialLength,
                          requiredMaterialLength,
                          requiredMaterial,
                          materialSize,
                          inputValue
                        );
                        props.selectedIQ5DataDetail.materialSize = material;
                        calculateMaterialWeight(materialIq5Info, material); let processMaster = props?.processMasterData?.filter((item) => item.workType === props?.workType)?.[0];
                        let processInput = props?.selectedProcessInput?.filter((item) => item.processId === processMaster?.id)?.[0];
                        if (processInput) {
                          processInput.deviceProcessInputDetails[0].details[0].cutCount = cutTimes;
                        }
                        calculateMaterialCost(
                          materialIq5UnitType,
                          detailData?.materialUnit,
                          originalMaterials,
                          inputValue,
                        );

                        props?.updateiq5ActionForCal(true);
                        props?.updateIsRecal(true);
                      }}
                    />
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>重量(kg)</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {materialWeight}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>捨代</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{disposal}</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>刃幅</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{bladeWidth}</label>
                  </td>
                </tr>
                {/** 本数 */}
                <tr>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title ">
                    <label>本数</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{detailData?.materialQuantity}</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>定尺名称</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={''}
                      id="standardLen"
                      name="standardLen"
                      className={'materialInstruction'}
                      value={standardLenKey}
                      disabled={materialInstruction === 3 ? false : true}
                      onChange={(e) => {
                        if (standardLenKey != e) {
                          calculateMaterialByStandardLan(e);
                          props?.updateiq5ActionForCal(true);
                          props?.updateIsRecal(true);
                        }
                      }}
                    >
                      <Select.Option key={0} id={'standardLen-1'} value={''}>
                        {''}
                      </Select.Option>
                      {standardLen?.map((item, index) => (
                        <Select.Option key={index} id={'standardLen' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title ">
                    <label>採用単価</label>
                  </td>
                  <td style={{ width: '2%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={1}
                      id="unit"
                      name="unit"
                      className={'materialInstruction'}
                      value={materialIq5UnitType}
                      onChange={(e) => {
                        if (materialIq5UnitType != e) {
                          props.selectedIQ5DataDetail.materialUnitType = e;
                          setMaterialIq5UnitType(e);
                          let mUnit = getMaterialUnitValue(e);
                          calculateMaterialCostByUnit(e, mUnit, detailData?.materialQuantity, materialDimension);
                          props?.updateiq5ActionForCal(true);
                          props?.updateIsRecal(true);
                        }
                      }}
                    >
                      {unitLst?.map((item, index) => (
                        <Select.Option key={index} id={'unit' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>単価</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <Input
                      style={{
                        textAlign: 'right',
                        fontSize: '13px',
                        height: 30,
                        padding: 5,
                        borderColor: '#a5a5a563',
                        borderRadius: 4,
                      }}
                      className={
                        unitLst?.filter((item) => item.key === materialIq5UnitType)?.[0]?.label === '手動'
                          ? 'input-editable'
                          : 'input-non-editable-koteicalculate'
                      }
                      name="materialUnit"
                      value={materialUnit}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        setMaterialUnit(inputValue);
                      }}
                      onFocus={() => {
                        oldMaterialUnitValueRef.current = materialUnit; 
                      }}
                      onBlur={(e) => {
                        let inputValue = e.target.value;
                        if (inputValue !== oldMaterialUnitValueRef.current) {
                          let inputValue = e.target.value;
                          calculateMaterialCost(materialIq5UnitType, inputValue, detailData?.materialQuantity, materialDimension);
                          props?.updateiq5ActionForCal(true);
                          props?.updateIsRecal(true);
                        }
                      }}
                    />
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料費合計</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {props?.selectedIQ5DataDetail?.materialCostTotalPrice
                        ? JPYs.format(props?.selectedIQ5DataDetail?.materialCostTotalPrice)
                        : JPYs.format(0)}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label>材料費</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {detailData?.materialCostTotalPrice
                        ? JPYs.format(detailData?.materialCostTotalPrice)
                        : JPYs.format(0)}
                    </label>
                  </td>
                </tr>
              </thead>
            </Table>
          </Col>
        </Row>
        <div>
          {/* 採用データ */}
          <Row style={{ width: '100%', marginTop: 20 }}>
            <Space size="middle">
              <label className="detail-iq5-tbl-val-title-label">採用データ</label>
              <RadioGroup
                name="dataType"
                defaultValue={0}
                className="radioCustomerGpEdit input-editable"
                onChange={(e) => {
                  setDataType(e.target.value);
                  handleProcessDataType(e);
                  props?.updateiq5ActionForCal(true);
                  props?.updateIsRecal(true);
                }}
                value={dataType}
              >
                <Radio value={0}>自動</Radio>
                <Radio value={1}>手動</Radio>
              </RadioGroup>
              <div style={{ textAlign: 'left' }}>
                <Button
                  className={'iq5-dataType-btn'}
                  onClick={(e) => {
                    copyAmt();
                    if (dataType === 1) {
                      props?.updateiq5ActionForCal(true);
                      props?.updateIsRecal(true);
                    }
                  }}
                >
                  手動に複写
                </Button>
              </div>
            </Space>
          </Row>
          {/* 工程積算情報 */}
          <Row style={{ width: '100%', marginTop: 5 }}>
            <div style={{ width: '45%' }}>
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 工程タイトル */}
                  <tr>
                    <td
                      rowSpan={2}
                      style={{ width: '3%' }}
                      className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                    >
                      <a
                        onClick={(e) => {
                          props?.updateDetailProcessSelect(true);
                        }}
                        href="#"
                      >
                        <label className="detail-tbl-val-process-iq5">選択</label>
                      </a>
                    </td>
                    <td
                      rowSpan={2}
                      style={{ width: '5%' }}
                      className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                    >
                      <a
                        onClick={(e) => {
                          onChangeProcessDetail(WorkType.PaSteelCut);
                        }}
                      >
                        <label className="detail-tbl-val-process-iq5">工程</label>
                      </a>
                    </td>
                    <td colSpan={4} className="detail-tbl-val-title-iq5-bg-color">
                      <label className="detail-iq5-tbl-val-title-label">単価自動</label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '5%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取時間</label>
                    </td>
                    <td style={{ width: '8%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取金額</label>
                    </td>
                    <td style={{ width: '5%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工時間</label>
                    </td>
                    <td style={{ width: '8%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工金額</label>
                    </td>
                  </tr>
                  {/** 工程合計金額 */}
                  {(() => {
                    const arr = [];
                    for (let j = 0; j < props?.processMasterData?.length; j++) {
                      let curProcess = props?.processMasterData?.[j];
                      let curAddition = detailData?.additions?.filter((item) => item.processId == curProcess?.id)?.[0];
                      let processSelect = detailData?.processSelect?.details?.filter(
                        (item) => item.processId == curProcess?.id && item.isUsedForCalc
                      );
                      let totSaGyoAmt = curAddition?.dataItems?.totalSagyoAmt ? curAddition?.dataItems?.totalSagyoAmt : 0;
                      let useProcess = detailData?.processSelect?.details[j];
                      arr.push(
                        <tr>
                          <td
                            style={{ width: '3%' }}
                            className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                          >
                            <Checkbox disabled={true} checked={processSelect?.length > 0 ? true : false} />
                          </td>
                          <td
                            style={{ width: '5%' }}
                            className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5"
                          >
                            <a
                              onClick={(e) => {
                                if (processSelect?.length > 0) {
                                  if (size > 0) {
                                    onChangeProcessDetail(curProcess?.workType);
                                  } else {
                                    // 部品寸法確認メッセージを表示
                                    showMaterialConfirm();
                                  }
                                }
                              }}
                            >
                              <label className="detail-tbl-val-process-iq5">{curProcess?.name}</label>
                            </a>
                          </td>
                          <td style={{ width: '5%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label ">
                              {useProcess?.isUsedForCalc ? curAddition?.dataItems?.totalDandoriTime
                                ? secondsToHms(curAddition?.dataItems?.totalDandoriTime)
                                : secondsToHms(0) : secondsToHms(0)}
                            </label>
                          </td>
                          <td style={{ width: '8%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {useProcess?.isUsedForCalc ? curAddition?.dataItems?.totalDandoriAmt
                                ? JPYs.format(curAddition?.dataItems?.totalDandoriAmt )
                                : JPYs.format(0) : JPYs.format(0)}
                            </label>
                          </td>
                          <td style={{ width: '5%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {useProcess?.isUsedForCalc ? curAddition?.dataItems?.totalSagyoTime
                                ? secondsToHms(curAddition?.dataItems?.totalSagyoTime)
                                : secondsToHms(0) : secondsToHms(0)}
                            </label>
                          </td>
                          <td style={{ width: '8%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {useProcess?.isUsedForCalc ? curAddition?.dataItems?.totalSagyoAmt
                                ? JPYs.format(totSaGyoAmt)
                                : JPYs.format(0) : JPYs.format(0)}
                            </label>
                          </td>
                        </tr>
                      );
                    }
                    return arr;
                  })()}
                </thead>
              </Table>
            </div>
            <div style={{ width: '36%' }} className="iq5-detail-tbl-space">
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 工程タイトル */}
                  <tr>
                    <td colSpan={4} style={{ width: '8%' }} className="detail-tbl-val-title-iq5-bg-color ">
                      <label className="detail-iq5-tbl-val-title-label">単価手動</label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '6%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取時間</label>
                    </td>
                    <td style={{ width: '10%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>段取金額</label>
                    </td>
                    <td style={{ width: '6%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工時間</label>
                    </td>
                    <td style={{ width: '10%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>加工金額</label>
                    </td>
                  </tr>
                  {/** 工程合計金額 */}
                  {(() => {
                    const arr = [];
                    

                    for (let j = 0; j < props?.processMasterData?.length; j++) {
                      
                      let newDetailData = JSON.parse(JSON.stringify(detailData));
                      let curProcess = props?.processMasterData?.[j];
                      let curAddition = newDetailData?.additions?.filter((item) => item.processId == curProcess?.id)?.[0];
                      let totSaGyoAmt = curAddition?.editItems?.totalSagyoAmt ? curAddition?.editItems?.totalSagyoAmt : 0;
                      let useProcess = newDetailData?.processSelect?.details[j];
                      arr.push(
                        <tr>
                          <td style={{ width: '6%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <TimeInput
                              // id={j + 'setupAmt'}
                              style={{ color: curAddition?.editItems?.totalDandoriTime === curAddition?.dataItems?.totalDandoriTime || !useProcess?.isUsedForCalc ? "black" : "red" }}
                              value={useProcess?.isUsedForCalc ? curAddition?.editItems?.totalDandoriTime
                                ? secondsToHms(curAddition?.editItems?.totalDandoriTime)
                                : secondsToHms(0) : secondsToHms(0)}
                              disabled={!useProcess?.isUsedForCalc}
                              onBlur={(e) => {
                                if (curAddition?.editItems?.totalDandoriTime !== setTimetoSec(e)) {
                                  curAddition.editItems.totalDandoriTime = setTimetoSec(e);
                                  props.selectedIQ5DataDetail.additions[j].editItems.totalDandoriTime = setTimetoSec(e);
                                  setDetailData(newDetailData);
                                  handleDetailDandoriTimeChange(setTimetoSec(e), j);
                                  if (dataType === 1) {
                                    props?.updateiq5ActionForCal(true);
                                    props?.updateIsRecal(true);
                                  }
                                }
                              }}
                            />
                          </td>
                          <td style={{ width: '10%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <CurrencyInput
                              id={j + 'setupAmt'}
                              value={useProcess?.isUsedForCalc ? curAddition?.editItems?.totalDandoriAmt ? curAddition?.editItems?.totalDandoriAmt : 0 : 0}
                              defaultValue={0}
                              disabled = {!useProcess?.isUsedForCalc}
                              onValueChange={(e) => {
                                if (curAddition?.editItems?.totalDandoriAmt != e){
                                  let curDetailData = 
                                  JSON.parse(
                                    JSON.stringify(detailData)
                                  );
                                  curDetailData.additions[j].editItems.totalDandoriAmt = Number(e);
                                  props.selectedIQ5DataDetail.additions[j].editItems.totalDandoriAmt = Number(e);
                                  setDetailData(curDetailData);
                                }
                              }}
                              onBlur={(e) => {
                                if (dataType === 1 && curAddition?.editItems?.totalDandoriAmt != e) {
                                  props?.updateiq5ActionForCal(true);
                                  props?.updateIsRecal(true);
                                }
                              }}
                              style={{ marginLeft: 1 ,color: curAddition?.editItems?.totalDandoriAmt === curAddition?.dataItems?.totalDandoriAmt || !useProcess?.isUsedForCalc ? "black" : "red"}}
                              className={'input-editable chargeAmount_currency'}
                              prefix="¥"
                              decimalsLimit={100}
                            />
                          </td>
                          <td style={{ width: '6%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <TimeInput
                                style={{ color: curAddition?.editItems?.totalSagyoTime === curAddition?.dataItems?.totalSagyoTime || !useProcess?.isUsedForCalc ? "black" : "red" }}
                                value={useProcess?.isUsedForCalc ? curAddition?.editItems?.totalSagyoTime
                                  ? secondsToHms(curAddition?.editItems?.totalSagyoTime)
                                  : secondsToHms(0) : secondsToHms(0)}
                                disabled={!useProcess?.isUsedForCalc}
                                onBlur={(e) => {
                                  if (curAddition?.editItems?.totalSagyoTime !== setTimetoSec(e)) {
                                    curAddition.editItems.totalSagyoTime = setTimetoSec(e);
                                    props.selectedIQ5DataDetail.additions[j].editItems.totalSagyoTime = setTimetoSec(e);
                                    setDetailData(newDetailData);
                                    handleDetailSagyoTimeChange(setTimetoSec(e), j);
                                    if (dataType === 1) {
                                      props?.updateiq5ActionForCal(true);
                                      props?.updateIsRecal(true);
                                    }
                                  }
                                  
                                }}
                              />
                          </td>
                          <td style={{ width: '10%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <CurrencyInput
                              id={j + 'processingAmt'}
                              value={useProcess?.isUsedForCalc ? totSaGyoAmt : 0}
                              defaultValue={0}
                              disabled = {!useProcess?.isUsedForCalc}
                              onValueChange={(e) => {
                                if ( curAddition?.editItems?.totalSagyoAmt != e){
                                  let curDetailData = JSON.parse(
                                    JSON.stringify(detailData)
                                  );
                                  totSaGyoAmt = Number(e);
                                  curDetailData.additions[j].editItems.totalSagyoAmt = Number(e);
                                  props.selectedIQ5DataDetail.additions[j].editItems.totalSagyoAmt = Number(e);
                                  setDetailData(curDetailData);
                                }
                              }}
                              onBlur={(e) => {
                                if (dataType === 1 && curAddition?.editItems?.totalSagyoAmt != e) {
                                  props?.updateiq5ActionForCal(true);
                                  props?.updateIsRecal(true);
                                }
                              }}
                              style={{ marginLeft: 1 ,color: curAddition?.editItems?.totalSagyoAmt === curAddition?.dataItems?.totalSagyoAmt || !useProcess?.isUsedForCalc ? "black" : "red"}}
                              className={'input-editable chargeAmount_currency'}
                              prefix="¥"
                              decimalsLimit={100}
                            />
                          </td>
                        </tr>
                      );
                    }
                    return arr;
                  })()}
                </thead>
              </Table>
            </div>
            <div style={{ width: '18.5%' }} className="iq5-detail-tbl-space">
              <Table className="iq5-detail-tbl">
                <thead>
                  {/** 工程タイトル */}
                  <tr>
                    <td colSpan={2} className="detail-tbl-val-title-iq5-bg-color"></td>
                  </tr>
                  <tr>
                    <td style={{ width: '12%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>単価</label>
                    </td>
                    <td style={{ width: '12%' }} className="detail-tbl-val-title-iq5-bg-color detail-tbl-val-title-iq5">
                      <label>合計金額</label>
                    </td>
                  </tr>
                  {/** 単価・合計金額 */}
                  {(() => {
                    const arr = [];
                    for (let j = 0; j < props?.processMasterData?.length; j++) {
                      let curProcess = props?.processMasterData?.[j];
                      let curAddition = props?.selectedIQ5DataDetail?.additions?.filter((item) => item.processId == curProcess?.id)?.[0];
                      let totalAmt = dataType === 0 ? curAddition?.totalDataPrice : curAddition?.totalEditPrice;
                      // totalAmt -= curAddition?.editItems?.totalDandoriAmt;
                      // totalAmt /= partCount;
                      // totalAmt += curAddition?.editItems?.totalDandoriAmt;
                      let unitAmt = 
                        totalAmt / (detailData?.partCount * props?.selectedEstimateData?.estimateProducts?.quantity);
                      unitAmt = Number.isFinite(unitAmt) ? unitAmt : 0;
                      arr.push(
                        <tr>
                          <td style={{ width: '12%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label ">
                              {unitAmt ? JPYs.format(unitAmt) : JPYs.format(0)}
                            </label>
                          </td>
                          <td style={{ width: '12%' }} className="detail-tbl-val detail-iq5-tbl-val-title-label-amt">
                            <label className="detail-iq5-tbl-val-title-label">
                              {totalAmt ? JPYs.format(totalAmt) : JPYs.format(0)}
                            </label>
                          </td>
                        </tr>
                      );
                    }
                    return arr;
                  })()}
                </thead>
              </Table>
            </div>
          </Row>
        </div>
      </div>
    </Spin>
    {confirmSizeMsgOpen
      ? commonModal(
          confirmSizeMsgOpen,
          confirmModalTitle,
          commonSizeMsgFooter(confirmOk),
          null,
          400,
          confirmOk,
          confirmCancle,
          confirmContent,
          null,
          null,
          false
        )
      : ''}
    </>
  );
});

export default IQ5EstimateDetail;
