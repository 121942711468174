/**
 * クラス名：形鋼の材質名称一覧画面
 * 説明：形鋼に使用の材質名称一覧ファイルです。
 * 作成者：ナンス
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import { Table, Button, Row, Input, Col, Modal, Select, Image, Typography } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import 'split-pane-react/esm/themes/default.css';
import SplitPane from 'split-pane-react';
import { arrayMoveImmutable } from 'array-move';

import '../../../../assets/styles/common.css';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmDeleteContent,
  confirmCreateContent,
  confirmUpdateContent,
  commonActionFooter,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal.js';
import {
  actionArr,
  formatDate,
  formValidatorMode,
  defaultValue,
  regexExp,
  toHalfWidth,
  formatDateString,
  todayTime,
  isDecimal,
  toDecimal,
  getIQ5MaterialTypeList,
} from '../../../common/Common.js';
import { ErrorMessage } from '../../../common/Message.js';
import { pageSizes } from '../../../common/Constant.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';
import SteelMaterialNameDetail from './SteelMaterialNameDetail.js';
import {
  createSteelMaterialNameCSV,
  createSteelMaterialNameInfo,
  deleteSteelMaterialNameInfo,
  getSteelMaterialNameInfo,
  updateSteelMaterialNameInfo,
  updateSteelMaterialNameSortInfo,
} from '../../../common/CommonAPI.js';
import { compareIQ5Info } from '../../../common/CommonIQ5.js';

dayjs.extend(customParseFormat);

const SteelMaterialNameList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showCopyNewModal: showCopyNewModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const [paramSelected, setParamSelected] = useState('材質名称');
  const [paramType, setParamType] = useState('iq5');
  const [steelMaterialNameData, setSteelMaterialNameData] = useState([]);
  const [tempSteelMaterialName, setTempSteelMaterialName] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [mstMaterialTypeIq3List, setMstMaterialTypeIq3List] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState();
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  // Content Resize
  const [sizes, setSizes] = useState(['60%']);
  // 一括編集選択行
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);
  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();
  // pagination
  const [pageSize, setPageSize] = useState(30);
  const [totalCount, setTotalCount] = useState(0);
  const [ascSortFlg, setAscSortFlg] = useState('ASC');
  const [sortNm, setSortNm] = useState('id');
  const [filterDatas, setFilterDatas] = useState({
    isUsedFlg: 1,
  });
  const [tblLoading, setTblLoading] = useState(false);
  const [curIndex, setCurIndex] = useState(0); // 現在Index
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [filteredDataBK, setFilteredDataBk] = useState([]);
  const [isDiff, setIsDiff] = useState(false);

  const csvExportFileName = `材質名称_${formatDateString(todayTime())}.csv`;
  const detailRef = useRef();
  const messageRef = useRef([]);
  const navigate = useNavigate();
  const inputItemRef = {};

  // 材質区分名をIDで取得する
  const getMaterilTypeName = (materialTypeId) => {
    return materialTypeList?.filter((item) => item.value === materialTypeId)?.[0]?.label;
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      render: (id, record, index) => {
        let pageBefore = currentPage - 1;
        let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
        return startPage + (index + 1); // IQX_WEBEST-240
      },
      className: 'cm-a-right',
    },
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: 80,
      sorter: (a, b) => setSortNm('code'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (record, i) => {
        return (
          <Typography.Text id={'typographyTxt'} ellipsis={{ tooltip: [record] }}>
            {record ? record : ''}
          </Typography.Text>
        );
      },
    },
    {
      key: 'name',
      title: '材質名称',
      dataIndex: 'name',
      width: 220,
      sorter: (a, b) => setSortNm('name'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (record, i) => {
        return (
          <Typography.Text id={'typographyTxt'} ellipsis={{ tooltip: [record] }}>
            {record ? record : ''}
          </Typography.Text>
        );
      },
    },
    {
      key: 'materialTypeId',
      title: '材質区分名',
      dataIndex: 'materialTypeId',
      width: 100,
      sorter: (a, b) => setSortNm('materialTypeId'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (materialTypeId) => getMaterilTypeName(materialTypeId),
    },
    {
      key: 'gravity',
      title: '比重',
      dataIndex: 'info',
      width: 90,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('gravity'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      render: (info) => toDecimal(info?.gravity, 2),
    },
  ];

  useEffect(() => {
    getSteelMaterialNameIQ5Data();
  }, []);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(filteredData?.find((item) => item.id === changeRecordId));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else if (discardOKAction.key === actionArr[6]?.key) {
        // CSV入力
        onClickCSVImport();
      } else if (discardOKAction.key === actionArr[7]?.key) {
        // CSV出力
        onClickCSVExport();
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  // 板金子部品材質名称マスタ情報を取得する
  const getSteelMaterialNameIQ5Data = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    let steelMaterialNameData = [];
    let pageBefore =
      props.initialParam === 'iq5_steelMaterialName' && props?.rowSelectPage !== undefined && props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    let propSortFlg =
      props.initialParam === 'iq5_steelMaterialName' && props?.ascSortFlg !== undefined
        ? props?.ascSortFlg
        : ascSortFlg;
    let propSortNm =
      props.initialParam === 'iq5_steelMaterialName' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
    let propFilterDatas =
      props.initialParam === 'iq5_steelMaterialName' && props?.filterDatas !== undefined
        ? props?.filterDatas
        : filterDatas;
    const steelMaterialNameInfo = await getSteelMaterialNameInfo(
      pageSize,
      pageBefore,
      propSortFlg,
      propSortNm,
      propFilterDatas
    );
    if (steelMaterialNameInfo) {
      steelMaterialNameData = steelMaterialNameInfo ? steelMaterialNameInfo?.data : [];
      setTotalCount(steelMaterialNameInfo ? steelMaterialNameInfo?.totalCount : 0);
    }
    setSteelMaterialNameData(steelMaterialNameData);
    setTempSteelMaterialName(steelMaterialNameData);
    setFilteredData(steelMaterialNameData);
    setFilteredDataBk(steelMaterialNameData);
    if (steelMaterialNameData?.length > 0) {
      setSelectedRowId(steelMaterialNameData[0]?.id);
      setSelectedData(steelMaterialNameData[0]);
      // 前回選択された行がある場合
      if (
        props.initialParam === 'iq5_steelMaterialName' &&
        props.selectedRowId !== undefined &&
        props.selectedRowId !== 0
      ) {
        let selectedRowData = steelMaterialNameData?.filter((item) => item.id === props?.selectedRowId);
        if (selectedRowData?.length > 0) {
          setSelectedRowId(selectedRowData?.[0]?.id);
          setSelectedData(selectedRowData?.[0]);
        }
        setShowFlag(propFilterDatas?.isUsedFlg);
      }
    }

    props.setParam(paramType, paramSelected);
    let materialTypeList = await getIQ5MaterialTypeList();
    setMaterialTypeList(materialTypeList);
    let mstMaterialTypeIq3List = [];
    setMstMaterialTypeIq3List(mstMaterialTypeIq3List);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 材質区分名をIDで取得する
  const getMaterialTypeName = (materialTypeId) => {
    return materialTypeList?.filter((item) => item.value === materialTypeId)?.[0]?.label;
  };

  // 材質名称をIDで取得する
  const getMaterialTypeIq3Name = (materialTypeIq3Id) => {
    return mstMaterialTypeIq3List?.filter((item) => item.value === materialTypeIq3Id)?.[0]?.label;
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setTblLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let ascSortFlg = 'ASC';
    if (sorter?.order === undefined) {
      ascSortFlg = '';
    } else if (sorter?.order === 'ascend') {
      ascSortFlg = 'ASC';
    } else if (sorter?.order === 'descend') {
      ascSortFlg = 'DESC';
    }
    setAscSortFlg(ascSortFlg);
    let steelMaterialNameIQ5Info = await getSteelMaterialNameInfo(
      pageSize,
      pageBefore,
      ascSortFlg,
      sorter?.columnKey,
      filterDatas
    );
    if (steelMaterialNameIQ5Info) {
      let materialTypeIQ5 = steelMaterialNameIQ5Info ? steelMaterialNameIQ5Info?.data : [];
      setFilteredData(materialTypeIQ5);
      setFilteredDataBk(materialTypeIQ5);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
  };

  // 新規作成の項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (name === 'gravity') {
      if (isCheckZenkaku) value = toDecimal(value, 2);
      setTempSteelMaterialName((prevData) => ({
        ...prevData,
        info: { ...prevData.info, gravity: value },
      }));
    }
  };

  const onRowSelect = (record, index) => {
    setCurIndex(index);
    setChangeRecordId(record?.id);
    setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowId(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        navigate(':iq5.steelMaterialName');
      }
    } else {
      setSelectedRowId(record?.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq5.steelMaterialName');
    }
    props?.updateSelectedRowId(record?.id, 0, currentPage, ascSortFlg, sortNm, filterDatas);
    props?.resetInitialParam();
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
  };

  const updConfirmOk = async (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(steelMaterialNameData));
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      // 画面の入力チェック
      if (!(await detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false))) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let createdDataInfo = await createSteelMaterialNameInfo(tempSteelMaterialName);
      if (createdDataInfo && createdDataInfo?.data?.length > 0) {
        let createdData = createdDataInfo?.data[0];
        stateData.push(createdData);
        setFilteredData(stateData);
        setFilteredDataBk(stateData);
        setSteelMaterialNameData(stateData);
        resetAddModal(stateData?.length + 1);
        setSelectedRowId(createdData?.id);
        setSelectedData(createdData);
        setItemNo(stateData?.length);
        props?.updateSelectedRowId(createdData?.id, 0, 1, ascSortFlg, sortNm, filterDatas);
      }
      setVisibleAddNewModal(false);

      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    }
  };

  const updConfirmCancel = () => {
    setUpdateCancelConfirm(false);
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    }
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      /* updateEditMode(false); */
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit(false);
      }
    } else {
      handleAddFormSubmit(false);
    }
  };

  const showCopyNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[8]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit(true);
      }
    } else {
      handleAddFormSubmit(true);
    }
  };

  // 新規作成ボタンの押下
  const handleAddFormSubmit = (isCopy) => {
    if (isCopy) {
      let copyData = JSON.parse(JSON.stringify(selectedData));
      setTempSteelMaterialName({
        code: copyData?.code,
        name: copyData?.name,
        materialTypeId: copyData?.materialTypeId,
        info: copyData?.info,
        created: dayjs().format('YYYY-MM-DD'),
        modified: dayjs().format('YYYY-MM-DD'),
      });
    } else {
      setTempSteelMaterialName({
        code: '',
        name: '',
        materialTypeId: defaultValue.materialType,
        info: { isUsed: true, gravity: 0, remarks: '' },
        created: dayjs().format('YYYY-MM-DD'),
        modified: dayjs().format('YYYY-MM-DD'),
      });
    }
    setVisibleAddNewModal(true);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const deleteOK = async () => {
    setVisibleDeleteModal(false);
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteSteelMaterialNameInfo(selectedData?.id);
    // IQX_WEBEST-281 使用しているパラメータなのに削除出来てしまう
    if (deletedData !== undefined && deletedData?.data?.[0]?.id > 0) {
      let data = [...filteredData];
      data = data?.filter((item) => item.id != deletedData?.data?.[0].id);
      setSteelMaterialNameData(data);
      setFilteredData(data);
      setTotalCount(deletedData?.totalCount);
      setFilteredDataBk(data);
      setSelectedRowId(data?.length > 0 ? data[0]?.id : -1);
      setSelectedData(data?.length > 0 ? data[0] : []);
      props?.updateSelectedRowId(data?.length > 0 ? data[0]?.id : 0, 0, currentPage, ascSortFlg, sortNm, filterDatas);
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const resetAddModal = (count) => {
    setTempSteelMaterialName({
      info: {
        isUsed: true,
        gravity: 0,
        remarks: '',
      },
    });
  };

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...steelMaterialNameData];
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updateSteelMaterialNameInfo(updateData);
    updatedInfo = updateData;
    var index = tmpData?.findIndex((item) => item.id === updatedInfo?.id);
    tmpData[index] = updatedInfo;
    setSteelMaterialNameData(tmpData);
    setFilteredData(tmpData);
    setFilteredDataBk(tmpData);
    setSelectedRowId(updatedInfo?.id);
    setSelectedData(updatedInfo);
    props?.updateSelectedRowId(updatedInfo?.id, 0, currentPage, ascSortFlg, sortNm, filterDatas);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col) => (str.length === 0 ? (str += col.title) : (str += ',' + col.title))); // ヘッダの情報
    return str;
  };

  // CSV出力情報
  const getCsvExportData = async () => {
    let str = '';
    str = getCSVHeaderInfo();
    let pageBefore =
      props.initialParam === 'iq5_steelMaterialName' && props?.rowSelectPage !== undefined && props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    let propSortFlg =
      props.initialParam === 'iq5_steelMaterialName' && props?.ascSortFlg !== undefined
        ? props?.ascSortFlg
        : ascSortFlg;
    let propSortNm =
      props.initialParam === 'iq5_steelMaterialName' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
    let propFilterDatas =
      props.initialParam === 'iq5_steelMaterialName' && props?.filterDatas !== undefined
        ? props?.filterDatas
        : filterDatas;
    const steelMaterialNameInfo = await getSteelMaterialNameInfo(
      totalCount,
      pageBefore,
      propSortFlg,
      propSortNm,
      propFilterDatas
    );
    if (steelMaterialNameInfo) {
      let filteredData = steelMaterialNameInfo ? steelMaterialNameInfo?.data : [];
      filteredData?.map((data, index) => {
        let materialTypeStr = '';
        materialTypeStr = getMaterilTypeName(data.materialTypeId);
        // CSV内容
        str += '\n' + (index + 1) + ',' + data.code + ',' + data.name + ',' + materialTypeStr + ',' + data.info.gravity;
      });
    }
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDataInfo = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let tempData = {
          code: '',
          name: '',
          materialTypeId: 0,
          info: { isUsed: true, gravity: 0, remarks: '' },
          created: dayjs().format('YYYY-MM-DD'),
          modified: dayjs().format('YYYY-MM-DD'),
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }
        let mType = materialTypeList?.filter((item) => item.label === data?.[3]);
        let mTypeId = mType && mType?.length > 0 ? mType?.[0]?.value : 0;
        tempData.id = 0;
        tempData.code = data?.[1];
        tempData.name = data?.[2];
        tempData.materialTypeId = mTypeId;
        tempData.info.gravity = toDecimal(data?.[4], 2);
        tempDatas.push(tempData);
      }
      let pageBefore =
        props.initialParam === 'iq5_steelMaterialName' &&
        props?.rowSelectPage !== undefined &&
        props?.rowSelectPage !== 0
          ? props?.rowSelectPage - 1
          : 0;
      setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
      let propSortFlg =
        props.initialParam === 'iq5_steelMaterialName' && props?.ascSortFlg !== undefined
          ? props?.ascSortFlg
          : ascSortFlg;
      let propSortNm =
        props.initialParam === 'iq5_steelMaterialName' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
      let propFilterDatas =
        props.initialParam === 'iq5_steelMaterialName' && props?.filterDatas !== undefined
          ? props?.filterDatas
          : filterDatas;
      createdDataInfo = await createSteelMaterialNameCSV(
        tempDatas,
        pageSize,
        pageBefore,
        propSortFlg,
        propSortNm,
        propFilterDatas
      );
      let createdDatas = [];
      if (createdDataInfo) {
        let createdDatas = createdDataInfo ? createdDataInfo?.data : [];
        setTotalCount(createdDataInfo ? createdDataInfo?.totalCount : 0);
        setSteelMaterialNameData(createdDatas);
        setTempSteelMaterialName(createdDatas);
        setFilteredData(createdDatas);
      }
      if (createdDatas?.length > 0) {
        setSelectedRowId(createdDatas[0]?.id);
        setSelectedData(createdDatas[0]);
        props?.updateSelectedRowId(createdDatas?.[0]?.id, 0, 1, ascSortFlg, sortNm, filterDatas);
        setCurrentPage(1);
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // 名称
    let input = data?.[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('名称').E007}</li>);
      return false;
    }

    // 材質区分名
    input = data?.[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質区分').E017}</li>);
      return false;
    }
    if (input?.length > 0) {
      let ret = true;
      input = input?.split(' ');
      for (var i = 0; i < input.length; i++) {
        // 材質区分が存在しない場合
        if (input[i] !== 'ALL' && materialTypeList?.findIndex((item) => item.label === input[i]) === -1) {
          messageRef?.current?.push(<li>{no + ErrorMessage('材質区分').E022}</li>);
          ret = false;
          break;
        }
      }
      if (!ret) return false;
    }

    // 比重
    input = data?.[4];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('比重').E007}</li>);
      return false;
    }

    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('比重').E014}</li>);
      return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  // mode「1：最初,2：一箇所前,3：一箇所後,4：最後」
  const onSortEnd = (e, mode) => {
    var maxIndex = filteredData?.length - 1; // リストの最大Index
    var oldIndex = curIndex; // 元Index
    var newIndex = curIndex; // 新Index

    //　現在、最初にある場合「最初、一箇所前へ」に移動不可。
    if (oldIndex === 0 && (mode === 1 || mode === 2)) return;
    //　現在、最後にある場合「一箇所後、最後へ」に移動不可。
    if (oldIndex === maxIndex && (mode === 3 || mode === 4)) return;

    // 最初へのボタンを押下
    if (mode === 1) newIndex = 0;
    // 一箇所前へのボタンを押下
    if (mode === 2) newIndex = newIndex - 1;
    // 一箇所後へのボタンを押下
    if (mode === 3) newIndex = newIndex + 1;
    //　最後へのボタンを押下
    if (mode === 4) newIndex = maxIndex;

    var newItems = arrayMoveImmutable(filteredData, oldIndex, newIndex);
    newItems?.map((item, index) => {
      item.info.sortNum = index + 1;
    });
    // Indexの切り替え
    setFilteredData(newItems);
    setFilteredDataBk(newItems);
    let isDiff = compareIQ5Info(filteredDataBK, newItems);
    setIsDiff(isDiff);
    setEditMode(isDiff);
    // 行の選択
    changeRowSelect(newIndex);
  };

  // Scrollの移動
  const scrollTo = (index) => {
    // Scrollの移動
    document.getElementById('sortId' + index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  // 行の選択
  const changeRowSelect = (index) => {
    setCurIndex(index);
    scrollTo(index);
  };

  const updateData = () => {
    setUpdateConfirm(true);
  };

  const discardData = () => {
    setCancelConfirm(true);
  };

  const updSortConfirmOk = async (e) => {
    setUpdateConfirm(false);
    let updateDatas = await updateSteelMaterialNameSortInfo(filteredData);
    if (updateDatas && updateDatas?.length > 0) {
      setFilteredData(updateDatas);
      setFilteredDataBk(JSON.parse(JSON.stringify(updateDatas)));
      setIsDiff(false);
      setEditMode(false);
      complete('データ更新は正常に完了しました。');
    }
  };

  const updSortConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardSortConfirmOk = () => {
    setCancelConfirm(false);
    setFilteredData(filteredDataBK);
    setIsDiff(false);
    setEditMode(false);
  };

  const discardSortConfirmCancel = () => {
    setCancelConfirm(false);
  };

  const addModalContent = (
    <div id="addNewMaterialName" style={{ marginTop: 8 }}>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="code"
                className={'input-editable'}
                value={tempSteelMaterialName?.code}
                onChange={(e) => {
                  {
                    setTempSteelMaterialName({ ...tempSteelMaterialName, code: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['code'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempSteelMaterialName?.name}
                onChange={(e) => {
                  {
                    setTempSteelMaterialName({ ...tempSteelMaterialName, name: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質区分名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                style={{ width: '60%' }}
                onChange={(e) => {
                  {
                    let gravityLst = filteredData?.filter((item) => item.materialTypeId === e);
                    let curGravity = tempSteelMaterialName?.info?.gravity;
                    if (gravityLst?.length > 0) {
                      curGravity = gravityLst?.[0]?.info.gravity;
                    }
                    curGravity = curGravity ? toDecimal(curGravity, 2) : toDecimal(0, 2);
                    setTempSteelMaterialName({
                      ...tempSteelMaterialName,
                      materialTypeId: e,
                      info: { ...tempSteelMaterialName.info, gravity: curGravity },
                    });
                  }
                }}
                id="materialTypeId"
                name="materialTypeId"
                defaultValue={defaultValue.materialType}
                value={tempSteelMaterialName?.materialTypeId}
              >
                <Select.Option id="MaterialType" value={defaultValue.materialType}>
                  {defaultValue.materialType}
                </Select.Option>
                {materialTypeList?.map((item, index) => (
                  <Select.Option key={index} id={'MaterialType' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">比重</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                id="gravity"
                name="gravity"
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempSteelMaterialName?.info?.gravity}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                ref={(ref) => {
                  inputItemRef['gravity'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
        </thead>
      </RTable>
    </div>
  );

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7, width: '68vw' }}>
            <>
              {/* 一覧アリア */}
              <Row>
                <Col span={16}>
                  <Table
                    showSorterTooltip={false}
                    columns={columns}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '10vw' }}
                    rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                    rowKey={(record) => record.id}
                    pagination={{
                      position: ['bottomLeft'],
                      total: totalCount,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      // defaultPageSize: 25,
                      defaultCurrent: 1,
                      pageSize: pageSize,
                      pageSizeOptions: pageSizes,
                      showSizeChanger: true,
                      onShowSizeChange: handlePageSizeChange,
                      current: currentPage,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow-steel-material-name"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, index);
                        },
                      };
                    }}
                    onChange={handleTableChange}
                    loading={tblLoading}
                  />
                </Col>
                <Col span={8}>
                  {/* ボタン */}
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        marginTop: 20,
                        float: 'left',
                        display: 'inline-flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginLeft: 7,
                      }}
                    >
                      <Button
                        id="toTop"
                        onClick={(e) => {
                          onSortEnd(e, 1);
                        }}
                        className="sortButton"
                      >
                        <div className="multiArrow">
                          <ArrowUpOutlined />
                          <ArrowUpOutlined />
                        </div>
                      </Button>
                      <Button
                        style={{ marginTop: 10 }}
                        id="toOneUp"
                        onClick={(e) => {
                          onSortEnd(e, 2);
                        }}
                        className="sortButton"
                      >
                        <ArrowUpOutlined />
                      </Button>
                      <Button
                        style={{ marginTop: 10 }}
                        id="toOneDown"
                        onClick={(e) => {
                          onSortEnd(e, 3);
                        }}
                        className="sortButton"
                      >
                        <ArrowDownOutlined />
                      </Button>
                      <Button
                        style={{ marginTop: 10 }}
                        id="toBottom"
                        onClick={(e) => {
                          onSortEnd(e, 4);
                        }}
                        className="sortButton"
                      >
                        <div className="multiArrow">
                          <ArrowDownOutlined />
                          <ArrowDownOutlined />
                        </div>
                      </Button>
                    </div>
                    <div
                      style={{
                        width: '20vw',
                        marginTop: 2,
                        float: 'right',
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    >
                      <Button
                        id="updateSort"
                        className={isDiff ? 'mainButton' : 'disabledButton'}
                        style={{ fontSize: '13.5px' }}
                        onClick={updateData}
                      >
                        更新
                      </Button>
                      <Button
                        id="cancelSort"
                        className={isDiff ? 'cancelButton' : 'disabledButton'}
                        style={{ marginLeft: 10, fontSize: '13.5px' }}
                        onClick={discardData}
                      >
                        破棄
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq5.steelMaterialName"
                element={
                  <SteelMaterialNameDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={steelMaterialNameData?.find((element) => element.id === selectedRowId)}
                    materialTypeList={materialTypeList}
                    mstMaterialTypeIq3List={mstMaterialTypeIq3List}
                    itemNo={itemNo}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    getMaterialTypeName={getMaterialTypeName}
                    getMaterialTypeIq3Name={getMaterialTypeIq3Name}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    getMaterilTypeName={getMaterilTypeName}
                    steelMaterialNames={filteredData}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm
        ? commonModal(
            updateCancelConfirm,
            confirmModalTitle,
            commonFooter(updCancelOK, updCancelCancel),
            null,
            400,
            updCancelOK,
            updCancelCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材質名称（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
      {/* 更新確認_Modal */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updSortConfirmOk, updSortConfirmCancel),
            null,
            400,
            updSortConfirmOk,
            updSortConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/* 破棄確認_Modal */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardSortConfirmOk, discardSortConfirmCancel),
            null,
            400,
            discardSortConfirmOk,
            discardSortConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default SteelMaterialNameList;
