/**
 * クラス名：板金子部品見積
 * 説明：板金子部品のタブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Table, Form, Button, Row, Col, Space, Image, Tooltip, Modal, Checkbox, Spin, Input, Typography } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  CloseOutlined,
  CaretRightOutlined,
  FileExclamationOutlined,
  FileDoneOutlined,
  FileAddOutlined,
  FormOutlined,
  DollarOutlined,
  MoneyCollectOutlined,
  ShopOutlined,
  ShoppingOutlined,
  ProductOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import '../../assets/styles/common.css';
import '../../assets/styles/style.css';
import down_Arrow from '../../assets/images/down_Arrow.png';
import up_Arrow from '../../assets/images/up_Arrow.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import IQ3EstimateDetail from './IQ3EstimateDetail';
import KouteiSuryouBetsuBtn from '../parent/KouteiSuryouBetsuBtn';
import IQ3Purchase from './IQ3Purchase';
import IQ3KouteiCalculate from '../iq3/IQ3KouteiCalculate';
import IQ3KouteiInput from '../iq3/IQ3KouteiInput';
import IQ3KouteiSentaku from '../iq3/IQ3KouteiSentaku';
import IQ3MaterialDetail from './IQ3MaterialDetail';
import {
  commonTaiochuModal,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  confirmModalTitle,
  commonModal,
  commonFooter,
  commonDeleteFooter,
} from '../common/CommonModal';
import parent_call_detail from '../../assets/images/parent_call_detail.png';
import ParentList from '../parent/ParentList';
import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  getMaterialThickById,
  iq3EstimateInitInfo,
  padWithLeadingZeros,
  formatDateString,
  todayTime,
  toDecimal,
  updateEstimateInfoByReCal,
  JPYs,
  getDetails,
  getInitMaterialSizeInfo,
  getInitMaterialPatternsInfo,
  reCalMaterial,
} from '../common/Common.js';
import { GetParametersByCompId, getEstimateIq3Info } from '../common/CommonAPI.js';
import { WorkType, DataType, ServiceClass, SurfaceProtectionType, EstimateCreationType } from '../common/enums.js';
import { amtEmptyStr } from '../common/Constant';
import copy_sm from '../../assets/images/copy_sm.png';
import edit_list from '../../assets/images/edit_list.png';
import manual from '../../assets/images/manual.png';
import auto from '../../assets/images/auto.png';

dayjs.extend(customParseFormat);

const IQ3Estimate = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = React.useState();
  const [modalTitleTxt, setMdalTitleTxt] = useState('確認');
  // 確認ダイアログ
  const [confirmMsgOpen, setConfirmMsgOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [discardMode, setDiscardMode] = useState(false);
  const [iQ3DataLst, setIQ3DataLst] = useState([]);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordNo, setCurrentRecordNo] = useState(0);
  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('0');
  const [selectedTabKey, setSelectedTabKey] = useState('1');
  const [isDialogOk, setIsDialogOk] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [parentListDisplay, setParentListDisplay] = useState(false);
  const [selectedParentAddEstimate, setSelectedParentAddEstimate] = useState([]);
  const [isAddPart, setIsAddPart] = useState(false);
  const [materialLst, setMaterialLst] = useState([]);
  const [isSetting, setIsSetting] = useState(false);
  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientMasterInfo, setClientMasterInfo] = useState([]);
  const [newParameters, setNewParameters] = useState();
  // Waitingダイアログの表示
  const [iq3Loading, setIq3Loading] = useState(true);
  // 材料詳細のタグを最初として入るかの判断
  const [isFirstTimeMaterialDetail, setIsFirstTimeMaterialDetail] = useState(true);
  const [iq3ActionForCal, setIq3ActionForCal] = useState(false);
  const [selectedProcessSelect, setSelectedProcessSelect] = useState({});
  const [iq3PMXDatas, setIq3PMXDatas] = useState([]);
  const [currentRecordNoPMX, setCurrentRecordNoPMX] = useState(0);
  const [detailTabModalWidth, setDetailTabModalWidth] = useState(1200);
  const [reCalByAddDelete, setReCalByAddDelete] = useState(false);
  const [isPartCountChange, setIsPartCountChange] = useState(false);
  const [currentRecordNoByChangeQty, setCurrentRecordNoByChangeQty] = useState(1);
  const [currentRecordNoByBultEdit, setCurrentRecordNoByBultEdit] = useState(0);
  const [isBulkEditAction, setIsBulkEditAction] = useState(false);

  // 板金子部品プロパティ情報
  let iq3DetailRef = useRef();

  // 工程選択
  let iq3KouteiSentakuRef = useRef();

  // 工程計算
  let iq3KouteiCalRef = useRef();

  // 工程入力
  let iq3KouteiInputRef = useRef();

  // 購入品
  let purchaseRef = useRef();

  // 材料詳細
  let iq3MaterialDetailRef = useRef();

  const estimateParameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

  // iQ3板金タブを選択中で、子部品追加呼出で追加した時
  useEffect(() => {
    if (props?.activePartMenu === '2' && !isAddPart) {
      let iq3Infos = props.selectedEstimateData?.esIq3Info;
      if (iq3Infos === undefined) return;
      setIQ3DataLst(iq3Infos);
    }
  }, [props.selectedEstimateData?.esIq3Info]);

  //　新規呼出
  useEffect(() => {
    if (!props.type) {
      // 新規モードの場合、
      if (props.selectedEstimateData?.estimateProducts?.id == 0) {
        initIq3EstimateInfo(props.selectedEstimateData);
      } else {
        // 保存データを開くの場合
        if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
          setIQ3DataLst(props.selectedEstimateData?.esIq3Info);
          setSelectedData(props.selectedEstimateData?.esIq3Info?.[0]);
          setCurrentRecordNo(props.selectedEstimateData?.esIq3Info?.[0]?.no);
          let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
          // 材料リスト
          let materialList = parameters?.materialIq3;
          setMaterialLst(materialList);
          if (parameters?.process?.length > 0) {
            let processMstData = getProcessMstInfo(parameters?.process);
            setProcessMasterInfo(processMstData);

            if (parameters?.device?.length > 0) {
              let deviceLst = getDeviceMstInfo(parameters?.device, parameters?.process);
              setDeviceMasterInfo(deviceLst);
            }
          }
          if (parameters?.client?.length > 0) {
            setClientMasterInfo(parameters?.client);
          }
        }
      }
    }
  }, [props.selectedEstimateData, props.type]);

  //基本情報・工程選択・工程入力・工程積算・購入品・材料詳細ダイアログのOKボタンを押した場合、再計算を行う。
  // 行追加・行削除の場合も再計算を行う。
  useEffect(() => {
    if (isDialogOk || reCalByAddDelete || iq3ActionForCal) {
      setTimeout(() => {
        let calEstimateInfo = updateEstimateInfoByReCal(props.selectedEstimateData, props?.isEstimateOpen);
        if (calEstimateInfo) {
          // 一覧の情報を反映
          if (calEstimateInfo?.esIq3Info) {
            const esIq3InfoByRecal = JSON.parse(JSON.stringify(calEstimateInfo?.esIq3Info));
            setIQ3DataLst(esIq3InfoByRecal);
            setSelectedData(esIq3InfoByRecal?.[0]);
            props.selectedEstimateData.esIq3Info = esIq3InfoByRecal;
          }
          props.updateEstimateInfo(calEstimateInfo);
          props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
          setSelectedEstimateData(calEstimateInfo);
          setIsDialogOk(false);
          setReCalByAddDelete(false);
          setIq3ActionForCal(false);
          props?.updateIsRecal(false);
        }
      }, 100);
    }
  }, [isDialogOk, reCalByAddDelete, iq3ActionForCal]);

  useEffect(() => {
    if (iq3PMXDatas?.length > 0 && props?.isPMXEvent) {
      props?.loading(true); // PMXデータ読み取り、完了後Waitingダイアログを非表示にする
      let curNo = currentRecordNoPMX;
      if (curNo <= iq3PMXDatas?.length) {
        let curData = iq3PMXDatas?.filter((item) => item.no === curNo);
        if (curData?.length > 0) {
          setSelectedData(curData?.[0]);
          setCurrentRecordNo(curData?.[0]?.no);
          setCurrentRecordNoPMX(curNo + 1);
          props.updateIsRecal(true);
        }
      } else {
        setIq3PMXDatas([]);
        setCurrentRecordNoPMX(0);
        props.updateCheckPMXOrNot();
      }
    }
  }, [currentRecordNoPMX]);

  useEffect(() => {
    if (iq3PMXDatas?.length === 0 && props?.isPMXEvent) {
      let calEstimateInfo = updateEstimateInfoByReCal(props.selectedEstimateData, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
      if (calEstimateInfo) {
        // 一覧の情報を反映
        setIQ3DataLst(calEstimateInfo?.esIq3Info);
        setSelectedData(calEstimateInfo?.esIq3Info?.[0]);
        setCurrentRecordNo(calEstimateInfo?.esIq3Info?.[0]?.no);
        props.updateEstimateInfo(calEstimateInfo);
        props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
        props.selectedEstimateData.esIq3Info = calEstimateInfo?.esIq3Info;
        setSelectedEstimateData(calEstimateInfo);
      }
      props.updateIsRecal(false);
      props.updatePMXEvent();
    }
  }, [iq3PMXDatas]);

  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
  // useEffect(() => {
  //   setSelectedTabKey(props?.iq3ActiveTabKey);
  //   onChangeTab(props?.iq3ActiveTabKey);
  //   props?.updateIq3ActiveTabKey(props?.iq3ActiveTabKey);
  //   setIsFirstTimeMaterialDetail(false); // IQX_WEBEST-335 見積りデータ作成中、パラメータ画面と行き来すると選択材料が変わってしまいます。
  // }, [props?.iq3ActiveTabKey]);

  useEffect(() => {
    if (isAddPart) {
      setIsAddPart(false);
      let no = iQ3DataLst?.length > 0 ? iQ3DataLst[iQ3DataLst?.length - 1].no : 0;
      scrollTo(no);
    }
  }, [iQ3DataLst]);

  useEffect(() => {
    if (props?.parentQuantityChangeFlg && props.isReCal) {
      let curNo = currentRecordNoByChangeQty;
      if (curNo <= iQ3DataLst?.length) {
        let curData = iQ3DataLst?.filter((item) => item.no === curNo);
        if (curData?.length > 0) {
          setSelectedData(curData?.[0]);
          setCurrentRecordNo(curData?.[0]?.no);
          setCurrentRecordNoByChangeQty(curNo + 1);
          props.updateIsRecal(true);
        }
      } else {
        if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
          setSelectedData(props.selectedEstimateData?.esIq3Info?.[0]);
          setCurrentRecordNo(props.selectedEstimateData?.esIq3Info?.[0]?.no);
        }
        props.updateParentQuantityChange(false);
        props.updateIsRecal(false);
      }
    }
  }, [props.isReCal]);

  useEffect(() => {
    if (isBulkEditAction) {
      let curNo = currentRecordNoByBultEdit;
      if (curNo <= iQ3DataLst?.length) {
        let curData = iQ3DataLst?.filter((item) => item.no === curNo);
        if (curData?.length > 0) {
          setSelectedData(curData?.[0]);
          setCurrentRecordNo(curData?.[0]?.no);
          setCurrentRecordNoByBultEdit(curNo + 1);
          props.updateIsRecal(true);
        }
      } else {
        let calEstimateInfo = updateEstimateInfoByReCal(props.selectedEstimateData, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
        if (calEstimateInfo) {
          // 一覧の情報を反映
          setIQ3DataLst(calEstimateInfo?.esIq3Info);
          setSelectedData(calEstimateInfo?.esIq3Info?.[0]);
          setCurrentRecordNo(calEstimateInfo?.esIq3Info?.[0]?.no);
          props.updateEstimateInfo(calEstimateInfo);
          props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
          props.selectedEstimateData.esIq3Info = calEstimateInfo?.esIq3Info;
          setSelectedEstimateData(calEstimateInfo);
        }
        setIq3ActionForCal(false);
        setIsBulkEditAction(false);
        setCurrentRecordNoByBultEdit(0);
        props.updateIsRecal(false);
      }
    }
  }, [currentRecordNoByBultEdit]);

  const updateIQ3DataByBulkEdit = (bulkEditIQ3Infos) => {
    if (bulkEditIQ3Infos?.length > 0) {
      if (props.selectedEstimateData.esIq3Info) {
        let totalPartCount = bulkEditIQ3Infos?.reduce((total, item) => {
          return total + parseFloat(item.partCount);
        }, 0);
        if (props.selectedEstimateData.estimateProducts) {
          props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.smPartsCount =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.smPartsCount =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.smPartsCount =
            totalPartCount;
          // IQX_WEBEST-179
          props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.autoExtract =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.autoExtract =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.autoExtract = totalPartCount;
        }
        setCurrentRecordNoByBultEdit(1);
        setIsBulkEditAction(true);
      }
    }
  };

  // 板金部品見積もりの初期化
  const initIq3EstimateInfo = async (selectedEstimateData) => {
    if (selectedEstimateData === undefined) {
      return;
    }
    let iq3Infos = [];
    if (!props.type) {
      // 新規モードの場合、
      if (selectedEstimateData?.estimateProducts?.id == 0) {
        let iq3Data = [...iq3EstimateInitInfo];
        let newIQ3 =
          selectedEstimateData?.esIq3Info && selectedEstimateData?.esIq3Info?.length > 0 // 親部品タブを選択中で、子部品追加呼出で追加した時
            ? selectedEstimateData?.esIq3Info
            : getNewIQ3EstimateInfo(iq3Data);
        iq3Infos = newIQ3;
        setIQ3DataLst(newIQ3);
      } else {
        //　板金情報リストを取得
        let estimateId = selectedEstimateData?.estimateProducts?.id;
        if (estimateId) iq3Infos = await getEstimateIQ3Data(estimateId);
      }
      selectedEstimateData.esIq3Info = iq3Infos;
      // props.updatedSelectedEstimateDataTemp(selectedEstimateData);
    } else {
      let newIQ3 = selectedEstimateData?.esIq3Info;
      iq3Infos = newIQ3;
      setIQ3DataLst(iq3Infos);
    }
    let selectedData = iq3Infos?.length > 0 ? iq3Infos?.[0] : {};
    // setQuantity(props?.getParentQuantity()); // 入力した数量情報を得る
    setSelectedEstimateData(selectedEstimateData);
    setCurrentRecordNo(selectedData?.no ? selectedData?.no : 1);
    setSelectedData(selectedData);

    let parameters = selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    // 材料リスト
    let materialList = parameters?.materialIq3;
    setMaterialLst(materialList);

    if (parameters?.process?.length > 0) {
      let processMstData = getProcessMstInfo(parameters?.process);
      setProcessMasterInfo(processMstData);

      if (parameters?.device?.length > 0) {
        let deviceLst = getDeviceMstInfo(parameters?.device, parameters?.process);
        setDeviceMasterInfo(deviceLst);
      }
    }
    if (parameters?.client?.length > 0) {
      setClientMasterInfo(parameters?.client);
    }
  };

  // 材料マスタ情報
  const getProcessMstInfo = (process) => {
    let processMstData = process?.filter((process) => process?.class === ServiceClass.SheetMetal);
    return processMstData;
  };

  // 装置マスタ情報
  const getDeviceMstInfo = (device, process) => {
    let processMstData = getProcessMstInfo(process);
    let deviceLst = device?.filter((item) => processMstData?.filter((process) => process?.id === item?.processId));
    return deviceLst;
  };

  // 新規モードの場合、板金部品情報を初期化する
  const getNewIQ3EstimateInfo = (lists) => {
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    let userSettingData = props.selectedEstimateData?.estimateProducts?.calcParameters?.settings?.userSettings;

    const patternLst = parameters?.processPattern; // 工程パターンマスタデータ
    let kouteiPatternList = patternLst?.filter((item) => item.id === userSettingData?.[0]?.users?.iq3ProcessPatternId);
    // IQX_WEBEST-323 新規処理の前回値の採用
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newSmInitialInfo.iq3ProcessPatternId !== undefined) {
      let userSettingProcessPattern = patternLst?.filter(
        (i) => i.id === userSettingData?.[0].users.newSmInitialInfo.iq3ProcessPatternId
      );
      kouteiPatternList = userSettingProcessPattern?.length > 0 ? userSettingProcessPattern : kouteiPatternList;
    }
    if (kouteiPatternList?.length === 0) {
      kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);
    }

    const materialType = parameters?.materialTypeIq3; // 材質マスタデータ
    let materialTypeList = materialType?.filter((item) => item.info.isUsed);

    const material = parameters?.materialIq3; // 材料マスタデータ
    let materialList = material?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    const materialSurface = parameters?.materialSurfaceIq3; // 材料表面マスタデータ
    let materialSurfaceList = materialSurface?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );
    // IQX_WEBEST-323 新規処理の前回値の採用
    let materialIq3Id = materialList?.length > 0 ? materialList[0]?.id : 0;
    let materialName = materialList?.length > 0 ? materialList[0]?.name : '';
    let materialTypeIq3Id_sm = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
    let materialTypeName = materialTypeList?.length > 0 ? materialTypeList[0]?.name : '';
    let thickness = materialList?.length > 0 ? materialList[0]?.id : 0;
    // 前回値の材料名称
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newSmInitialInfo.materialIq3Id !== undefined) {
      // 前回値の材料名称
      let userSettingMaterial = material?.filter(
        (i) => i.id === userSettingData?.[0].users.newSmInitialInfo.materialIq3Id
      );
      materialIq3Id = userSettingMaterial?.length > 0 ? userSettingMaterial[0]?.id : materialIq3Id;
      materialName = userSettingMaterial?.length > 0 ? userSettingMaterial[0]?.name : materialName;
      // 前回値の材料名称に該当する材質名称
      let userSettingMaterialTypeIq3 = materialTypeList?.filter(
        (i) => i.id === userSettingMaterial[0]?.materialTypeIq3Id
      );
      materialTypeIq3Id_sm = userSettingMaterialTypeIq3?.length > 0 ? userSettingMaterialTypeIq3[0]?.id : 0;
      materialTypeName = userSettingMaterialTypeIq3?.length > 0 ? userSettingMaterialTypeIq3[0]?.name : '';
      // 前回値の材料名称に該当する板厚
      thickness = userSettingMaterial?.length > 0 ? userSettingMaterial[0]?.id : 0;
    }
    // 前回値の表面保護
    let materialSurfaceIq3Id = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.id : 0;
    let materialSurfaceName = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.name : '';
    if (userSettingData?.length > 0 && userSettingData?.[0].users.newSmInitialInfo.materialSurfaceIq3Id !== undefined) {
      let userSettingMaterialSurfaceIq3 = materialSurface?.filter(
        (i) => i.info.isUsed && i.id === userSettingData?.[0].users.newSmInitialInfo.materialSurfaceIq3Id
      );
      materialSurfaceIq3Id =
        userSettingMaterialSurfaceIq3?.length > 0 ? userSettingMaterialSurfaceIq3[0]?.id : materialSurfaceIq3Id;
      materialSurfaceName =
        userSettingMaterialSurfaceIq3?.length > 0 ? userSettingMaterialSurfaceIq3[0]?.name : materialSurfaceName;
    }

    // 工程マスタ情報取得
    let processMaster = [];
    const processMstData = parameters?.process; // 工程マスタデータ
    processMaster = processMstData?.filter((process) => process?.class === ServiceClass.SheetMetal);
    let blankIsUsedData = [];
    processMaster?.filter((i) => {
      if (
        i.workType === WorkType.SmBlankCombi ||
        i.workType === WorkType.SmBlankLaser ||
        i.workType === WorkType.SmBlankPunch
      ) {
        blankIsUsedData.push(i.id);
        // return i.id;
      }
    });
    const filteredList2 = kouteiPatternList?.[0]?.details?.filter((item2) =>
      blankIsUsedData.some((item1) => item1 === item2.processId)
    );

    const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

    // 工程入力
    let processSelectDetails = kouteiPatternList?.[0]?.details;

    let kouteiInputArr = [];
    if (processSelectDetails) {
      kouteiInputArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

        const workType = processMstInfo.workType || '';

        let data = {
          id: index + 1,
          processId: item.processId,
          details: getDetails(workType, parameters, processSelectDetails),
          autoExtractXSize: 0,
          autoExtractYSize: 0,
        };

        return data;
      });
    }

    //　工程積算
    let kouteiCalArr = [];
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

        const workType = processMstInfo.workType || '';
        let dataItems = [];
        if (item.multiprocesses?.length > 0) {
          for (let m = 0; m < item.multiprocesses?.length; m++) {
            let dataItem = {
              id: m + 1,
              processName: processMstInfo?.name,
              processDetailTypeNo: item.multiprocesses[m]?.processDetailTypeId,
              dandoriAmt: 0,
              dandoriTime: 0,
              sagyoAmt: 0,
              sagyoTime: 0,
              processDetailWorkTypeGroup: item.multiprocesses[m]?.processDetailGroup,
              kasho: 1,
              isCommission: false,
              supplier: '',
              supplierCode: '',
              formula: '',
            };
            dataItems.push(dataItem);
          }
        }

        let addition = {
          no: item.processId,
          processId: item.processId,
          dataType: DataType.Data,
          dataItems: dataItems,
          editItems: [],
          totalDandori: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalSagyo: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
          editDataFlg: false,
        };

        return addition;
      });
    }

    // ブランクの場合
    let blankProcess = getProcessRecord(
      ServiceClass.SheetMetal,
      [WorkType.SmBlankLaser, WorkType.SmBlankPunch, WorkType.SmBlankCombi], // レーザー、パンチ、複合機
      processMstData
    );
    // 板金工程選択にブランクを使用している場合、
    let usedBlankProcess = processSelectDetails?.find(
      (selectItem) =>
        selectItem.isUsedForCalc && blankProcess?.findIndex((item) => item.id === selectItem.processId) > -1
    );
    // 使用している装置の情報を取得する
    let usedDeviceInfo = parameters?.device?.find((device) => device.id === usedBlankProcess?.deviceId);
    let grabbingX = 0;
    let grabbingY = 0;
    let pierWidthX = 0;
    let pierWidthY = 0;
    let worlRangeX = 0;
    let worlRangeY = 0;
    // 装置がある場合、
    if (usedDeviceInfo) {
      // 装置から材料配置情報
      let materialArrange = usedDeviceInfo?.details?.materialArrange;
      grabbingX = materialArrange?.graspAllowanceX;
      grabbingY = materialArrange?.graspAllowanceY;
      pierWidthX = materialArrange?.xWidth;
      pierWidthY = materialArrange?.yWidth;
      worlRangeX = materialArrange?.workRangeX;
      worlRangeY = materialArrange?.workRangeY;
    }

    // 材料詳細パターン情報を準備する
    let materialDetailPatterns = [];

    // 材料詳細サイズリスト情報を準備する
    let materialDetailSizes = [];
    let materialSizeIq3Infos = parameters?.materialSizeIq3;

    const updatedList = lists?.map((i, index) => {
      if (i?.id === 0) {
        materialDetailSizes = [];
        let materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
        if (materialSizeIq3Infos) {
          materialDetailSizes = materialSizeIq3Infos
            ?.filter(
              (item) =>
                item.info.isUsed &&
                item.name !== 'Free' &&
                item?.materialTypeIq3Id?.findIndex(
                  (id) => id === (i.materialTypeIq3Id === 0 ? materialTypeIq3Id : i.materialTypeIq3Id)
                ) > -1
            )
            ?.map((item, index) => {
              let data = getInitMaterialSizeInfo(index, item);
              let copyItem = JSON.parse(JSON.stringify(item));
              if (data?.sizeName === 'Other') {
                data = { ...data, isUsed: true };
                // /材料サイズX計算
                let sheetX = Number(0) + Number(pierWidthX) + Number(grabbingX);
                // /材料サイズY計算
                let sheetY = Number(0) + Number(pierWidthY) + Number(grabbingY);
                copyItem = { ...copyItem, info: { ...copyItem.info, sizeX: sheetX, sizeY: sheetY } };
              }
              materialDetailPatterns = getInitMaterialPatternsInfo(materialDetailPatterns, copyItem);
              return data;
            });
        }

        i.no = 1;
        i.creationType = EstimateCreationType.Auto;
        i.drawingNo = 'Part_' + formatDateString(todayTime());
        i.name = 'Part_' + padWithLeadingZeros(index + 1, 5);
        i.surfaceProtection = SurfaceProtectionType.OneSideVinyl;
        i.materialIq3Id = materialIq3Id;
        i.materialName = materialName;
        i.materialTypeIq3Id = materialTypeIq3Id_sm;
        i.materialTypeName = materialTypeName;
        i.materialSurfaceIq3Id = materialSurfaceIq3Id;
        i.materialSurfaceName = materialSurfaceName;
        i.thickness = thickness;
        i.processSelect = {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        };
        i.processInput = kouteiInputArr;
        i.purchases = [];
        i.additions = kouteiCalArr;
        i.materialDetailPatterns = materialDetailPatterns;
        i.materialDetailSizes = materialDetailSizes;
        i.weight = 0;
        i.totalSurfaceArea = 0;
        i.partSizeX = 0;
        i.partSizeY = 0;
        i.partCount = 1;
        i.grabbingX = grabbingX;
        i.grabbingY = grabbingY;
        i.pierWidthX = pierWidthX;
        i.pierWidthY = pierWidthY;
        i.worlRangeX = worlRangeX;
        i.worlRangeY = worlRangeY;
        i.workCostUnitPrice = 0;
        i.workCostTotalPrice = 0;
        i.costUnitPrice = 0;
        i.costTotalPrice = 0;
        i.surfaceTotalPrice = 0;
        i.surfaceUnitPrice = 0;
        i.purchaseUnitPrice = 0;
        i.purchaseTotalPrice = 0;
        i.materialCostUnitPrice = 0;
        i.materialCostTotalPrice = 0;
      }
      return i;
    });
    return updatedList;
  };

  // 工程マスタのレコードIDを取得する
  const getProcessRecord = (classId, processTypes, mstInfo) => {
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstRecordInfo = [...mstInfo]?.filter(
      (item) => item?.class === classId && processTypes?.findIndex((workType) => workType === item?.workType) > -1
    );
    return mstRecordInfo;
  };

  // 板金部品見積もりを取得する
  const getEstimateIQ3Data = async (estimateProductId) => {
    let estimateIq3Infos = await getEstimateIq3Info(estimateProductId);
    if (estimateIq3Infos) {
      setIQ3DataLst(estimateIq3Infos);
    }
    return estimateIq3Infos;
  };

  // Scrollの移動
  const scrollTo = (rowKey) => {
    // Scrollの移動
    var activeRowElements = document.getElementById('iq3EstimateTbl').getElementsByClassName('ant-table-row');
    for (let i = 0; i < activeRowElements?.length; i++) {
      var targetElement = activeRowElements[i].dataset.rowKey == rowKey ? activeRowElements[i] : [];
      if (targetElement != '') {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  // 編集中破棄確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    props.updateMainEstimateEditMode(false);
    onChangeTab(selectedTabKey);
    props?.updateIq3ActiveTabKey(selectedTabKey);
    getAllEstimateIq3Info();
  };

  // 編集中破棄確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  // 部品リストの行を選択した場合、
  const onRowSelect = (record) => {
    setCurrentRecordNo(record.no);
    setSelectedData(record);
  };

  // 選択中であり、部品の各タブ情報をリストに反映するようにする
  const getAllEstimateIq3Info = () => {
    setConfirmMsgOpen(false);
    props.updateMainEstimateEditMode(false);

    let estimateData = { ...props.selectedEstimateData };
    let selectedIq3EstimateData = estimateData?.esIq3Info?.find((iq3) => iq3?.no === selectedData?.no);

    // 工程積算
    //工程パターンでチェック外した工程を工程積算から削除
    let orgAdditions = estimateData?.esIq3Info?.filter((item) => item.no === selectedIq3EstimateData?.no)?.[0]
      ?.additions;
    let updatedAdditions = [];
    for (let i = 0; i < selectedIq3EstimateData?.processSelect?.details?.length; i++) {
      let targetAddition = orgAdditions?.filter(
        (item) =>
          item.processId === selectedIq3EstimateData?.processSelect?.details[i].processId &&
          selectedIq3EstimateData?.processSelect?.details[i].isUsedForCalc
      );
      if (targetAddition?.length > 0) {
        //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
        if (targetAddition?.length > 1) {
          for (let o = 0; o < orgAdditions?.length; o++) {
            let customProc = estimateData?.estimateProducts?.calcParameters?.parameters?.process?.filter(
              (item) => item.id === orgAdditions[o]?.processId
            );
            let customType = customProc ? customProc[0]?.workType : 0;
            if (
              selectedIq3EstimateData?.processSelect?.details[i]?.multiprocesses?.[0]?.processDetailTypeId ===
                orgAdditions[o]?.dataItems?.[0]?.processDetailTypeNo &&
              selectedIq3EstimateData?.processSelect?.details[i]?.processId === orgAdditions[o]?.processId &&
              customType === WorkType.SmCustom
            ) {
              updatedAdditions?.push(orgAdditions?.[o]);
              break;
            }
          }
        } else {
          updatedAdditions?.push(targetAddition?.[0]);
        }
      }
    }
    if (selectedIq3EstimateData?.processSelect?.details?.length > 0) {
      //工程選択から外した場合、見積データに外した情報を更新するため、updatedAdditionsのNullチェックを削除
      selectedIq3EstimateData.additions = updatedAdditions;
    }

    // 材料詳細
    // if (iq3MaterialDetailRef?.current) {
    //   const materialDetailInfo = iq3MaterialDetailRef?.current?.getIQ3MaterialDetailInfo();
    //   let materialPatterns = materialDetailInfo?.materialDetailPatterns;
    //   let materialSizes = materialDetailInfo?.materialDetailSizes;

    //   if (activeTabKey !== '1' && materialDetailInfo) {
    //     if (selectedIq3EstimateData.materialIq3Id !== materialDetailInfo?.materialIq3Id)
    //       selectedIq3EstimateData.materialIq3Id = materialDetailInfo?.materialIq3Id;
    //     if (selectedIq3EstimateData.materialName !== materialDetailInfo?.materialName)
    //       selectedIq3EstimateData.materialName = materialDetailInfo?.materialName;
    //     if (selectedIq3EstimateData.materialTypeIq3Id !== materialDetailInfo?.materialTypeIq3Id)
    //       selectedIq3EstimateData.materialTypeIq3Id = materialDetailInfo?.materialTypeIq3Id;
    //     if (selectedIq3EstimateData.materialTypeName !== materialDetailInfo?.materialTypeName)
    //       selectedIq3EstimateData.materialTypeName = materialDetailInfo?.materialTypeName;
    //     if (selectedIq3EstimateData.materialSurfaceIq3Id !== materialDetailInfo?.materialSurfaceIq3Id)
    //       selectedIq3EstimateData.materialSurfaceIq3Id = materialDetailInfo?.materialSurfaceIq3Id;
    //     if (selectedIq3EstimateData.materialSurfaceName !== materialDetailInfo?.materialSurfaceName)
    //       selectedIq3EstimateData.materialSurfaceName = materialDetailInfo?.materialSurfaceName;
    //     if (selectedIq3EstimateData.thickness !== materialDetailInfo?.thickness)
    //       selectedIq3EstimateData.thickness = materialDetailInfo?.thickness;
    //   }
    //   if (materialDetailInfo) {
    //     let selectedMaterialSizeInfo;
    //     let materialTotalPrice = 0; // 材料原価合計
    //     let materialUnitPrice = 0; // // 材料原価
    //     if (materialSizes && materialSizes.length > 0) {
    //       selectedMaterialSizeInfo = materialSizes.find((item) => item.isUsed); // 採用されるサイズの情報
    //       if (selectedMaterialSizeInfo) {
    //         materialTotalPrice = selectedMaterialSizeInfo?.price; // 材料原価合計
    //         materialUnitPrice = materialTotalPrice / (selectedIq3EstimateData?.partCount * props.getParentQuantity()); // 材料原価/員数＊数量
    //       }
    //     }
    //     selectedIq3EstimateData.grabbingX = materialDetailInfo?.grabbingX;
    //     selectedIq3EstimateData.grabbingY = materialDetailInfo?.grabbingY;
    //     selectedIq3EstimateData.pierWidthX = materialDetailInfo?.pierWidthX;
    //     selectedIq3EstimateData.pierWidthY = materialDetailInfo?.pierWidthY;
    //     selectedIq3EstimateData.materialCostUnitPrice =
    //       materialUnitPrice && !isInfinity(materialUnitPrice) ? materialUnitPrice : 0; // 材料原価
    //     selectedIq3EstimateData.materialCostTotalPrice =
    //       materialTotalPrice && !isInfinity(materialTotalPrice) ? materialTotalPrice : 0; // 材料原価合計
    //     selectedIq3EstimateData.materialDetailPatterns = materialPatterns ? materialPatterns : [];
    //     selectedIq3EstimateData.materialDetailSizes = materialSizes ? materialSizes : [];
    //   }
    // }

    // 一覧の情報を反映するため、データ取得
    let updatedData = estimateData?.esIq3Info;
    let data = [...iQ3DataLst];
    if (data?.length > 0) {
      updatedData = data?.map((item) => {
        if (item.no === selectedIq3EstimateData?.no) {
          item = selectedIq3EstimateData;
        }
        // IQX_WEBEST-286 自動：工程積算タブでデータ欄を使用している状態
        let isManual = false;
        for (let i = 0; i < item?.additions?.length; i++) {
          let eqAddition = item?.additions[i];
          if (eqAddition?.dataType) {
            isManual = true;
            break;
          }
        }
        if (isManual) {
          item.creationType = EstimateCreationType.Manual;
        } else {
          item.creationType = EstimateCreationType.Auto;
        }
        return item;
      });
    }

    estimateData = { ...estimateData, esIq3Info: updatedData };
    if (estimateData) {
      //タブ切替で再計算
      // let calEstimateInfo = updateEstimateInfoByReCal(estimateData, props?.isEstimateOpen); // IQX_WEBEST-305 過去データを開いた直後、再計算をしない限りはタブ切替しても計算しないでほしい。
      // if (calEstimateInfo) {
      //   // 一覧の情報を反映
      //   setIQ3DataLst(calEstimateInfo?.esIq3Info);
      //   calEstimateInfo?.esIq3Info?.map((item) => {
      //     if (item.no === selectedIq3EstimateData?.no) {
      //       // 選択中の部品情報を反映
      //       setSelectedData(item);
      //     }
      //   });

      // }
      props.updateEstimateInfo(estimateData);
      props.selectedEstimateData.estimateProducts = estimateData?.estimateProducts;
      props.selectedEstimateData.esIq3Info = estimateData?.esIq3Info;
      setSelectedEstimateData(estimateData);
      return estimateData;
    }
  };

  const confirmOk = (e) => {
    getAllEstimateIq3Info();
  };

  const confirmCancel = () => {
    setConfirmMsgOpen(false);
    // props.childEditMode(false);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  // 工程選択画面の工程リストを選択した場合、
  const updateSelectedData = (selectedPartNo) => {
    getAllEstimateIq3Info(); // タブの切り替えで入力中の情報を保持するようにする
    let selectedData = iQ3DataLst?.filter((item) => item.no == selectedPartNo);
    setCurrentRecordNo(selectedPartNo);
    setSelectedData(selectedData?.length > 0 ? selectedData[0] : iQ3DataLst[0]);
    scrollTo(selectedPartNo);
  };

  // 工程選択画面のイベント変更により呼出
  const updateProcessSelectOfSelectedData = (processSelect) => {
    setSelectedProcessSelect(processSelect);
  };

  // import PMX file
  const importPMXFile = (pmxData) => {
    setIQ3DataLst(pmxData);
    setCurrentRecordNo(0);
    setSelectedData(pmxData[0]);
    navigate(':estimate.iq3');
  };

  const kihonFormValidation = () => {
    let validation = iq3DetailRef?.current?.formValidation;
    return validation;
  };

  useImperativeHandle(ref, () => ({
    importPMXFile: importPMXFile,
    getIQ3EditMode: () => {
      return editMode;
    },
    getIQ3EstimateDetailInfo: getAllEstimateIq3Info,
    getEstimateIq3Info: () => {
      return iQ3DataLst;
    },
    kihonFormValidation: kihonFormValidation,
    updateIq3PMXDatas: updateIq3PMXDatas,
    getIQ3ActiveTabKey: () => {
      return activeTabKey;
    },
    updateIQ3DataByBulkEdit: updateIQ3DataByBulkEdit,
    getNewIQ3EstimateInfo: getNewIQ3EstimateInfo,
    updateIq3ActionForCal: updateIq3ActionForCal,
  }));

  // 部品追加ボタンの押下
  const addPart = async () => {
    let iq3Data = [...iq3EstimateInitInfo];
    var copyPart = getNewIQ3EstimateInfo(JSON.parse(JSON.stringify(iq3Data)));
    if (copyPart?.length > 0) copyPart = copyPart[0];
    if (props?.selectedEstimateData?.esIq3Info != undefined && props?.selectedEstimateData?.esIq3Info?.length > 0) {
      let tempIQ3List = [...props?.selectedEstimateData?.esIq3Info];
      var lastRecord = tempIQ3List.sort((a, b) => b.no - a.no)?.[0];
      copyPart = {
        ...copyPart,
        no: lastRecord?.no + 1,
        name: 'Part_' + padWithLeadingZeros(lastRecord?.no + 1, 5),
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
      let tempIQ3DataLst = [...iQ3DataLst];
      tempIQ3DataLst?.push(copyPart);
      setIQ3DataLst(tempIQ3DataLst);
      props?.selectedEstimateData?.esIq3Info?.push(copyPart);
    } else {
      copyPart = {
        ...copyPart,
        no: 1,
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
      let tempIQ3DataLst = [...iQ3DataLst];
      tempIQ3DataLst?.push(copyPart);
      setIQ3DataLst(tempIQ3DataLst);
      props?.selectedEstimateData?.esIq3Info?.push(copyPart);
    }
    setIsAddPart(true);
    setSelectedEstimateData(props.selectedEstimateData);
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
    // 部品追加により再計算
    setReCalByAddDelete(true);
    props.updateIsRecal(true);
  };

  // 部品削除ボタンの押下
  const deletePart = () => {
    if (iQ3DataLst?.length <= 1) return;
    let deletePart =
      props?.userSettingInfo?.[0]?.massages?.deletePart == undefined
        ? true
        : props?.userSettingInfo?.[0]?.massages?.deletePart;
    setIsDelete(deletePart);
    if (!deletePart) {
      deleteOk();
    }
  };

  // 削除ポップアップのOKボタンの押下
  const deleteOk = () => {
    setIsDelete(false);
    let tempIQ3List = [...iQ3DataLst];
    tempIQ3List = tempIQ3List?.filter((item) => item.no !== currentRecordNo);
    tempIQ3List = tempIQ3List?.map((item, index) => {
      return { ...item, no: index + 1 };
    });
    setIQ3DataLst(tempIQ3List);
    setSelectedEstimateData((prev) => ({ ...prev, esIq3Info: tempIQ3List }));
    if (tempIQ3List?.length > 0) {
      setCurrentRecordNo(tempIQ3List[0]?.no);
      setSelectedData(tempIQ3List[0]);
    }
    if (props.selectedEstimateData.esIq3Info) {
      props.selectedEstimateData.esIq3Info = tempIQ3List;
    }
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
    // 部品削除により再計算
    setReCalByAddDelete(true);
    props.updateIsRecal(true);
  };

  // 削除ポップアップのCancelボタンの押下
  const deleteCancel = () => {
    setIsDelete(false);
  };

  // 部品複写追加ボタンの押下(IQX_WEBEST-311 板金子部品の「部品複写追加」機能の追加)
  const addCopyPart = async () => {
    let tempIQ3List = JSON.parse(JSON.stringify(props?.selectedEstimateData?.esIq3Info));
    var copyPart = tempIQ3List?.filter((item) => item.no === currentRecordNo);
    if (tempIQ3List != undefined && tempIQ3List?.length > 0 && copyPart?.length > 0) {
      copyPart = JSON.parse(JSON.stringify(copyPart[0]));
      var lastRecord = tempIQ3List.sort((a, b) => b.no - a.no)?.[0];
      copyPart.no = lastRecord?.no + 1;
      copyPart.drawingNo = 'Part_' + formatDateString(todayTime()); // IQX_WEBEST-311 部品複写機能で新たに追加された子部品の子部品図番と子部品名称は、新規子部品追加時と同様
      copyPart.name = 'Part_' + padWithLeadingZeros(lastRecord?.no + 1, 5); // IQX_WEBEST-311 部品複写機能で新たに追加された子部品の子部品図番と子部品名称は、新規子部品追加時と同様
      let tempIQ3DataLst = [...iQ3DataLst];
      tempIQ3DataLst?.push(copyPart);
      setIQ3DataLst(tempIQ3DataLst);
      props?.selectedEstimateData?.esIq3Info?.push(copyPart);
      setIsAddPart(true);
      setSelectedEstimateData(props.selectedEstimateData);
      calculateChildPartCount();
      calculateWeightAreaForPaSurface();
      // 部品複写により再計算
      setReCalByAddDelete(true);
      props.updateIsRecal(true);
    }
  };

  const calculateChildPartCount = () => {
    let childpart = 0;
    if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
      childpart = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
        return total + parseInt(item.partCount);
      }, 0);
    }
    if (props?.selectedEstimateData?.estimateProducts?.processInput != undefined) {
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.smPartsCount = childpart;
      // IQX_WEBEST-179
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.autoExtract = childpart;
    }
  };

  const calculateWeightAreaForPaSurface = () => {
    let totalWeight = 0;
    let totalArea = 0;
    totalWeight = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.weight * Number(item.partCount)); // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
    }, 0);
    totalArea = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.totalSurfaceArea * Number(item.partCount)); // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
    }, 0);
    if (totalWeight === 0 && totalArea === 0) {
    } else {
      if (props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.length > 0) {
        let parentSurfaceData =
          props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.map((paSurface) => {
            return {
              ...paSurface,
              areaUnitPrice: parseFloat(toDecimal(totalArea, 2)),
              weightUnitPrice: parseFloat(toDecimal(totalWeight, 2)),

              areaAutoExtract: parseFloat(toDecimal(totalArea, 2)),
              weightAutoExtract: parseFloat(toDecimal(totalWeight, 2)),
              totalArea: parseFloat(toDecimal(totalArea * paSurface?.areaCount, 2)),
              totalWeight: parseFloat(toDecimal(totalWeight * paSurface?.weightCount, 2)),
            };
          });
        props.selectedEstimateData.estimateProducts.processInput.surfaceProcessInputInfo = parentSurfaceData;
      }
    }
  };

  // 上方向ボタンの押下
  const upRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let no = selectedData?.no - 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setCurrentRecordNo(record[0]?.no);
      setSelectedData(record[0]);
      scrollTo(record[0]?.no);
    }
  };

  // 下方向ボタンの押下
  const downRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let no = selectedData?.no + 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setCurrentRecordNo(record[0]?.no);
      setSelectedData(record[0]);
      scrollTo(record[0]?.no);
    }
  };

  //　追加呼出ボタンの押下
  const displayAddParentInfo = async () => {
    let newParameters = await GetParametersByCompId();
    newParameters.estimateStatus = newParameters?.estimateStatus?.filter((item) => item.info.isUsed);
    newParameters.staff = newParameters?.staff?.filter((item) => item.info.isUsed);
    newParameters.client = newParameters?.client?.filter((item) => item.info.isUsed);
    setNewParameters(newParameters);
    setParentListDisplay(true);
    setIq3Loading(true); // Waitingダイアログを表示にする
  };

  // 追加呼出ダイアログのOKボタン押下
  const addPartFromParentInfoOk = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    setParentListDisplay(false);
    if (selectedParentAddEstimate !== undefined && selectedParentAddEstimate?.estimateProducts?.id) {
      let estimateProductId = selectedParentAddEstimate?.estimateProducts?.id;
      const addIQ3Lst = await getEstimateIQ3Data(estimateProductId);
      var lastRecord = iQ3DataLst?.length > 0 ? iQ3DataLst[iQ3DataLst?.length - 1] : [];
      let no = lastRecord != [] ? lastRecord?.no + 1 : 1;
      const addNewIQ3Lst = [];
      for (let i = 0; i < addIQ3Lst?.length; i++) {
        var iq3Data = { ...addIQ3Lst[i] };
        iq3Data.id = 0;
        iq3Data.no = no;
        addNewIQ3Lst.push(iq3Data);
        no++;
      }
      setIQ3DataLst([...iQ3DataLst, ...addNewIQ3Lst]);
      setSelectedEstimateData({
        ...selectedEstimateData,
        esIq3Info: [...selectedEstimateData?.esIq3Info, ...addNewIQ3Lst],
      });
      if (props.selectedEstimateData.esIq3Info) {
        props.selectedEstimateData.esIq3Info = [...props.selectedEstimateData.esIq3Info, ...addNewIQ3Lst];
      }
    }
    props?.loading(false); // Waitingダイアログを非表示にする
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
    //部品呼出追加より再計算
    setReCalByAddDelete(true);
    props.updateIsRecal(true);
  };

  // 追加呼出ダイアログのCancelボタン押下
  const addPartFromParentInfoCancel = () => {
    setParentListDisplay(false);
  };

  // 追加呼出画面に行を選択した場合、
  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setSelectedParentAddEstimate(estimateData);
  };

  // タブの切り替え
  const onChange = (key) => {
    setSelectedTabKey(key);
    onChangeTab(key);
    props?.updateIq3ActiveTabKey(key);
  };

  // タブの切り替え
  const onChangeTab = (key) => {
    setActiveTabKey(key);
    if (key === '1') {
      setMdalTitleTxt('基本情報');
      setDetailTabModalWidth(1290);
    } else if (key === '2') {
      setMdalTitleTxt('工程選択');
      setDetailTabModalWidth(1200);
    } else if (key === '3') {
      setMdalTitleTxt('工程入力');
      setDetailTabModalWidth(1390);
    } else if (key === '4') {
      setMdalTitleTxt('工程積算');
      setDetailTabModalWidth(1370);
    } else if (key === '5') {
      setMdalTitleTxt('購入品');
      setDetailTabModalWidth(1300);
    } else if (key === '6') {
      setMdalTitleTxt('材料詳細');
      setDetailTabModalWidth(1300);
    }
  };

  const settingOK = () => {
    setIsSetting(false);
  };
  const settingCancel = () => {
    setIsSetting(false);
  };

  // 材料詳細の最初として入るかの判断を更新する
  const updateFirstTimeMaterialDetail = (value) => {
    setIsFirstTimeMaterialDetail(value);
  };

  const updateCurIQ3MaterialDetailData = (iq3) => {
    if (iq3?.no === selectedData?.no) {
      selectedData.thickness = iq3.thickness;
      selectedData.materialIq3Id = iq3.materialIq3Id;
      selectedData.materialName = iq3.materialName;
      selectedData.materialSurfaceIq3Id = iq3.materialSurfaceIq3Id;
      selectedData.materialSurfaceName = iq3.materialSurfaceName;
      selectedData.materialDetailSizes = iq3.materialDetailSizes;
      selectedData.materialDetailPatterns = iq3.materialDetailPatterns;
      selectedData.materialTypeName = iq3.materialTypeName;
      selectedData.materialTypeIq3Id = iq3.materialTypeIq3Id;
      selectedData.materialCostUnitPrice = iq3.materialCostUnitPrice; // 材料原価
      selectedData.materialCostTotalPrice = iq3.materialCostTotalPrice; // 材料原価合計
      selectedData.grabbingX = selectedData.grabbingX;
      selectedData.grabbingY = selectedData.grabbingY;
      selectedData.pierWidthX = selectedData.pierWidthX;
      selectedData.pierWidthY = selectedData.pierWidthY;
    }
  };

  const updateIq3PMXDatas = (value) => {
    setIq3PMXDatas(value);
    setCurrentRecordNoPMX(1);
  };

  // 基本情報タブのOKボタン押下
  const detailDialogOk = () => {
    setIsDialogOk(true);
    setActiveTabKey('0');
    if (activeTabKey === '2') {
      if (selectedData && Object.keys(selectedProcessSelect)?.length > 0) {
        selectedData.processSelect = selectedProcessSelect;
        // 工程選択を変更した時、材料詳細も計算する
        isReCalMaterial(true);
      }
    }
    props.updateIsRecal(true);
  };

  // 基本情報タブのCancelボタン押下
  const detailDialogCancel = () => {
    setIsDialogOk(false);
    setActiveTabKey('0');
  };

  const menuIconList = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        height: '6%',
      }}
    >
      <Row style={{ marginTop: '0px', marginLeft: '0', width: '-webkit-fill-available' }}>
        <Col span={24}>
          <Space size="middle">
            <Tooltip title="部品追加" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  addPart();
                }}
              >
                <Image preview={false} width={25} src={child_add} style={{ marginLeft: '5px' }}></Image>
              </a>
            </Tooltip>
            {/** IQX_WEBEST-311 板金子部品の「部品複写追加」機能の追加 */}
            <Tooltip title="部品複写追加" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  addCopyPart();
                }}
              >
                <Image preview={false} width={25} src={copy_sm}></Image>
              </a>
            </Tooltip>
            <Tooltip title="部品削除" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  deletePart();
                }}
              >
                <Image preview={false} width={23} src={child_delete} style={{ marginBottom: '1px' }}></Image>
              </a>
            </Tooltip>
            <Tooltip title="Up" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  upRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={up_Arrow}
                  style={{ marginLeft: '5px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
            <Tooltip title="Down" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  downRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={down_Arrow}
                  style={{ marginLeft: '5px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
            <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
              <a onClick={props?.updateAll}>
                <Image preview={false} width={25} src={edit_list} style={{ marginLeft: '5px' }}></Image>
              </a>
            </Tooltip>
            <Tooltip title="追加呼出" placement="bottom" overlayClassName="tooltip-text">
              <a onClick={displayAddParentInfo}>
                <Image preview={false} width={25} src={parent_call_detail} style={{ marginLeft: '5px' }}></Image>
              </a>
            </Tooltip>
            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '1' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('1');
                }}
              >
                <FileExclamationOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '1' ? 'white' : '#4a4747', fontWeight: 'bold' }}>基本情報</label>
              </Button>
            </div>

            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '2' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('2');
                }}
              >
                <FileDoneOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '2' ? 'white' : '#4a4747', fontWeight: 'bold' }}>工程選択</label>
              </Button>
            </div>

            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '3' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('3');
                }}
              >
                <FormOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '3' ? 'white' : '#4a4747', fontWeight: 'bold' }}>工程入力</label>
              </Button>
            </div>
            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '6' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('6');
                }}
              >
                <ProductOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '6' ? 'white' : '#4a4747', fontWeight: 'bold' }}>材料詳細</label>
              </Button>
            </div>
            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '4' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('4');
                }}
              >
                <MoneyCollectOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '4' ? 'white' : '#4a4747', fontWeight: 'bold' }}>工程積算</label>
              </Button>
            </div>

            <div className="iq3-detail-btn-div">
              <Button
                className={activeTabKey == '5' ? 'iq3-detail-btn-active' : 'iq3-detail-btn'}
                onClick={(e) => {
                  onChange('5');
                }}
              >
                <ShoppingOutlined style={{ fontSize: '23px' }} />
                <label style={{ color: activeTabKey == '5' ? 'white' : '#4a4747', fontWeight: 'bold' }}>購入品</label>
              </Button>
            </div>
          </Space>
        </Col>
      </Row>
    </div>
  );

  const childPartListHeader = (
    <div
      style={{
        display: 'flex',
        height: 27,
      }}
    >
      <Space size="small">
        <p
          style={{
            fontSize: '13px',
            fontWeight: 'bold',
            margin: 0,
            paddingLeft: 7,
            color: 'black',
          }}
        >
          部品リスト：
        </p>
        <div style={{ display: 'flex', marginLeft: '0px', marginTop: '1.3px' }}>
          {' '}
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 3,
              paddingRight: 3,
              color: '#4A4747',
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#4A4747',
              backgroundColor: '#fcfade',
              borderRadius: 3,
            }}
          >
            選択中
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              paddingLeft: 10,
              margin: 0,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined && selectedData.no != undefined ? 'No.' + selectedData.no : 'No.0'}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              color: 'black',
            }}
          >
            /
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 10,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined ? selectedData.drawingNo : 0}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              color: 'black',
            }}
          >
            /
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 10,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined ? selectedData.name : 0}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              color: 'black',
            }}
          >
            /
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              margin: 0,
              paddingLeft: 10,
              paddingRight: 10,
              color: 'black',
            }}
          >
            {selectedData != undefined ? selectedData.partCount : 0}
          </p>
        </div>
      </Space>
    </div>
  );

  const modalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitleTxt}</p>
    </div>
  );

  const parentListModalTitle = (
    <div
      style={{
        width: 1670,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 33,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 5, paddingLeft: 18, color: 'white' }}>追加呼出</p>
    </div>
  );

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      className: 'cm-a-right',
      width: '5%',
    },
    {
      id: '2',
      title: '状態',
      dataIndex: 'creationType',
      // width: 80 + 'px',
      width: '5%',
      align: 'center',
      render: (value) =>
        value === EstimateCreationType.Auto ? (
          <Image src={auto} alt="" width={31} preview={false} />
        ) : (
          <Image src={manual} alt="" width={31} preview={false} />
        ),
    },
    {
      id: '3',
      title: '画像',
      dataIndex: 'imageUrl', // IQX_WEBEST-207
      // width: 180 + 'px',
      width: '8%',
      align: 'center',
      render: (value) => (
        <img className={value === undefined || value === '' ? 'pmx-no-img' : 'pmx-img'} src={value} alt="" />
      ), // IQX_WEBEST-247
    },
    {
      id: '4',
      // title: ['工程パターン', ' / ', '子部品図番', ' / ', '子部品名称'],
      title: (
        <div style={{ textAlign: 'center' }}>
          <div>工程パターン</div>
          <div>子部品図番</div>
          <div>子部品名称</div>
        </div>
      ),
      dataIndex: 'drawingNoName',
      // width: 280 + 'px',
      width: '18%',
      align: 'center',
      render: (_, record, index) => (
        <div style={{ textAlign: 'left' }}>
          {/* <label style={{ fontSize: '13px' }}>{record?.processSelect?.name}</label> */}
          <Typography.Text id={'typographyTxt'}  style={{ fontSize: '13px' }} ellipsis={{ tooltip: [record?.processSelect?.name] }}>
            {record?.processSelect?.name}
          </Typography.Text>
          <br />
          <Input
            style={{
              fontSize: '13px',
              height: 25,
              padding: 3,
              borderColor: '#a5a5a563',
              borderRadius: 4,
              marginTop: 8,
            }}
            className="input-editable"
            name="drawingNo"
            value={record.drawingNo}
            onChange={(e) => {
              if (record.drawingNo !== e.target.value) {
                let tempIQ3List = [...iQ3DataLst];
                tempIQ3List[index].drawingNo = e.target.value;
                setIQ3DataLst(tempIQ3List);
                props.selectedEstimateData.esIq3Info = tempIQ3List;
              }
            }}
          />
          <br />
          <Input
            style={{
              fontSize: '13px',
              height: 25,
              padding: 3,
              borderColor: '#a5a5a563',
              borderRadius: 4,
              marginTop: 8,
            }}
            className="input-editable"
            name="name"
            value={record.name}
            onChange={(e) => {
              if (record.name !== e.target.value) {
                let tempIQ3List = [...iQ3DataLst];
                tempIQ3List[index].name = e.target.value;
                setIQ3DataLst(tempIQ3List);
                props.selectedEstimateData.esIq3Info = tempIQ3List;
              }
            }}
          />
          <br />
        </div>
      ),
    },
    {
      id: '5',
      title: '員数(製造数)',
      dataIndex: 'partCount',
      // width: 150 + 'px',
      width: '8%',
      align: 'center',
      render: (_, record, index) => (
        <div style={{ textAlign: 'center' }}>
          <Input
            style={{
              textAlign: 'center',
              width: 50,
              fontSize: '13px',
              height: 25,
              padding: 3,
              borderColor: '#a5a5a563',
              borderRadius: 4,
            }}
            className="input-editable"
            name="partCount"
            value={record.partCount}
            onChange={(e) => {
              if (record.partCount !== e.target.value) {
                let tempIQ3List = [...iQ3DataLst];
                tempIQ3List[index].partCount = e.target.value;
                setIQ3DataLst(tempIQ3List);
                setIsPartCountChange(true);
              }
            }}
            onBlur={(e) => {
              // 員数はフォーカス離す、タブを離れることで自動計算される。
              if (isPartCountChange) {
                setIsPartCountChange(false);
                setIq3ActionForCal(true);
                props.updateIsRecal(true);
              }
            }}
            onPressEnter={(e) => {
              // 員数はEnterキーを押すことで自動計算される。
              if (isPartCountChange) {
                setIsPartCountChange(false);
                setIq3ActionForCal(true);
                props.updateIsRecal(true);
              }
            }}
          />
          <label style={{ fontSize: '13px', marginLeft: 5 }}>
            {'(' + record.partCount * props?.selectedEstimateData?.estimateProducts?.quantity + ')'}
          </label>
        </div>
      ),
    },
    {
      id: '6',
      // title: ['材質', ' / ', '板厚', ' / ', '表面保護'],
      title: (
        <div style={{ textAlign: 'center' }}>
          <div>材質 / 板厚</div>
          <div>表面保護</div>
        </div>
      ),
      dataIndex: 'materialItems',
      // width: 200 + 'px',
      width: '13%',
      align: 'center',
      render: (_, record) =>
        estimateParameters ? (
          <div style={{ textAlign: 'left' }}>
            {/* <label style={{ fontSize: '13px' }}>
              {getMaterialTypeById(record.materialTypeIq3Id, estimateParameters?.materialTypeIq3)}
            </label> */}
            <Typography.Text id={'typographyTxt'}  style={{ fontSize: '13px' }} ellipsis={{ tooltip: [getMaterialTypeById(record.materialTypeIq3Id, estimateParameters?.materialTypeIq3)] }}>
              {getMaterialTypeById(record.materialTypeIq3Id, estimateParameters?.materialTypeIq3)}
            </Typography.Text>
            &nbsp;&nbsp;&nbsp;
            <Typography.Text id={'typographyTxt'}  style={{ fontSize: '13px', marginTop: 2 }} ellipsis={{ tooltip: [getMaterialThickById(record.thickness, estimateParameters?.materialIq3)] }}>
              {getMaterialThickById(record.thickness, estimateParameters?.materialIq3)}
              </Typography.Text>
            <br />
            {/* <label style={{ fontSize: '13px', marginTop: 2 }}> */}
            <Typography.Text id={'typographyTxt'}  style={{ fontSize: '13px', marginTop: 2 }} ellipsis={{ tooltip: [getMaterialSurfaceById(record.materialSurfaceIq3Id, estimateParameters?.materialSurfaceIq3)] }}>
              
              {getMaterialSurfaceById(record.materialSurfaceIq3Id, estimateParameters?.materialSurfaceIq3)}
              </Typography.Text>
            {/* <br /> */}
            {/* <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialNameById(record.materialIq3Id, estimateParameters?.materialIq3)}
            </label> */}
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: '7',
      // title: ['重量', ' / ', '面積', ' / ', '表面処理費'],
      title: (
        <div style={{ textAlign: 'center' }}>
          <div>重量</div>
          <div>面積</div>
          <div>表面処理費</div>
        </div>
      ),
      dataIndex: 'amountItems',
      // width: 200 + 'px',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.weight ? toDecimal(record.weight, 2) : toDecimal(0, 2)}
            kg
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.totalSurfaceArea != undefined ? toDecimal(record.totalSurfaceArea, 2) : toDecimal(0, 2)}
            cm<sup>2</sup>
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.surfaceTotalPrice ? JPYs.format(Math.round(record.surfaceTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '8',
      // title: ['材料', ' / ', '製造', ' / ', '購入品'],
      title: (
        <div style={{ textAlign: 'center' }}>
          <div>材料</div>
          <div>製造</div>
          <div>購入品</div>
        </div>
      ),
      dataIndex: 'amountItems',
      // width: 200 + 'px',
      width: '11%',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.materialCostUnitPrice ? JPYs.format(Math.round(record.materialCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.workCostUnitPrice ? JPYs.format(Math.round(record.workCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.purchaseUnitPrice ? JPYs.format(Math.round(record.purchaseUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '9',
      title: '原価',
      dataIndex: 'costUnitPrice',
      // width: 200 + 'px',
      width: '11%',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costUnitPrice ? JPYs.format(Math.round(record.costUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '10',
      title: '原価合計',
      dataIndex: 'costTotalPrice',
      // width: 200 + 'px',
      width: '14%',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costTotalPrice ? JPYs.format(Math.round(record.costTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
  ];

  const settingModalTitle = (
    <div
      style={{
        width: 450,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <p style={{ marginLeft: 7, paddingTop: 8, color: 'white' }}>設定</p>
    </div>
  );

  // 設定コンテンツ
  const settingContent = (
    <>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>空送線を見積計算に含める</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>プランク加工の下穴を見積計算に含める</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>外形線を1本化処理して計算する</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>同一線上の曲げ線をまとめる</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={settingOK} className="mainButton" id="update">
            OK
          </Button>

          <Button onClick={settingCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  const detailModalTitle = (
    <div
      style={{
        width: detailTabModalWidth,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitleTxt}</p>
    </div>
  );

  // タブ切り替えで、材料詳細の情報を再計算するかどうかのチェック
  const isReCalMaterial = (isChangedProcessSelect = false) => {
    if (isDialogOk || activeTabKey === '6' || props?.isReCal || iq3ActionForCal) {
      let selectedIq3Data = { ...selectedData };
      if (isChangedProcessSelect) {
        selectedIq3Data = props?.selectedEstimateData?.esIq3Info?.find((iq3) => iq3?.no === selectedData?.no);
        setSelectedData(selectedIq3Data);
      }
      if (isDialogOk && activeTabKey !== '6' && !props?.isEstimateOpen) return reCalMaterial.oneRecordCal;
      let oldSelectedEstimateData = iq3MaterialDetailRef?.current?.getSelectedEstimateData();
      if (oldSelectedEstimateData === undefined && !isDialogOk) return reCalMaterial.noCal; // IQX_WEBEST-335 見積りデータ作成中、パラメータ画面と行き来すると選択材料が変わってしまいます。
      if (props?.selectedEstimateData?.estimateProducts?.id === 0 && oldSelectedEstimateData === undefined)
        return reCalMaterial.oneRecordCal;
      if (props?.selectedEstimateData?.estimateProducts?.id !== 0 && oldSelectedEstimateData === undefined)
        return reCalMaterial.noCal;
      let oldSelectedData = oldSelectedEstimateData?.esIq3Info?.find((iq3) => iq3.no === selectedIq3Data?.no);
      if (props?.selectedEstimateData?.estimateProducts?.id === 0 && oldSelectedData === undefined)
        return reCalMaterial.noCal;
      if (props?.selectedEstimateData?.estimateProducts?.id !== 0 && oldSelectedData === undefined)
        return reCalMaterial.noCal;
      let oldEstiamteQuantity = oldSelectedEstimateData?.estimateProducts?.quantity;
      let estiamteQuantity = props?.selectedEstimateData?.estimateProducts?.quantity;
      oldEstiamteQuantity = oldEstiamteQuantity ? oldEstiamteQuantity : 0;
      estiamteQuantity = estiamteQuantity ? estiamteQuantity : 0;
      // IQX_WEBEST-335 見積りデータ作成中、パラメータ画面と行き来すると選択材料が変わってしまいます。
      oldSelectedData.materialSurfaceIq3Id = oldSelectedData?.materialSurfaceIq3Id
        ? oldSelectedData?.materialSurfaceIq3Id
        : 0;
      selectedIq3Data.materialSurfaceIq3Id = selectedIq3Data?.materialSurfaceIq3Id
        ? selectedIq3Data?.materialSurfaceIq3Id
        : 0;
      if (
        oldSelectedData &&
        (Number(oldSelectedData?.partCount) !== Number(selectedIq3Data?.partCount) ||
          Number(oldSelectedData?.materialIq3Id) !== Number(selectedIq3Data?.materialIq3Id) ||
          Number(oldSelectedData?.materialTypeIq3Id) !== Number(selectedIq3Data?.materialTypeIq3Id) ||
          Number(oldSelectedData?.materialSurfaceIq3Id) !== Number(selectedIq3Data?.materialSurfaceIq3Id) ||
          Number(oldSelectedData?.thickness) !== Number(selectedIq3Data?.thickness) ||
          Number(oldSelectedData?.partSizeX) !== Number(selectedIq3Data?.partSizeX) ||
          Number(oldSelectedData?.partSizeY) !== Number(selectedIq3Data?.partSizeY) ||
          Number(oldSelectedData?.grabbingX) !== Number(selectedIq3Data?.grabbingX) ||
          Number(oldSelectedData?.grabbingY) !== Number(selectedIq3Data?.grabbingY) ||
          Number(oldSelectedData?.pierWidthX) !== Number(selectedIq3Data?.pierWidthX) ||
          Number(oldSelectedData?.pierWidthY) !== Number(selectedIq3Data?.pierWidthY) ||
          Number(oldSelectedData?.worlRangeX) !== Number(selectedIq3Data?.worlRangeX) ||
          Number(oldSelectedData?.worlRangeY) !== Number(selectedIq3Data?.worlRangeY))
      ) {
        return reCalMaterial.oneRecordCal; // 選択中の板金子部品のみ材料を再計算
      } else if (
        Number(estiamteQuantity) !== Number(oldEstiamteQuantity) || // 数量を変更した場合、
        props?.isReCalMaterial || // 再計算の場合、
        (iq3PMXDatas?.length === 0 && props?.isPMXEvent) // PMXデータの取り込み完了した場合、
      ) {
        if (props?.isReCalMaterial) {
          props?.updateIsRecalMaterial(false);
        }
        return reCalMaterial.allRecordCal; // 板金子部品全部の材料を再計算
      }
      return reCalMaterial.noCal; // 計算しない
    }
  };

  // Waitingダイアログを表示・非表示に更新する
  const updateLoading = (load) => {
    setIq3Loading(load);
  };

  const updateIq3ActionForCal = (flg) => {
    setIq3ActionForCal(flg);
  };

  return (
    <>
      <Spin
        spinning={isDialogOk || reCalByAddDelete || iq3ActionForCal || props?.activePartMenu !== '2' ? true : false}
        tip=""
        size="large"
      >
        {/* {props?.activePartMenu === '2' ? ( //見積保存の時、板金の基本情報をRefをアクセスできないため、この条件を解除して子JSごとに条件をいれる。 */}
        <>
          <Form>
            {/* 一覧とプロパティ表示 */}{' '}
            <div
              style={{
                height: '90.8vh',
                marginLeft: '6px',
                marginRight: '6px',
              }}
            >
              <Row id="iq3-rows" style={{ width: '99.5%' }}>
                <Col span={24}>{childPartListHeader}</Col>
              </Row>
              {menuIconList}
              <div id="iq3-list-div" className="iq3-list-div">
                <Table
                  id="iq3EstimateTbl"
                  showSorterTooltip={false}
                  rowClassName={(record) => (record.no == currentRecordNo ? 'active-row' : 'data-row')}
                  columns={iq3Columns}
                  rowKey={(record) => record.no}
                  dataSource={iQ3DataLst}
                  className="iq3-list-tb"
                  pagination={false}
                  // scroll={{ y: 717, x: '10vw' }}
                  scroll={{ y: 717, x: 'max-content' }}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                />
              </div>
              {/* ダイアログOKで再計算するための設置、条件付けで実行 */}
              <div style={{ display: 'none' }} className="iq3-tab-div">
                {/* 基本情報 */}
                <IQ3EstimateDetail
                  selectedIQ3DataDetail={selectedData}
                  ref={iq3DetailRef}
                  materialLst={estimateParameters?.materialIq3}
                  materialTypeLst={estimateParameters?.materialTypeIq3}
                  materialSurfaceLst={estimateParameters?.materialSurfaceIq3}
                  isReCal={props?.isReCal}
                  selectedEstimateData={props.selectedEstimateData}
                  currentRecordNo={currentRecordNo}
                  activeTabKey={activeTabKey}
                  partMenuChange={props.activePartMenu}
                />
                {/* 工程入力 */}
                <IQ3KouteiInput
                  ref={iq3KouteiInputRef}
                  processMasterData={processMasterInfo}
                  selectedIQ3DataDetail={selectedData}
                  materialList={materialLst}
                  currentRecordNo={currentRecordNo}
                  activeTabKey={activeTabKey}
                  selectedEstimateData={props.selectedEstimateData}
                  isReCal={props?.isReCal}
                  dialogOk={isDialogOk}
                />
                {/* 工程積算 */}
                <IQ3KouteiCalculate
                  ref={iq3KouteiCalRef}
                  editMode={editMode}
                  selectedIQ3DataDetail={selectedData}
                  deviceMasterInfo={deviceMasterInfo}
                  processMasterData={processMasterInfo}
                  selectedEstimateData={props.selectedEstimateData}
                  activeTabKey={activeTabKey}
                  currentRecordNo={currentRecordNo}
                  iq3ActionForCal={iq3ActionForCal}
                  isReCal={props?.isReCal}
                  activePartMenu={props?.activePartMenu}
                  selectedProcessSelect={selectedProcessSelect}
                  dialogOk={isDialogOk}
                />
                {/* 購入品 */}
                <IQ3Purchase
                  ref={purchaseRef}
                  selectedIQ3DataDetail={selectedData}
                  selectedEstimateData={props.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                  // editMode={editMode}
                  isChildPart={true}
                  activeTabKey={activeTabKey}
                  currentRecordNo={currentRecordNo}
                  isReCal={props?.isReCal}
                  dialogOk={isDialogOk}
                />
                {/* 材料詳細 */}
                <IQ3MaterialDetail
                  ref={iq3MaterialDetailRef}
                  editMode={editMode}
                  selectedIQ3DataDetail={selectedData}
                  selectedEstimateData={props.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                  materialLst={estimateParameters?.materialIq3}
                  materialTypeLst={estimateParameters?.materialTypeIq3}
                  materialSurfaceLst={estimateParameters?.materialSurfaceIq3}
                  processMasterData={getProcessMstInfo(estimateParameters?.process)}
                  deviceMasterData={getDeviceMstInfo(estimateParameters?.device, estimateParameters?.process)}
                  isReCalMaterial={isReCalMaterial()}
                  activeTabKey={activeTabKey}
                  // 新規子部品+材料詳細のタグ押下+初回押下
                  isFirstTimeMaterialDetail={
                    selectedData && selectedData?.id === 0 && activeTabKey === '6' && isFirstTimeMaterialDetail
                      ? true
                      : false
                  }
                  updateFirstTimeMaterialDetail={updateFirstTimeMaterialDetail}
                  currentRecordNo={currentRecordNo}
                  updateCurIQ3MaterialDetailData={updateCurIQ3MaterialDetailData}
                  loading={props?.loading}
                  dialogOk={isDialogOk}
                  isReCal={props?.isReCal}
                  iq3ActionForCal={iq3ActionForCal}
                />
              </div>
              {/* 工程別合計 */}
              <div
                style={{
                  display: 'flex',
                  marginTop: '0px',
                  position: 'absolute',
                  top: '-36px',
                  right: 0,
                }}
              >
                <KouteiSuryouBetsuBtn
                  selectedEstimateData={props?.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                />
              </div>
            </div>
          </Form>
          {/** 開発中ポップアップ */}
          {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
          {/** 編集中破棄確認ポップアップ */}
          {editModeCancelConfirm
            ? commonModal(
                editModeCancelConfirm,
                confirmScreenChangeTitle,
                commonFooter(discardConfirmOk, discardConfirmCancel),
                null,
                400,
                discardConfirmOk,
                discardConfirmCancel,
                confirmScreenChangeContent,
                null,
                null,
                false
              )
            : ''}
          {/** 更新 / 破棄の確認メッセージ */}
          {confirmMsgOpen
            ? commonModal(
                confirmMsgOpen,
                modalTitle,
                commonFooter(confirmOk, confirmCancel),
                null,
                400,
                confirmOk,
                confirmCancel,
                discardMode ? confirmDiscardContent : confirmUpdateContent,
                null,
                null,
                false
              )
            : ''}

          {/* 削除 */}
          {isDelete
            ? commonModal(
                isDelete,
                confirmModalTitle,
                commonDeleteFooter(deleteOk, deleteCancel),
                null,
                400,
                deleteOk,
                deleteCancel,
                /*  editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent, */
                <p style={{ fontSize: '13.5px' }}>
                  選択中の子部品を削除します。
                  <br></br>削除したデータは戻せませんが、よろしいですか？
                </p>,
                null,
                null,
                false
              )
            : ''}

          {/* 親部品リストダイアログ */}
          {parentListDisplay ? (
            <Modal
              maskClosable={false}
              open={parentListDisplay}
              title={parentListModalTitle}
              footer={null}
              bodyStyle={{ height: '71.7vh', marginTop: -5, marginLeft: -23 }}
              width={1670}
              onOk={addPartFromParentInfoOk}
              onCancel={addPartFromParentInfoCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 190, left: 50 }}
              centered={false}
            >
              <Spin spinning={iq3Loading} tip="Loading..." size="large">
                <div style={{ borderBottom: '1px solid #6c757d', width: '101.2%' }}>
                  <ParentList
                    getSelectedEstimateData={getSelectedEstimateData}
                    editMode={false}
                    parentListClick={false}
                    isFromiQ3={true}
                    orderOptions={newParameters?.estimateStatus}
                    companyInfo={newParameters?.company}
                    clientInfo={newParameters?.client}
                    userInfo={newParameters?.currentUserInfo}
                    staffInfo={newParameters?.staff}
                    processInfo={newParameters?.process}
                    iq3Loading={updateLoading}
                  />
                </div>
                <Row
                  justify="end"
                  style={{
                    marginBottom: 0,
                    marginTop: 7,
                  }}
                >
                  <Button className="mainButton" onClick={addPartFromParentInfoOk} style={{ marginRight: 5 }}>
                    追加
                  </Button>
                  <Button className="cancelButton" onClick={addPartFromParentInfoCancel}>
                    閉じる
                  </Button>
                </Row>
              </Spin>
            </Modal>
          ) : (
            ''
          )}
          {/* 設定操作 */}
          {isSetting
            ? commonModal(
                isSetting,
                settingModalTitle,
                null,
                { height: 123 },
                450,
                settingOK,
                settingCancel,
                settingContent,
                'updateModalStyle',
                null,
                false
              )
            : ''}

          {/* 基本情報ダイアログ */}
          {activeTabKey === '1' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '1' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '58vh', marginLeft: -17 }}
              width={1290}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 230, left: 200 }}
              centered={false}
            >
              <div className="iq3-tab-div">
                <IQ3EstimateDetail
                  selectedIQ3DataDetail={selectedData}
                  ref={iq3DetailRef}
                  materialLst={estimateParameters?.materialIq3}
                  materialTypeLst={estimateParameters?.materialTypeIq3}
                  materialSurfaceLst={estimateParameters?.materialSurfaceIq3}
                  isReCal={props?.isReCal}
                  selectedEstimateData={props.selectedEstimateData}
                  currentRecordNo={currentRecordNo}
                  activeTabKey={activeTabKey}
                  partMenuChange={props.activePartMenu}
                  tabOpen={activeTabKey === '1' ? true : false}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                  OK
                </Button>
                <Button className="cancelButton" onClick={detailDialogCancel}>
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 工程選択ダイアログ */}
          {activeTabKey === '2' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '2' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1200}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 150 }}
              centered={true}
            >
              <div className="iq3-tab-div" style={{ height: '70vh', overflowY: 'auto' }}>
                <IQ3KouteiSentaku
                  type={'list'}
                  ref={iq3KouteiSentakuRef}
                  editMode={editMode}
                  selectedIQ3DataDetail={selectedData}
                  // selectedEstimateData={props.selectedEstimateData}
                  selectedDataDetail={props.selectedEstimateData}
                  iQ3DataLst={iQ3DataLst}
                  pmxMode={false}
                  updateSelectedData={updateSelectedData}
                  processMasterData={processMasterInfo}
                  deviceMasterData={deviceMasterInfo}
                  clientMasterData={clientMasterInfo}
                  currentRecordNo={currentRecordNo}
                  activeTabKey={activeTabKey}
                  isReCal={props?.isReCal}
                  updateProcessSelectOfSelectedData={updateProcessSelectOfSelectedData}
                  tabOpen={activeTabKey === '2' ? true : false}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                  OK
                </Button>
                <Button className="cancelButton" onClick={detailDialogCancel}>
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 工程入力ダイアログ */}
          {activeTabKey === '3' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '3' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1390}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 215 }}
              centered={true}
            >
              <div className="iq3-tab-div" style={{ height: '70vh', overflowY: 'auto' }}>
                <IQ3KouteiInput
                  ref={iq3KouteiInputRef}
                  processMasterData={processMasterInfo}
                  // editMode={editMode}
                  selectedIQ3DataDetail={selectedData}
                  materialList={materialLst}
                  currentRecordNo={currentRecordNo}
                  activeTabKey={activeTabKey}
                  selectedEstimateData={props.selectedEstimateData}
                  isReCal={props?.isReCal}
                  tabOpen={activeTabKey === '3' ? true : false}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                  OK
                </Button>
                <Button className="cancelButton" onClick={detailDialogCancel}>
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 工程積算ダイアログ */}
          {activeTabKey === '4' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '4' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1370}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 215 }}
              centered={true}
            >
              <div className="iq3-tab-div" style={{ height: '70vh', overflowY: 'auto' }}>
                <IQ3KouteiCalculate
                  ref={iq3KouteiCalRef}
                  editMode={editMode}
                  selectedIQ3DataDetail={selectedData}
                  deviceMasterInfo={deviceMasterInfo}
                  processMasterData={processMasterInfo}
                  selectedEstimateData={props.selectedEstimateData}
                  activeTabKey={activeTabKey}
                  currentRecordNo={currentRecordNo}
                  iq3ActionForCal={iq3ActionForCal}
                  isReCal={props?.isReCal}
                  activePartMenu={props?.activePartMenu}
                  selectedProcessSelect={selectedProcessSelect}
                  tabOpen={activeTabKey === '4' ? true : false}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                  OK
                </Button>
                <Button className="cancelButton" onClick={detailDialogCancel}>
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 購入品ダイアログ */}
          {activeTabKey === '5' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '5' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1300}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 205 }}
              centered={true}
            >
              <div className="iq3-tab-div" style={{ height: '70vh', overflowY: 'auto' }}>
                <IQ3Purchase
                  ref={purchaseRef}
                  selectedIQ3DataDetail={selectedData}
                  selectedEstimateData={props.selectedEstimateData}
                  getParentQuantity={props?.getParentQuantity}
                  // editMode={editMode}
                  isChildPart={true}
                  activeTabKey={activeTabKey}
                  currentRecordNo={currentRecordNo}
                  isReCal={props?.isReCal}
                  tabOpen={activeTabKey === '5' ? true : false}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                }}
              >
                <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                  OK
                </Button>
                <Button className="cancelButton" onClick={detailDialogCancel}>
                  キャンセル
                </Button>
              </Row>
            </Modal>
          ) : (
            ''
          )}
          {/* 材料詳細ダイアログ */}
          {activeTabKey === '6' ? (
            <Modal
              maskClosable={false}
              open={activeTabKey === '6' ? true : false}
              title={detailModalTitle}
              footer={null}
              bodyStyle={{ height: '73vh', marginLeft: -17 }}
              width={1300}
              onOk={detailDialogOk}
              onCancel={detailDialogCancel}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />}
              className={null}
              style={{ top: 85, left: 205 }}
              centered={true}
            >
              <Spin spinning={isDialogOk} tip="Loading..." size="large">
                <div className="iq3-tab-div" style={{ height: '70vh', overflowY: 'auto' }}>
                  <IQ3MaterialDetail
                    ref={iq3MaterialDetailRef}
                    editMode={editMode}
                    selectedIQ3DataDetail={selectedData}
                    selectedEstimateData={props.selectedEstimateData}
                    getParentQuantity={props?.getParentQuantity}
                    materialLst={estimateParameters?.materialIq3}
                    materialTypeLst={estimateParameters?.materialTypeIq3}
                    materialSurfaceLst={estimateParameters?.materialSurfaceIq3}
                    processMasterData={getProcessMstInfo(estimateParameters?.process)}
                    deviceMasterData={getDeviceMstInfo(estimateParameters?.device, estimateParameters?.process)}
                    isReCalMaterial={isReCalMaterial()}
                    activeTabKey={activeTabKey}
                    // 新規子部品+材料詳細のタグ押下+初回押下
                    isFirstTimeMaterialDetail={
                      selectedData && selectedData?.id === 0 && activeTabKey === '6' && isFirstTimeMaterialDetail
                        ? true
                        : false
                    }
                    updateFirstTimeMaterialDetail={updateFirstTimeMaterialDetail}
                    currentRecordNo={currentRecordNo}
                    updateCurIQ3MaterialDetailData={updateCurIQ3MaterialDetailData}
                    loading={props?.loading}
                    tabOpen={activeTabKey === '6' ? true : false}
                    iq3ActionForCal={iq3ActionForCal}
                  />
                </div>
                <Row
                  justify="end"
                  style={{
                    marginBottom: 0,
                    marginTop: 5,
                  }}
                >
                  <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
                    OK
                  </Button>
                  <Button className="cancelButton" onClick={detailDialogCancel}>
                    キャンセル
                  </Button>
                </Row>
              </Spin>
            </Modal>
          ) : (
            ''
          )}
        </>
        {/* ) : (
          ''
        )} */}
      </Spin>
    </>
  );
});

export default IQ3Estimate;
