/**
 * クラス名：形鋼子部品の各工程の計算
 * 説明：形鋼子部品の各工程の計算ファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/12
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { Input, Row, Col, Select, Image, Space, Button, Radio, Checkbox, Divider, Modal, Tabs } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';

import { JPYs, toDecimal, secondsToHms, getDetails, toDecimal_place3 } from '../common/Common.js';
import {
  amtEmptyStr,
  Weight_IQ5ManufactSizeKind,
  Area_IQ5ManufactSizeKind,
  WeldFinish_IQ5ManufactSizeKind,
  Debbur_IQ5ManufactSizeKind,
  Groove_IQ5ManufactSizeKind,
  Mount_IQ5ManufactSizeKind,
  Bend_IQ5ManufactSizeKind,
  Notch_IQ5ManufactSizeKind,
  Hole_Type,
  Hole_Instructions,
  Hole_IQ5ManufactSizeKind,
  Cut_IQ5ManufactSizeKind,
} from '../common/Constant.js';
import { WorkType } from '../common/enums.js';
import {
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDeleteContent,
  error,
  getConfirmSochiDeleteContent,
  getConfirmSochiUpdateContent,
} from '../common/CommonModal';
import {
  updateProcessAdditionsAndCalculateTotals,
  calAmt,
  calculateCommonMaterial,
  calculateSteelCutAmt,
  getCoeff,
  getWeightMmCoeff,
  calculateByProcess,
  calculateArea,
  calculateIQ5Material,
} from '../common/CommonIQ5.js';

const IQ5KouteiCalculate = forwardRef((props, ref) => {
  const [materialInstruction, setMaterialInstruction] = useState(1);
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [curDeviceLst, setCurDeviceLst] = useState([]);
  const [curDeviceInfo, setCurDeviceInfo] = useState({});
  const [curProcessMasterInfo, setCurProcessMasterInfo] = useState({});
  const [curDeviceProcessInputDetails, setCurDeviceProcessInputDetails] = useState([]);
  const [curAdditionsDetails, setCurAdditionsDetails] = useState([]);
  const [curRowNo, setCurRowNo] = useState(0);
  const [selectedDeviceInfo, setSelectedDeviceInfo] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateModalContent, setUpdateModalContent] = useState(null);
  const [oldDeviceName, setOldDeviceName] = useState('');
  const [newDeviceName, setNewDeviceName] = useState('');
  const [materialDimension, setMaterialDimension] = useState(0);
  const [cutTimes, setCutTimes] = useState(0);
  const [materialNum, setMaterialNum] = useState(0);
  const [standardLenKey, setStandardLenKey] = useState(''); 
  const [selectedDetailData, setSelectedDetailData] = useState({});
  const [materialIq5Info, setMaterialIq5Info] = useState({});
  const [materialIq5UnitType, setMaterialIq5UnitType] = useState(1);
  const [bladeWidth, setBladeWidth] = useState(1);
  const [disposal, setDisposal] = useState(1);
  const [materialUnit, setMaterialUnit] = useState(0);
  const [localWorkType, setLocalWorkType] = useState(props?.workType);

  useEffect(() => {
    setLocalWorkType(props?.workType);
    settingCurDeviceProcessInputDetails(props?.workType);
  }, [props?.workType]);

  useEffect(() => {
    settingCurDeviceProcessInputDetails(props?.workType);
  }, [props?.isOpenProcessDetail]);

  // ダイアログの工程より情報設定
  const settingCurDeviceProcessInputDetails = (workType) => {
    let processMaster = props?.processMasterInfo?.filter((item) => item.workType === workType)?.[0];
    setCurProcessMasterInfo(processMaster);
    let activeItem = props?.processTabItems?.filter((item) => item.label === processMaster?.name)?.[0];
    setActiveTabKey(activeItem?.key);

    // 部品詳細の工程選択により工程積算と工程入力設定
    let processInput = props?.selectedProcessInput?.filter((item) => item.processId === processMaster?.id)?.[0];
    if (processInput) {
      setCurDeviceProcessInputDetails(processInput?.deviceProcessInputDetails);
    }
    let processAdditions = props?.selectedProcessAdditions?.filter((item) => item.processId === processMaster?.id)?.[0];
    if (processAdditions) {
      setCurAdditionsDetails(processAdditions?.deviceAdditionsDetails);
    }
    // 部品詳細の工程選択により装置情報リスト設定
    let devices = props.deviceMasterInfo;
    let curDevices = devices?.filter((item) => item.processId === processMaster?.id);
    if (curDevices?.length > 0) {
      setCurDeviceLst(curDevices);
      if (processInput?.deviceProcessInputDetails?.length > 0) {
        let currentSochiInfos = curDevices.filter(
          (cur) => cur.id === processInput?.deviceProcessInputDetails?.[0]?.deviceId
        );
        setCurDeviceInfo(currentSochiInfos?.[0]);
        setSelectedDeviceInfo(currentSochiInfos?.[0]);
      } else {
        setCurDeviceInfo(curDevices?.[0]);
        setSelectedDeviceInfo(curDevices?.[0]);
      }
    }

    if ( localWorkType === WorkType.PaSteelCut) {
      setSelectedDetailData(props?.selectedIQ5DataDetail);

      let materialIq5Parm = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.materialIq5;
      let materialIq5 = materialIq5Parm?.filter((item) => item.id === props?.selectedIQ5DataDetail?.materialIq5Id);
      setMaterialIq5Info(materialIq5?.length > 0 ? materialIq5?.[0] : {});
      setMaterialIq5UnitType(props?.selectedIQ5DataDetail?.materialUnitType);
      setBladeWidth(curDevices?.[0]?.details?.bladeWidth);
      setDisposal(curDevices?.[0]?.details?.disposal);
      // 
      setMaterialUnit(props?.selectedIQ5DataDetail?.materialUnit);
      setStandardLenKey(props?.selectedIQ5DataDetail?.standardLen !== 0 ? props?.selectedIQ5DataDetail?.standardLen : '');
      setMaterialInstruction(props?.selectedIQ5DataDetail?.materialInstruction);
      setMaterialDimension(props?.selectedIQ5DataDetail?.materialSize);
      setCutTimes(processInput?.deviceProcessInputDetails?.[0]?.details?.[0]?.cutCount);
      setMaterialNum(props.selectedIQ5DataDetail.materialQuantity);
    }
  };

  const getMaterialUnitValue = (materialUnitType, materialIq5Info) => {
    let matUnitNm = unitLst?.find((u) => u.key === materialUnitType)?.label;
    let matUnit = 0;
    if (matUnitNm === 'M単価') {
      matUnit = materialIq5Info?.info?.pricePerM ? JPYs.format(materialIq5Info?.info?.pricePerM) : JPYs.format(0);
    } else if (matUnitNm === 'Kg単価') {
      matUnit = materialIq5Info?.info?.pricePerKg ? JPYs.format(materialIq5Info?.info?.pricePerKg) : JPYs.format(0);
    } else {
      matUnit = JPYs.format(0);
    }
    setMaterialUnit(matUnit);
  };

  useEffect(() => {
    if (
      props?.workType === WorkType.PaSteelCut &&
      curDeviceProcessInputDetails.length > 0 &&
      curAdditionsDetails.length > 0
    ) {
      let deviceProcessInputDetails = JSON.parse(JSON.stringify(curDeviceProcessInputDetails));
      calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
    }
  }, [cutTimes]);

  // 材料指示（定尺選択の時）によって、カット数と本数計算式
  const changeMaterialValue = (e) => {
    let materialInst = materialInstructionLst?.filter((item) => item.key === e)?.[0]?.label;

    let count = 0;
    let materialLength = Number(props?.selectedIQ5DataDetail?.size); // mm
    let requiredMaterialLength = Number(props?.selectedIQ5DataDetail?.size); // mm
    let requiredMaterial =
      Number(props?.selectedIQ5DataDetail?.partCount * props?.selectedEstimateData?.estimateProducts?.quantity);
    let materialSize = Number(props?.selectedIQ5DataDetail?.materialSize);

    if (materialInst === '定尺') {
      let materialInst = standardLen?.filter((item) => item.key === 1)?.[0]?.label;
      if (materialInst !== '' || materialInst !== null || materialInst !== undefined) {
        const number = parseFloat(materialInst);
        const result = Number.isInteger(number) ? Math.floor(number) : number;
        setStandardLenKey(1);
        props.selectedIQ5DataDetail.standardLen = 1;
        let newMatriValue = result * 1000;

        const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
          bladeWidth,
          disposal,
          count,
          materialLength,
          requiredMaterialLength,
          requiredMaterial,
          materialSize,
          newMatriValue
        );

        props.selectedIQ5DataDetail.materialSize = material;
        props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
        setMaterialDimension(material);
        setCutTimes(cutTimes);
        setMaterialNum(originalMaterials);

        calculateMaterialCost(materialIq5UnitType, materialUnit, materialNum, material);
      }
    } else if (materialInst === '自動') {
      let newMaterial = materialLength + disposal + bladeWidth * 2;

      const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
        bladeWidth,
        disposal,
        count,
        materialLength,
        requiredMaterialLength,
        requiredMaterial,
        newMaterial
      );
      //
      props.selectedIQ5DataDetail.materialSize = material;
      props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
      setMaterialDimension(material);
      setCutTimes(cutTimes);
      setMaterialNum(originalMaterials);
      setStandardLenKey('');

      calculateMaterialCost(materialIq5UnitType, materialUnit, materialNum, material);
      props.selectedIQ5DataDetail.standardLen = 0;
    } else {
      const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
        bladeWidth,
        disposal,
        count,
        materialLength,
        requiredMaterialLength,
        requiredMaterial,
        materialSize
      );
      props.selectedIQ5DataDetail.materialSize = material;
      props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
      setMaterialDimension(material);
      setCutTimes(cutTimes);
      setMaterialNum(originalMaterials);
      setStandardLenKey('');

      calculateMaterialCost(materialIq5UnitType, materialUnit, materialNum, material);
      props.selectedIQ5DataDetail.standardLen = 0;
    }
    props.selectedIQ5DataDetail.materialInstruction = e;
  };

  // 材料指示（定尺）によって、カット数と本数計算式
  const calculateMaterialByStandardLan = (e) => {
    if (e !== '') {
      let materialInst = standardLen?.filter((item) => item.key === e)?.[0]?.label;
      if (materialInst !== '' || materialInst !== null || materialInst !== undefined) {
        const number = parseFloat(materialInst);
        const result = Number.isInteger(number) ? Math.floor(number) : number;
        setStandardLenKey(e);
        let newMatriValue = result * 1000;
        let count = 0;

        let materialLength = props?.selectedIQ5DataDetail?.size; // mm
        let requiredMaterialLength = props?.selectedIQ5DataDetail?.size; // mm
        let requiredMaterial =
          props?.selectedIQ5DataDetail?.partCount * props?.selectedEstimateData?.estimateProducts?.quantity;
        let materialSize = props?.selectedIQ5DataDetail?.materialSize;

        const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
          bladeWidth,
          disposal,
          count,
          materialLength,
          requiredMaterialLength,
          requiredMaterial,
          materialSize,
          newMatriValue
        );

        props.selectedIQ5DataDetail.materialSize = material;
        props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
        setMaterialDimension(material);
        setCutTimes(cutTimes);
        setMaterialNum(originalMaterials);

        calculateMaterialCost(materialIq5UnitType, materialUnit, materialNum, material);
        props.selectedIQ5DataDetail.standardLen = e;
      }
    }
  };

  // 材料寸法によって、カット数と本数計算式
  const clculateMaterialValueByMateDim = (inputValue) => {
    setMaterialDimension(inputValue);
    let count = 0;
    let materialLength = props?.selectedIQ5DataDetail?.size; // mm
    let requiredMaterialLength = props?.selectedIQ5DataDetail?.size; // mm
    let requiredMaterial =
      props?.selectedIQ5DataDetail?.partCount * props?.selectedEstimateData?.estimateProducts?.quantity;
    let materialSize = props?.selectedIQ5DataDetail?.materialSize;
    const { material, cutTimes, originalMaterials } = calculateCommonMaterial(
      bladeWidth,
      disposal,
      count,
      materialLength,
      requiredMaterialLength,
      requiredMaterial,
      materialSize,
      inputValue
    );

    props.selectedIQ5DataDetail.materialSize = material;
    props.selectedIQ5DataDetail.materialQuantity = originalMaterials;
    setMaterialDimension(material);
    setCutTimes(cutTimes);
    setMaterialNum(originalMaterials);
  };

  // 材料費計算する
  const calculateMaterialCost = (materialIq5UnitType, materailUnitValue, materialNum, materialDimension) => {
    let materialSize = materialDimension;
    selectedDetailData.materialSize = materialSize;
    let materialWeight = (materialIq5Info?.info?.kgPerM * materialSize) / 1000;
    let iq5_manufacturingCnt = selectedDetailData?.partCount * props.selectedEstimateData?.estimateProducts?.quantity;

    // 材料費計算する
    calculateIQ5Material(
      selectedDetailData,
      materialSize,
      materialIq5UnitType,
      materailUnitValue,
      materialNum,
      materialWeight,
      materialIq5Info,
      iq5_manufacturingCnt,
      props.selectedEstimateData?.estimateProducts?.quantity
    );
  };

  // 装置追加
  const newDeviceProcessDetail = () => {
    let sameDeviceInfo = curAdditionsDetails?.filter((item) => item.deviceId === selectedDeviceInfo?.id);
    if (sameDeviceInfo?.length > 0) {
      return;
    }
    // 工程入力新規設定
    let inputDetails = JSON.parse(JSON.stringify(curDeviceProcessInputDetails));
    const deviceProcessInputDetails = {
      no: inputDetails?.length === 0 ? 1 : inputDetails?.length + 1,
      deviceId: selectedDeviceInfo ? selectedDeviceInfo?.id : 0,
      deviceNm: selectedDeviceInfo ? selectedDeviceInfo?.name : 0,
      details:
        props?.workType === WorkType.PaSteelCut
          ? [
              {
                no: 1,
                processDetailTypeNo: 0,
                disposal: selectedDeviceInfo ? selectedDeviceInfo?.details?.disposal : 0,
                bladeWidth: selectedDeviceInfo ? selectedDeviceInfo?.details?.bladeWidth : 0,
                kindNo: 0,
                cutCount: 0,
                cutLength: 0,
              },
            ]
          : getDetails(props?.workType, props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters, []),
    };
    // 子部品重量のリセット
    let updateSelectedProcessInput = JSON.parse(JSON.stringify(props?.selectedProcessInput));
    if (props?.workType === WorkType.PaSteelWeight) {
      deviceProcessInputDetails.details[0].weight = props?.selectedIQ5DataDetail?.weight;
    }
    // 子部品表面積のリセット
    else if (props?.workType === WorkType.PaSteelArea) {
      deviceProcessInputDetails.details[0].area = props?.selectedIQ5DataDetail?.totalSurfaceArea;
    }
    // 子部品溶接仕上・溶接・バリ取り・開先リセット
    else if (
      props?.workType === WorkType.PaSteelWeldFinish ||
      props?.workType === WorkType.PaSteelWeld ||
      props?.workType === WorkType.PaSteelDebbur ||
      props?.workType === WorkType.PaSteelGroove
    ) {
      let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
        (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
      )?.[0];
      deviceProcessInputDetails.details[0].weldLength = materialIq5?.info?.perimeter;
      deviceProcessInputDetails.details[0].dimension = props?.selectedIQ5DataDetail?.size;
    }
    // 曲げ
    else if (props?.workType === WorkType.PaSteelBend) {
      deviceProcessInputDetails.details[0].dimension = props?.selectedIQ5DataDetail?.size;
    }
    // 切欠
    else if (props?.workType === WorkType.PaSteelNotch) {
      let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
        (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
      )?.[0];
      deviceProcessInputDetails.details[0].notchLength = materialIq5?.info?.perimeter;
      deviceProcessInputDetails.details[0].dimension = props?.selectedIQ5DataDetail?.size;
    }
    deviceProcessInputDetails.details[0].kindNo = selectedDeviceInfo?.details?.initialDisplay;
    inputDetails.push(deviceProcessInputDetails);
    setCurDeviceProcessInputDetails(inputDetails);

    updateSelectedProcessInput?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceProcessInputDetails = inputDetails;
      }
    });

    // 工程積算新規設定
    let additionsDetails = JSON.parse(JSON.stringify(curAdditionsDetails));
    const deviceAdditionsDetails = {
      no: additionsDetails?.length === 0 ? 1 : additionsDetails?.length + 1,
      deviceId: selectedDeviceInfo ? selectedDeviceInfo?.id : 0,
      deviceNm: selectedDeviceInfo ? selectedDeviceInfo?.name : 0,
      dandoriTime: 0,
      dandoriAmt: 0,
      sagyoTime: 0,
      sagyoAmt: 0,
      sagyoDetails: [
        {
          no: 1,
          processDetailTypeNo:
            curProcessMasterInfo?.details?.processDetailTypes?.length > 0
              ? curProcessMasterInfo?.details?.processDetailTypes?.[0]?.id
              : 0,
          sagyoTime: 0,
          sagyoAmt: 0,
        },
      ],
    };
    additionsDetails.push(deviceAdditionsDetails);
    setCurAdditionsDetails(additionsDetails);
    let updateSelectedProcessAdditions = JSON.parse(JSON.stringify(props?.selectedProcessAdditions));
    updateSelectedProcessAdditions?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceAdditionsDetails = additionsDetails;
      }
    });
    props?.changeSelectedProcessInputAdditions(updateSelectedProcessAdditions, updateSelectedProcessInput);
    setCurDeviceInfo(selectedDeviceInfo);
    if (props?.workType === WorkType.PaSteelCut) {
      calculateByEachProcess(inputDetails, additionsDetails);
    }
  };

  // 装置変更
  const updateDeviceProcessDetail = () => {
    setIsUpdate(false);
    // 工程入力変更
    let inputDetails = JSON.parse(JSON.stringify(curDeviceProcessInputDetails));
    let selected_deviceProcessInputDetails = inputDetails?.filter(
      (detail) => detail.deviceId === selectedDeviceInfo?.id
    );
    if (selected_deviceProcessInputDetails?.length > 0) return;

    let selectedInputDeviceNo = inputDetails?.filter((detail) => detail.deviceId === curDeviceInfo?.id)?.[0]?.no;
    inputDetails?.map((upItem) => {
      if (upItem.no === selectedInputDeviceNo) {
        upItem.deviceId = selectedDeviceInfo?.id;
        upItem.deviceNm = selectedDeviceInfo?.name;
        let idd = upItem.details?.[0]?.kindNo;
        let kindNo = selectedDeviceInfo?.details?.iQ5ManufactSizeInfo.kinds?.find(
          (detail) => detail.no === upItem.details?.[0]?.kindNo && detail.dispSW
        )?.no;
        if (kindNo) {
          upItem.kindNo = kindNo;
        } else {
          upItem.details[0].kindNo = selectedDeviceInfo?.details?.initialDisplay;
        }
      }
    });
    setCurDeviceProcessInputDetails(inputDetails);
    let updateSelectedProcessInput = JSON.parse(JSON.stringify(props?.selectedProcessInput));
    updateSelectedProcessInput?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceProcessInputDetails = inputDetails;
      }
    });

    // 工程積算変更
    let additionsDetails = JSON.parse(JSON.stringify(curAdditionsDetails));
    let selectedAdditionDeviceNo = additionsDetails?.filter((detail) => detail.deviceId === curDeviceInfo?.id)?.[0]?.no;
    additionsDetails?.map((upItem) => {
      if (upItem.no === selectedAdditionDeviceNo) {
        upItem.deviceId = selectedDeviceInfo?.id;
        upItem.deviceNm = selectedDeviceInfo?.name;
      }
    });
    if (props?.workType === WorkType.PaSteelCut) {
      calculateByEachProcess(inputDetails, additionsDetails);
    } else {
      calculateByEachProcess(inputDetails, additionsDetails);
    }
    setCurDeviceInfo(selectedDeviceInfo);
  };

  // 装置削除
  const deleteDeviceProcessDetail = () => {
    setIsDelete(false);
    // 工程入力削除
    let inputDetails = JSON.parse(JSON.stringify(curDeviceProcessInputDetails));
    inputDetails = inputDetails?.filter((detail) => detail.deviceId !== curDeviceInfo?.id);
    setCurDeviceProcessInputDetails(inputDetails);
    let updateSelectedProcessInput = JSON.parse(JSON.stringify(props?.selectedProcessInput));
    updateSelectedProcessInput?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceProcessInputDetails = inputDetails;
      }
    });
    // 工程積算削除
    let additionsDetails = JSON.parse(JSON.stringify(curAdditionsDetails));
    additionsDetails = additionsDetails?.filter((detail) => detail.deviceId !== curDeviceInfo?.id);
    setCurAdditionsDetails(additionsDetails);
    let updateSelectedProcessAdditions = JSON.parse(JSON.stringify(props?.selectedProcessAdditions));
    updateSelectedProcessAdditions?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceAdditionsDetails = additionsDetails;
      }
    });
    props?.changeSelectedProcessInputAdditions(updateSelectedProcessAdditions, updateSelectedProcessInput);
    let select_curDevice = curDeviceLst?.find((item) => item.id === inputDetails?.[0]?.deviceId);
    setCurDeviceInfo(Object.keys(select_curDevice)?.length > 0 ? select_curDevice : {});
    setSelectedDeviceInfo(Object.keys(select_curDevice)?.length > 0 ? select_curDevice : {});
  };

  // 削除確認
  const deleteConfirm = (sochiName) => {
    const content = getConfirmSochiDeleteContent(sochiName);
    setDeleteModalContent(content);
    setIsDelete(true);
  };

  //　削除キャンセル
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  // 更新確認
  const updateConfirm = (oldDeviceName, newDeviceName) => {
    if (oldDeviceName !== newDeviceName) {
      const content = getConfirmSochiUpdateContent(oldDeviceName, newDeviceName);
      setUpdateModalContent(content);
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  };

  //　更新キャンセル
  const updateCancel = (e) => {
    setIsUpdate(false);
  };

  // 種類追加
  const newProcessTypeDetail = (deviceId) => {
    let curDeviceInfo = curDeviceLst?.filter((d) => d.id === deviceId)?.[0];
    // 工程入力の種類情報の新規設定
    let upDeviceProcessInputDetails = JSON.parse(JSON.stringify(curDeviceProcessInputDetails));
    const typeDetail = getDetails(
      props?.workType,
      props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters,
      []
    );
    // 子部品表面積のリセット
    if (props?.workType === WorkType.PaSteelArea) {
      typeDetail[0].area = props?.selectedIQ5DataDetail?.totalSurfaceArea;
    }
    // 子部品溶接仕上・溶接・バリ取り・開先のリセット
    if (
      props?.workType === WorkType.PaSteelWeldFinish ||
      props?.workType === WorkType.PaSteelWeld ||
      props?.workType === WorkType.PaSteelDebbur ||
      props?.workType === WorkType.PaSteelGroove
    ) {
      typeDetail[0].weldLength = props?.selectedIQ5DataDetail?.weldLength;
      typeDetail[0].dimension = props?.selectedIQ5DataDetail?.size;
    }
    // 子部品重量のリセット
    typeDetail[0].weight = props?.selectedIQ5DataDetail?.weight;
    upDeviceProcessInputDetails?.map((proInput) => {
      if (proInput.deviceId === curDeviceInfo?.id) {
        const maxId = proInput?.details?.length > 0 ? Math.max(...proInput?.details?.map((item) => item.no)) : 0;
        typeDetail[0].no = maxId + 1;
        proInput?.details?.push(typeDetail[0]);
      }
    });
    // 子部品曲げのリセット
    if (props?.workType === WorkType.PaSteelBend) {
      typeDetail[0].dimension = props?.selectedIQ5DataDetail?.size;
    }
    // 子部品切欠のリセット
    if (props?.workType === WorkType.PaSteelNotch) {
      typeDetail[0].notchLength = props?.selectedIQ5DataDetail?.notchLength;
      typeDetail[0].dimension = props?.selectedIQ5DataDetail?.size;
    }
    setCurDeviceProcessInputDetails(upDeviceProcessInputDetails);
    let updateSelectedProcessInput = JSON.parse(JSON.stringify(props?.selectedProcessInput));
    updateSelectedProcessInput?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceProcessInputDetails = upDeviceProcessInputDetails;
      }
    });
    // 工程積算の種類情報の新規設定
    let upAdditionsDetails = JSON.parse(JSON.stringify(curAdditionsDetails));
    const newSagyoDetail = {
      no: 0,
      processDetailTypeNo:
        curProcessMasterInfo?.details?.processDetailTypes?.length > 0
          ? curProcessMasterInfo?.details?.processDetailTypes?.[0]?.id
          : 0,
      sagyoTime: 0,
      sagyoAmt: 0,
    };
    upAdditionsDetails?.map((addition) => {
      if (addition.deviceId === curDeviceInfo?.id) {
        const maxId =
          addition?.sagyoDetails?.length > 0 ? Math.max(...addition?.sagyoDetails?.map((item) => item.no)) : 0;
        newSagyoDetail.no = maxId + 1;
        addition?.sagyoDetails?.push(newSagyoDetail);
      }
    });
    setCurAdditionsDetails(upAdditionsDetails);
    let updateSelectedProcessAdditions = JSON.parse(JSON.stringify(props?.selectedProcessAdditions));
    updateSelectedProcessAdditions?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceAdditionsDetails = upAdditionsDetails;
      }
    });
    props?.changeSelectedProcessInputAdditions(updateSelectedProcessAdditions, updateSelectedProcessInput);
  };

  // 種類の複写
  const copyProcessTypeDetail = (deviceId) => {
    let curDeviceInfo = curDeviceLst?.filter((d) => d.id === deviceId)?.[0];
    // 工程入力の種類情報の複写
    let upDeviceProcessInputDetails = JSON.parse(JSON.stringify(curDeviceProcessInputDetails));
    upDeviceProcessInputDetails?.map((proInput) => {
      if (proInput.deviceId === curDeviceInfo?.id) {
        let typeDetail = proInput?.details?.filter((detail) => detail.no === curRowNo);
        typeDetail = JSON.parse(JSON.stringify(typeDetail));
        if (typeDetail?.length > 0) {
          const maxId = proInput?.details?.length > 0 ? Math.max(...proInput?.details?.map((item) => item.no)) : 0;
          typeDetail[0].no = maxId + 1;
          proInput?.details?.push(typeDetail[0]);
        }
      }
    });
    setCurDeviceProcessInputDetails(upDeviceProcessInputDetails);
    let updateSelectedProcessInput = JSON.parse(JSON.stringify(props?.selectedProcessInput));
    updateSelectedProcessInput?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceProcessInputDetails = upDeviceProcessInputDetails;
      }
    });
    // 工程積算の種類情報の複写
    let upAdditionsDetails = JSON.parse(JSON.stringify(curAdditionsDetails));
    upAdditionsDetails?.map((addition) => {
      if (addition.deviceId === curDeviceInfo?.id) {
        let newSagyoDetail = addition?.sagyoDetails?.filter((item) => item.no === curRowNo);
        newSagyoDetail = JSON.parse(JSON.stringify(newSagyoDetail));
        if (newSagyoDetail?.length > 0) {
          const maxId =
            addition?.sagyoDetails?.length > 0 ? Math.max(...addition?.sagyoDetails?.map((item) => item.no)) : 0;
          newSagyoDetail[0].no = maxId + 1;
          addition?.sagyoDetails?.push(newSagyoDetail[0]);
        }
      }
    });
    setCurAdditionsDetails(upAdditionsDetails);
    let updateSelectedProcessAdditions = JSON.parse(JSON.stringify(props?.selectedProcessAdditions));
    updateSelectedProcessAdditions?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        // 複写と削除の場合はdeviceIdを設定
        item = updateProcessAdditionsAndCalculateTotals(
          item,
          upAdditionsDetails,
          deviceId,
          props?.selectedIQ5DataDetail,
          props?.selectedEstimateData
        );
      }
    });
    props?.changeSelectedProcessInputAdditions(updateSelectedProcessAdditions, updateSelectedProcessInput);
  };

  // 種類の削除
  const deleteProcessTypeDetail = (deviceId) => {
    let curDeviceInfo = curDeviceLst?.filter((d) => d.id === deviceId)?.[0];
    // 工程入力の種類情報の削除
    let upDeviceProcessInputDetails = JSON.parse(JSON.stringify(curDeviceProcessInputDetails));
    upDeviceProcessInputDetails?.map((proInput) => {
      if (proInput.deviceId === curDeviceInfo?.id) {
        if (proInput?.details?.length > 1) {
          const deletedTypeDetails = proInput?.details?.filter((detail) => detail.no !== curRowNo);
          proInput.details = deletedTypeDetails;
        }
      }
    });
    setCurDeviceProcessInputDetails(upDeviceProcessInputDetails);
    let updateSelectedProcessInput = JSON.parse(JSON.stringify(props?.selectedProcessInput));
    updateSelectedProcessInput?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceProcessInputDetails = upDeviceProcessInputDetails;
      }
    });
    // 工程積算の種類情報の削除
    let upAdditionsDetails = JSON.parse(JSON.stringify(curAdditionsDetails));
    upAdditionsDetails?.map((addition) => {
      if (addition.deviceId === curDeviceInfo?.id) {
        if (addition?.sagyoDetails?.length > 1) {
          const deletedSagyoDetails = addition?.sagyoDetails?.filter((item) => item.no !== curRowNo);
          addition.sagyoDetails = deletedSagyoDetails;
        }
      }
    });
    setCurAdditionsDetails(upAdditionsDetails);
    let updateSelectedProcessAdditions = JSON.parse(JSON.stringify(props?.selectedProcessAdditions));

    updateSelectedProcessAdditions?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        // 複写と削除の場合はdeviceIdを設定
        item = updateProcessAdditionsAndCalculateTotals(
          item,
          upAdditionsDetails,
          deviceId,
          props?.selectedIQ5DataDetail,
          props?.selectedEstimateData
        );
      }
    });
    props?.changeSelectedProcessInputAdditions(updateSelectedProcessAdditions, updateSelectedProcessInput);
  };

  // 画面の上のタップ切り替え
  const onTabChange = (e) => {
    setActiveTabKey(e);

    let processNm = props?.processTabItems?.filter((item) => item.key === e)?.[0];
    props?.changeProcessTabItemsModalTitleTxt(processNm ? processNm?.label : '');

    let processMaster = props?.processMasterInfo?.filter((item) => item.name === processNm?.label)?.[0];
    if (processMaster?.workType) {
      setLocalWorkType(processMaster.workType);
      props?.changeWorkType(processMaster.workType);
    } else {
      settingCurDeviceProcessInputDetails('');
    }
  };

  // 各工程の段取時間・加工時間・段取り金額・加工金額を計算する
  const calculateByEachProcess = (deviceProcessInputDetails, additionsDetails) => {
    const { upAdditionsDetails, upDeviceProcessInputDetails } = calculateByProcess(
      deviceProcessInputDetails,
      additionsDetails,
      props?.selectedIQ5DataDetail,
      props?.estimateParameters,
      props?.selectedEstimateData,
      curDeviceLst,
      cutTimes,
      props?.workType,
    );

    // 工程入力情報更新
    let updateSelectedProcessInput = JSON.parse(JSON.stringify(props?.selectedProcessInput));
    updateSelectedProcessInput?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        item.deviceProcessInputDetails = upDeviceProcessInputDetails;
      }
    });
    // 工程積算情報更新
    setCurAdditionsDetails(upAdditionsDetails);
    let updateSelectedProcessAdditions = JSON.parse(JSON.stringify(props?.selectedProcessAdditions));

    updateSelectedProcessAdditions?.map((item) => {
      if (item.processId === curProcessMasterInfo?.id) {
        // 複写と削除ではないのでdeviceIdを０
        item = updateProcessAdditionsAndCalculateTotals(
          item,
          upAdditionsDetails,
          0,
          props?.selectedIQ5DataDetail,
          props?.selectedEstimateData
        );
      }
    });
    props?.changeSelectedProcessInputAdditions(updateSelectedProcessAdditions, updateSelectedProcessInput);
  };

  // 表示長さを設定する (溶接仕上・溶接・バリ取り・開先)
  const getDetailLength = (detailLength, kindNo, detailItem, deviceInfo) => {
    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter((k) => k.no === kindNo)?.[0]?.name;
    if (props?.workType === WorkType.PaSteelWeldFinish || props?.workType === WorkType.PaSteelWeld) {
      if (kindNm === WeldFinish_IQ5ManufactSizeKind[2]) {
        let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
          (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
        )?.[0];
        detailLength = materialIq5?.info?.perimeter;
      } else if (kindNm === WeldFinish_IQ5ManufactSizeKind[3]) {
        let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
          (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
        )?.[0];
        detailLength = materialIq5?.info?.perimeter / 2;
      } else if (kindNm === WeldFinish_IQ5ManufactSizeKind[4]) {
        detailLength = detailItem.dimension;
      } else if (kindNm === WeldFinish_IQ5ManufactSizeKind[0]) {
        detailLength = 0;
      } else if (kindNm === WeldFinish_IQ5ManufactSizeKind[1]) {
        detailLength = 0;
      }
    } else if (props?.workType === WorkType.PaSteelDebbur || props?.workType === WorkType.PaSteelGroove) {
      if (kindNm === Debbur_IQ5ManufactSizeKind[1] || kindNm === Groove_IQ5ManufactSizeKind[1]) {
        let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
          (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
        )?.[0];
        detailLength = materialIq5?.info?.perimeter;
      } else if (kindNm === Debbur_IQ5ManufactSizeKind[2] || kindNm === Groove_IQ5ManufactSizeKind[2]) {
        let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
          (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
        )?.[0];
        detailLength = materialIq5?.info?.perimeter / 2;
      } else if (kindNm === Debbur_IQ5ManufactSizeKind[3] || kindNm === Groove_IQ5ManufactSizeKind[3]) {
        detailLength = detailItem.dimension;
      } else if (kindNm === Debbur_IQ5ManufactSizeKind[0] || kindNm === Groove_IQ5ManufactSizeKind[0]) {
        detailLength = 0;
      }
    } else if (props?.workType === WorkType.PaSteelNotch) {
      let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
        (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
      )?.[0];
      if (kindNm === Notch_IQ5ManufactSizeKind[1]) {
        detailLength = materialIq5?.info?.a;
      } else if (kindNm === Notch_IQ5ManufactSizeKind[2]) {
        detailLength = materialIq5?.info?.b;
      } else if (kindNm === Notch_IQ5ManufactSizeKind[3]) {
        detailLength = materialIq5?.info?.a + materialIq5?.info?.b + materialIq5?.info?.c;
      } else if (kindNm === Notch_IQ5ManufactSizeKind[4]) {
        detailLength = detailItem.dimension;
      } else if (kindNm === Notch_IQ5ManufactSizeKind[5]) {
        detailLength = materialIq5?.info?.perimeter;
      } else if (kindNm === Notch_IQ5ManufactSizeKind[6]) {
        detailLength = materialIq5?.info?.perimeter / 2;
      }
    } else if (props?.workType === WorkType.PaSteelCut) {
      let materialIq5 = props?.estimateParameters?.materialIq5?.filter(
        (item) => item.info.isUsed && !item.isDeleted && item.id === props?.selectedIQ5DataDetail?.materialIq5Id
      )?.[0];
      if (kindNm === Cut_IQ5ManufactSizeKind[1]) {
        detailLength = materialIq5?.info?.a;
      } else if (kindNm === Cut_IQ5ManufactSizeKind[2]) {
        detailLength = materialIq5?.info?.b;
      } else if (kindNm === Cut_IQ5ManufactSizeKind[3]) {
        detailLength = materialIq5?.info?.a + materialIq5?.info?.b + materialIq5?.info?.c;
      } else if (kindNm === Cut_IQ5ManufactSizeKind[4]) {
        detailLength = materialIq5?.info?.perimeter;
      } else if (kindNm === Cut_IQ5ManufactSizeKind[5]) {
        detailLength = materialIq5?.info?.perimeter / 2;
      }
    }
    return toDecimal(detailLength, 2);
  };

  // 穴の種類によって長さを計算する
  const calculateHoleLength = (type, xL, yL) => {
    let perimeter = 0;
    switch (type) {
      case 1:
        if (xL !== undefined && xL !== null) perimeter = Number(xL) * Math.PI;
        break;
      case 2:
        if (xL !== undefined && xL !== null) perimeter = 4 * Number(xL);
        break;
      case 3:
        if (xL !== undefined && xL !== null && yL !== undefined && yL !== null)
          if (Number(xL) > Number(yL)) {
            perimeter = Number(xL) - Number(yL) + (Number(xL) - Number(yL)) + Number(yL) * Math.PI;
          } else {
            perimeter = Number(yL) - Number(xL) + (Number(yL) - Number(xL)) + Number(xL) * Math.PI;
          }

        break;
      case 4:
        if (
          xL !== undefined &&
          xL !== null &&
          yL !== undefined &&
          yL !== null
        )
        perimeter = 2 * (Number(xL) + Number(yL));
        break;
      default:
        perimeter = 0;
        break;
    }
    return toDecimal(perimeter, 2);
  };

  // 穴に関するパラメーター（XL）のEnable/Disable設定
  const getHoleClassNameForXL = (no, kind) => {
    let typeName = curProcessMasterInfo?.details?.processDetailTypes?.filter((p) => p.id === no)?.[0]?.name;
    if (typeName === Hole_Type[0] || typeName === Hole_Type[1]) {
      if (kind.includes(Hole_Instructions[1])) {
        return 'input-editable';
      }
    } else if (typeName === Hole_Type[2] || typeName === Hole_Type[3]) {
      if (kind.includes(Hole_Instructions[0]) || kind.includes(Hole_Instructions[1])) {
        return 'input-editable';
      }
    } else {
      if (kind.includes(Hole_Instructions[1])) {
        return 'input-editable';
      }
    }
    return 'input-non-editable-koteicalculate';
  };

  // 穴に関するパラメーター（YL）のEnable/Disable設定
  const getHoleClassNameForYL = (no, kind) => {
    let typeName = curProcessMasterInfo?.details?.processDetailTypes?.filter((p) => p.id === no)?.[0]?.name;
    if (typeName === Hole_Type[0] || typeName === Hole_Type[1]) {
      if (kind.includes(Hole_Instructions[0])) {
        return 'input-editable';
      }
    } else if (typeName === Hole_Type[2] || typeName === Hole_Type[3]) {
      if (kind.includes(Hole_Instructions[0]) || kind.includes(Hole_Instructions[1])) {
        return 'input-editable';
      }
    } else {
      if (kind.includes(Hole_Instructions[0])) {
        return 'input-editable';
      }
    }
    return 'input-non-editable-koteicalculate';
  };

  // 穴に関するパラメーター（長さ）のEnable/Disable設定
  const getHoleClassNameForHoleLength = (kind) => {
    if (kind.includes(Hole_Instructions[2])) {
      return 'input-editable';
    }
    return 'input-non-editable-koteicalculate';
  };

  //OKボタン押下
  const detailDialogOk = () => {
    props?.kouteiCalculateDialogOk();
  };

  // Cancelボタン押下
  const detailDialogCancel = () => {
    props?.kouteiCalculateDialogCancel();
  };

  const materialInstructionLst = [
    {
      label: '自動',
      key: 1,
    },
    {
      label: '手動',
      key: 2,
    },
    {
      label: '定尺',
      key: 3,
    },
  ];

  const standardLen = [
    {
      label: '2m',
      key: 1,
    },
    {
      label: '4m',
      key: 2,
    },
    {
      label: '4.5m',
      key: 3,
    },
    {
      label: '5.5m',
      key: 4,
    },
    {
      label: '8m',
      key: 5,
    },
    {
      label: '10m',
      key: 6,
    },
    {
      label: '12m',
      key: 7,
    },
  ];

  const unitLst = [
    {
      label: 'M単価',
      key: 1,
    },
    {
      label: 'Kg単価',
      key: 2,
    },
    {
      label: '手動',
      key: 3,
    },
  ];

  const tabItems = props?.processTabItems.map((tab) => {
    let tabDetailId = props?.processMasterInfo.find((detail) => detail.name === tab.label)?.id;
    let tabDetail = props?.selectedIQ5DataDetail?.processSelect.details.find(
      (detail) => detail.processId === tabDetailId
    ).isUsedForCalc;

    return {
      key: tab.key,
      label: tab.label,
      disabled: tabDetail ? false : true,
    };
  });

  return (
    <>
      {/* 工程詳細ダイアログ */}
      <div className="iq3-tab-div" style={{ height: '60vh', overflow: 'hidden' }}>
        <Tabs
          className="iq5-detail-tab"
          type="card"
          defaultActiveKey={1}
          activeKey={activeTabKey}
          items={tabItems}
          onChange={onTabChange}
        />
        <div style={{ border: '1px solid #a5a5a5', height: '56vh', overflow: 'hidden', padding: 5, paddingLeft: 10 }}>
          {/** 装置選択エリア */}
          <div style={{ display: 'flex' }}>
            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
              <thead>
                {/** 装置名称 */}
                <tr>
                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                    <div style={{ width: 100 }}>
                      <label className="detail-iq5-tbl-val-title-label">装置名称</label>
                    </div>
                  </td>
                  <td style={{ width: 20 }}>
                    <Select
                      defaultValue={curDeviceLst?.[0]?.id}
                      id="deviceLst"
                      name="deviceLst"
                      className={'deviceLst'}
                      value={selectedDeviceInfo?.id}
                      onChange={(e) => {
                        if (selectedDeviceInfo?.id != e) {
                          let curDevice = curDeviceLst?.filter((item) => item.id === e);
                          if (curDevice?.length > 0) {
                            setOldDeviceName(selectedDeviceInfo?.name || '');
                            setSelectedDeviceInfo(curDevice?.[0]);
                            setNewDeviceName(curDevice?.[0]?.name);
                            let processInputDevice = curDeviceProcessInputDetails?.filter(
                              (d) => d.deviceId === curDevice?.[0]?.id
                            );
                            if (processInputDevice?.length > 0) {
                              setCurDeviceInfo(curDevice?.[0]);
                            }
                          }
                        }
                      }}
                    >
                      {curDeviceLst?.map((item, index) => (
                        <Select.Option key={index} id={'deviceLst' + item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>
              </thead>
            </Table>
            {/** 装置の追加・変更・削除 */}
            <div style={{ marginLeft: 10 }}>
              <Button
                className="iq5-detail-sochi-btn"
                onClick={(e) => newDeviceProcessDetail()}
                style={{ marginRight: 10 }}
              >
                追加
              </Button>
              <Button
                className="iq5-detail-sochi-btn"
                onClick={(e) => updateConfirm(oldDeviceName, newDeviceName)}
                style={{ marginRight: 10 }}
              >
                変更
              </Button>
              <Button
                className="iq5-detail-sochi-btn-del"
                onClick={(e) => deleteConfirm(selectedDeviceInfo.name)}
                style={{ marginRight: 10 }}
              >
                削除
              </Button>
            </div>
          </div>
          {/** 切断の場合表示 */}
          <div style={{ marginLeft: 5, marginTop: 5, display: props?.workType === WorkType.PaSteelCut ? '' : 'none' }}>
            <Table className="iq5-detail-tbl" style={{ width: '90%' }}>
              <thead>
                {/** 材料 */}
                <tr>
                  <td rowSpan={2} style={{ width: '4%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">材料</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">部品合計(mm)</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {selectedDetailData?.size *
                        selectedDetailData?.partCount *
                        props?.selectedEstimateData?.estimateProducts?.quantity}
                    </label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">材料指示</label>
                  </td>
                  <td style={{ width: '1%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={1}
                      id="materialInstruction"
                      name="materialInstruction"
                      className={'materialInstruction'}
                      value={materialInstruction}
                      onChange={(e) => {
                        if (materialInstruction != e) {
                          setMaterialInstruction(e);
                          changeMaterialValue(e);
                        }
                      }}
                    >
                      {materialInstructionLst?.map((item, index) => (
                        <Select.Option key={index} id={'materialInstruction' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">定尺名称</label>
                  </td>
                  <td style={{ width: '1%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={''}
                      id="standardLen"
                      name="standardLen"
                      className={'materialInstruction'}
                      value={standardLenKey}
                      disabled={materialInstruction === 3 ? false : true}
                      onChange={(e) => {
                        if (standardLenKey != e) {
                          calculateMaterialByStandardLan(e);
                        }
                      }}
                    >
                      <Select.Option key={0} id={'standardLen-1'} value={''}>
                        {''}
                      </Select.Option>
                      {standardLen?.map((item, index) => (
                        <Select.Option key={index} id={'standardLen' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">捨代</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{disposal}</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">刃幅</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">{bladeWidth}</label>
                  </td>
                </tr>
                {/** 本数 */}
                <tr>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">材料寸法(mm)</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val">
                    <Input
                      style={{
                        textAlign: 'right',
                        fontSize: '13px',
                        height: 30,
                        padding: 5,
                        borderColor: '#a5a5a563',
                        borderRadius: 4,
                      }}
                      className={
                        materialInstructionLst?.filter((item) => item.key === materialInstruction)?.[0]?.label ===
                        '手動'
                          ? 'input-editable'
                          : 'input-non-editable-koteicalculate'
                      }
                      name="materialDimension"
                      value={materialDimension}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (materialDimension != inputValue) {
                          setMaterialDimension(inputValue);
                        }
                      }}
                      onBlur={(e) => {
                        let inputValue = e.target.value;
                        clculateMaterialValueByMateDim(inputValue);
                        calculateMaterialCost(materialIq5UnitType, materialUnit, materialNum, inputValue);
                      }}
                    />
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title ">
                    <label className="detail-iq5-tbl-val-title-label">本数</label>
                  </td>
                  <td style={{ width: '1%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <Input
                      style={{
                        textAlign: 'right',
                        fontSize: '13px',
                        height: 30,
                        padding: 5,
                        borderColor: '#a5a5a563',
                        borderRadius: 4,
                      }}
                      className="input-non-editable-koteicalculate"
                      name="materialNum"
                      value={materialNum}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (materialNum != inputValue) {
                          setMaterialNum(inputValue);
                          calculateMaterialCost(materialIq5UnitType, materialUnit, inputValue, materialDimension);
                        }
                      }}
                    />
                  </td>

                  <td style={{ width: '8%' }} className="detail-tbl-val-title ">
                    <label className="detail-iq5-tbl-val-title-label">採用単価</label>
                  </td>
                  <td style={{ width: '1%' }} className="detail-tbl-val">
                    <Select
                      defaultValue={1}
                      id="unit"
                      name="unit"
                      className={'materialInstruction'}
                      value={materialIq5UnitType}
                      onChange={(e) => {
                        if (materialIq5UnitType != e) {
                          getMaterialUnitValue(e, materialIq5Info);
                          setMaterialIq5UnitType(e);
                          calculateMaterialCost(e, materialUnit, materialNum, materialDimension);
                        }
                      }}
                    >
                      {unitLst?.map((item, index) => (
                        <Select.Option key={index} id={'unit' + item.key} value={item.key}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">単価</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <Input
                      style={{
                        textAlign: 'right',
                        fontSize: '13px',
                        height: 30,
                        padding: 5,
                        borderColor: '#a5a5a563',
                        borderRadius: 4,
                      }}
                      className={
                        unitLst?.filter((item) => item.key === materialIq5UnitType)?.[0]?.label === '手動'
                          ? 'input-editable'
                          : 'input-non-editable-koteicalculate'
                      }
                      name="materialUnit"
                      value={materialUnit}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (materialUnit != inputValue) {
                          setMaterialUnit(inputValue);
                        }
                      }}
                      onBlur={(e) => {
                        let inputValue = e.target.value;
                        calculateMaterialCost(materialIq5UnitType, inputValue, materialNum, materialDimension);
                      }}
                      onPressEnter={(e) => {
                        let inputValue = e.target.value;
                        calculateMaterialCost(materialIq5UnitType, inputValue, materialNum, materialDimension);
                      }}
                    />
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val-title">
                    <label className="detail-iq5-tbl-val-title-label">合計</label>
                  </td>
                  <td style={{ width: '8%' }} className="detail-tbl-val detail-tbl-val-bg">
                    <label className="detail-iq5-tbl-val-title-label">
                      {selectedDetailData?.materialCostTotalPrice
                        ? JPYs.format(selectedDetailData?.materialCostTotalPrice)
                        : JPYs.format(0)}
                    </label>
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
          <div style={{ marginLeft: 5, height: '50.3vh', overflowY: 'auto' }}>
            {(() => {
              const arr = [];
              for (let j = 0; j < curDeviceProcessInputDetails?.length; j++) {
                let processInputInfo = curDeviceProcessInputDetails[j];
                let additionsInfo = curAdditionsDetails?.filter(
                  (item) => item.deviceId === processInputInfo?.deviceId
                )?.[0];
                let deviceInfoHole = JSON.parse(JSON.stringify(curDeviceLst));
                let deviceInfo = deviceInfoHole?.filter((item) => item.id === processInputInfo?.deviceId)?.[0];
                let iq5_manufacturingCnt = selectedDetailData?.partCount * props.selectedEstimateData?.estimateProducts?.quantity;
                arr.push(
                  <div
                    style={{
                      marginTop: 5,
                      borderTop: '2px solid #a5a5a5',
                      paddingTop: 1,
                      backgroundColor: deviceInfo.id === curDeviceInfo?.id ? '#91caff3d' : '#fff',
                    }}
                    onClick={(e) => {
                      setCurDeviceInfo(deviceInfo);
                      setSelectedDeviceInfo(deviceInfo);
                    }}
                  >
                    <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                      <thead>
                        {/** 装置 */}
                        <tr>
                          <td className="detail-tbl-val-title">
                            <label className="detail-iq5-tbl-val-title-label detail-iq5-tbl-val-title-label-width">
                              装置名称
                            </label>
                          </td>
                          <td className="detail-tbl-val detail-tbl-val-bg">
                            <label className="detail-iq5-tbl-val-title-label" style={{ width: 140 }}>
                              {additionsInfo.deviceNm}
                            </label>
                          </td>
                          <td className="detail-tbl-val-title">
                            <label className="detail-iq5-tbl-val-title-label detail-iq5-tbl-val-title-label-width">
                              段取時間
                            </label>
                          </td>
                          <td className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right">
                            <label className="detail-iq5-tbl-val-title-label-padding-right detail-iq5-tbl-val-title-label-width">
                              {additionsInfo.dandoriTime ? secondsToHms(additionsInfo.dandoriTime) : secondsToHms(0)}
                            </label>
                          </td>
                          <td className="detail-tbl-val-title">
                            <label className="detail-iq5-tbl-val-title-label detail-iq5-tbl-val-title-label-width">
                              段取金額
                            </label>
                          </td>
                          <td className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right">
                            <label className="detail-iq5-tbl-val-title-label-padding-right detail-iq5-tbl-val-title-label-width">
                              {additionsInfo.dandoriAmt ? JPYs.format(additionsInfo.dandoriAmt) : JPYs.format(0)}
                            </label>
                          </td>
                          <td className="detail-tbl-val-title">
                            <label className="detail-iq5-tbl-val-title-label detail-iq5-tbl-val-title-label-width">
                              加工時間
                            </label>
                          </td>
                          <td className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right">
                            <label className="detail-iq5-tbl-val-title-label-padding-right detail-iq5-tbl-val-title-label-width">
                              {additionsInfo.sagyoTime ? secondsToHms(props?.workType === WorkType.PaSteelCut ? additionsInfo.sagyoTime * iq5_manufacturingCnt : additionsInfo.sagyoTime ) : secondsToHms(0)}
                            </label>
                          </td>
                          <td className="detail-tbl-val-title">
                            <label className="detail-iq5-tbl-val-title-label detail-iq5-tbl-val-title-label-width">
                              加工金額
                            </label>
                          </td>
                          <td className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right">
                            <label className="detail-iq5-tbl-val-title-label-padding-right detail-iq5-tbl-val-title-label-width">
                              {additionsInfo.sagyoAmt ? JPYs.format( props?.workType === WorkType.PaSteelCut ? additionsInfo.sagyoAmt * iq5_manufacturingCnt : additionsInfo.sagyoAmt) : JPYs.format(0)}
                            </label>
                          </td>
                        </tr>
                      </thead>
                    </Table>
                    <div>
                      {/** 重量の場合表示 */}
                      <div style={{ marginTop: 2, display: props?.workType === WorkType.PaSteelWeight ? '' : 'none' }}>
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">種類</label>
                                  </td>

                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">Kg</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">回</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;
                                    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter(
                                      (k) => k.no === detailItem.kindNo
                                    )?.[0]?.name;
                                    let detailWeight =
                                      kindNm === Weight_IQ5ManufactSizeKind[2]
                                        ? detailItem.weight / 2
                                        : detailItem.weight;
                                    detailWeight = Number.isFinite(detailWeight) ? detailWeight : 0;
                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.processDetailTypeNo}
                                            id="processDetailTypeNo"
                                            name="processDetailTypeNo"
                                            className={'processInputDetail'}
                                            value={detailItem.processDetailTypeNo}
                                            onChange={(e) => {
                                              let inputDetailType = e;
                                              if (detailItem.processDetailTypeNo != inputDetailType) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].processDetailTypeNo =
                                                  inputDetailType;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {curProcessMasterInfo?.details?.processDetailTypes?.map(
                                              (processDetailType, index) => (
                                                <Select.Option
                                                  key={index}
                                                  id={'processDetailTypeNo' + processDetailType.id}
                                                  value={processDetailType.id}
                                                >
                                                  {processDetailType.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </td>
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>
                                        <td
                                          style={{ width: 10 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <Input
                                              style={{
                                                textAlign: 'right',
                                                width: 100,
                                                fontSize: '13px',
                                                height: 30,
                                                padding: 5,
                                                borderColor: '#a5a5a563',
                                                borderRadius: 4,
                                              }}
                                              className={
                                                kindNm === Weight_IQ5ManufactSizeKind[3]
                                                  ? 'input-editable'
                                                  : 'input-non-editable-koteicalculate'
                                              }
                                              name="weight"
                                              value={
                                                detailWeight
                                                  ? toDecimal_place3(detailWeight, 3)
                                                  : toDecimal_place3(0, 3)
                                              }
                                              onChange={(e) => {
                                                let inputWeight = e.target.value;
                                                if (detailItem.weight != inputWeight) {
                                                  let deviceProcessInputDetails = JSON.parse(
                                                    JSON.stringify(curDeviceProcessInputDetails)
                                                  );
                                                  deviceProcessInputDetails[j].details[i].weight = inputWeight;
                                                  setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                  calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                                }
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 100,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="partCount"
                                            value={detailItem.count}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                          <Col span={6}>
                            {/** 種類の追加・変更・削除 */}
                            <div>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => newProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                追加
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => copyProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                複写
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn-del"
                                onClick={(e) => deleteProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                削除
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* 表面積 */}
                      <div style={{ marginTop: 2, display: props?.workType === WorkType.PaSteelArea ? '' : 'none' }}>
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">種類</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">X(mm)</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">Y(mm)</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">cm2</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">面数</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;
                                    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter(
                                      (k) => k.no === detailItem.kindNo
                                    )?.[0]?.name;
                                    let detailArea = detailItem.area;
                                    if (kindNm === Area_IQ5ManufactSizeKind[2]) {
                                      detailArea = detailArea / 2;
                                    } else if (kindNm === Area_IQ5ManufactSizeKind[1]) {
                                      detailArea = detailItem.area;
                                    } else if (kindNm === Area_IQ5ManufactSizeKind[0]) {
                                      detailArea = 0;
                                    }
                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.processDetailTypeNo}
                                            id="processDetailTypeNo"
                                            name="processDetailTypeNo"
                                            className={'processInputDetail'}
                                            value={detailItem.processDetailTypeNo}
                                            onChange={(e) => {
                                              let inputDetailType = e;
                                              if (detailItem.processDetailTypeNo != inputDetailType) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].processDetailTypeNo =
                                                  inputDetailType;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {curProcessMasterInfo?.details?.processDetailTypes?.map(
                                              (processDetailType, index) => (
                                                <Select.Option
                                                  key={index}
                                                  id={'processDetailTypeNo' + processDetailType.id}
                                                  value={processDetailType.id}
                                                >
                                                  {processDetailType.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={
                                              kindNm === Area_IQ5ManufactSizeKind[3]
                                                ? 'input-editable'
                                                : 'input-non-editable-koteicalculate'
                                            }
                                            name="x"
                                            value={detailItem.x}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].x = inputCount;
                                                deviceProcessInputDetails[j].details[i].area = calculateArea(
                                                  deviceProcessInputDetails[j]?.details[i]?.x,
                                                  deviceProcessInputDetails[j]?.details[i]?.y
                                                );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={
                                              kindNm === Area_IQ5ManufactSizeKind[3]
                                                ? 'input-editable'
                                                : 'input-non-editable-koteicalculate'
                                            }
                                            name="y"
                                            value={detailItem.y}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].y = inputCount;
                                                deviceProcessInputDetails[j].details[i].area = calculateArea(
                                                  deviceProcessInputDetails[j]?.details[i]?.x,
                                                  deviceProcessInputDetails[j]?.details[i]?.y
                                                );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={
                                              kindNm === Area_IQ5ManufactSizeKind[4]
                                                ? 'input-editable'
                                                : 'input-non-editable-koteicalculate'
                                            }
                                            name="area"
                                            value={detailArea}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].area = inputCount;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="partCount"
                                            value={detailItem.count}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                          <Col span={6}>
                            {/** 種類の追加・変更・削除 */}
                            <div>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => newProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                追加
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => copyProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                複写
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn-del"
                                onClick={(e) => deleteProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                削除
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* 溶接仕上・溶接・バリ取り・開先 */}
                      <div
                        style={{
                          marginTop: 2,
                          display:
                            props?.workType === WorkType.PaSteelWeldFinish ||
                            props?.workType === WorkType.PaSteelWeld ||
                            props?.workType === WorkType.PaSteelDebbur ||
                            props?.workType === WorkType.PaSteelGroove
                              ? ''
                              : 'none',
                        }}
                      >
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">種類</label>
                                  </td>

                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">長さ</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">回</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;
                                    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter(
                                      (k) => k.no === detailItem.kindNo
                                    )?.[0]?.name;
                                    let detailLength = detailItem.weldLength;
                                    detailLength = getDetailLength(
                                      detailLength,
                                      detailItem.kindNo,
                                      detailItem,
                                      deviceInfo
                                    );

                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.processDetailTypeNo}
                                            id="processDetailTypeNo"
                                            name="processDetailTypeNo"
                                            className={'processInputDetail'}
                                            value={detailItem.processDetailTypeNo}
                                            onChange={(e) => {
                                              let inputDetailType = e;
                                              if (detailItem.processDetailTypeNo != inputDetailType) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].processDetailTypeNo =
                                                  inputDetailType;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {curProcessMasterInfo?.details?.processDetailTypes?.map(
                                              (processDetailType, index) => (
                                                <Select.Option
                                                  key={index}
                                                  id={'processDetailTypeNo' + processDetailType.id}
                                                  value={processDetailType.id}
                                                >
                                                  {processDetailType.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </td>
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                deviceProcessInputDetails[j].details[i].weldLength = getDetailLength(
                                                  detailLength,
                                                  inputKind,
                                                  detailItem,
                                                  deviceInfo
                                                );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>
                                        <td
                                          style={{ width: 10 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <Input
                                              style={{
                                                textAlign: 'right',
                                                width: 100,
                                                fontSize: '13px',
                                                height: 30,
                                                padding: 5,
                                                borderColor: '#a5a5a563',
                                                borderRadius: 4,
                                              }}
                                              className={
                                                kindNm === WeldFinish_IQ5ManufactSizeKind[5]
                                                  ? 'input-editable'
                                                  : 'input-non-editable-koteicalculate'
                                              }
                                              name="weldLength"
                                              value={detailLength}
                                              onChange={(e) => {
                                                let inputCount = e.target.value;
                                                if (detailLength != inputCount) {
                                                  let deviceProcessInputDetails = JSON.parse(
                                                    JSON.stringify(curDeviceProcessInputDetails)
                                                  );
                                                  deviceProcessInputDetails[j].details[i].weldLength = inputCount;
                                                  setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                  calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                                }
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 100,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="partCount"
                                            value={detailItem.count}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                deviceProcessInputDetails[j].details[i].weldLength = detailLength;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                          <Col span={6}>
                            {/** 種類の追加・変更・削除 */}
                            <div>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => newProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                追加
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => copyProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                複写
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn-del"
                                onClick={(e) => deleteProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                削除
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* 取付 */}
                      <div style={{ marginTop: 2, display: props?.workType === WorkType.PaSteelMount ? '' : 'none' }}>
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">種類</label>
                                  </td>

                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">サイズ</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">個数</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;
                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.processDetailTypeNo}
                                            id="processDetailTypeNo"
                                            name="processDetailTypeNo"
                                            className={'processInputDetail'}
                                            value={detailItem.processDetailTypeNo}
                                            onChange={(e) => {
                                              let inputDetailType = e;
                                              if (detailItem.processDetailTypeNo != inputDetailType) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].processDetailTypeNo =
                                                  inputDetailType;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {curProcessMasterInfo?.details?.processDetailTypes?.map(
                                              (processDetailType, index) => (
                                                <Select.Option
                                                  key={index}
                                                  id={'processDetailTypeNo' + processDetailType.id}
                                                  value={processDetailType.id}
                                                >
                                                  {processDetailType.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </td>
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>
                                        <td
                                          style={{ width: 10 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <Input
                                              style={{
                                                textAlign: 'right',
                                                width: 100,
                                                fontSize: '13px',
                                                height: 30,
                                                padding: 5,
                                                borderColor: '#a5a5a563',
                                                borderRadius: 4,
                                              }}
                                              className={'input-editable'}
                                              name="mountSize"
                                              value={detailItem.mountSize}
                                              onChange={(e) => {
                                                let inputSize = e.target.value;
                                                if (detailItem.mountSize != inputSize) {
                                                  let deviceProcessInputDetails = JSON.parse(
                                                    JSON.stringify(curDeviceProcessInputDetails)
                                                  );
                                                  deviceProcessInputDetails[j].details[i].mountSize = inputSize;
                                                  setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                  calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                                }
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 100,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="partCount"
                                            value={detailItem.count}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                          <Col span={6}>
                            {/** 種類の追加・変更・削除 */}
                            <div>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => newProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                追加
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => copyProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                複写
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn-del"
                                onClick={(e) => deleteProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                削除
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* 曲げ */}
                      <div style={{ marginTop: 2, display: props?.workType === WorkType.PaSteelBend ? '' : 'none' }}>
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">種類</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">長さ</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">Rサイズ</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">回</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;

                                    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter(
                                      (k) => k.no === detailItem.kindNo
                                    )?.[0]?.name;
                                    if (kindNm === Bend_IQ5ManufactSizeKind[1]) {
                                      detailItem.bendLength = detailItem.dimension;
                                    }
                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.processDetailTypeNo}
                                            id="processDetailTypeNo"
                                            name="processDetailTypeNo"
                                            className={'processInputDetail'}
                                            value={detailItem.processDetailTypeNo}
                                            onChange={(e) => {
                                              let inputDetailType = e;
                                              if (detailItem.processDetailTypeNo != inputDetailType) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].processDetailTypeNo =
                                                  inputDetailType;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {curProcessMasterInfo?.details?.processDetailTypes?.map(
                                              (processDetailType, index) => (
                                                <Select.Option
                                                  key={index}
                                                  id={'processDetailTypeNo' + processDetailType.id}
                                                  value={processDetailType.id}
                                                >
                                                  {processDetailType.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={
                                              kindNm === Bend_IQ5ManufactSizeKind[2]
                                                ? 'input-editable'
                                                : 'input-non-editable-koteicalculate'
                                            }
                                            name="bendLength"
                                            value={detailItem.bendLength}
                                            onChange={(e) => {
                                              let inputSize = e.target.value;
                                              if (detailItem.bendLength != inputSize) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].bendLength = inputSize;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={'input-editable'}
                                            name="bendSize"
                                            value={detailItem.bendSize}
                                            onChange={(e) => {
                                              let inputSize = e.target.value;
                                              if (detailItem.bendSize != inputSize) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].bendSize = inputSize;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>
                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="partCount"
                                            value={detailItem.count}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                          <Col span={6}>
                            {/** 種類の追加・変更・削除 */}
                            <div>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => newProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                追加
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => copyProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                複写
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn-del"
                                onClick={(e) => deleteProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                削除
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* 切欠 */}
                      <div style={{ marginTop: 2, display: props?.workType === WorkType.PaSteelNotch ? '' : 'none' }}>
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">種類</label>
                                  </td>

                                  <td style={{ width: 20 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">長さ</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">箇所</label>
                                  </td>

                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td style={{ width: 10 }} className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;

                                    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter(
                                      (k) => k.no === detailItem.kindNo
                                    )?.[0]?.name;
                                    let detailLength = detailItem.notchLength;
                                    detailLength = getDetailLength(
                                      detailLength,
                                      detailItem.kindNo,
                                      detailItem,
                                      deviceInfo
                                    );
                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.processDetailTypeNo}
                                            id="processDetailTypeNo"
                                            name="processDetailTypeNo"
                                            className={'processInputDetail'}
                                            value={detailItem.processDetailTypeNo}
                                            onChange={(e) => {
                                              let inputDetailType = e;
                                              if (detailItem.processDetailTypeNo != inputDetailType) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].processDetailTypeNo =
                                                  inputDetailType;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {curProcessMasterInfo?.details?.processDetailTypes?.map(
                                              (processDetailType, index) => (
                                                <Select.Option
                                                  key={index}
                                                  id={'processDetailTypeNo' + processDetailType.id}
                                                  value={processDetailType.id}
                                                >
                                                  {processDetailType.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </td>
                                        <td style={{ width: 20 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                deviceProcessInputDetails[j].details[i].notchLength = getDetailLength(
                                                  detailLength,
                                                  inputKind,
                                                  detailItem,
                                                  deviceInfo
                                                );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>
                                        <td
                                          style={{ width: 10 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <Input
                                              style={{
                                                textAlign: 'right',
                                                width: 100,
                                                fontSize: '13px',
                                                height: 30,
                                                padding: 5,
                                                borderColor: '#a5a5a563',
                                                borderRadius: 4,
                                              }}
                                              className={
                                                kindNm === Notch_IQ5ManufactSizeKind[7]
                                                  ? 'input-editable'
                                                  : 'input-non-editable-koteicalculate'
                                              }
                                              name="notchLength"
                                              value={detailLength}
                                              onChange={(e) => {
                                                let inputSize = e.target.value;
                                                if (detailItem.notchLength != inputSize) {
                                                  let deviceProcessInputDetails = JSON.parse(
                                                    JSON.stringify(curDeviceProcessInputDetails)
                                                  );
                                                  deviceProcessInputDetails[j].details[i].notchLength = inputSize;
                                                  setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                  calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                                }
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 100,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="partCount"
                                            value={detailItem.count}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                deviceProcessInputDetails[j].details[i].notchLength = getDetailLength(
                                                  detailLength,
                                                  deviceProcessInputDetails[j]?.details[i]?.kindNo,
                                                  detailItem,
                                                  deviceInfo
                                                );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 20 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 150 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                          <Col span={6}>
                            {/** 種類の追加・変更・削除 */}
                            <div>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => newProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                追加
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => copyProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                複写
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn-del"
                                onClick={(e) => deleteProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                削除
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* 穴 */}
                      <div style={{ marginTop: 2, display: props?.workType === WorkType.PaSteelHole ? '' : 'none' }}>
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">種類</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">XL</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">YL</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">長さ</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">個数</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;
                                    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter(
                                      (k) => k.no === detailItem.kindNo
                                    )?.[0]?.name;
                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.processDetailTypeNo}
                                            id="processDetailTypeNo"
                                            name="processDetailTypeNo"
                                            className={'processInputDetail'}
                                            value={detailItem.processDetailTypeNo}
                                            onChange={(e) => {
                                              let inputDetailType = e;
                                              if (detailItem.processDetailTypeNo != inputDetailType) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].processDetailTypeNo =
                                                  inputDetailType;
                                                // 穴の種類によって長さを計算する
                                                deviceProcessInputDetails[j].details[i].holeLength =
                                                  calculateHoleLength(
                                                    deviceProcessInputDetails[j]?.details[i]?.processDetailTypeNo,
                                                    deviceProcessInputDetails[j]?.details[i]?.xL,
                                                    deviceProcessInputDetails[j]?.details[i]?.yL
                                                  );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {curProcessMasterInfo?.details?.processDetailTypes?.map(
                                              (processDetailType, index) => (
                                                <Select.Option
                                                  key={index}
                                                  id={'processDetailTypeNo' + processDetailType.id}
                                                  value={processDetailType.id}
                                                >
                                                  {processDetailType.name}
                                                </Select.Option>
                                              )
                                            )}
                                          </Select>
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              .filter((k) => {
                                                let typeName =
                                                  curProcessMasterInfo?.details?.processDetailTypes?.filter(
                                                    (p) => p.id === detailItem?.processDetailTypeNo
                                                  )?.[0]?.name;
                                                if (
                                                  (typeName === Hole_Type[0] || typeName === Hole_Type[1]) &&
                                                  k.name.includes(Hole_Instructions[0])
                                                ) {
                                                  // 丸・角の場合(YL)の指示を表示されない
                                                } else {
                                                  return k;
                                                }
                                              })
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={getHoleClassNameForXL(detailItem.processDetailTypeNo, kindNm)}
                                            name="xL"
                                            value={detailItem.xL}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.xL != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].xL = inputCount;
                                                deviceProcessInputDetails[j].details[i].holeLength =
                                                  calculateHoleLength(
                                                    deviceProcessInputDetails[j]?.details[i]?.processDetailTypeNo,
                                                    deviceProcessInputDetails[j]?.details[i]?.xL,
                                                    deviceProcessInputDetails[j]?.details[i]?.yL
                                                  );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={getHoleClassNameForYL(detailItem.processDetailTypeNo, kindNm)}
                                            name="yL"
                                            value={detailItem.yL}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.yL != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].yL = inputCount;
                                                deviceProcessInputDetails[j].details[i].holeLength =
                                                  calculateHoleLength(
                                                    deviceProcessInputDetails[j]?.details[i]?.processDetailTypeNo,
                                                    deviceProcessInputDetails[j]?.details[i]?.xL,
                                                    deviceProcessInputDetails[j]?.details[i]?.yL
                                                  );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={getHoleClassNameForHoleLength(kindNm)}
                                            name="holeLength"
                                            value={detailItem.holeLength}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.holeLength != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].holeLength = inputCount;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="partCount"
                                            value={detailItem.count}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                // JSON.parse(JSON.stringify(curAdditionsDetails));
                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                          <Col span={6}>
                            {/** 種類の追加・変更・削除 */}
                            <div>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => newProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                追加
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn"
                                onClick={(e) => copyProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                複写
                              </Button>
                              <Button
                                className="iq5-detail-sochi-btn-del"
                                onClick={(e) => deleteProcessTypeDetail(processInputInfo?.deviceId)}
                                style={{ marginRight: 10, width: 45, height: 28 }}
                              >
                                削除
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* 切断 */}
                      <div style={{ marginTop: 2, display: props?.workType === WorkType.PaSteelCut ? '' : 'none' }}>
                        <Row>
                          <Col span={18}>
                            <Table className="iq5-detail-tbl" style={{ width: 100 }}>
                              <thead>
                                {/**　種類タイトル */}
                                <tr>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">捨代</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">刃幅</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">指示</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">長さ</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">カット数</label>
                                  </td>

                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工時間</label>
                                  </td>
                                  <td className="detail-tbl-val-title">
                                    <label className="detail-iq5-tbl-val-title-label">加工金額</label>
                                  </td>
                                </tr>
                                {(() => {
                                  const detailArr = [];
                                  for (let i = 0; i < processInputInfo.details?.length; i++) {
                                    let detailItem = processInputInfo.details[i];
                                    let sagyoTime = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoTime;
                                    let sagyoAmt = additionsInfo.sagyoDetails?.filter(
                                      (sagyo) => sagyo.no === detailItem.no
                                    )?.[0]?.sagyoAmt;

                                    let kindNm = deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds?.filter(
                                      (k) => k.no === detailItem.kindNo
                                    )?.[0]?.name;
                                    let detailLength = detailItem.cutLength;
                                    detailLength = getDetailLength(
                                      detailLength,
                                      detailItem.kindNo,
                                      detailItem,
                                      deviceInfo
                                    );
                                    
                                    detailArr.push(
                                      <tr
                                        key={'type' + processInputInfo?.deviceId + detailItem.no}
                                        className="detailTypeTr"
                                        style={{
                                          backgroundColor:
                                            detailItem.no === curRowNo && deviceInfo.id === curDeviceInfo?.id
                                              ? '#91caff'
                                              : '#fff',
                                        }}
                                        onClick={(e) => {
                                          setCurRowNo(detailItem.no);
                                          setCurDeviceInfo(deviceInfo);
                                        }}
                                      >
                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-non-editable-koteicalculate"
                                            name="disposal"
                                            value={disposal}
                                          />
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-non-editable-koteicalculate"
                                            name="bladeWidth"
                                            value={bladeWidth}
                                          />
                                        </td>
                                        <td style={{ width: 10 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Select
                                            defaultValue={detailItem.kindNo}
                                            id="kindNo"
                                            name="kindNo"
                                            className={'processInputDetail'}
                                            value={detailItem.kindNo}
                                            onChange={(e) => {
                                              let inputKind = e;
                                              if (detailItem.kindNo != inputKind) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].kindNo = inputKind;
                                                deviceProcessInputDetails[j].details[i].cutLength = getDetailLength(
                                                  detailLength,
                                                  inputKind,
                                                  detailItem,
                                                  deviceInfo
                                                );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          >
                                            {deviceInfo?.details?.iQ5ManufactSizeInfo?.kinds
                                              ?.filter((f) => f.dispSW)
                                              ?.map((kind, index) => (
                                                <Select.Option key={index} id={'kinds' + kind.no} value={kind.no}>
                                                  {kind.name}
                                                </Select.Option>
                                              ))}
                                          </Select>
                                        </td>

                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className={
                                              kindNm === Cut_IQ5ManufactSizeKind[0]
                                                ? 'input-non-editable-koteicalculate'
                                                : 'input-editable'
                                            }
                                            name="cutLength"
                                            value={detailLength}
                                            onChange={(e) => {
                                              let inputSize = e.target.value;
                                              if (detailItem.cutLength != inputSize) {
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );
                                                deviceProcessInputDetails[j].details[i].cutLength = inputSize;
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>
                                        <td style={{ width: 5 }} className="detail-tbl-val detail-tbl-val-bg">
                                          <Input
                                            style={{
                                              textAlign: 'right',
                                              width: 80,
                                              fontSize: '13px',
                                              height: 30,
                                              padding: 5,
                                              borderColor: '#a5a5a563',
                                              borderRadius: 4,
                                            }}
                                            className="input-editable"
                                            name="count"
                                            value={cutTimes}
                                            onChange={(e) => {
                                              let inputCount = e.target.value;
                                              if (detailItem.count != inputCount) {
                                                setCutTimes(inputCount);
                                                let deviceProcessInputDetails = JSON.parse(
                                                  JSON.stringify(curDeviceProcessInputDetails)
                                                );

                                                deviceProcessInputDetails[j].details[i].count = inputCount;
                                                deviceProcessInputDetails[j].details[i].cutLength = getDetailLength(
                                                  detailLength,
                                                  detailItem.kindNo,
                                                  detailItem,
                                                  deviceInfo
                                                );
                                                setCurDeviceProcessInputDetails(deviceProcessInputDetails);
                                                calculateByEachProcess(deviceProcessInputDetails, curAdditionsDetails);
                                              }
                                            }}
                                          />
                                        </td>

                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right ">
                                              {sagyoTime ? secondsToHms(sagyoTime * iq5_manufacturingCnt) : secondsToHms(0)}
                                            </label>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: 30 }}
                                          className="detail-tbl-val detail-tbl-val-bg detail-iq5-tbl-val-title-label-right"
                                        >
                                          <div style={{ width: 100 }}>
                                            <label className="detail-iq5-tbl-val-title-label-padding-right">
                                              {sagyoAmt ? JPYs.format(sagyoAmt * iq5_manufacturingCnt) : JPYs.format(0)}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return detailArr;
                                })()}
                              </thead>
                            </Table>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                );
              }
              return arr;
            })()}
          </div>
        </div>
      </div>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 5,
        }}
      >
        <Button className="mainButton" onClick={detailDialogOk} style={{ marginRight: 5 }}>
          OK
        </Button>
        <Button className="cancelButton" onClick={detailDialogCancel}>
          キャンセル
        </Button>
      </Row>
      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonFooter(deleteDeviceProcessDetail, deleteCancel),
            null,
            400,
            deleteDeviceProcessDetail,
            deleteCancel,
            deleteModalContent,
            null,
            null,
            false
          )
        : ''}
      {isUpdate
        ? commonModal(
            isUpdate,
            confirmModalTitle,
            commonFooter(updateDeviceProcessDetail, updateCancel),
            null,
            400,
            updateDeviceProcessDetail,
            updateCancel,
            updateModalContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default IQ5KouteiCalculate;
