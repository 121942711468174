/**
 * クラス名：購入品パラメータ
 * 説明：購入品情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Button, Row, Input, Col, Select, Space, Image, Radio, Modal, Popover } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  PlusCircleFilled,
  CloseCircleFilled,
  StarOutlined,
  StarFilled,
  SearchOutlined,
} from '@ant-design/icons';
import 'split-pane-react/esm/themes/default.css';
import SplitPane, { Pane } from 'split-pane-react';
import { StatusCodes } from 'http-status-codes';
import CurrencyInput from 'react-currency-input-field';

import PurchaseDetails from './PurchaseDetails';
import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  error,
  authorizeError,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import { getPurchaseInfo, getPurchaseCategoryInfo, createPurchaseCSV } from '../../../common/CommonAPI';
import {
  dataStateList,
  today,
  TextArea,
  JPYs,
  removeYen,
  formatDate,
  formValidatorMode,
  updateAccessToken,
  getAccessToken,
  regexExp,
  toHalfWidth,
  discardChange,
  formatDateString,
  todayTime,
  isDigit,
  isDigitZenkaku,
  handleKeyPress,
} from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData';
import { ClientType } from '../../../common/enums';
import { NOT_CHANGE, Supplier, pageSizes, purchaseCategoryDefaultValue } from '../../../common/Constant';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const iconWidth = 30;
const RadioGroup = Radio.Group;

const PurchaseParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const inputItemRef = {};

  let loginUser = JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser')));
  let token = localStorage?.getItem('iQxWeb_AccessToken');

  const csvExportFileName = `購入品_${formatDateString(todayTime())}.csv`;

  const detailRef = useRef();
  const messageRef = useRef([]);

  const navigate = useNavigate();
  const location = useLocation();
  // デフォルトページの表示件数
  const defaultPageSize = 25;

  document.body.style.overflowY = 'hidden';

  const [paramSelected, setParamSelected] = useState('purchase');
  const [paramType, setParamType] = useState('common');
  const [purchasesData, setPurchasesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState(0);

  const [purchaseCategoryId, setPurchaseCategoryId] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [priceLots, setPriceLots] = useState([]);
  const [priceHistories, setPriceHistories] = useState([]);
  const [isUsed, setIsUsed] = useState('');
  const [isAllocation, setIsAllocation] = useState('');
  const [model, setModel] = useState('');
  const [makerName, setMakerName] = useState('');
  const [supplierCode, setSupplierCode] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [isKeepPrice, setIsKeepPrice] = useState(true);
  const [processTime, setProcessTime] = useState(0);
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [modifier, setModifier] = useState('');

  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);

  const [dataState, setDataState] = useState(1);
  const [dataStatePurchaseType, setDataStatePurchaseType] = useState(-1);
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [supplierKeyword, setSupplierKeyWord] = useState('');

  const [hasCheckData, setHasCheckData] = useState(true);
  const [leftCol, setLeftCol] = useState(10);
  const [rightCol, setRightCol] = useState(13);
  const [middlecol, setMiddleCol] = useState(1);
  const [infoShowHide, setInfoShowHide] = useState(true);
  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [tempPurchasesData, setTempPurchasesData] = useState([]);

  const [lotUpperTh, setLotUpperTh] = useState([]);
  const [lotPrice, setLotPrice] = useState([]);

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);
  const [isUsedEdit, setIsUsedEdit] = useState(0);
  const [isAllocationEdit, setIsAllocationEdit] = useState(0);
  const [isKeepPriceEdit, setIsKeepPriceEdit] = useState(0);

  // ヘッダーバーの押下で情報を表示／非表示
  const [isPriceLots, setIsPriceLots] = useState(true);

  // 数量別単価の初期値
  const initInfoPriceLots = { upperTh: 1, price: 0 };

  const [tmpFilteredData, setTmpFilteredData] = useState();

  // 仕入れ先情報一覧
  const [clientModal, setClientModal] = useState(false);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  const [currentPage, setCurrentPage] = useState(1);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const [pageSize, setPageSize] = useState(30);
  const [editPageSize, setEditPageSize] = useState(30);
  const [editPage, setEditPage] = useState(1);

  const [totalCount, setTotalCount] = useState(0);
  const [ascSortFlg, setAscSortFlg] = useState('ASC');
  const [sortNm, setSortNm] = useState('id');
  const [filterDatas, setFilterDatas] = useState({
    name: '',
    model: '',
    supplierName: '',
    purchaseCategoryId: -1,
    isUsedFlg: 1,
  });
  const [tblLoading, setTblLoading] = useState(false);
  const [isFilterFlg, setIsFilterFlg] = useState(false);

  const [inValidItem, setInValidItem] = useState([]); // 入力不正な項目

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  useEffect(() => {
    getPurchaseCategoryData();
  }, [paramType, paramSelected]);

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    if (isFilterFlg) {
      // 絞り込み、
      getDataByFilter();
    }
  }, [nameKeyword, modelKeyword, supplierKeyword, dataState, dataStatePurchaseType, tmpFilteredData, purchasesData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          setSelectedRowId(changeRecordId);
          setSelectedData(getSelectedData(purchasesData, changeRecordId));
          break;
        case discardChange[1]?.no: // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          setSelectedData(getSelectedData(purchasesData, selectedRowId));
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
        case discardChange[4]?.no: // CSV入力
          onClickCSVImport();
          break;
        case discardChange[5]?.no: // CSV出力
          onClickCSVExport();
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 購入品種別情報をDBから取得する
  async function getPurchaseCategoryData() {
    try {
      props?.loading(true); // Waitingダイアログを表示にする
      let purchaseTypeLst = await getPurchaseCategoryInfo();
      let purchaseCategory = purchaseTypeLst?.filter((i) => i?.info?.isUsed);
      setPurchaseCategoryInfo(purchaseCategory);
      setDataState(1);
      await getPurchaseData();
      props?.loading(false); // Waitingダイアログを非表示にする
    } catch (e) {
      error(e.message);
      return;
    }
  }

  // 購入品情報をDBから取得する
  async function getPurchaseData() {
    let purchasesData = [];
    let selectedData = [];
    try {
      let pageBefore =
        props.initialParam === 'purchase' && props?.rowSelectPage !== undefined && props?.rowSelectPage !== 0
          ? props?.rowSelectPage - 1
          : 0;
      setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
      let propSortFlg =
        props.initialParam === 'purchase' && props?.ascSortFlg !== undefined ? props?.ascSortFlg : ascSortFlg;
      let propSortNm = props.initialParam === 'purchase' && props?.sortNm !== undefined ? props?.sortNm : sortNm;
      let propFilterDatas =
        props.initialParam === 'purchase' && props?.filterDatas?.purchaseCategoryId !== undefined
          ? props?.filterDatas
          : filterDatas;
      let purchasesInfo = await getPurchaseInfo(pageSize, pageBefore, propSortFlg, propSortNm, propFilterDatas);
      if (purchasesInfo) {
        purchasesData = purchasesInfo ? purchasesInfo?.data : [];
        setTotalCount(purchasesInfo?.totalCount);
      }
      if (purchasesData?.length > 0) selectedData = purchasesData[0];
      setPurchasesData(purchasesData);
      setFilteredData(purchasesData);
      setSelectedRowId(selectedData?.id);
      setSelectedData(selectedData);
      // 前回選択された行がある場合
      if (props.initialParam === 'purchase' && props.selectedRowId !== undefined && props.selectedRowId !== 0) {
        let selectedRowData = purchasesData?.filter((item) => item.id === props?.selectedRowId);
        if (selectedRowData?.length > 0) {
          setSelectedRowId(selectedRowData[0].id);
          setSelectedData(selectedRowData[0]);
        }
        setDataState(propFilterDatas?.isUsedFlg);
        setNameKeyWord(propFilterDatas?.name);
        setModelKeyWord(propFilterDatas?.model);
        setSupplierKeyWord(propFilterDatas?.supplierName);
        setDataStatePurchaseType(propFilterDatas?.purchaseCategoryId);
        setFilterDatas(propFilterDatas);
      }
      navigate(':common.purchase');
      setParamType('common');
      props.setParam(paramType, paramSelected);
    } catch (e) {
      error(e.message);
      return;
    }
  }

  // 購入品種別のリストボックス情報
  const purchaseCategoryOptions = (data = purchaseCategoryInfo) => {
    let list;
    list = data?.filter((i) => i.info.isUsed)?.map((item) => ({ value: item.id, label: item.name }));
    return list;
  };

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 数量別単価のヘッダーバーを押下
  const clickPriceLots = () => {
    setIsPriceLots(!isPriceLots);
  };

  // 数量別単価に行を追加
  const addPriceLots = (e) => {
    let tempPriceLots = [...priceLots];
    tempPriceLots.push(initInfoPriceLots);
    setPriceLots(tempPriceLots);
    resetInputPriceLots(true);
  };

  // 入力項目の初期値を設定
  const resetInputPriceLots = (isAdd = false) => {
    setLotUpperTh((prev) => {
      if (!isAdd) prev = [];
      return [...prev, lotUpperTh?.length + 1];
    });
    setLotPrice((prev) => {
      if (!isAdd) prev = [];
      return [...prev, initInfoPriceLots.price];
    });
  };

  // 入力項目のチェック
  const isValid = (inputVal, name, index, lotUpperThArr) => {
    let input = '';
    let item = [];
    let ret = true;
    input = document.getElementsByName(name + index)?.[0];
    input?.classList?.remove('input-error');
    var filterItems = lotUpperThArr?.filter(function (value) {
      return Number(value) === Number(inputVal);
    });
    if (filterItems?.length > 1) {
      item = inValidItem;
      item.push(name + index);
      input?.classList.add('input-error');
      ret = false;
    }
    setInValidItem(item);
    return ret;
  };

  // 「数量別単価」情報に入力した場合、
  const onChangeValue = (name, value, index, isCheckZenkaku = true) => {
    let tempData = [];
    switch (name) {
      case 'lotUpperTh': // 数量（以上）
        // IQX_WEBEST-370 親部品‐購入品にロット数価格を使用している購入品情報を追加してもロット数価格情報が表示されていない。
        if (regexExp.test(value) && !isDigitZenkaku(value)) break;
        if (value === '0') value = 1;
        tempData = [...lotUpperTh];
        value = onChangeInputForm(value, isCheckZenkaku); // 全角⇒半角変換
        tempData[index] = value;
        setLotUpperTh(tempData);
        if (!isValid(value, 'lotUpperTh_add', index, tempData)) break;
        break;
      case 'lotPrice': // 単価
        tempData = [...lotPrice];
        tempData[index] = value;
        setLotPrice(tempData);
        break;
    }
  };

  // 行を削除する
  const deleteSelectedRow = (index, e, id) => {
    if (id === 'btnPriceLots') {
      // 数量別単価
      setPriceLots(removedData(priceLots, index));
      setLotUpperTh(removedData(lotUpperTh, index));
      setLotPrice(removedData(lotPrice, index));
    }
  };

  // 情報を削除する
  const removedData = (data, index) => {
    return data?.filter((v, i) => i !== index);
  };

  // 入力ボックスは金額タイプの場合、他の項目CSSを参照にして利用
  const getAntInputBoxClassName = (id, mode = true) => {
    let classList = document.getElementById(id)?.classList;
    let className = '';
    if (classList?.length > 0)
      className =
        ' ' +
        Object.values(classList)?.filter(
          (v) => v !== 'ant-input' && v !== 'input-editable' && v !== 'input-non-editable'
        );
    if (mode) {
      return 'ant-input input-editable' + className;
    } else {
      return 'input-non-editable';
    }
  };

  // 新規作成の項目値を変更した場合、
  const onChangeInputForm = (value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    return value;
  };

  // 入力項目情報
  const inputFormItem = (
    <>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          {/* 引当用 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">引当用</label>
            </td>
            {isBulkEdit ? (
              <td style={{ verticalAlign: 'middle' }}>
                <RadioGroup
                  id="isAllocationEdit"
                  name="isAllocationEdit"
                  defaultValue={isAllocationEdit}
                  value={isAllocationEdit}
                  className="radioCustomerGpEdit"
                  onChange={(e) => setIsAllocationEdit(e.target.value)}
                >
                  <Radio value={0}>{NOT_CHANGE}</Radio>
                  <Radio value={1}>オン</Radio>
                  <Radio value={2}>オフ</Radio>
                </RadioGroup>
              </td>
            ) : (
              <td style={{ verticalAlign: 'middle' }}>
                <RadioGroup
                  id="isAllocation"
                  name="isAllocation"
                  defaultValue={isAllocation}
                  value={isAllocation}
                  className="radioCustomerGpEdit"
                  onChange={(e) => setIsAllocation(e.target.value)}
                >
                  <Radio value={true}>オン</Radio>
                  <Radio value={false}>オフ</Radio>
                </RadioGroup>
              </td>
            )}
          </tr>

          {/* コード */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-editable'}
                name="code"
                id="code"
                value={code}
                type="text"
                ref={(ref) => {
                  inputItemRef['code'] = ref;
                }}
                maxLength={50}
                onChange={(e) => setCode(e.target.value)}
              />
            </td>
          </tr>
          {/* 種別 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">種別</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                id="purchaseCategoryId"
                name="purchaseCategoryId"
                defaultValue={isBulkEdit ? NOT_CHANGE : purchaseCategoryDefaultValue}
                value={purchaseCategoryId}
                onChange={(e) => {
                  setPurchaseCategoryId(e);
                }}
              >
                {purchaseCategoryInfo?.map((item, index) => (
                  <Select.Option key={index} id={'purchaseCategoryId' + item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          {/* 名称 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-editable'}
                name="name"
                id="name"
                value={name}
                type="text"
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={100}
                onChange={(e) => setName(e.target.value)}
              />
            </td>
          </tr>
          {/* 型番 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">型番</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-editable'}
                name="model"
                id="model"
                value={model}
                type="text"
                ref={(ref) => {
                  inputItemRef['model'] = ref;
                }}
                maxLength={100}
                onChange={(e) => setModel(e.target.value)}
              />
            </td>
          </tr>
          {/* メーカー名 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">メーカー名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-editable'}
                name="makerName"
                id="makerName"
                value={makerName}
                type="text"
                ref={(ref) => {
                  inputItemRef['makerName'] = ref;
                }}
                maxLength={150}
                onChange={(e) => setMakerName(e.target.value)}
              />
            </td>
          </tr>
          {/* 仕入先 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">仕入先</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <>
                <Button
                  icon={<SearchOutlined />}
                  size="small"
                  onClick={(e) => getSupplierName(e)}
                  type="link"
                  className="supplier-search-icon"
                ></Button>
                <Input
                  placeholder={isBulkEdit ? NOT_CHANGE : ''}
                  className={'input-non-editable supplier-input-label'}
                  name="supplierName"
                  id="supplierName"
                  value={supplierName}
                  type="text"
                  ref={(ref) => {
                    inputItemRef['supplierName'] = ref;
                  }}
                  maxLength={50}
                />
              </>
            </td>
          </tr>
          {/* 仕入先コード */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">仕入先コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-non-editable'}
                name="supplierCode"
                id="supplierCode"
                value={supplierCode}
                type="text"
                ref={(ref) => {
                  inputItemRef['supplierCode'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          {/* 単価 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">単価</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <CurrencyInput
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                name="price"
                id="price"
                value={price}
                defaultValue={0}
                onValueChange={(e) => {
                  setPrice(e);
                }}
                ref={(ref) => {
                  inputItemRef['price'] = ref;
                }}
                className={getAntInputBoxClassName('price') + ' param-currency'}
                prefix="¥"
                decimalsLimit={100}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          {/* 単価保持 */}
          {/** IQX_WEBEST-166 単価優先を非表示する->IQX_WEBEST-229 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">単価保持</label>
            </td>
            {isBulkEdit ? (
              <td style={{ verticalAlign: 'middle' }}>
                <RadioGroup
                  id="isKeepPriceEdit"
                  name="isKeepPriceEdit"
                  defaultValue={1}
                  value={isKeepPriceEdit}
                  className="radioCustomerGpEdit"
                  onChange={(e) => setIsKeepPriceEdit(e.target.value)}
                >
                  <Radio value={0}>{NOT_CHANGE}</Radio>
                  <Radio value={1}>オン</Radio>
                  <Radio value={2}>オフ</Radio>
                </RadioGroup>
              </td>
            ) : (
              <td style={{ verticalAlign: 'middle' }}>
                <RadioGroup
                  id="isKeepPrice"
                  name="isKeepPrice"
                  defaultValue={1}
                  value={isKeepPrice}
                  className="radioCustomerGpEdit"
                  onChange={(e) => setIsKeepPrice(e.target.value)}
                >
                  <Radio value={true}>オン</Radio>
                  <Radio value={false}>オフ</Radio>
                </RadioGroup>
              </td>
            )}
          </tr>

          {/* ソート番号 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">ソート番号</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-editable'}
                name="sortNum"
                id="sortNum"
                value={sortNum}
                type="text"
                ref={(ref) => {
                  inputItemRef['sortNum'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
                onPressEnter={(e) => setSortNum(onChangeInputForm(e.target.value))}
                onBlur={(e) => setSortNum(onChangeInputForm(e.target.value))}
                onChange={(e) => setSortNum(onChangeInputForm(e.target.value, false))}
              />
            </td>
          </tr>
          {/* 作成日 更新日 */}
          {isBulkEdit ? (
            ''
          ) : (
            <>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{created}</label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modified}</label>
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modifier}</label>
                </td>
              </tr>
            </>
          )}
          {/* 取付時間(秒) */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">取付時間(秒)</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-editable'}
                name="processTime"
                id="processTime"
                value={processTime}
                type="text"
                ref={(ref) => {
                  inputItemRef['processTime'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
                onPressEnter={(e) => setProcessTime(onChangeInputForm(e.target.value))}
                onBlur={(e) => setProcessTime(onChangeInputForm(e.target.value))}
                onChange={(e) => setProcessTime(onChangeInputForm(e.target.value, false))}
              />
            </td>
          </tr>
          {/* 備考 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">備考</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <TextArea
                placeholder={isBulkEdit ? NOT_CHANGE : ''}
                className={'input-editable'}
                name="remarks"
                id="remarks"
                value={remarks}
                style={{ margin: 5, padding: 5, width: '98.3%' }}
                type="text"
                ref={(ref) => {
                  inputItemRef['remarks'] = ref;
                }}
                maxLength={2048}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </td>
          </tr>
          {/* 表示 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">表示</label>
            </td>
            {isBulkEdit ? (
              <td style={{ verticalAlign: 'middle' }}>
                <RadioGroup
                  name="isUsedEdit"
                  defaultValue={0}
                  className="radioCustomerGpEdit"
                  onChange={(e) => setIsUsedEdit(e.target.value)}
                  value={isUsedEdit}
                >
                  <Radio value={0}>{NOT_CHANGE}</Radio>
                  <Radio value={1}>オン</Radio>
                  <Radio value={2}>オフ</Radio>
                </RadioGroup>
              </td>
            ) : (
              <td style={{ verticalAlign: 'middle' }}>
                <RadioGroup
                  id="isUsed"
                  name="isUsed"
                  defaultValue={1}
                  value={isUsed}
                  className="radioCustomerGpEdit"
                  onChange={(e) => setIsUsed(e.target.value)}
                >
                  <Radio value={true}>オン</Radio>
                  <Radio value={false}>オフ</Radio>
                </RadioGroup>
              </td>
            )}
          </tr>
        </thead>
      </RTable>
      {/* 数量別単価 */}
      {isBulkEdit ? (
        ''
      ) : (
        <>
          <Row style={{ color: 'black', marginTop: 0 }} className="oyabuhin oyabuhin-row" onClick={clickPriceLots}>
            <Col span={20} className="deli-col">
              <div style={{ display: 'flex' }}>
                <label>数量別単価</label>
                {isPriceLots ? (
                  <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                )}
              </div>
            </Col>
          </Row>
          <div style={{ display: isPriceLots ? 'block' : 'none' }}>
            <RTable className="tblPriceLots" style={{ width: '-webkit-fill-available' }}>
              <thead>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">数量（以上）</td>
                  <td className="param-detail-tbl-val-title">単価</td>
                  <td style={{ width: 125, border: 'none', backgroundColor: 'white' }}></td>
                </tr>
                {[...priceLots]?.map((listValue, index) => {
                  return (
                    <tr key={index} className="detail-tbl-row">
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          className={'input-editable'}
                          name={'lotUpperTh_add' + index}
                          id={'lotUpperTh' + index}
                          value={lotUpperTh[index]}
                          onPressEnter={(e) => onChangeValue('lotUpperTh', e.target.value, index)}
                          onBlur={(e) => onChangeValue('lotUpperTh', e.target.value, index)}
                          onChange={(e) => onChangeValue('lotUpperTh', e.target.value, index, false)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, true);
                          }}
                          type="text"
                          ref={(ref) => {
                            inputItemRef['lotUpperTh' + index] = ref;
                          }}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        />
                        {inValidItem?.includes('lotUpperTh_add' + index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{'本値は既に入力されています。'}</div>}
                            placement="right"
                            open={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <CurrencyInput
                          name="lotPrice"
                          id={'lotPrice' + index}
                          value={lotPrice[index]}
                          defaultValue={0}
                          onValueChange={(e) => {
                            onChangeValue('lotPrice', e, index, false);
                          }}
                          ref={(ref) => {
                            inputItemRef['lotPrice' + index] = ref;
                          }}
                          className={getAntInputBoxClassName('lotPrice' + index) + ' param-currency'}
                          style={{ height: 30 }}
                          prefix="¥"
                          decimalsLimit={100}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        />
                      </td>
                      <td style={{ border: '0px solid', textAlign: 'left' }}>
                        {priceLots?.length - 1 === index ? (
                          <PlusCircleFilled className="add-icon" onClick={(e) => addPriceLots(e, `btnPriceLots`)} />
                        ) : (
                          <></>
                        )}
                        {priceLots?.length > 1 ? (
                          <CloseCircleFilled
                            className="add-icon"
                            onClick={(e) => deleteSelectedRow(index, e, 'btnPriceLots')}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </thead>
            </RTable>
          </div>
        </>
      )}
    </>
  );

  // 選択中行のデータを取得する
  const getSelectedData = (data, id) => {
    let ret = [];
    let record;
    record = data?.find((item) => item.id === id);
    return record ? record : ret;
  };

  // 最大ソート番号を得る
  const getMaxSortNum = () => {
    let sortedList = purchasesData.sort((a, b) => b.info.sortNum - a.info.sortNum);
    return sortedList?.length > 0 ? sortedList[0].info.sortNum + 1 : 1;
  };

  // Idで降順する
  // const descOrderById = (data) => {
  //   let sortedList = data.sort((a, b) => b.id - a.id);
  //   return sortedList;
  // };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    let selectedPurchasesData = [];
    if (record !== undefined) {
      selectedPurchasesData.push({
        id: record.id,
        purchaseCategoryId: record.purchaseCategoryId,
        code: record.code,
        name: record.name,
        price: record.price,
        priceLots: record.priceLots,
        priceHistories: record.priceHistories,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });

      setChangeRecordId(record.id);
      if (editMode) {
        setDiscardChangeMode(discardChange[0]);
        //setEditModeCancelConfirm(true);
        setEditModeCancelConfirm(props.showConfirmMsgs?.moveInEdit);
        if (!props.showConfirmMsgs?.moveInEdit) {
          setSelectedRowId(record.id);
          setSelectedData(selectedPurchasesData[0]);
          navigate(':common.purchase');
        }
      } else {
        setSelectedRowId(record.id);
        setSelectedData(selectedPurchasesData[0]);
        navigate(':common.purchase');
      }
      props?.updateSelectedRowId(record?.id, 0, currentPage, ascSortFlg, sortNm, filterDatas);
      props?.resetInitialParam();
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.purchase');
    }
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setTblLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let ascSortFlg = 'ASC';
    if (sorter?.order === undefined) {
      ascSortFlg = '';
    } else if (sorter?.order === 'ascend') {
      ascSortFlg = 'ASC';
    } else if (sorter?.order === 'descend') {
      ascSortFlg = 'DESC';
    }
    setAscSortFlg(ascSortFlg);
    let purchasesInfo = await getPurchaseInfo(pageSize, pageBefore, ascSortFlg, sorter?.columnKey, filterDatas);
    if (purchasesInfo) {
      let purchasesData = purchasesInfo ? purchasesInfo?.data : [];
      setFilteredData(purchasesData);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
  };

  const onRowSelectWithoutEditMode = (record) => {
    let selectedPurchasesData = [];
    if (record !== undefined) {
      selectedPurchasesData.push({
        id: record.id,
        purchaseCategoryId: record.purchaseCategoryId,
        code: record.code,
        name: record.name,
        price: record.price,
        priceLots: record.priceLots,
        priceHistories: record.priceHistories,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });

      setChangeRecordId(record.id);
      setSelectedRowId(record.id);
      setSelectedData(selectedPurchasesData[0]);
      const purchaseLst = [...filteredData];
      if (purchaseLst?.length > 0) {
        setCurrentPage(Math.ceil(purchaseLst?.length / 25));
      } else {
        setCurrentPage(1);
      }
      props?.updateSelectedRowId(record?.id, 0, currentPage, ascSortFlg, sortNm, filterDatas);
      navigate(':common.purchase');
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.purchase');
    }
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    if (!props.showConfirmMsgs.moveInEdit) {
      setIsDelete(false);
      deleteOk();
    } else {
      setIsDelete(true);
    }
  };

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = async (e) => {
    if (isDelete || !props.showConfirmMsgs.moveInEdit) {
      props?.loading(true); // Waitingダイアログを表示にする
      setIsDelete(false);
      setEditMode(false);
      let selectedId = selectedRowId;
      let deletedData = await deletePurchaseData(selectedId);
      // IQX_WEBEST-281 使用しているパラメータなのに削除出来てしまう
      if (deletedData !== undefined && deletedData?.id > 0) {
        let filterdData = [...filteredData];
        filterdData = filterdData?.filter((item) => item.id !== deletedData.id);
        setFilteredData(filterdData);
        /* let lastKey = filterdData[filterdData?.length - 1]?.key;
      setSelectedRowKeys(lastKey);
      let record = getSelectedData(filterdData, lastKey);
      setSelectedData(record); */

        let currentData = [...purchasesData];
        currentData = currentData?.filter((item) => item.id !== deletedData.id);
        onRowSelectWithoutEditMode(currentData !== undefined && currentData?.length > 0 ? currentData[0] : []);
        setPurchasesData(currentData);
        setTmpFilteredData(currentData);
        // setCurrentPage(1);
        getPurchaseCategoryData();
      }
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 購入品情報をDBに削除する
  async function deletePurchaseData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const resetInputInfo = () => {
    setPurchaseCategoryId();
    setCode('');
    setName('');
    setPrice('');
    setPriceLots([initInfoPriceLots]);
    setIsUsed();
    setIsAllocation();
    setModel('');
    setMakerName('');
    setSupplierCode('');
    setSupplierName('');
    setIsKeepPrice();
    setProcessTime();
    setRemarks('');
    setSortNum('');
    setCreated(today);
    setModified(today);
    setModifier('');
    resetInputPriceLots();
    setIsUsedEdit(0);
    setIsAllocationEdit(0);
    setIsKeepPriceEdit(0);
  };

  // 表示の押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
    setIsFilterFlg(true);
  };

  // 種類の押下
  const searchByDataStatePurchaseType = (value) => {
    dataStatePurchaseType === value ? getDataByFilter() : setDataStatePurchaseType(value);
    setIsFilterFlg(true);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
      case 'supplier':
        supplierKeyword === e.target.value ? getDataByFilter() : setSupplierKeyWord(e.target.value);
        break;
    }
    setIsFilterFlg(true);
  };

  // 絞り込み、
  const getDataByFilter = async () => {
    if (purchasesData?.length <= 0) {
      return;
    }
    let tKeyword = dataStatePurchaseType !== undefined && dataStatePurchaseType !== '' ? dataStatePurchaseType : 0;
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let rKeyword = supplierKeyword;
    let flag;
    let currentData = [...purchasesData];
    let purchaseFilterData = currentData;

    // // 名称のキーワードで絞り込み
    // if (nKeyword != '') {
    //   purchaseFilterData = purchaseFilterData?.filter((item) => item.name?.toString().indexOf(nKeyword) >= 0);
    // }
    // // 型番のキーワードで絞り込み
    // if (mKeyword != '') {
    //   purchaseFilterData = purchaseFilterData?.filter((item) => item.info.model?.toString().indexOf(mKeyword) >= 0);
    // }
    // // 仕入先のキーワードで絞り込み
    // if (rKeyword != '') {
    //   purchaseFilterData = purchaseFilterData?.filter(
    //     (item) => item.info.supplierName?.toString().indexOf(rKeyword) >= 0
    //   );
    // }

    // //  表示で絞り込み
    // if (dataState === 1 || dataState === 2) {
    //   dataState === 1 ? (flag = true) : (flag = false);
    //   purchaseFilterData = purchaseFilterData?.filter((item) => item.info.isUsed === flag);
    // }

    // // 種別で絞り込み
    // if (tKeyword > 0) {
    //   purchaseFilterData = purchaseFilterData?.filter((item) => item.purchaseCategoryId === tKeyword);
    // }

    /* let index = purchaseFilterData?.findIndex((item) => item.key === selectedRowKeys);
    // 検索した結果に、選択中行のKeyが無い場合、
    if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        setEditModeCancelConfirm(true);
        return;
      }
      // 検索結果がある場合、
      if (purchaseFilterData?.length > 0) {
        index = purchaseFilterData?.length - 1;
        setSelectedRowKeys(purchaseFilterData[index]?.key);
      }
    }
    purchaseFilterData?.length > 0
      ? setSelectedData(purchaseFilterData[index]) // 検索結果がある場合、
      : setSelectedData(purchaseFilterData); // 検索結果が無い場合、
    // 一覧に表示するデータ */
    let filterData = {
      name: nKeyword,
      model: mKeyword,
      supplierName: rKeyword,
      purchaseCategoryId: tKeyword,
      isUsedFlg: dataState,
    };
    setFilterDatas(filterData);
    let purchaseTypeInfo = await getPurchaseInfo(pageSize, 0, ascSortFlg, sortNm, filterData);
    if (purchaseTypeInfo) {
      purchaseFilterData = purchaseTypeInfo ? purchaseTypeInfo?.data : [];
      setTotalCount(purchaseTypeInfo?.totalCount);
    }
    setFilteredData(purchaseFilterData);
    setCurrentPage(1);
    setIsFilterFlg(false);
    props?.updateSelectedRowId(selectedRowId, 0, currentPage, ascSortFlg, sortNm, filterData);
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isCreateNew) {
      setCreateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false)) {
        return;
      }
      // 数量別単価の数量（以上）
      if (inValidItem?.length > 0) {
        let input = inputItemRef[inValidItem[0]]?.input;
        input.classList.add('input-error');
        error(
          <ul>
            <li>{'数量別単価の数量（以上）に同じ値が含まれています。'}</li>
          </ul>
        );
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let lastIndex = tempPurchasesData?.length - 1;
      let tempData = tempPurchasesData[lastIndex];
      let createdData = await createPurchaseData(tempData);
      setPurchasesData((prevData) => [...prevData, createdData]);
      setTmpFilteredData(createdData);
      setFilteredData((filteredData) => [...filteredData, createdData]);
      onRowSelectWithoutEditMode(createdData);
      setSelectedRowId(createdData?.id);
      setSelectedData(createdData);
      props?.updateSelectedRowId(createdData?.id, 0, 1, ascSortFlg, sortNm, filterDatas);
      // setCurrentPage(1);
      setIsCreateNew(false);
      setIsFilterFlg(true);
      props?.loading(false); // Waitingダイアログを非表示にする
    } else if (isBulkEdit) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit, false)) {
        return;
      }
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      props?.loading(true); // Waitingダイアログを表示にする
      // 選択された全ての対象行を更新、
      for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
        let item = bulkEditSelectedRow[i];
        if (purchaseCategoryId !== undefined) item.purchaseCategoryId = purchaseCategoryId;
        if (code) item.code = code;
        if (name) item.name = name;
        if (price) {
          item.price = price;
        }
        if (isUsedEdit > 0) {
          item.info.isUsed = isUsedEdit === 1 ? true : false;
        }
        if (isAllocationEdit > 0) {
          item.info.isAllocation = isAllocationEdit === 1 ? true : false;
        }
        if (model) item.info.model = model;
        if (makerName) item.info.makerName = makerName;
        if (supplierCode) item.info.supplierCode = supplierCode;
        if (supplierName) item.info.supplierName = supplierName;
        if (isKeepPriceEdit > 0) {
          item.info.isKeepPrice = isKeepPriceEdit === 1 ? true : false;
        }
        if (processTime) item.info.processTime = processTime;
        if (remarks) item.info.remarks = remarks;
        if (sortNum) item.info.sortNum = sortNum;
        item.modified = today;
        await submitData(item);
      }
      setIsFilterFlg(true);
      props?.loading(false); // Waitingダイアログを非表示にする
    }
    resetInputInfo();
  };

  // 購入品情報をDBに新規登録する
  async function createPurchaseData(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          purchaseCategoryId: createData.purchaseCategoryId,
          code: createData.code,
          name: createData.name,
          price: createData.price,
          priceLots: createData.priceLots,
          priceHistories: createData.priceHistories,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [];
    var updatedInfo = [];
    var index;
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updatePurchaseData(updateData);
    tmpData = [...purchasesData];
    index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setPurchasesData(tmpData);
    setTmpFilteredData(tmpData);

    tmpData = [...filteredData];
    index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setFilteredData(tmpData);

    // 詳細情報を再表示
    if (updatedInfo.id === selectedRowId) detailRef.current?.displayDetailInfo(updatedInfo);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 購入品情報をDBに更新する
  async function updatePurchaseData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          purchaseCategoryId: updateData.purchaseCategoryId,
          code: updateData.code,
          name: updateData.name,
          price: updateData.price,
          priceLots: updateData.priceLots,
          priceHistories: updateData.priceHistories,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setIsCreateNew(true);
    setIsUsed(true);
    setIsAllocation(false);
    setIsKeepPrice(true);
    setProcessTime(0);
    setPrice(0);
    setSortNum(getMaxSortNum());
    setChildTitle('購入品（新規作成）');
    setInValidItem([]);
  };

  const createNewOK = () => {
    prepareCreateNewInfo();
  };

  const createNewCancel = (e) => {
    //  setCancelConfirm(true);
    setCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle && isCreateNew) {
      setIsCreateNew(false);
    }
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = (e) => {
    let tempPurchasesData = [];
    let info = {
      isUsed: isUsed,
      isAllocation: isAllocation,
      model: model,
      makerName: makerName,
      supplierCode: supplierCode,
      supplierName: supplierName,
      isKeepPrice: isKeepPrice,
      processTime: processTime,
      remarks: remarks,
      sortNum: sortNum,
    };
    priceLots?.map((item, i) => {
      item.upperTh = lotUpperTh[i];
      item.price = lotPrice[i];
    });
    tempPurchasesData.push({
      purchaseCategoryId: purchaseCategoryId,
      code: code,
      name: name,
      price: price,
      info: info,
      priceLots: priceLots,
      priceHistories: priceHistories,
      created: today,
      modified: today,
    });
    setTempPurchasesData(tempPurchasesData);
    setCreateConfirm(true);
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 700,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 20,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 新規作成-終了

  const getPurchaseCategoryName = (id) => {
    let data = [];
    data = purchaseCategoryInfo;
    let record = data?.filter((item) => item.id === id);
    let name = record?.length > 0 ? record[0]?.name : '';
    return name;
  };

  // 名称で購入種別Idを得る
  const getPurchaseCategoryId = (name) => {
    let data = [];
    data = purchaseCategoryInfo;
    let record = data?.filter((item) => item.name === name);
    let id = record?.length > 0 ? record[0]?.id : -1;
    return id;
  };

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 55 + 'px',
      render: (id, record, index) => {
        let pageBefore = currentPage - 1;
        let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
        return startPage + (index + 1); // IQX_WEBEST-240
      },
      className: 'cm-a-right',
      key: 'no',
    },
    {
      id: '2',
      title: '引当用',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (index, item) => {
        return item.info.isAllocation ? <StarFilled /> : <StarOutlined />;
      },
      className: 'cm-a-center',
      sorter: (a, b) => setSortNm('isAllocation'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'isAllocation',
    },

    {
      id: '3',
      title: 'コード',
      dataIndex: 'code',
      width: 100 + 'px',
      sorter: (a, b) => setSortNm('code'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'code',
    },
    {
      id: '4',
      title: '種別',
      dataIndex: 'purchaseCategoryId',
      width: 100 + 'px',
      render: (id) => `${getPurchaseCategoryName(id)}`,
      sorter: (a, b) => setSortNm('purchaseCategoryId'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'purchaseCategoryId',
    },
    {
      id: '5',
      title: '型番',
      dataIndex: 'info',
      width: 120 + 'px',
      render: (info) => info.model,
      sorter: (a, b) => setSortNm('model'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'model',
    },
    {
      id: '6',
      title: '名称',
      dataIndex: 'name',
      width: 150 + 'px',
      sorter: (a, b) => setSortNm('name'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'name',
    },
    {
      id: '7',
      title: 'メーカー名',
      dataIndex: 'info',
      width: 120 + 'px',
      render: (info) => info.makerName,
      sorter: (a, b) => setSortNm('makerName'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'makerName',
    },
    {
      id: '8',
      title: '仕入先',
      dataIndex: 'info',
      width: 150 + 'px',
      render: (info) => info.supplierName,
      sorter: (a, b) => setSortNm('supplierName'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'supplierName',
    },
    {
      id: '9',
      title: '仕入先コード',
      dataIndex: 'info',
      width: 150 + 'px',
      render: (info) => info.supplierCode,
      sorter: (a, b) => setSortNm('supplierCode'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'supplierCode',
    },
    {
      id: '10',
      title: '単価',
      dataIndex: 'price',
      width: 120 + 'px',
      className: 'cm-a-right',
      render: (value) => JPYs.format(value),
      sorter: (a, b) => setSortNm('price'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'price',
    },

    {
      id: '11',
      title: '単価保持',
      dataIndex: 'info',
      width: 95 + 'px',
      render: (index, item) => {
        return item.info.isKeepPrice ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.info.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    }, // IQX_WEBEST-166 単価優先を非表示する->IQX_WEBEST-229
    {
      id: '12',
      title: 'ソート番号',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (info) => info.sortNum,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('sortNum'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'sortNum',
    },
    {
      id: '13',
      title: '作成日',
      dataIndex: 'created',
      width: 100 + 'px',
      render: (created) => formatDate(created),
      sorter: (a, b) => setSortNm('created'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'created',
    },
    {
      id: '14',
      title: '更新日',
      dataIndex: 'modified',
      width: 100 + 'px',
      render: (modified) => formatDate(modified),
      sorter: (a, b) => setSortNm('modified'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'modified',
    },
    {
      id: '15',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150 + 'px',
      sorter: (a, b) => setSortNm('modifier'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'modifier',
    },
    {
      id: '16',
      title: '取付時間(秒)',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (info) => info.processTime,
      className: 'cm-a-right',
      sorter: (a, b) => setSortNm('processTime'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'processTime',
    },
    {
      id: '17',
      title: '備考',
      dataIndex: 'info',
      render: (info) => info.remarks,
      width: 200 + 'px',
      sorter: (a, b) => setSortNm('remarks'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'remarks',
    },
    {
      id: '18',
      title: '表示',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.info.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
      sorter: (a, b) => setSortNm('isUsed'),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'isUsed',
    },
    {
      id: '19',
      title: '',
      dataIndex: 'id',
      hidden: true,
    },
  ]?.filter((item) => !item.hidden);

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  // 一括編集-開始
  const onRowCheckboxSelect = (checked, record, index) => {
    let checkedRow = [...checked];
    setBulkEditSelectedRow([...record]);
    setBulkEditSelectedRowId(checkedRow);
    setChkRowsCount(checkedRow?.length);
    navigate(':common.purchase');
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditSelectedRow([]);
    setBulkEditSelectedRowId([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle('購入品（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const handlePageSizeChangeEdit = (current, pagesize) => {
    setEditPageSize(pagesize);
  };

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={filteredData}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.id == bulkEditSelectedRowId ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={bulkEditRowSelect}
            pagination={{
              position: ['bottomLeft'],
              total: totalCount,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultCurrent: 1,
              pageSize: editPageSize,
              pageSizeOptions: pageSizes,
              current: editPage,
              showSizeChanger: true,
              onShowSizeChange: handlePageSizeChangeEdit,
              onChange: (page) => {
                setEditPage(page);
              },
            }}
            onChange={handleTableChange}
            loading={tblLoading}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  // 仕入れ先名称を検索
  const getSupplierName = (e) => {
    setClientModal(true);
  };

  // 仕入れ先情報一覧に選択した、データを表示項目にセットする
  const selectedSupplierData = (selectedData) => {
    setSupplierCode(selectedData.code);
    setSupplierName(selectedData.name);
    setClientModal(false);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col) => (str.length === 0 ? (str += col.title) : (str += ',' + col.title))); // ヘッダの情報
    str = 'ID,' + str;
    return str;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let str = '';
    str = getCSVHeaderInfo();
    filteredData.map((data, index) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (index + 1) +
        ',' +
        data.info.isAllocation +
        ',' +
        data.code +
        ',' +
        getPurchaseCategoryName(data.purchaseCategoryId) +
        ',' +
        data.info.model +
        ',' +
        data.name +
        ',' +
        data.info.makerName +
        ',' +
        data.info.supplierName +
        ',' +
        data.info.supplierCode +
        ',' +
        data.price +
        ',' +
        data.info.isKeepPrice +
        ',' +
        data.info.sortNum +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.modifier +
        ',' +
        data.info.processTime +
        ',' +
        data.info.remarks +
        ',' +
        data.info.isUsed;
    });
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDatas = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let info = {
          isUsed: true,
          isAllocation: true,
          model: '',
          makerName: '',
          supplierCode: '',
          supplierName: '',
          isKeepPrice: true,
          processTime: 0,
          remarks: '',
          sortNum: '',
        };
        let tempData = {
          purchaseCategoryId: 0,
          code: '',
          name: '',
          price: 0,
          info: info,
          priceLots: [],
          priceHistories: [],
          created: today,
          modified: today,
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }
        let purchaseId = getPurchaseCategoryId(data?.[4]);
        tempData.id = data?.[0];
        tempData.info.isAllocation = data?.[2] ? JSON.parse(data?.[2]?.toLowerCase()) : true; // IQX_WEBEST-301
        tempData.code = data?.[3];
        tempData.purchaseCategoryId = purchaseId < 0 ? 0 : purchaseId;
        tempData.info.model = data?.[5];
        tempData.name = data?.[6];
        tempData.info.makerName = data?.[7];
        tempData.info.supplierName = data?.[8];
        tempData.info.supplierCode = data?.[9];
        tempData.price = data?.[10];
        tempData.info.isKeepPrice = data?.[11] ? JSON.parse(data?.[11]?.toLowerCase()) : true; // IQX_WEBEST-301
        tempData.info.sortNum = data?.[12];
        tempData.info.processTime = data?.[16];
        tempData.info.remarks = data?.[17];
        tempData.info.isUsed = data?.[18] ? JSON.parse(data?.[18]?.toLowerCase()) : true; // IQX_WEBEST-301
        tempDatas.push(tempData);
      }
      createdDatas = await createPurchaseCSV(tempDatas);
      if (createdDatas) {
        if (createdDatas !== undefined && createdDatas?.length > 0) {
          setPurchasesData(createdDatas);
          setFilteredData(createdDatas?.filter((item) => item.info.isUsed === true));
          setSelectedRowId(createdDatas?.[0]?.id);
          setSelectedData(createdDatas?.[0]);
          props?.updateSelectedRowId(createdDatas?.[0]?.id, 0, 1, ascSortFlg, sortNm, filterDatas);
          setCurrentPage(1);
          // onRowSelectWithoutEditMode(createdDatas?.[0]);
        }
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // コード
    let input = data[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('コード').E007}</li>);
      return false;
    }

    // 種別
    input = data[4];
    if (getPurchaseCategoryId(input) < 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('種別').E022}</li>);
      return false;
    }

    // 名称
    input = data[6];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('名称').E007}</li>);
      return false;
    }

    // 単価
    input = data[10];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('単価').E007}</li>);
      return false;
    }

    if (input?.length > 0 && !isDigit(input)) {
      // 数字チェック
      messageRef?.current?.push(<li>{no + ErrorMessage('単価').E009}</li>);
      return false;
    }

    // 取付時間（秒）
    input = data[16];
    if (input?.length > 0 && !isDigit(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('取付時間（秒）').E009}</li>);
      return false;
    }

    // ソート番号
    input = data[12];
    if (input?.length > 0 && !isDigit(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('ソート番号').E009}</li>);
      return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Select
                    className="search-select-input"
                    onChange={searchByDataStatePurchaseType}
                    id="dataStatePurchaseType"
                    name="dataStatePurchaseType"
                    defaultValue={-1}
                    value={dataStatePurchaseType}
                  >
                    {/** IQX_WEBEST-375 パラメーター購入品画面で絞込の種類プルダウンに選択値（全ての種類となし）のキーが重複して選択できていない。 */}
                    <Select.Option key={-1} id={'dataStatePurchaseType-1'} value={-1}>
                      {'全ての種別'}
                    </Select.Option>
                    {purchaseCategoryInfo?.map((item, index) => (
                      <Select.Option key={index} id={'dataStatePurchaseType' + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <Input
                    className="input-editable search-select-input"
                    placeholder="名称"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="name"
                    id="name"
                    // onChange={(e) => {
                    //   if (e.target.value === '') {
                    //     searchByKeyword(e);
                    //   }
                    // }}
                    onChange={searchByKeyword}
                    value={nameKeyword}
                  />
                  <Input
                    className="input-editable search-select-input"
                    placeholder="型番"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="model"
                    id="model"
                    // onChange={(e) => {
                    //   if (e.target.value === '') {
                    //     searchByKeyword(e);
                    //   }
                    // }}
                    onChange={searchByKeyword}
                    value={modelKeyword}
                  />
                  <Input
                    className="input-editable search-select-input"
                    placeholder="仕入先"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="supplier"
                    id="supplier"
                    // onChange={(e) => {
                    //   if (e.target.value === '') {
                    //     searchByKeyword(e);
                    //   }
                    // }}
                    onChange={searchByKeyword}
                    value={supplierKeyword}
                  />
                  <Select
                    className="search-select"
                    onChange={searchByDataState}
                    id="dataStateDrop"
                    name="dataStateDrop"
                    value={dataState}
                  >
                    {dataStateList?.map((item, index) => (
                      <Select.Option key={index} id={'dataState' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '10vw' }}
                    rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                    pagination={{
                      position: ['bottomLeft'],
                      total: totalCount,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      // defaultPageSize: defaultPageSize,
                      defaultCurrent: 1,
                      // pageSize: defaultPageSize,
                      current: currentPage,
                      pageSize: pageSize,
                      pageSizeOptions: pageSizes,
                      showSizeChanger: true,
                      onShowSizeChange: handlePageSizeChange,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                      };
                    }}
                    onChange={handleTableChange}
                    loading={tblLoading}
                  />
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":purchase"
                element={
                  <PurchaseDetails
                    ref={detailRef}
                    editMode={editMode}
                    purchaseCategoryInfo={purchaseCategoryInfo}
                    selectedData={selectedData}
                    purchaseCategoryOptions={purchaseCategoryOptions}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    getPurchaseCategoryData={getPurchaseCategoryData}
                    getPurchaseCategoryName={getPurchaseCategoryName}
                    getAntInputBoxClassName={getAntInputBoxClassName}
                    onChangeInputForm={onChangeInputForm}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* XML呼出 */}
      {/* <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 仕入れ先情報一覧 */}
      {clientModal ? (
        <SearchAndGetCustomerData
          customerModal={clientModal}
          selectedCustomerData={selectedSupplierData}
          title={Supplier}
          clientType={ClientType.Supplier}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default PurchaseParamList;
