import { Button, Row, Col, Modal, Radio } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CloseOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import '../../assets/styles/common.css';
import { formatDateString, JPYs, secondsToHms, setTimetoSec, todayTime } from '../common/Common';
import { commonTaiochuModal } from '../common/CommonModal';
import { ServiceClass, DataType, WorkType } from '../common/enums';
import { CSVLink } from 'react-csv';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';
import Draggable from 'react-draggable';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';

const KouteibetsuInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let [propsParam, setPropsParam] = useState(props.paramNm);
  const [showDetailRow, setShowDetailRow] = useState(true);
  const [showDetailRowIQ3, setShowDetailRowIQ3] = useState(true);
  const [showDetailRowIQ5, setShowDetailRowIQ5] = useState(true);
  const [showDetailRowIQ7, setShowDetailRowIQ7] = useState(true);
  const [activePartMenu, setActivePartMenu] = useState('total');
  const [parentProcess, setParentProcess] = useState([]);
  const [iq3Process, setIq3Process] = useState([]);
  const [iq5Process, setIq5Process] = useState([]);
  const [setupTotalTime, setSetupTotalTime] = useState(0);
  const [processingTotalTime, setProcessingTotalTime] = useState(0);
  const [setupTotalAmount, setSetupTotalAmount] = useState(0);
  const [processingTotalAmount, setProcessingTotalAmount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tanka, setTanka] = useState(0);
  // iq3
  const [setupTotalTime_IQ3, setSetupTotalTime_IQ3] = useState(0);
  const [processingTotalTime_IQ3, setProcessingTotalTime_IQ3] = useState(0);
  const [setupTotalAmount_IQ3, setSetupTotalAmount_IQ3] = useState(0);
  const [processingTotalAmount_IQ3, setProcessingTotalAmount_IQ3] = useState(0);
  const [totalTime_IQ3, setTotalTime_IQ3] = useState(0);
  const [totalAmount_IQ3, setTotalAmount_IQ3] = useState(0);
  const [tanka_IQ3, setTanka_IQ3] = useState(0);
  const [iq3MaterialAmount, setIq3MaterialAmount] = useState(0);

  // iq5
  const [setupTotalTime_IQ5, setSetupTotalTime_IQ5] = useState(0);
  const [processingTotalTime_IQ5, setProcessingTotalTime_IQ5] = useState(0);
  const [setupTotalAmount_IQ5, setSetupTotalAmount_IQ5] = useState(0);
  const [processingTotalAmount_IQ5, setProcessingTotalAmount_IQ5] = useState(0);
  const [totalTime_IQ5, setTotalTime_IQ5] = useState(0);
  const [totalAmount_IQ5, setTotalAmount_IQ5] = useState(0);
  const [tanka_IQ5, setTanka_IQ5] = useState(0);
  const [iq5MaterialAmount, setIq5MaterialAmount] = useState(0);

  // all total
  const [setupAllTotalTime, setSetupAllTotalTime] = useState(0);
  const [setupAllTotalAmount, setSetupAllTotalAmount] = useState(0);
  const [processingAllTotalTime, setProcessingAllTotalTime] = useState(0);
  const [processingAllTotalAmount, setProcessingAllTotalAmount] = useState(0);
  const [allTotalTime, setAllTotalTime] = useState(0);
  const [allTotalAmount, setAllTotalAmount] = useState(0);
  const [tanka_all, setTanka_all] = useState(0);
  const [allMaterialAmount, setallMaterialAmount] = useState(0);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [suryou, setSuryou] = useState(1);
  const [processCalAmount, setProcessCalAmount] = useState([]);
  const [parentCalAmount, setParentCalAmount] = useState([]);
  const [iq3CalAmount, setIQ3Amount] = useState([]);
  const [parentCalAmountTotal, setParentCalAmountTotal] = useState([]);
  const [iq3CalAmountTotal, setIQ3AmountTotal] = useState([]);
  const [iq5CalAmountTotal, setIQ5AmountTotal] = useState([]);

  // CSV出力
  const csvExportRef = useRef();
  const [fileName, setFileName] = useState(`工程別合計_${formatDateString(todayTime())}.csv`);

  useEffect(() => {
    if (props.selectedEstimateData == undefined || props.selectedEstimateData == null) return;

    if (props.selectedEstimateData !== undefined) {
      // 材料原価
      const price = props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        ? props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        : 0;

      setallMaterialAmount(price);
      const parentCalData = props.selectedEstimateData?.estimateProducts?.additions;
      let processes = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.process;
      let parentProcesses = [];
      let iq3Processes = [];
      //形鋼工程
      let iq5Processes = [];
      if (processes) {
        parentProcesses = processes?.filter((item) => item.class === ServiceClass.Parent);
        iq3Processes = processes?.filter((item) => item.class === ServiceClass.SheetMetal);
        iq5Processes = processes?.filter((item) => item.class === ServiceClass.Beams);
      }
      // 数量
      let qty = props.selectedEstimateData?.estimateProducts?.quantity
        ? Number(props.selectedEstimateData?.estimateProducts?.quantity)
        : 1;
      let parentResult = settingParentCalInfo(props.selectedEstimateData, parentProcesses, qty);

      let processIdMap = settingIQ3CalInfo(props.selectedEstimateData, qty);

      let processIdMapIq5 = settingIQ5CalInfo(props.selectedEstimateData, qty);

      // convert the mapping into an array of objects
      const result = Object.values(processIdMap)?.map((entry, key) => {
        const process = iq3Processes?.find((i) => i.id === entry.processId);
        return {
          key: key + 1,
          processNm: process ? process.name : '',
          setupAmount: entry.setupAmount,
          setupTime: entry.setupTime,
          processingAmount: entry.processingAmount,
          processingTime: entry.processingTime,
          totalAmount: entry.totalAmount,
          unitAmount: entry.unitAmount,
          totalAmountTime: entry.totalAmountTime,
          unitAmountTime: entry.unitAmountTime,
          manufacturingCnt: entry.manufacturingCnt,
          // workType: entry.workType,
          // partCount: entry.partCount,
        };
      });

      const resultIq5 = Object.values(processIdMapIq5)?.map((entry, key) => {
        const process = iq5Processes?.find((i) => i.id === entry.processId);
        return {
          key: key + 1,
          processNm: process ? process.name : '',
          setupAmount: entry.setupAmount,
          setupTime: entry.setupTime,
          processingAmount: entry.processingAmount,
          processingTime: entry.processingTime,
          totalAmount: entry.totalAmount,
          unitAmount: entry.unitAmount,
          totalAmountTime: entry.totalAmountTime,
          unitAmountTime: entry.unitAmountTime,
          manufacturingCnt: entry.manufacturingCnt,
          // workType: entry.workType,
          // partCount: entry.partCount,
        };
      });

      // calTotalIq5(parentResult, resultIq5, qty);

      setIQ3Amount(result);
      setIQ3AmountTotal(JSON.parse(JSON.stringify(result)));

      setIQ5AmountTotal(resultIq5);
      calTotal(parentResult, result, resultIq5, qty);

      
    }
  }, [props.selectedEstimateData, props.quantity]);

  const settingParentCalInfo = (selectedDataDetail, parentProcesses, quantity) => {
    let device = selectedDataDetail?.estimateProducts?.calcParameters?.parameters?.device;
    //親部品の工程積算情報
    let updateKouteiCalInfo = selectedDataDetail?.estimateProducts?.additions;
    if (updateKouteiCalInfo) {
      // 製造原価計算(段取金額＊段取個数係数+作業/加工金額*生産個数係数)
      let processPattern = selectedDataDetail?.estimateProducts?.processSelect;
      // 親部品の計算した工程データの足し算
      let parentResult = [];
      let index = 0;
      updateKouteiCalInfo?.forEach((updatedKouteiContent) => {
        let productionQuantityCoeff = 1;
        let patternInfoFilter = [];
        let curParPatternInfoFilter = processPattern
          ? processPattern?.details?.filter(
              (item) => item.processId === updatedKouteiContent.processId && item.isUsedForCalc
            )
          : [];
        if (curParPatternInfoFilter && curParPatternInfoFilter?.length > 0) {
          patternInfoFilter = curParPatternInfoFilter[0];
          let process = parentProcesses?.filter((item) => item.id === updatedKouteiContent.processId)?.[0];
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          // 段取原価合計金額計算
          // 段取金額（各工程計算で段取個数係数を参照して計算したため、ここに段取係数を再計算しない。）
          let setupCostPrice =
            updatedKouteiContent.dataType == DataType.Data
              ? updatedKouteiContent.totalDandori.dataItemsAmt
              : updatedKouteiContent.totalDandori.editItemsAmt;
          setupCostPrice = setupCostPrice ? setupCostPrice : 0;

          // 加工原価合計金額計算
          // 作業/加工金額*生産個数係数
          let workCostPrice =
            updatedKouteiContent.dataType == DataType.Data
              ? updatedKouteiContent.totalSagyo.dataItemsAmt
              : updatedKouteiContent.totalSagyo.editItemsAmt;
          workCostPrice = workCostPrice * quantity; // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい

          let totalCostPrice =
            (setupCostPrice ? Math.round(setupCostPrice) : 0) + (workCostPrice ? Math.round(workCostPrice) : 0);
          let unitCostPrice = Number.isFinite(totalCostPrice / quantity) ? totalCostPrice / quantity : 0;

          // 段取時間
          let setupCostPriceTm =
            updatedKouteiContent.dataType == DataType.Data
              ? updatedKouteiContent.totalDandori.dataItemsTime
              : updatedKouteiContent.totalDandori.editItemsTime;
          setupCostPriceTm = setupCostPriceTm ? setupCostPriceTm : 0;

          // 加工時間
          let workCostPriceTm =
            updatedKouteiContent.dataType == DataType.Data
              ? updatedKouteiContent.totalSagyo.dataItemsTime
              : updatedKouteiContent.totalSagyo.editItemsTime;
          workCostPriceTm = workCostPriceTm * quantity; // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい

          // 合計時間（段取時間＋(加工時間×数量)　）
          let totalCostPriceTm = setupCostPriceTm + workCostPriceTm;
          // 合計時間（単品）（合計時間÷数量）
          let unitCostPriceTm = Number.isFinite(totalCostPriceTm / quantity) ? totalCostPriceTm / quantity : 0;

          parentResult.push({
            key: index + 1,
            processNm: process?.name,
            setupAmount: setupCostPrice,
            setupTime: setupCostPriceTm,
            processingAmount: workCostPrice,
            processingTime: workCostPriceTm,
            totalCostAmount: totalCostPrice,
            unitCostAmount: unitCostPrice,
            totalCostAmountTime: totalCostPriceTm,
            unitCostAmountTime: unitCostPriceTm,
          });
          index++;
        }
      });
      setParentCalAmount(parentResult);
      setParentCalAmountTotal(JSON.parse(JSON.stringify(parentResult)));
      setSuryou(quantity);
      return parentResult;
    }
  };

  const settingIQ3CalInfo = (selectedDataDetail, quantity) => {
    let parameters = selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    let device = selectedDataDetail?.estimateProducts?.calcParameters?.parameters?.device;
    let processIdMap = {};
    let iq3MaterialAmt = 0;
    selectedDataDetail?.esIq3Info?.forEach((iq3) => {
      iq3MaterialAmt += iq3.materialCostTotalPrice;
      let manufacturingCnt = quantity * iq3.partCount;
      let iq3ProcessPattern = iq3?.processSelect;
      iq3.additions?.forEach((iq3UpdatedKouteiContent) => {
        let deviceMstFilter = [];
        //利用する工程パターンを取得
        let patternInfoFilter = [];
        let curPatternInfoFilter = iq3ProcessPattern
          ? iq3ProcessPattern?.details?.filter(
              (item) => item.processId == iq3UpdatedKouteiContent?.processId && item.isUsedForCalc
            )
          : [];
        if (curPatternInfoFilter && curPatternInfoFilter?.length > 0) {
          patternInfoFilter = curPatternInfoFilter[0];
          //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
          if (curPatternInfoFilter?.length > 1) {
            let patternInfoFilterByTypeId = curPatternInfoFilter?.filter(
              (item) =>
                item.multiprocesses?.[0]?.processDetailTypeId ===
                iq3UpdatedKouteiContent?.dataItems?.[0]?.processDetailTypeNo
            );
            if (patternInfoFilterByTypeId && patternInfoFilterByTypeId?.length > 0) {
              patternInfoFilter = patternInfoFilterByTypeId[0];
            }
          }
          deviceMstFilter = device
            ? device?.filter(
                (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
              )
            : [];
          //　現在の工程タイプ取得
          let curWorkType = parameters?.process?.filter((item) => item.id == iq3UpdatedKouteiContent?.processId)?.[0]
            ?.workType;
          //　段取金額
          let iq3SetupCostPrice =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
              : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;
          iq3SetupCostPrice = iq3SetupCostPrice ? iq3SetupCostPrice : 0; // NaNチェック
          //　段取時間
          let iq3SetupCostPriceTime =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalDandori.dataItemsTime
              : iq3UpdatedKouteiContent.totalDandori.editItemsTime;
          iq3SetupCostPriceTime = iq3SetupCostPriceTime ? iq3SetupCostPriceTime : 0;

          //　作業/加工金額
          let iq3WorkCostPrice =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
              : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;
          // 作業/加工金額
          iq3WorkCostPrice = iq3WorkCostPrice ? iq3WorkCostPrice : 0; // NaNチェック

          //　作業時間
          let iq3WorkCostPriceTime =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalSagyo.dataItemsTime
              : iq3UpdatedKouteiContent.totalSagyo.editItemsTime;
          // 作業時間
          iq3WorkCostPriceTime = iq3WorkCostPriceTime ? iq3WorkCostPriceTime : 0; // NaNチェック

          // プログラム工程の場合、プログラム工程の原価は子部品の員数、全体の数量に影響されないため、分けて計算
          if (curWorkType == WorkType.SmProgram) {
            //　プログラム工程の合計の場合、そのまま、製造数と掛けない
            iq3WorkCostPrice = iq3WorkCostPrice;
          } else if (curWorkType == WorkType.SmShearing) {
            // シャーリングOne by one項目チェックを取得
            let oneByoneCheck = deviceMstFilter[0]?.details?.isOneCut;
            if (oneByoneCheck === false) {
              //　One by one項目チェックがOFFの場合、そのまま、製造数と掛けない
              iq3WorkCostPrice = iq3WorkCostPrice;
            } else {
              //　One by one項目チェックがONの場合、製造数と掛ける
              iq3WorkCostPrice = iq3WorkCostPrice * manufacturingCnt;
              iq3WorkCostPriceTime = iq3WorkCostPriceTime * manufacturingCnt;
            }
          } else {
            // 加工時間×製造数
            iq3WorkCostPrice = iq3WorkCostPrice * manufacturingCnt;
            iq3WorkCostPriceTime = iq3WorkCostPriceTime * manufacturingCnt;
          }
          //　合計金額
          let totalCostPrice =
            (iq3SetupCostPrice ? Math.round(iq3SetupCostPrice) : 0) +
            (iq3WorkCostPrice ? Math.round(iq3WorkCostPrice) : 0);
          let unitCostPrice = Number.isFinite(totalCostPrice / quantity) ? totalCostPrice / quantity : 0;
          //　合計時間
          let totalCostPriceTm = iq3SetupCostPriceTime + iq3WorkCostPriceTime;
          let unitCostPriceTm = Number.isFinite(totalCostPriceTm / quantity) ? totalCostPriceTm / quantity : 0;

          const { processId, dataType, totalDandori, totalSagyo } = iq3UpdatedKouteiContent;
          if (!processIdMap[processId]) {
            processIdMap[processId] = {
              processId,
              setupAmount: 0,
              setupTime: 0,
              processingAmount: 0,
              processingTime: 0,
              totalAmount: 0,
              unitAmount: 0,
              totalAmountTime: 0,
              unitAmountTime: 0,
              manufacturingCnt: manufacturingCnt,
            };
          }
          processIdMap[processId].setupAmount += iq3SetupCostPrice;
          processIdMap[processId].processingAmount += iq3WorkCostPrice;
          processIdMap[processId].setupTime += iq3SetupCostPriceTime;
          processIdMap[processId].processingTime += iq3WorkCostPriceTime;
          processIdMap[processId].totalAmount += totalCostPrice;
          processIdMap[processId].unitAmount += unitCostPrice;
          processIdMap[processId].totalAmountTime += totalCostPriceTm;
          processIdMap[processId].unitAmountTime += unitCostPriceTm;
        }
      });
    });
    setIq3MaterialAmount(iq3MaterialAmt);
    return processIdMap;
  };

  const settingIQ5CalInfo = (selectedDataDetail, quantity) => {
    let parameters = selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    let device = selectedDataDetail?.estimateProducts?.calcParameters?.parameters?.device;
    let processIdMap = {};

    let iq5MaterialAmt = 0;

    selectedDataDetail?.esIq5Info?.forEach((iq5) => {
      
      iq5MaterialAmt += iq5.materialCostTotalPrice;
      let manufacturingCnt = quantity * iq5.partCount;
      let iq5ProcessPattern = iq5?.processSelect;
      iq5.additions?.forEach((iq5UpdatedKouteiContent) => {
        let deviceMstFilter = [];
        //利用する工程パターンを取得
        let patternInfoFilter = [];
        let curPatternInfoFilter = iq5ProcessPattern
          ? iq5ProcessPattern?.details?.filter(
              (item) => item.processId == iq5UpdatedKouteiContent?.processId && item.isUsedForCalc
            )
          : [];
          if (curPatternInfoFilter && curPatternInfoFilter?.length > 0) {
            patternInfoFilter = curPatternInfoFilter[0];
            //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
            if (curPatternInfoFilter?.length > 1) {
              let patternInfoFilterByTypeId = curPatternInfoFilter?.filter(
                (item) =>
                  item.multiprocesses?.[0]?.processDetailTypeId ===
                  iq5UpdatedKouteiContent?.dataItems?.[0]?.processDetailTypeNo
              );
              if (patternInfoFilterByTypeId && patternInfoFilterByTypeId?.length > 0) {
                patternInfoFilter = patternInfoFilterByTypeId[0];
              }
            }
            deviceMstFilter = device
            ? device?.filter(
                (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
              )
            : [];

            //　現在の工程タイプ取得
            let curWorkType = parameters?.process?.filter((item) => item.id == iq5UpdatedKouteiContent?.processId)?.[0]
            ?.workType;

            //　段取金額
            let iq5SetupCostPrice =
            iq5UpdatedKouteiContent.dataType === DataType.Data
              ? iq5UpdatedKouteiContent?.dataItems?.totalDandoriAmt
              : iq5UpdatedKouteiContent?.editItems?.totalDandoriAmt;
            iq5SetupCostPrice = iq5SetupCostPrice ? iq5SetupCostPrice : 0; // NaNチェック
            //　段取時間
            let iq5SetupCostPriceTime =
              iq5UpdatedKouteiContent.dataType === DataType.Data
                ? iq5UpdatedKouteiContent?.dataItems?.totalDandoriTime
                : iq5UpdatedKouteiContent?.editItems?.totalDandoriTime;
            iq5SetupCostPriceTime = iq5SetupCostPriceTime ? iq5SetupCostPriceTime : 0;

            //　作業/加工金額
            let iq5WorkCostPrice =
              iq5UpdatedKouteiContent.dataType === DataType.Data
                ? iq5UpdatedKouteiContent?.totalDataPrice - iq5UpdatedKouteiContent?.dataItems?.totalDandoriAmt
                : iq5UpdatedKouteiContent?.totalEditPrice - iq5UpdatedKouteiContent?.editItems?.totalDandoriAmt;
            // 作業/加工金額
            iq5WorkCostPrice = iq5WorkCostPrice ? iq5WorkCostPrice : 0; // NaNチェック

            //　作業時間
            let iq5WorkCostPriceTime =
              iq5UpdatedKouteiContent.dataType === DataType.Data
                ? iq5UpdatedKouteiContent?.totalDataTime - iq5UpdatedKouteiContent?.dataItems?.totalDandoriTime
                : iq5UpdatedKouteiContent?.totalEditTime - iq5UpdatedKouteiContent?.editItems?.totalDandoriTime;
            // 作業時間
            iq5WorkCostPriceTime = iq5WorkCostPriceTime ? iq5WorkCostPriceTime : 0; // NaNチェック

            //　合計金額
            // let totalCostPrice =
            // (iq5SetupCostPrice ? Math.round(iq5SetupCostPrice) : 0) +
            // (iq5WorkCostPrice ? Math.round(iq5WorkCostPrice) : 0);
            let totalCostPrice = iq5UpdatedKouteiContent.dataType === DataType.Data
            ? iq5UpdatedKouteiContent?.totalDataPrice
            : iq5UpdatedKouteiContent?.totalEditPrice;
            let unitCostPrice = Number.isFinite(totalCostPrice / quantity) ? totalCostPrice / quantity : 0;

            //　合計時間
            // let totalCostPriceTm = iq5SetupCostPriceTime + iq5WorkCostPriceTime;
            let totalCostPriceTm = iq5UpdatedKouteiContent.dataType === DataType.Data
            ? iq5UpdatedKouteiContent?.totalDataTime
            : iq5UpdatedKouteiContent?.totalEditTime;
            let unitCostPriceTm = Number.isFinite(totalCostPriceTm / quantity) ? totalCostPriceTm / quantity : 0;

            const { processId, dataType, totalDandori, totalSagyo } = iq5UpdatedKouteiContent;
            if (!processIdMap[processId]) {
              processIdMap[processId] = {
                processId,
                setupAmount: 0,
                setupTime: 0,
                processingAmount: 0,
                processingTime: 0,
                totalAmount: 0,
                unitAmount: 0,
                totalAmountTime: 0,
                unitAmountTime: 0,
                manufacturingCnt: manufacturingCnt,
              };
            }
            processIdMap[processId].setupAmount += iq5SetupCostPrice;
            processIdMap[processId].processingAmount += iq5WorkCostPrice;
            processIdMap[processId].setupTime += iq5SetupCostPriceTime;
            processIdMap[processId].processingTime += iq5WorkCostPriceTime;
            processIdMap[processId].totalAmount += totalCostPrice;
            processIdMap[processId].unitAmount += unitCostPrice;
            processIdMap[processId].totalAmountTime += totalCostPriceTm;
            processIdMap[processId].unitAmountTime += unitCostPriceTm;
          }
      })
    });
    setIq5MaterialAmount(iq5MaterialAmt);
    return processIdMap;
  };

  const calTotal = (parentData, iq3Data, iq5Data, suryou) => {
    // parent
    setParentProcess(parentData);
    let setupAmount = 0;
    let processingAmount = 0;
    let setupTime = 0;
    let processingTime = 0;
    let totalTime = 0;
    let totalAmount = 0;
    let tanka = 0;
    if (parentData != null) {
      for (let i = 0; i < parentData?.length; i++) {
        setupAmount += parentData[i].setupAmount;
        processingAmount += parentData[i].processingAmount;
        totalAmount += parentData[i].totalCostAmount;
        tanka += parentData[i].unitCostAmount;
        setupTime += parentData[i].setupTime;
        processingTime += parentData[i].processingTime;
        totalTime += parentData[i].totalCostAmountTime;
      }
      setSetupTotalAmount(setupAmount);
      setProcessingTotalAmount(processingAmount);
      setTotalAmount(totalAmount);
      setTanka(tanka);
      setSetupTotalTime(setupTime);
      setProcessingTotalTime(processingTime);
      setTotalTime(totalTime);
    }

    // iq3
    let setupAmount_IQ3 = 0;
    let processingAmount_IQ3 = 0;
    let setupTime_IQ3 = 0;
    let processingTime_IQ3 = 0;
    let totalTime_IQ3 = 0;
    let totalAmount_IQ3 = 0;
    let unitAmount_IQ3 = 0;
    let tanka_IQ3 = 0;
    if (iq3Data != null) {
      for (let i = 0; i < iq3Data?.length; i++) {
        setupAmount_IQ3 += iq3Data[i].setupAmount;
        processingAmount_IQ3 += iq3Data[i].processingAmount;
        totalAmount_IQ3 += iq3Data[i].totalAmount;
        tanka_IQ3 += iq3Data[i].unitAmount;
        setupTime_IQ3 += iq3Data[i].setupTime;
        processingTime_IQ3 += iq3Data[i].processingTime;
        totalTime_IQ3 += iq3Data[i].totalAmountTime;
      }

      setSetupTotalAmount_IQ3(setupAmount_IQ3);
      setProcessingTotalAmount_IQ3(processingAmount_IQ3);
      setTotalAmount_IQ3(totalAmount_IQ3);
      setTanka_IQ3(tanka_IQ3);
      setSetupTotalTime_IQ3(setupTime_IQ3);
      setProcessingTotalTime_IQ3(processingTime_IQ3);
      setTotalTime_IQ3(totalTime_IQ3);
      // setIq3MaterialAmount(targetParent.iq3MaterialAmount);
      setIq3Process(iq3Data);

      // 材料原価
      const price = props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        ? props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        : 0;
      setallMaterialAmount(price);
    }



    // iq5
    let setupAmount_IQ5 = 0;
    let processingAmount_IQ5 = 0;
    let setupTime_IQ5 = 0;
    let processingTime_IQ5 = 0;
    let totalTime_IQ5 = 0;
    let totalAmount_IQ5 = 0;
    let tanka_IQ5 = 0;
      if (iq5Data != null) {
      for (let i = 0; i < iq5Data?.length; i++) {
        setupAmount_IQ5 += iq5Data[i].setupAmount;
        processingAmount_IQ5 += iq5Data[i].processingAmount;
        totalAmount_IQ5 += iq5Data[i].totalAmount;
        tanka_IQ5 += iq5Data[i].unitAmount;
        setupTime_IQ5 += iq5Data[i].setupTime;
        processingTime_IQ5 += iq5Data[i].processingTime;
        totalTime_IQ5 += iq5Data[i].totalAmountTime;
      }

      setSetupTotalAmount_IQ5(setupAmount_IQ5);
      setProcessingTotalAmount_IQ5(processingAmount_IQ5);
      setTotalAmount_IQ5(totalAmount_IQ5);
      setTanka_IQ5(tanka_IQ5);
      setSetupTotalTime_IQ5(setupTime_IQ5);
      setProcessingTotalTime_IQ5(processingTime_IQ5);
      setTotalTime_IQ5(totalTime_IQ5);
      // setIq3MaterialAmount(targetParent.iq3MaterialAmount);
      setIq5Process(iq5Data);

      // 材料原価
      const price = props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        ? props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        : 0;
      setallMaterialAmount(price);
    }
    let totalAmount_all = totalAmount + totalAmount_IQ3 + totalAmount_IQ5;
    let tanka_all = tanka + tanka_IQ3 + tanka_IQ5;
    setSetupAllTotalAmount(setupAmount + setupAmount_IQ3 + setupAmount_IQ5);
    setSetupAllTotalTime(setupTime + setupTime_IQ3 + setupTime_IQ5);
    setProcessingAllTotalAmount(processingAmount + processingAmount_IQ3 + processingAmount_IQ5);
    setProcessingAllTotalTime(processingTime + processingTime_IQ3 + processingTime_IQ5);
    setAllTotalTime(setupTime + setupTime_IQ3 + setupTime_IQ5 + processingTime + processingTime_IQ3 + processingTime_IQ5);
    setAllTotalAmount(totalAmount_all);
    setTanka_all(tanka_all);
  };

  const calUnit = (parentData, iq3Data, iq5Data, suryou) => {
    // parent
    let setupAmount = 0;
    let processingAmount = 0;
    let setupTime = 0;
    let processingTime = 0;
    let totalTime = 0;
    let totalAmount = 0;
    if (parentData != null) {
      for (let i = 0; i < parentData?.length; i++) {
        parentData[i].setupAmount = Number.isFinite(parentData[i].setupAmount / suryou)
          ? parentData[i].setupAmount / suryou
          : 0;
        setupAmount += parentData[i].setupAmount;

        parentData[i].processingAmount = Number.isFinite(parentData[i].processingAmount / suryou)
          ? parentData[i].processingAmount / suryou
          : 0;
        processingAmount += parentData[i].processingAmount;

        let unitAmountPar = Number.isFinite(parentData[i].totalCostAmount / suryou)
          ? parentData[i].totalCostAmount / suryou
          : 0;
        parentData[i].totalCostAmount = unitAmountPar;
        totalAmount += unitAmountPar;

        let setupPerSec = Number.isFinite(parentData[i].setupTime / suryou) ? parentData[i].setupTime / suryou : 0;
        // let setupPerSec = setTimetoSec(parentData[i].setupTime) / suryou;
        parentData[i].setupTime = setupPerSec;
        setupTime += setupPerSec;

        let processingPerSec = Number.isFinite(parentData[i].processingTime / suryou)
          ? parentData[i].processingTime / suryou
          : 0;
        // let processingPerSec = setTimetoSec(parentData[i].processingTime) / suryou;
        parentData[i].processingTime = processingPerSec;
        processingTime += processingPerSec;

        let unitAmountTimePar = Number.isFinite(parentData[i].totalCostAmountTime / suryou)
          ? parentData[i].totalCostAmountTime / suryou
          : 0;
        parentData[i].totalCostAmountTime = unitAmountTimePar;
        totalTime += unitAmountTimePar;
      }

      setSetupTotalAmount(setupAmount);
      setProcessingTotalAmount(processingAmount);
      setTotalAmount(totalAmount);
      setSetupTotalTime(setupTime);
      setProcessingTotalTime(processingTime);
      setTotalTime(totalTime);
      setParentProcess(parentData);

      // 材料原価
      const price = props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        ? props.selectedEstimateData?.estimateProducts?.materialCostTotalPrice
        : 0;
      let totalMaterialAmount = Number.isFinite(price / suryou) ? price / suryou : 0;
      setallMaterialAmount(totalMaterialAmount);
    }

    // iq3
    let setupAmount_IQ3 = 0;
    let processingAmount_IQ3 = 0;
    let setupTime_IQ3 = 0;
    let processingTime_IQ3 = 0;
    let totalTime_IQ3 = 0;
    let totalAmount_IQ3 = 0;
    if (iq3Data != null) {
      for (let i = 0; i < iq3Data?.length; i++) {
        let unitAmount = Number.isFinite(iq3Data[i].totalAmount / suryou) ? iq3Data[i].totalAmount / suryou : 0;
        totalAmount_IQ3 += unitAmount;
        iq3Data[i].totalAmount = unitAmount;

        iq3Data[i].setupAmount = Number.isFinite(iq3Data[i].setupAmount / suryou) ? iq3Data[i].setupAmount / suryou : 0;
        setupAmount_IQ3 += iq3Data[i].setupAmount;

        iq3Data[i].processingAmount = Number.isFinite(iq3Data[i].processingAmount / suryou)
          ? iq3Data[i].processingAmount / suryou
          : 0;
        processingAmount_IQ3 += iq3Data[i].processingAmount;

        let setupIQ3PerSec = Number.isFinite(iq3Data[i].setupTime / suryou) ? iq3Data[i].setupTime / suryou : 0;
        iq3Data[i].setupTime = setupIQ3PerSec;
        setupTime_IQ3 += setupIQ3PerSec;

        let processingIQ3PerSec = Number.isFinite(iq3Data[i].processingTime / suryou)
          ? iq3Data[i].processingTime / suryou
          : 0;
        iq3Data[i].processingTime = processingIQ3PerSec;
        processingTime_IQ3 += processingIQ3PerSec;

        let unitAmountTime = Number.isFinite(iq3Data[i].totalAmountTime / suryou)
          ? iq3Data[i].totalAmountTime / suryou
          : 0;
        totalTime_IQ3 += unitAmountTime;
        iq3Data[i].totalAmountTime = unitAmountTime;
      }

      setSetupTotalAmount_IQ3(setupAmount_IQ3);
      setProcessingTotalAmount_IQ3(processingAmount_IQ3);
      setTotalAmount_IQ3(totalAmount_IQ3);
      setSetupTotalTime_IQ3(setupTime_IQ3);
      setProcessingTotalTime_IQ3(processingTime_IQ3);
      setTotalTime_IQ3(totalTime_IQ3);
      setIq3Process(iq3Data);

      
    }

    // iq5
    let setupAmount_IQ5 = 0;
    let processingAmount_IQ5 = 0;
    let setupTime_IQ5 = 0;
    let processingTime_IQ5 = 0;
    let totalTime_IQ5 = 0;
    let totalAmount_IQ5 = 0;
    if (iq5Data != null) {
      for (let i = 0; i < iq5Data?.length; i++) {
        let unitAmount = Number.isFinite(iq5Data[i].totalAmount / suryou) ? iq5Data[i].totalAmount / suryou : 0;
        totalAmount_IQ5 += unitAmount;
        iq5Data[i].totalAmount = unitAmount;

        iq5Data[i].setupAmount = Number.isFinite(iq5Data[i].setupAmount / suryou) ? iq5Data[i].setupAmount / suryou : 0;
        setupAmount_IQ5 += iq5Data[i].setupAmount;

        iq5Data[i].processingAmount = Number.isFinite(iq5Data[i].processingAmount / suryou)
          ? iq5Data[i].processingAmount / suryou
          : 0;
        processingAmount_IQ5 += iq5Data[i].processingAmount;

        let setupIQ5PerSec = Number.isFinite(iq5Data[i].setupTime / suryou) ? iq5Data[i].setupTime / suryou : 0;
        iq5Data[i].setupTime = setupIQ5PerSec;
        setupTime_IQ5 += setupIQ5PerSec;

        let processingIQ5PerSec = Number.isFinite(iq5Data[i].processingTime / suryou)
          ? iq5Data[i].processingTime / suryou
          : 0;
        iq5Data[i].processingTime = processingIQ5PerSec;
        processingTime_IQ5 += processingIQ5PerSec;

        let unitAmountTime = Number.isFinite(iq5Data[i].totalAmountTime / suryou)
          ? iq5Data[i].totalAmountTime / suryou
          : 0;
        totalTime_IQ5 += unitAmountTime;
        iq5Data[i].totalAmountTime = unitAmountTime;
      }

      setSetupTotalAmount_IQ5(setupAmount_IQ5);
      setProcessingTotalAmount_IQ5(processingAmount_IQ5);
      setTotalAmount_IQ5(totalAmount_IQ5);
      setSetupTotalTime_IQ5(setupTime_IQ5);
      setProcessingTotalTime_IQ5(processingTime_IQ5);
      setTotalTime_IQ5(totalTime_IQ5);
      setIq5Process(iq5Data);

    }

    // All total
    let totalAmount_all = setupAmount + setupAmount_IQ3 + setupAmount_IQ5 + processingAmount + processingAmount_IQ3 + processingAmount_IQ5;
    setSetupAllTotalAmount(setupAmount + setupAmount_IQ3 + setupAmount_IQ5);
    setSetupAllTotalTime(setupTime + setupTime_IQ3 + setupTime_IQ5);
    setProcessingAllTotalAmount(processingAmount + processingAmount_IQ3 + processingAmount_IQ5);
    setProcessingAllTotalTime(processingTime + processingTime_IQ3 + processingTime_IQ5);
    setAllTotalTime(setupTime + setupTime_IQ3 + setupTime_IQ5 + processingTime + processingTime_IQ3 + processingTime_IQ5);
    setAllTotalAmount(totalAmount_all);
    //setShowProcessTotalAmount(true);
  };

  const partMenuChange = (e) => {
    setActivePartMenu(e.target.value);
    let orgParentCalAmount = JSON.parse(JSON.stringify(parentCalAmountTotal));
    let orgIQ3CalAmount = JSON.parse(JSON.stringify(iq3CalAmountTotal));
    let orgIQ5CalAmount = JSON.parse(JSON.stringify(iq5CalAmountTotal));
    if (e.target.value === 'unit') {
      calUnit(orgParentCalAmount, orgIQ3CalAmount, orgIQ5CalAmount, suryou);
    } else {
      calTotal(orgParentCalAmount, orgIQ3CalAmount, orgIQ5CalAmount, suryou);
    }
  };

  // 開発中ポップアップ呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  const showDetail = (e, type) => {
    if (type == 'parent') {
      setShowDetailRow(false);
    } else if (type == 'iq3') {
      setShowDetailRowIQ3(false);
    } else if (type == 'iq5') {
      setShowDetailRowIQ5(false);
    } else if (type == 'iq7') {
      setShowDetailRowIQ7(false);
    }
  };

  const showHide = (e, type) => {
    if (type === 'parent') {
      setShowDetailRow(true);
    } else if (type === 'iq3') {
      setShowDetailRowIQ3(true);
    } else if (type === 'iq5') {
      setShowDetailRowIQ5(true);
    } else if (type === 'iq7') {
      setShowDetailRowIQ7(true);
    }
  };
  const confirmCancel = () => {
    props.closeProcessTotalAmountDialog();
  };

  // CSV出力データ準備
  const kouteibetsuTbl1 = [
    ['No', '工程', '段取時間', '段取原価', '加工時間', '加工原価', '合計時間', '合計金額', '単価'],
    [
      '',
      '合計',
      secondsToHms(setupAllTotalTime),
      JPYs.format(Math.round(setupAllTotalAmount)),
      secondsToHms(processingAllTotalTime),
      JPYs.format(Math.round(processingAllTotalAmount)),
      secondsToHms(allTotalTime),
      JPYs.format(Math.round(allTotalAmount)),
      JPYs.format(Math.round(tanka_all)),
    ],
  ];

  const table2Headers = ['No', '工程', '段取時間', '段取原価', '加工時間', '加工原価', '合計時間', '合計金額', '単価'];
  if (activePartMenu === 'unit') {
    kouteibetsuTbl1?.forEach((row) => row.pop()); // remove the last element
  }
  const parentTotal = [
    '',
    '合計',
    secondsToHms(setupTotalTime),
    JPYs.format(Math.round(setupTotalAmount)),
    secondsToHms(processingTotalTime),
    JPYs.format(Math.round(processingTotalAmount)),
    secondsToHms(totalTime),
    JPYs.format(Math.round(totalAmount)),
    JPYs.format(Math.round(tanka)),
  ];
  const parentTotalNoUnit = parentTotal.slice(0, parentTotal?.length - 1);
  const iq3Total = [
    '',
    '合計',
    secondsToHms(setupTotalTime_IQ3),
    JPYs.format(Math.round(setupTotalAmount_IQ3)),
    secondsToHms(processingTotalTime_IQ3),
    JPYs.format(Math.round(processingTotalAmount_IQ3)),
    secondsToHms(totalTime_IQ3),
    JPYs.format(Math.round(totalAmount_IQ3)),
    JPYs.format(Math.round(tanka_IQ3)),
  ];
  const iq3TotalNoUnit = iq3Total.slice(0, iq3Total?.length - 1);
  const iq5Total = [
    '',
    '合計',
    secondsToHms(setupTotalTime_IQ5),
    JPYs.format(Math.round(setupTotalAmount_IQ5)),
    secondsToHms(processingTotalTime_IQ5),
    JPYs.format(Math.round(processingTotalAmount_IQ5)),
    secondsToHms(totalTime_IQ5),
    JPYs.format(Math.round(totalAmount_IQ5)),
    JPYs.format(Math.round(tanka_IQ5)),
  ];
  const iq5TotalNoUnit = iq5Total.slice(0, iq5Total?.length - 1);
  const allData = [
    ...kouteibetsuTbl1,
    [
      '',
      '',
      '',
      '',
      '',
      '',
      '材料原価',
      JPYs.format(Math.round(allMaterialAmount)),
      activePartMenu === 'total' ? JPYs.format(Math.round(allMaterialAmount / suryou)) : '',
    ],
    ['親部品工程'],
    activePartMenu === 'unit' ? table2Headers?.filter((header) => header !== '単価') : table2Headers,

    ...parentProcess?.map((item) => {
      const rowData = [
        item.key, // No
        item.processNm, // 工程
        secondsToHms(item.setupTime), // 段取時間
        JPYs.format(Math.round(item.setupAmount)), // 段取原価
        secondsToHms(item.processingTime), // 加工時間
        JPYs.format(Math.round(item.processingAmount)), // 段取原価

        secondsToHms(item.setupTime + item.processingTime),
        JPYs.format(Math.round(item.setupAmount) + Math.round(item.processingAmount)),
      ];

      if (activePartMenu === 'total') {
        rowData.push(JPYs.format((Math.round(item.setupAmount) + Math.round(item.processingAmount)) / suryou));
      }

      return rowData;
    }),
    activePartMenu === 'unit' ? parentTotalNoUnit : parentTotal,
    ['板金子部品工程'],
    activePartMenu === 'unit' ? table2Headers?.filter((header) => header !== '単価') : table2Headers,
    ...iq3Process?.map((item) => {
      const temp = [
        item.key, // No
        item.processNm, // 工程
        secondsToHms(item.setupTime), // 段取時間
        JPYs.format(Math.round(item.setupAmount)), // 段取原価
        secondsToHms(item.processingTime), // 加工時間
        JPYs.format(Math.round(item.processingAmount)), // 段取原価

        secondsToHms(item.setupTime + item.processingTime),
        JPYs.format(Math.round(item.setupAmount) + Math.round(item.processingAmount)),
      ];

      if (activePartMenu === 'total') {
        temp.push(JPYs.format((Math.round(item.setupAmount) + Math.round(item.processingAmount)) / suryou));
      }
      return temp;
    }),

    activePartMenu === 'unit' ? parentTotalNoUnit : parentTotal,
    ['形鋼子部品工程'],
    activePartMenu === 'unit' ? table2Headers?.filter((header) => header !== '単価') : table2Headers,
    ...iq5Process?.map((item) => {
      const temp = [
        item.key, // No
        item.processNm, // 工程
        secondsToHms(item.setupTime), // 段取時間
        JPYs.format(Math.round(item.setupAmount)), // 段取原価
        secondsToHms(item.processingTime), // 加工時間
        JPYs.format(Math.round(item.processingAmount)), // 段取原価

        secondsToHms(item.setupTime + item.processingTime),
        JPYs.format(Math.round(item.setupAmount) + Math.round(item.processingAmount)),
      ];

      if (activePartMenu === 'total') {
        temp.push(JPYs.format((Math.round(item.setupAmount) + Math.round(item.processingAmount)) / suryou));
      }
      return temp;
    }),

    activePartMenu === 'unit' ? iq5TotalNoUnit : iq5Total,
    [
      '',
      '',
      '',
      '',
      '',
      '',
      '材料原価',
      JPYs.format(Math.round(allMaterialAmount)),
      activePartMenu === 'total' ? JPYs.format(Math.round(allMaterialAmount / suryou)) : '',
    ],
  ];

  const exportCSVData = () => {
    setFileName(`工程別合計_${formatDateString(todayTime())}.csv`);

    csvExportRef.current.link.click();
  };
  /* 工程別合計コンポーネント開始 */
  const tblTile = (
    <>
      <Row className="estimate-tab2-title-row" style={{ marginTop: 25 }}>
        <Col span={1} className="estimate-tab2-title-col">
          No.
        </Col>
        <Col span={4} className="estimate-tab2-title-col">
          工程
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          段取時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          段取原価
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          加工時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          加工原価
        </Col>
        <Col span={2} className="estimate-tab2-title-col" style={{ marginLeft: 5 }}>
          合計時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          合計金額
        </Col>
        <Col span={3} className="estimate-tab2-title-col" style={{ display: activePartMenu === 'unit' ? 'none' : '' }}>
          単価
        </Col>
      </Row>
    </>
  );
  const totalRow = (
    <>
      {tblTile}
      <Row className="estimate-tab2-row">
        <Col span={1} className="estimate-tab2-col-total"></Col>
        <Col span={4} className="estimate-tab2-col-total" style={{ textAlign: 'center', fontWeight: 'bold' }}>
          合計
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {setupAllTotalTime ? secondsToHms(setupAllTotalTime) : timeHmsStr}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {setupAllTotalAmount !== undefined ? JPYs.format(Math.round(setupAllTotalAmount)) : amtEmptyStr}
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {processingAllTotalTime ? secondsToHms(processingAllTotalTime) : timeHmsStr}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {processingAllTotalAmount !== undefined ? JPYs.format(Math.round(processingAllTotalAmount)) : amtEmptyStr}
        </Col>
        <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main" style={{ marginLeft: 5 }}>
          {allTotalTime ? secondsToHms(allTotalTime) : timeHmsStr}
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {allTotalAmount !== undefined ? JPYs.format(Math.round(allTotalAmount)) : '¥'}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
        >
          {tanka_all !== undefined ? JPYs.format(Math.round(tanka_all)) : amtEmptyStr}
        </Col>
      </Row>
      <Row className="estimate-tab2-row" style={{ marginTop: '0px' }}>
        <Col span={15}></Col>
        <Col
          span={2}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ textAlign: 'right', paddingRight: '25px', fontWeight: 'bold', marginLeft: 5 }}
        >
          材料原価
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {allMaterialAmount !== undefined ? JPYs.format(Math.round(allMaterialAmount)) : amtEmptyStr}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
        >
          {allMaterialAmount !== undefined ? JPYs.format(Math.round(allMaterialAmount / suryou)) : amtEmptyStr}
        </Col>
      </Row>
    </>
  );

  let parentItemComponent = [];
  parentProcess != null
    ? parentProcess?.forEach((item, index) => {
        parentItemComponent.push(
          <Row className="estimate-tab2-row">
            <Col span={1} className="estimate-tab2-col">
              {item.key}
            </Col>
            <Col span={4} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
              {item.processNm}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.setupTime ? secondsToHms(item.setupTime) : timeHmsStr}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.setupAmount !== undefined ? JPYs.format(Math.round(item.setupAmount)) : amtEmptyStr}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.processingTime ? secondsToHms(item.processingTime) : timeHmsStr}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.processingAmount !== undefined ? JPYs.format(Math.round(item.processingAmount)) : amtEmptyStr}
            </Col>
            <Col span={2} className="estimate-tab2-col estimate-tab2-col-total" style={{ marginLeft: 5 }}>
              {/* {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))} */}
              {item.setupTime !== undefined && item.processingTime !== undefined
                ? secondsToHms(item.setupTime + item.processingTime)
                : timeHmsStr}
            </Col>
            <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
              {item.totalCostAmount !== undefined ? JPYs.format(Math.round(item.totalCostAmount)) : amtEmptyStr}
            </Col>
            <Col
              span={3}
              className="estimate-tab2-col estimate-tab2-col-total"
              style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
            >
              {item.unitCostAmount !== undefined ? JPYs.format(Math.round(item.unitCostAmount)) : amtEmptyStr}
            </Col>
          </Row>
        );
      })
    : '';
  const parentTBL = (
    <>
      <div>
        {tblTile}
        {showDetailRow ? parentItemComponent : ''}
        <Row className="estimate-tab2-row" style={{ marginTop: 5 }}>
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={4} className="estimate-tab2-col-total" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {setupTotalTime ? secondsToHms(setupTotalTime) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount !== undefined ? JPYs.format(Math.round(setupTotalAmount)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {processingTotalTime ? secondsToHms(processingTotalTime) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount !== undefined ? JPYs.format(Math.round(processingTotalAmount)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main" style={{ marginLeft: 5 }}>
            {totalTime ? secondsToHms(totalTime) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount !== undefined ? JPYs.format(Math.round(totalAmount)) : amtEmptyStr}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {tanka !== undefined ? JPYs.format(Math.round(tanka)) : amtEmptyStr}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq3工程 */
  let iq3ItemComponent = [];
  iq3Process != null
    ? iq3Process?.forEach((item, index) => {
        iq3ItemComponent.push(
          <Row className="estimate-tab2-row">
            <Col span={1} className="estimate-tab2-col">
              {item.key}
            </Col>
            <Col span={4} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
              {item.processNm}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.setupTime ? secondsToHms(item.setupTime) : timeHmsStr}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.setupAmount !== undefined ? JPYs.format(Math.round(item.setupAmount)) : amtEmptyStr}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {item.processingTime ? secondsToHms(item.processingTime) : timeHmsStr}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.processingAmount !== undefined ? JPYs.format(Math.round(item.processingAmount)) : amtEmptyStr}
            </Col>
            <Col span={2} className="estimate-tab2-col estimate-tab2-col-total" style={{ marginLeft: 5 }}>
              {item.setupTime !== undefined && item.processingTime !== undefined
                ? secondsToHms(item.setupTime + item.processingTime)
                : timeHmsStr}
            </Col>
            <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
              {/* {item.setupAmount !== undefined && item.processingAmount
                ? JPYs.format(Math.round(item.setupAmount + item.processingAmount))
                : amtEmptyStr} */}
              {item.totalAmount !== undefined ? JPYs.format(Math.round(item.totalAmount)) : amtEmptyStr}
            </Col>
            <Col
              span={3}
              className="estimate-tab2-col estimate-tab2-col-total"
              style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
            >
              {/* {item.setupAmount !== undefined
                ? JPYs.format(Math.round(item.setupAmount + item.processingAmount) / suryou)
                : amtEmptyStr} */}
              {item.unitAmount !== undefined ? JPYs.format(Math.round(item.unitAmount)) : amtEmptyStr}
            </Col>
          </Row>
        );
      })
    : '';
  const iq3TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ3 ? iq3ItemComponent : ''}
        <Row className="estimate-tab2-row" style={{ marginTop: 5 }}>
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={4} className="estimate-tab2-col-total" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {setupTotalTime_IQ3 ? secondsToHms(setupTotalTime_IQ3) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount_IQ3 !== undefined ? JPYs.format(Math.round(setupTotalAmount_IQ3)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {processingTotalTime_IQ3 ? secondsToHms(processingTotalTime_IQ3) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount_IQ3 !== undefined ? JPYs.format(Math.round(processingTotalAmount_IQ3)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main" style={{ marginLeft: 5 }}>
            {totalTime_IQ3 ? secondsToHms(totalTime_IQ3) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount_IQ3 !== undefined ? JPYs.format(Math.round(totalAmount_IQ3)) : amtEmptyStr}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {tanka_IQ3 !== undefined ? JPYs.format(Math.round(tanka_IQ3)) : amtEmptyStr}
          </Col>
        </Row>
        <Row className="estimate-tab2-row" style={{ marginTop: '0px' }}>
          <Col span={15}></Col>
          <Col
            span={2}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ textAlign: 'right', paddingRight: '25px', fontWeight: 'bold', marginLeft: 5 }}
          >
            材料原価
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {iq3MaterialAmount !== undefined ? activePartMenu === 'unit' ? JPYs.format(Math.round(iq3MaterialAmount) / suryou) : JPYs.format(Math.round(iq3MaterialAmount)) : amtEmptyStr}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {iq3MaterialAmount !== undefined ? JPYs.format(Math.round(iq3MaterialAmount) / suryou) : amtEmptyStr}
          </Col>
        </Row>
      </div>
    </>
  );

  let iq5ItemComponent = [];

  iq5Process != null
  ? iq5Process?.forEach((item, index) => {
      iq5ItemComponent.push(
        <Row className="estimate-tab2-row">
          <Col span={1} className="estimate-tab2-col">
            {item.key}
          </Col>
          <Col span={4} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
            {item.processNm}
          </Col>
          <Col span={2} className="estimate-tab2-col">
            {item.setupTime ? secondsToHms(item.setupTime) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col">
            {item.setupAmount !== undefined ? JPYs.format(Math.round(item.setupAmount)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col">
            {item.processingTime ? secondsToHms(item.processingTime) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col">
            {item.processingAmount !== undefined ? JPYs.format(Math.round(item.processingAmount)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col estimate-tab2-col-total" style={{ marginLeft: 5 }}>
            {item.setupTime !== undefined && item.processingTime !== undefined
              ? secondsToHms(item.setupTime + item.processingTime)
              : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
            {item.totalAmount !== undefined ? JPYs.format(Math.round(item.totalAmount)) : amtEmptyStr}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col estimate-tab2-col-total"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {item.unitAmount !== undefined ? JPYs.format(Math.round(item.unitAmount)) : amtEmptyStr}
          </Col>
        </Row>
      );
    })
  : '';

  const iq5TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ5 ? iq5ItemComponent : ''}
        <Row className="estimate-tab2-row" style={{ marginTop: 5 }}>
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={4} className="estimate-tab2-col-total" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {setupTotalTime_IQ5 ? secondsToHms(setupTotalTime_IQ5) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount_IQ5 !== undefined ? JPYs.format(Math.round(setupTotalAmount_IQ5)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {processingTotalTime_IQ5 ? secondsToHms(processingTotalTime_IQ5) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount_IQ5 !== undefined ? JPYs.format(Math.round(processingTotalAmount_IQ5)) : amtEmptyStr}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main" style={{ marginLeft: 5 }}>
            {totalTime_IQ5 ? secondsToHms(totalTime_IQ5) : timeHmsStr}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount_IQ5 !== undefined ? JPYs.format(Math.round(totalAmount_IQ5)) : amtEmptyStr}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {tanka_IQ5 !== undefined ? JPYs.format(Math.round(tanka_IQ5)) : amtEmptyStr}
          </Col>
        </Row>
        <Row className="estimate-tab2-row" style={{ marginTop: '0px' }}>
          <Col span={15}></Col>
          <Col
            span={2}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ textAlign: 'right', paddingRight: '25px', fontWeight: 'bold', marginLeft: 5 }}
          >
            材料原価
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {iq5MaterialAmount !== undefined ? activePartMenu === 'unit' ? JPYs.format(Math.round(iq5MaterialAmount) / suryou) : JPYs.format(Math.round(iq5MaterialAmount)) : amtEmptyStr}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {iq5MaterialAmount !== undefined ? JPYs.format(Math.round(iq5MaterialAmount) / suryou) : amtEmptyStr}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq5工程 */
  // let iq5Items = [
  //   {
  //     id: 1,
  //     process: `切断`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `穴`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  // ];
  // let iq5ItemComponent = [];
  // iq5Process != null
  //   ? iq5Process?.forEach((item, index) => {
  //       iq5ItemComponent.push(
  //         <Row className="estimate-tab2-row">
  //           <Col span={1} className="estimate-tab2-col">
  //             {item.key}
  //           </Col>
  //           <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
  //             {item.processNm}
  //           </Col>
  //           <Col span={2} className="estimate-tab2-col">
  //             {item.setupTime}
  //           </Col>
  //           <Col span={3} className="estimate-tab2-col">
  //             {item.setupAmount !== undefined
  //               ? '¥' +
  //                 item.setupAmount.toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : 0}
  //           </Col>
  //           <Col span={2} className="estimate-tab2-col">
  //             {item.processingTime}
  //           </Col>
  //           <Col span={3} className="estimate-tab2-col">
  //             {item.processingAmount !== undefined
  //               ? '¥' +
  //                 item.processingAmount.toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : amtEmptyStr}
  //           </Col>
  //           <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
  //             {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))}
  //           </Col>
  //           <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
  //             {item.setupAmount !== undefined
  //               ? '¥' +
  //                 (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : 0}
  //           </Col>
  //           <Col
  //             span={3}
  //             className="estimate-tab2-col estimate-tab2-col-total"
  //             style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
  //           >
  //             {item.setupAmount !== undefined
  //               ? '¥' +
  //                 (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : 0}
  //           </Col>
  //         </Row>
  //       );
  //     })
  //   : '';
  // const iq5TBL = (
  //   <>
  //     <div>
  //       {tblTile}
  //       {showDetailRowIQ5 ? iq5ItemComponent : ''}
  //       <Row className="estimate-tab2-row">
  //         <Col span={1} className="estimate-tab2-col-total"></Col>
  //         <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
  //           合計
  //         </Col>
  //         <Col span={2} className="estimate-tab2-col-total">
  //           {setupTotalTime_IQ5}
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total">
  //           {setupTotalAmount_IQ5 !== undefined
  //             ? '¥' +
  //               setupTotalAmount_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col span={2} className="estimate-tab2-col-total">
  //           {processingTotalTime_IQ5}
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total">
  //           {processingTotalAmount_IQ5 !== undefined
  //             ? '¥' +
  //               processingTotalAmount_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
  //           {totalTime_IQ5}
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
  //           {totalAmount_IQ5 !== undefined
  //             ? '¥' +
  //               totalAmount_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col
  //           span={3}
  //           className="estimate-tab2-col-total estimate-tab2-col-total-main"
  //           style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
  //         >
  //           {tanka_IQ5 !== undefined
  //             ? '¥' +
  //               tanka_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //       </Row>
  //       <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
  //         <Col span={16}></Col>
  //         <Col
  //           span={2}
  //           className="estimate-tab2-col-total estimate-tab2-col-total-main"
  //           style={{ textAlign: 'right', paddingRight: '25px', fontWeight: 'bold', marginLeft: 5 }}
  //         >
  //           材料費
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
  //           {iq5MaterialAmount !== undefined
  //             ? '¥' +
  //               iq5MaterialAmount.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col
  //           span={3}
  //           className="estimate-tab2-col-total estimate-tab2-col-total-main"
  //           style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
  //         >
  //           {iq5MaterialAmount !== undefined
  //             ? '¥' +
  //               iq5MaterialAmount.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //       </Row>
  //     </div>
  //   </>
  // );
  /* 工程別合計コンポーネント　iq7工程 */
  // let iq7Items = [
  //   {
  //     id: 1,
  //     process: `切断`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `穴`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //   },
  // ];
  // let iq7ItemComponent = [];
  // iq7Items?.forEach((item, index) => {
  //   iq7ItemComponent.push(
  //     <Row className="estimate-tab2-row">
  //       <Col className="estimate-tab2-col" style={{ width: 50 }}>
  //         {item.id}
  //       </Col>
  //       <Col className="estimate-tab2-col" style={{ textAlign: 'center' }}>
  //         {item.process}
  //       </Col>
  //       <Col className="estimate-tab2-col">{item.setupTime}</Col>
  //       <Col className="estimate-tab2-col">{item.setupAmount}</Col>
  //       <Col className="estimate-tab2-col">{item.setupTime}</Col>
  //       <Col className="estimate-tab2-col">{item.processTime}</Col>
  //       <Col className="estimate-tab2-col">{item.processAmount}</Col>
  //       <Col className="estimate-tab2-col">{item.totalTime}</Col>
  //       <Col className="estimate-tab2-col">{item.totalAmount}</Col>
  //     </Row>
  //   );
  // });
  // const iq7TBL = (
  //   <>
  //     <div>
  //       {tblTile}
  //       {showDetailRowIQ7 ? iq7ItemComponent : ''}
  //       <Row className="estimate-tab2-row">
  //         <Col className="estimate-tab2-col-total" style={{ width: 50 }}></Col>
  //         <Col className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
  //           合計
  //         </Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //       </Row>
  //     </div>
  //   </>
  // );
  /* 工程別合計コンポーネント */

  const processTotalAmountComponent = (
    <>
      <div style={{ overflowY: 'auto', marginTop: 5, height: '800px' }}>
        <Row id="totalRow" style={{ marginBottom: '5px' }}>
          <div style={{ float: 'left', width: '60%' }}>
            <Radio.Group value={activePartMenu} onChange={partMenuChange}>
              <Radio.Button value="total" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>合計</p>
              </Radio.Button>
              <Radio.Button value="unit" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>単品</p>
              </Radio.Button>
            </Radio.Group>
          </div>

          <div style={{ float: 'right', width: '40%' }}>
            <Button key="submit24" type="primary" className="csvoutput" onClick={exportCSVData}>
              CSV出力
              <CSVLink ref={csvExportRef} data={allData} filename={fileName} style={{ display: 'none' }}></CSVLink>
            </Button>
          </div>
        </Row>
        {/* <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="合計" />
        </Row> */}
        {totalRow}

        <Row id="parentTitleRow" style={{ marginTop: '13px' }} align="middle" className="estimate-tab2-tbl-thead">
          <label className="" value="親部品工程">
            親部品工程
          </label>
          {showDetailRow ? (
            <a onClick={(e) => showDetail(e, 'parent')}>
              <CaretUpOutlined className="koutebetsuDDLArrow" />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'parent')}>
              <CaretDownOutlined className="koutebetsuDDLArrow" />
            </a>
          )}
        </Row>
        {parentTBL}

        <Row id="iq3TitleRow" style={{ marginTop: 15 }} align="middle" className="estimate-tab2-tbl-thead">
          <label value="板金子部品工程">板金子部品工程</label>
          {showDetailRowIQ3 ? (
            <a onClick={(e) => showDetail(e, 'iq3')}>
              <CaretUpOutlined className="koutebetsuDDLArrow" />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq3')}>
              <CaretDownOutlined className="koutebetsuDDLArrow" />
            </a>
          )}
        </Row>
        {iq3TBL}

        <Row id="iq3TitleRow" style={{ marginTop: 15 }} align="middle" className="estimate-tab2-tbl-thead">
          <label value="板金子部品工程">形鋼子部品工程</label>
          {showDetailRowIQ5 ? (
            <a onClick={(e) => showDetail(e, 'iq5')}>
              <CaretUpOutlined className="koutebetsuDDLArrow" />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq5')}>
              <CaretDownOutlined className="koutebetsuDDLArrow" />
            </a>
          )}
        </Row>
        {iq5TBL}

        {/* <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="形鋼子部品工程" />
          {showDetailRowIQ5 ? (
            <a onClick={(e) => showDetail(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        {iq5TBL} */}
        {/* <Row id="iq7TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="機械子部品工程" />
          {showDetailRowIQ7 ? (
            <a onClick={(e) => showDetail(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        <Row id="iq7Row" style={{ marginTop: 5 }}>
          {iq7TBL}
        </Row> */}
      </div>
    </>
  );
  /* 工程別合計コンポーネント 終了*/

  return (
    <>
      <Modal
        maskClosable={false}
        title={
          <div
            style={{
              width: 1150,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>工程別合計</p>
          </div>
        }
        width={1150}
        bodyStyle={{ height: 800 }}
        style={{ top: 50 }}
        open={props.showProcessTotalAmount}
        onOk={null}
        onCancel={confirmCancel}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: 'white', fontSize: '13.5px' }} />}
        modalRender={(modal) => (
          <Draggable handle=".ant-modal-header">
            <div>{modal}</div>
          </Draggable>
        )}
      >
        {processTotalAmountComponent}
      </Modal>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
};

export default KouteibetsuInfo;
